import { DateTime } from 'luxon'

import Time from '../../modules/Time'

type DateTimeInput = DateTime | string | null | undefined

type Props = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'min' | 'max'
> & {
  dateTime?: DateTime | null
  updateDateTime: (dateTime: DateTime | null) => void
  min?: DateTime
  max?: DateTime
}

const DateTimeInput = ({ dateTime, updateDateTime, min, max, className, ...props }: Props) => {
  const value = dateTime ? Time.toDateTimeInputFormat(dateTime) : ''

  const onChange = ({ target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>) => {
    const newDateTime = newValue !== '' ? Time.fromDateTimeInputFormat(newValue) : null
    updateDateTime(newDateTime)
  }

  return (
    <input
      {...props}
      value={value}
      onChange={onChange}
      min={min && Time.toDateTimeInputFormat(min)}
      max={max && Time.toDateTimeInputFormat(max)}
      type="datetime-local"
      className={`text-sm border border-gray-300 rounded-md text-gray-700 ${className}`}
    />
  )
}

export default DateTimeInput
