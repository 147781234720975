import { Maybe } from '@/types'
import classNames from 'classnames'
import FieldError from './FieldError'

const Field = ({
  label,
  error,
  children,
  className,
  desc,
  help,
  htmlFor,
  header,
  headerContainerClassName,
}: {
  label?: Maybe<string>
  error?: Maybe<string | string[]>
  children: React.ReactNode
  className?: string
  desc?: React.ReactNode
  help?: React.ReactNode
  htmlFor?: string
  header?: React.ReactNode
  headerContainerClassName?: string
}) => (
  <div className={classNames('space-y-1', className)}>
    <div className={headerContainerClassName}>
      {label && (
        <label className="text-sm text-gray-900 block" htmlFor={htmlFor}>
          {label}
        </label>
      )}
      {header}
    </div>

    {help && <div className="text-sm text-gray-500 leading-tight">{help}</div>}
    {children}
    {desc && <div className="text-sm text-gray-500">{desc}</div>}
    <FieldError error={error ?? undefined} />
  </div>
)

export default Field
