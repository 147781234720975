import { v4 } from 'uuid'
import { IsFilter, Option } from '../../../types'

const build = ({
  id,
  fieldId,
  options = [],
  selectedOption = '',
}: {
  id?: string
  fieldId: string
  options?: Option[]
  selectedOption?: string
}): IsFilter => ({
  id: id || v4(),
  typeId: 'is',
  fieldId,
  options,
  selectedOption,
})

const toApiFilter = (filter: IsFilter) => ['is', filter.fieldId, filter.selectedOption]

export default { build, toApiFilter }
