import BaseTitleMetric from '@/gf/components/next/TitleMetric'
import { toParam } from '@/gf/modules/ReportDuration'
import { formatConversionRate, rfqsToConversionRate } from '../Performance'
import { useContext } from './context'

const TitleMetric = () => {
  const { rfqs, comparisonRfqs, fromDate, toDate, duration } = useContext()

  return (
    <BaseTitleMetric
      title="Conversion Rate"
      value={rfqs && rfqsToConversionRate(rfqs)}
      comparisonValue={comparisonRfqs && rfqsToConversionRate(comparisonRfqs)}
      valueToDisplay={(rate) => formatConversionRate(rate) || 'N/A'}
      duration={{ durationId: toParam(duration), durationStart: fromDate, durationEnd: toDate }}
    />
  )
}

export default TitleMetric
