import { CreditCardIcon, UserCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

import useMsgs from '@/gf/hooks/useMsgs'
import MoneyM from '@/gf/modules/Money'
import { Maybe, Money } from '@/types'

import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import { copyText } from '@/gf/modules/utils'

const SummaryItem = ({ text, value, className = '' }) => (
  <div className={classNames('flex gap-x-3 items-center', className)}>
    <div className="flex-grow leading-6">{text}</div>
    <div>{value}</div>
  </div>
)

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

const Summary = ({
  total,
  subtotal,
  discount,
  discountPercent,
  taxCost,
  customerFee,
  shippingCost,
  showShippingCost,
  refunded,
  // Don't send payment method unless the order has been paid
  paidWithPaymentMethod,
  className,
}: {
  total: Money
  subtotal: Money
  discount: Money
  discountPercent: Maybe<number>
  taxCost: Maybe<Money>
  customerFee: Money | null
  shippingCost: Maybe<Money>
  showShippingCost: boolean
  refunded: Money
  paidWithPaymentMethod?: 'gearflow_payments' | { accountNumber: string }
  className?: string
}) => {
  const [_, mgr] = useMsgs()

  return (
    <div className={classNames('space-y-1', className)}>
      <SummaryItem text="Subtotal" value={MoneyM.format(subtotal)} />

      {MoneyM.isPos(discount) && (
        <SummaryItem
          text={`Customer Discount (${discountPercent}%)`}
          value={MoneyM.format(MoneyM.mult(discount, -1))}
        />
      )}

      {showShippingCost && (
        <SummaryItem text="Shipping" value={MoneyM.format(shippingCost ?? zeroPrice)} />
      )}

      {taxCost && <SummaryItem text="Tax" value={MoneyM.format(taxCost)} />}
      {customerFee && <SummaryItem text="Customer Fee" value={MoneyM.format(customerFee)} />}

      <SummaryItem
        text="Total"
        value={
          <div className="inline-flex flex-row items-center gap-x-2">
            {paidWithPaymentMethod && (
              <Tooltip>
                <TooltipTrigger
                  onClick={
                    paidWithPaymentMethod !== 'gearflow_payments' &&
                    paidWithPaymentMethod.accountNumber
                      ? () => {
                          copyText(paidWithPaymentMethod.accountNumber)
                          mgr.add('Account number copied to clipboard', 'positive')
                        }
                      : undefined
                  }
                >
                  <div className="px-2 py-1 inline-flex flex-row items-center gap-x-1.5 rounded border border-gray-200 whitespace-nowrap bg-gray-100 text-xs font-medium text-gray-600">
                    {paidWithPaymentMethod === 'gearflow_payments' ? (
                      <CreditCardIcon className="-m-0.5 inline-flex shrink-0 w-4 h-4" />
                    ) : (
                      <UserCircleIcon className="-m-0.5 inline-flex shrink-0 w-4 h-4" />
                    )}

                    <div className="inline-flex">
                      {paidWithPaymentMethod === 'gearflow_payments' ? (
                        'Gearflow payments'
                      ) : (
                        <span>Pay on account</span>
                      )}
                    </div>
                  </div>
                </TooltipTrigger>

                <TooltipContent className="max-w-prose p-3 z-50 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                  {paidWithPaymentMethod === 'gearflow_payments' ? (
                    <span>The customer paid through Gearflow.</span>
                  ) : (
                    <div className="flex flex-col justify-center text-center gap-y-4">
                      <span>The customer is paying through their account with you.</span>
                      <div className="flex flex-col justify-center text-center">
                        <span>Account number: {paidWithPaymentMethod.accountNumber}</span>
                        <span className="italic text-gray-500">
                          Click to copy the account number
                        </span>
                      </div>
                    </div>
                  )}
                </TooltipContent>
              </Tooltip>
            )}

            {MoneyM.format(total)}
          </div>
        }
        className="text-lg font-medium"
      />

      {!MoneyM.isZero(refunded) && (
        <>
          <SummaryItem text="Refunded" value={MoneyM.format(MoneyM.mult(refunded, -1))} />

          <SummaryItem
            text="Net Total"
            value={MoneyM.format(MoneyM.sub(total, refunded))}
            className="text-lg font-medium"
          />
        </>
      )}
    </div>
  )
}

export default Summary
