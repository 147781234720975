import { CustomerAccountDetailsQuery, useCustomerAccountActivityQuery } from '@/dealers/_gen/gql'
import SingleMarkerMap from '@/gf/components/SingleMarkerMap'
import RequestCard from './Activity/RequestCard'

const Activity = ({
  customerAccountId,
  address,
}: {
  customerAccountId: string
  address: CustomerAccountDetailsQuery['customer']['address']
}) => {
  const { data, loading } = useCustomerAccountActivityQuery({ variables: { customerAccountId } })
  const lastRequest = data?.paginatedRequestForQuotes.entries?.[0] ?? null

  return (
    <div className="space-y-4">
      {address && address.point && (
        <SingleMarkerMap lat={address.point.lat} lng={address.point.lng} />
      )}

      {lastRequest && <RequestCard loading={loading} request={lastRequest} />}
    </div>
  )
}

export default Activity
