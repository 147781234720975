import { Address, Maybe, Point } from '../../types'

export type GoogleBusinessDetails = {
  name: Maybe<string>
  address: Address
  phoneNumber: Maybe<string>
}

const placeDetailsToAddress = (details: google.maps.places.PlaceResult) => {
  const component = (type) => details.address_components?.find((c) => type === c.types[0]) ?? null

  return {
    lineOne: `${component('street_number')?.long_name ?? ''} ${
      component('route')?.short_name ?? ''
    }`.trim(),
    lineTwo: null,
    state: component('administrative_area_level_1')?.short_name,
    city: component('locality')?.long_name ?? component('administrative_area_level_2')?.long_name,
    postalCode: [component('postal_code')?.long_name, component('postal_code_suffix')?.long_name]
      .filter((p) => !!p)
      .join('-'),
    country: component('country')?.long_name,
    firstName: '',
    lastName: '',
    companyName: '',
    latitude: details.geometry?.location?.lat() ?? null,
    longitude: details.geometry?.location?.lng() ?? null,
  } as Address
}

const autocompletePlaces = async (
  inputText: string,
  options?: { types?: string[]; country: string[] | null; location?: Point; origin?: Point }
) => {
  const autocompleteService = new google.maps.places.AutocompleteService()
  const response = await autocompleteService.getPlacePredictions({
    input: inputText,
    componentRestrictions: { country: options?.country ?? null },
    types: options?.types,
    location: options?.location
      ? new google.maps.LatLng({ lat: options.location.lat, lng: options.location.lng })
      : undefined,
    origin: options?.origin
      ? new google.maps.LatLng({ lat: options.origin.lat, lng: options.origin.lng })
      : undefined,
  })

  return response.predictions
}

const placesTextSearch = async (
  query: string,
  options?: { type?: string; region: string | null; location?: Point; radius?: number }
) => {
  const placesService = new google.maps.places.PlacesService(document.createElement('div'))
  return new Promise<google.maps.places.PlaceResult[]>((resolve, reject) => {
    placesService.textSearch(
      {
        query,
        region: options?.region,
        location: options?.location
          ? new google.maps.LatLng({ lat: options.location.lat, lng: options.location.lng })
          : undefined,
        type: options?.type,
        radius: options?.radius,
      },
      (results, status, _pagination) => {
        if (status === 'OK' && results) {
          resolve(results)
        } else {
          reject(status)
        }
      }
    )
  })
}

const getPlaceDetails = (placeId, fields) => {
  const placesService = new google.maps.places.PlacesService(document.createElement('div'))

  return new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
    placesService.getDetails({ placeId, fields }, (result, status) => {
      if (status === 'OK' && result) {
        resolve(result)
      } else {
        reject(status)
      }
    })
  })
}

const getPlaceAddress = async (placeId: string) => {
  const details = await getPlaceDetails(placeId, ['address_components', 'geometry'])

  return placeDetailsToAddress(details)
}

const getPlaceBusinessDetails = async (placeId: string): Promise<GoogleBusinessDetails> => {
  const details = await getPlaceDetails(placeId, [
    'address_components',
    'geometry',
    'formatted_phone_number',
    'name',
  ])

  return {
    name: details.name ?? null,
    address: placeDetailsToAddress(details),
    phoneNumber: details.formatted_phone_number ?? null,
  }
}

export default {
  autocompletePlaces,
  getPlaceAddress,
  getPlaceBusinessDetails,
  placesTextSearch,
  placeDetailsToAddress,
}
