import { useContext } from 'react'
import ReloadSessionContext from '../contexts/ReloadSession'

const useReloadSession = () => {
  const reloadSession = useContext(ReloadSessionContext)
  if (reloadSession === undefined) throw new Error('must be used within provider')
  return reloadSession
}

export default useReloadSession
