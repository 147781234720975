import Time from './Time'

import { Point, Vendor, VendorPayload } from '../../types'
import Address from './Address'
import Organization from './Organization'

const shortenId = (id: string) => id.split('-')[0]

const fromPayload = (payload: VendorPayload): Vendor => ({
  ...payload,
  insertedAt: Time.fromPayload(payload.insertedAt),
  address: payload?.address ? Address.fromPayload(payload.address) : undefined,
  organization: payload?.organization ? Organization.fromPayload(payload.organization) : undefined,
  brands: payload?.brands ?? [],
  location: payload?.location as Point,
})

export default {
  shortenId,
  fromPayload,
}
