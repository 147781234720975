import { useFetchAdditionalChargeQuery } from '../_gen/gql'
import useGqlClient from './useGqlClient'

const useAdditionalCharge = (id: string) => {
  const { refetch, loading, error, data } = useFetchAdditionalChargeQuery({
    variables: { id },
    fetchPolicy: 'no-cache',
    client: useGqlClient(),
  })

  return { refetch, loading, error, additionalCharge: data && data.fetchAdditionalCharge }
}

export type AdditionalCharge = NonNullable<
  ReturnType<typeof useAdditionalCharge>['additionalCharge']
>
export default useAdditionalCharge
