import { useSetCustomerDiscountMutation } from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import QuantityInput from '@/gf/components/inputs/Quantity'
import { useState } from 'react'

const UpdateDiscountForm = ({
  customerAccountId,
  initialValue,
  onAfterSave,
}: {
  customerAccountId: string
  initialValue: number
  onAfterSave: () => void
}) => {
  const [setDiscount, { loading: saveInProgress }] = useSetCustomerDiscountMutation()
  const [value, setValue] = useState(initialValue)

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    e.stopPropagation()

    await setDiscount({ variables: { customerAccountId, discount: value } })

    onAfterSave()
  }

  return (
    <form className="mt-2 space-y-2" onSubmit={onSubmit}>
      <Field label="Discount (%)">
        <QuantityInput
          value={value}
          onChange={(e) => setValue(e.target.valueAsNumber)}
          min={1}
          max={99}
        />
      </Field>

      <Action.P type="submit" performing={saveInProgress}>
        Save
      </Action.P>
    </form>
  )
}

export default UpdateDiscountForm
