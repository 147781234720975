import { ReactNode } from 'react'
import RedAlert from '@/gf/components/RedAlert'
import { LightBulbIcon } from '@heroicons/react/outline'

const ChartBreakdownLayout = ({
  error = false,
  titleMetric,
  durationInput = null,
  insights,
  scorecards,
  chart,
  breakdownTable = null,
  historyTable = null,
}: {
  error?: boolean
  titleMetric?: ReactNode
  durationInput?: ReactNode
  insights?: ReactNode
  scorecards?: ReactNode
  chart: ReactNode
  breakdownTable?: ReactNode
  historyTable?: ReactNode
}) => (
  <div className="flex flex-col gap-y-8">
    {error ? (
      <RedAlert className="border border-red-200" title="Error, please contact support." />
    ) : (
      <>
        {insights && (
          <div className="flex flex-col gap-y-2 items-center">
            <div className="flex flex-row gap-x-2 items-center">
              <LightBulbIcon className="w-6 h-6 text-gray-700" />
              <span className="text-xl text-gray-900">Insights</span>
            </div>
            <div className="w-full flex flex-row gap-x-2 justify-center">{insights}</div>
          </div>
        )}

        <div className="flex flex-col gap-y-6">
          {scorecards && <div className="flex flex-row gap-x-4">{scorecards}</div>}
          <div className="flex flex-row flex-wrap justify-between items-start gap-x-4">
            {titleMetric}
            {durationInput}
          </div>
        </div>

        <div className="flex flex-row gap-x-6">
          <div className="w-[calc(100%-24rem-1.5rem)] h-96">{chart}</div>
          <div className="w-96 max-w-96 h-auto max-h-96 flex shrink-0">{breakdownTable}</div>
        </div>

        {historyTable}
      </>
    )}
  </div>
)

export default ChartBreakdownLayout
