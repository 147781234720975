import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useCloseQuoteMutation } from '@/dealers/_gen/gql'
import useMsgs from '@/gf/hooks/useMsgs'

import Action from '@/gf/components/Action'
import Form from '@/gf/components/Form'
import Modal from '@/gf/components/ModalNext'
import SelectField from '@/gf/components/SelectField'
import TextAreaField from '@/gf/components/TextAreaField'

const reasons = [
  'Located elsewhere',
  'Duplicate request',
  'No longer needed',
  'Pricing too high',
  'Backordered item',
  'Customer unresponsive',
  'Other',
]

const use = ({ storeOrderId, onClosed }: { storeOrderId: string; onClosed: () => void }) => {
  const [_, msgr] = useMsgs()
  const [submitting, setSubmitting] = useState(false)
  const { rfqId } = useParams() as { rfqId: string }
  const [fields, setFields] = useState({ reason: reasons[0], details: '' })
  const [closeQuoteMutation] = useCloseQuoteMutation()
  const updateFields = (updates: Partial<typeof fields>) => setFields({ ...fields, ...updates })

  const submit = () => {
    setSubmitting(true)
    const details = fields.reason === 'Other' ? fields.details : null

    closeQuoteMutation({
      variables: { storeOrderId, rfqId, reason: fields.reason, details },
    })
      .then(() => {
        msgr.add('Quote closed.', 'positive')
        onClosed()
      })
      .finally(() => setSubmitting(false))
  }

  return { fields, submitting, updateFields, submit }
}

const CloseQuote = ({ storeOrderId, onClosed }: { storeOrderId: string; onClosed: () => void }) => {
  const { rfqId } = useParams() as { rfqId: string }
  const navigate = useNavigate()
  const close = () => navigate(`/rfqs/${rfqId}`)

  const { fields, submitting, updateFields, submit } = use({
    storeOrderId,
    onClosed: () => {
      close()
      onClosed()
    },
  })

  return (
    <Modal open onClose={close}>
      <Form onSubmit={submit} className="p-6 space-y-4">
        <div className="text-xl">Close Quote</div>

        <p className="text-base">
          This will cancel the request in your dashboard. The customer will no longer be able to
          approve this quote.
        </p>

        <SelectField
          label="Reason"
          options={reasons.map((r) => ({ id: r, display: r }))}
          currentId={fields.reason}
          onChange={(reason) => updateFields({ reason })}
        />

        {fields.reason === 'Other' && (
          <TextAreaField
            label="What is the reason for closing this quote?"
            value={fields.details}
            onChange={({ target }) => updateFields({ details: target.value })}
            required
          />
        )}

        <div className="flex gap-2 flex-row-reverse">
          <Action.P type="submit" performing={submitting}>
            Close Quote
          </Action.P>

          <Action.S onClick={close}>Leave Open</Action.S>
        </div>
      </Form>
    </Modal>
  )
}

export default CloseQuote
