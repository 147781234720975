import classNames from 'classnames'

const Bullet = ({ className, size = 'md' }: { className?: string; size?: 'sm' | 'md' }) => (
  <span
    className={classNames('inline-block rounded-full', className, {
      'h-4 w-4': size === 'md',
      'h-2.5 w-2.5': size === 'sm',
    })}
  />
)

export default Bullet
