import { Fragment, ReactNode, RefObject } from 'react'
import { XIcon } from '@heroicons/react/solid'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'

const Layout = ({ children }: { children: ReactNode }) => (
  <div className="relative flex-1 py-6 px-4 sm:px-6">{children}</div>
)

const FixedBottomElement = ({
  className,
  show,
  children,
}: {
  className?: string | undefined
  show: boolean | undefined
  children: ReactNode
}) => (
  <Transition
    className={classNames(
      'fixed bottom-0 right-0 max-w-2xl border-t drop-shadow-lg bg-white',
      className
    )}
    show={show}
    enter="transition-opacity duration-110"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-110"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
)

type SlideOverSize = 'sm' | 'md' | 'lg'
type Theme = 'blue' | 'white'

const SlideOver = ({
  title,
  titleIcon,
  subtitle,
  open,
  onClose,
  children,
  scrollDivRef,
  size = 'md',
  theme = 'blue',
}: {
  title: string
  titleIcon?: JSX.Element
  subtitle?: string
  open: boolean
  onClose: () => void
  children: ReactNode
  scrollDivRef?: RefObject<HTMLDivElement>
  size?: SlideOverSize
  theme?: Theme
}) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-400"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 md:pl-8 lg:pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-400 sm:duration-600"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-400 sm:duration-600"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel
                className={classNames('pointer-events-auto w-screen', {
                  'max-w-96': size === 'sm',
                  'max-w-2xl': size === 'md',
                  'max-w-3xl': size === 'lg',
                })}
              >
                <div
                  ref={scrollDivRef}
                  className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                >
                  <div
                    className={classNames('py-5 px-4 sm:px-6 border-b', {
                      'bg-blue-600 border-blue-600': theme === 'blue',
                    })}
                  >
                    <div className="flex items-center justify-between">
                      <Dialog.Title
                        className={classNames('text-lg font-medium flex items-center gap-x-2', {
                          'text-white': theme === 'blue',
                          'text-gray-900': theme === 'white',
                        })}
                      >
                        {titleIcon} {title}
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className={classNames(
                            'rounded-md focus:outline-none focus:ring-2 focus:ring-white',
                            {
                              'bg-blue-600 text-blue-200 hover:text-white': theme === 'blue',
                              'bg-transparent text-gray-900 hover:text-gray-700': theme === 'white',
                            }
                          )}
                          onClick={() => onClose()}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    {subtitle && (
                      <div className="mt-1">
                        <p className="text-sm text-blue-200">{subtitle}</p>
                      </div>
                    )}
                  </div>
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
)

SlideOver.Layout = Layout
SlideOver.FixedBottomElement = FixedBottomElement
export default SlideOver
