import { useUpdateStoreLogoMutation } from '@/dealers/_gen/gql'
import useReloadSession from '@/dealers/hooks/useReloadSession'
import Action from '@/gf/components/Action'
import Modal from '@/gf/components/ModalNext'
import UppyFileInput from '@/gf/components/UppyFileInput'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import useUppy from '@/gf/hooks/useUppy'
import { PhotographIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import constants from './constants'

interface File {
  id: string
  name: string
  extension: string
  type?: string
  data: string
}

const ChangeLogoModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const [_, msgr] = useMsgs()
  const reloadSession = useReloadSession()
  const [updateLogo] = useUpdateStoreLogoMutation()
  const [uploadFile, setUploadFile] = useState<File>()
  const [uploading, uploadingToggle] = useToggle()

  const changeStoreLogo = async (url) => {
    await updateLogo({ variables: { url } })

    reloadSession()

    reset()

    uploadingToggle.off()

    onClose()

    msgr.add('Store logo updated.', 'positive')
  }

  const onError = (error) => {
    uploadingToggle.off()
    msgr.add(error, 'negative')
  }

  const uppy = useUppy({
    autoProceed: false,
    onFilesAdded: ([file]) => {
      const reader = new FileReader()
      reader.onloadend = (e) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          const fileWithData: File = {
            id: file.id,
            name: file.name,
            extension: file.extension,
            type: file.type,
            data: e.target.result,
          }
          setUploadFile(fileWithData)
        }
      }
      reader.readAsDataURL(file.data)
    },
    allowedFileTypes: constants.ALLOWED_FILE_TYPES,
    onComplete: ([file]) => {
      changeStoreLogo(file.url)
    },
    maxNumberOfFiles: 1,
  })

  const reset = () => {
    uppy.cancelAll()
    setUploadFile(undefined)
  }

  const startUpload = () => {
    uploadingToggle.on()
    uppy.upload()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className="px-6 py-4 w-full">
        <h2 className="text-lg">Change Store Logo</h2>

        {uploadFile && (
          <div className="space-y-4 mt-4">
            <div className="inline-block rounded-lg overflow-hidden">
              <img src={uploadFile.data} alt={uploadFile.name} />
            </div>

            <div className="flex justify-end gap-x-4">
              <Action.S type="button" onClick={reset}>
                Discard
              </Action.S>
              <Action.P type="button" onClick={startUpload} performing={uploading} className="w-44">
                Save
              </Action.P>
            </div>
          </div>
        )}

        {!uploadFile && (
          <>
            <div className="rounded-lg border  border-slate-200 p-2 mt-4 border-dashed">
              <div className="flex gap-x-4 items-center">
                <PhotographIcon className="w-16 inline-block text-slate-300" />
                <div className="space-y-1">
                  <p className="font-medium text-slate-500">Select a file</p>
                  <p className="text-sm italic text-slate-400 max-w-prose">
                    Select a file below and click <span className="font-medium">Save</span> to
                    change your store logo.
                  </p>
                </div>
              </div>
              <div className="pl-20">
                <UppyFileInput
                  uppy={uppy}
                  onFileInputError={onError}
                  allowedFileTypes={constants.ALLOWED_FILE_TYPES}
                  className="max-w-72"
                />
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <Action.S onClick={onClose}>Cancel</Action.S>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ChangeLogoModal
