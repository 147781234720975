import { SortOrder } from '@/dealers/_gen/gql'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import MoneyM from '@/gf/modules/Money'
import { Money } from '@/types'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import { useContext } from '../Context'

const Part = () => {
  const context = useContext()
  const { storeOrders, breakdownPartSort, update } = context
  const sortField = breakdownPartSort ? breakdownPartSort[0] : 'total'
  const sortOrder = breakdownPartSort ? (breakdownPartSort[1] === 'desc' ? 'desc' : 'asc') : 'desc'

  const storeOrdersByMpn = storeOrders?.reduce<
    Record<string, { storeOrderCount: number; total: Money }>
  >((acc, storeOrder) => {
    const lineItemsByMpn = groupBy(storeOrder.lineItems, (li) => li.product.mpn)

    return reduce(
      lineItemsByMpn,
      (subAcc, lineItems, mpn) => {
        const existingRow = mpn in acc ? acc[mpn] : null
        const total = lineItems.map((li) => li.extendedPrice).reduce(MoneyM.add)

        const row = existingRow
          ? {
              storeOrderCount: existingRow.storeOrderCount + 1,
              total: MoneyM.add(existingRow.total, total),
            }
          : { storeOrderCount: 1, total }

        return { ...subAcc, [mpn]: row }
      },
      acc
    )
  }, {})

  const unsortedRows = storeOrdersByMpn && map(storeOrdersByMpn, (row, mpn) => ({ ...row, mpn }))

  const orderByField = (() => {
    if (sortField === 'part') return 'mpn'
    if (sortField === 'orders') return 'storeOrderCount'
    if (sortField === 'total') return 'total.amount'
    return sortField
  })()

  const rows = unsortedRows && orderBy(unsortedRows, orderByField, sortOrder)

  return (
    <ReportingTable
      data={rows}
      getRowKey={(row) => row.mpn}
      checkbox={{
        getChecked: ({ mpn }) => mpn === context.mpn,
        onToggleRow: ({ mpn }) => update({ mpn: mpn !== context.mpn ? mpn : null }),
        onClear: () => update({ mpn: null }),
      }}
      sortBy={{
        sortBy: {
          field: sortField,
          order: sortOrder === 'desc' ? SortOrder.Desc : SortOrder.Asc,
        },
        setSortBy: (sortBy) => update({ breakdownPartSort: sortBy }),
      }}
      columns={[
        { header: 'Part', getValue: (row) => row.mpn, sortByField: 'part' },
        { header: 'Orders', getValue: (row) => row.storeOrderCount, sortByField: 'orders' },
        {
          header: 'Total',
          getValue: (row) => MoneyM.format(row.total, { maximumFractionDigits: 0 }),
          sortByField: 'total',
        },
      ]}
    />
  )
}

export default Part
