import { useNavigate, useParams } from 'react-router-dom'

import { useOrderContext } from '../context'

import ReportProblemModal from '@/buyers/pages/StoreOrder/Order/ReportProblemModal'

const ReportProblem = () => {
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const { shipmentId } = useParams()
  const navigate = useNavigate()

  return (
    <ReportProblemModal
      storeOrderId={storeOrder.id}
      shipmentId={shipmentId}
      onSuccess={refetchStoreOrder}
      close={() => navigate(`/orders/${storeOrder.id}`)}
    />
  )
}

export default ReportProblem
