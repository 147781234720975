import { useParticipateMutation } from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import Action from '@/gf/components/Action'
import { useRfqContext } from '../context'

const ParticipateAction = () => {
  const { rfqId, refetch } = useRfqContext()
  const { storeId } = useSession()
  const [mutation] = useParticipateMutation()
  const participate = () => mutation({ variables: { storeId, rfqId } }).then(() => refetch())

  return (
    <div className="space-y-2">
      <p className="prose italic text-sm">Change your mind?</p>
      <Action.S onClick={participate}>Participate &amp; Create Quote</Action.S>
    </div>
  )
}

export default ParticipateAction
