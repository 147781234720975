import {
  StripeAccountCapabilityStatus,
  useSetupCompletedMutation,
  useSetupGearflowSupplierNetworkQuery,
} from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import Action from '@/gf/components/Action'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Frame from './Frame'
import locations from './GearflowSupplierNetwork/locations.png'
import payments from './GearflowSupplierNetwork/payments.png'
import wallet from './GearflowSupplierNetwork/wallet.png'

const GearflowSupplierNetwork = () => {
  const navigate = useNavigate()
  const { storeId } = useSession()
  const { store } = useSetupGearflowSupplierNetworkQuery({ variables: { storeId } }).data || {}
  const [setupCompleted] = useSetupCompletedMutation()
  const [savingAndExiting, setSavingAndExiting] = useState(false)

  useEffect(() => {
    if (store?.stripeAccount?.capabilities.transfers === StripeAccountCapabilityStatus.Active)
      navigate('/dashboard/setup-complete')
  }, [!store])

  const saveAndExit = async () => {
    setSavingAndExiting(true)
    await setupCompleted({ variables: { storeId } })
    navigate('/dashboard')
    setSavingAndExiting(false)
  }

  return (
    <Frame
      className="space-y-6 max-w-4xl flex flex-col items-center px-6"
      progress={{ name: <>Gearflow Supplier Network</>, mins: 5, className: 'w-2/5' }}
      savingAndExiting={savingAndExiting}
      saveAndExit={saveAndExit}
      back={() => navigate('/setup/company-details')}
    >
      <div className="space-y-4 max-w-md">
        <h2 className="text-2xl font-medium">Join the Gearflow Supplier Network</h2>

        <p className="prose">
          Start receiving requests from customers across the country needing the parts you sell.
          Gearflow handles the entire process - from initial quote to automatic deposits in your
          account.
        </p>
      </div>

      <div className="flex gap-4 items-center">
        <Action.S onClick={saveAndExit} performing={savingAndExiting} size="lg">
          Maybe Later
        </Action.S>

        <Action.P onClick={() => navigate('/setup/territory-restrictions')} color="blue" size="lg">
          Begin Setup
        </Action.P>
      </div>

      <div className="grid grid-cols-2 gap-4 text-center text-lg text-medium sm:grid-cols-3 md:gap-8">
        <div className="space-y-2">
          <img src={locations} alt="map" />
          <div>Win new business across the country.</div>
        </div>

        <div className="space-y-2">
          <img src={payments} alt="deposit" />
          <div>Automatic deposits once your order is fulfilled.</div>
        </div>

        <div className="space-y-2">
          <img src={wallet} alt="wallet" />
          <div>Easily view and withdraw earnings from your wallet.</div>
        </div>
      </div>
    </Frame>
  )
}

export default GearflowSupplierNetwork
