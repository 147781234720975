import { Shipment, ShipmentLabel, ShipmentLabelPayload, ShipmentPayload } from '../../types'
import Money from './Money'

import Time from './Time'

const Label = {
  fromPayload: (payload: ShipmentLabelPayload): ShipmentLabel => ({
    ...payload,
    cost: Money.fromPayload(payload.cost),
    shipDate: Time.fromPayload(payload.shipDate),
  }),
}

const fromPayload = (payload: ShipmentPayload): Shipment => ({
  ...payload,
  shippedAt: payload.shippedAt ? Time.fromPayload(payload.shippedAt) : undefined,
  receivedAt: payload.receivedAt ? Time.fromPayload(payload.receivedAt) : undefined,
  label: payload.label ? Label.fromPayload(payload.label) : undefined,
})

export default {
  fromPayload,
}
