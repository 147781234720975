import { useCreateCreditMemoMutation, useSearchCreditMemosQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import QueryString from '@/buyers/modules/QueryString'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import LinkNext from '@/gf/components/Link'
import Modal from '@/gf/components/ModalNext'
import Field from '@/gf/components/next/forms/Field'
import PriceInput from '@/gf/components/next/forms/PriceInput'
import TextArea from '@/gf/components/next/forms/TextArea'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import RedAlert from '@/gf/components/RedAlert'
import useMsgs from '@/gf/hooks/useMsgs'
import * as GE from '@/gf/modules/GrammarEvents'
import MoneyM from '@/gf/modules/Money'
import { ModalSize, Money } from '@/types'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

type FormValues = {
  amount: number
  description: string
}

const validationSchema = (maxAmount: number) =>
  Yup.object().shape({
    amount: Yup.number()
      .required('Credit memo amount is required')
      .max(
        maxAmount,
        ({ max }) =>
          `Credit memo should not surpass the order total: ${MoneyM.format(
            MoneyM.fromDecimal(max, 'USD')
          )}`
      )
      .min(0, 'Credit memo should be greater than $0.00'),

    description: Yup.string().optional(),
  })

const LogCreditMemoModal = ({
  storeOrder: { id, total },
}: {
  storeOrder: { total: Money; id: string }
}) => {
  const [_, msgs] = useMsgs()
  const client = useGqlClient()
  const navigate = useNavigate()
  const [createCreditMemo, { loading: saving }] = useCreateCreditMemoMutation({ client })
  const { data: creditMemoData, error: creditMemoError } = useSearchCreditMemosQuery({
    client,
    variables: {
      filter: JSON.stringify(['store_order_id_eq', id]),
    },
  })

  const form = useForm<FormValues>({
    defaultValues: {
      amount: undefined,
      description: '',
    },
    resolver: yupResolver(validationSchema(MoneyM.toDecimal(total))),
  })

  const onClose = () => navigate(`/orders/${id}`)

  const onSubmit = async (values: FormValues) => {
    try {
      const { data } = await createCreditMemo({
        variables: {
          storeOrderId: id,
          description: values.description,
          amount: values.amount,
        },
      })

      msgs.addWithAction('Credit Memo recorded', {
        text: 'View Memo',
        // TODO: add filter for the store order id
        callback: () => navigate(`/invoices${QueryString.invoicesStoreOrderId(id)}`),
      })

      if (data) {
        GE.createsCreditMemo(id, data.createCreditMemo)
      }

      onClose()
    } catch (err) {
      msgs.addUnknownError()
    }
  }

  return (
    <Modal open onClose={onClose} size={ModalSize.SM}>
      <div className="relative p-6 flex flex-col gap-y-6">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />
        <hgroup>
          <h2 className="text-2xl font-medium">Log Credit Memo</h2>
          <p>Record a credit memo for your records of this order.</p>
        </hgroup>
        {creditMemoError ? (
          <RedAlert title="Error fetching previous credit memos for this order" />
        ) : creditMemoData && creditMemoData.searchCreditMemos.length > 0 ? (
          <div className="px-3 py-2 flex flex-col gap-y-2 rounded bg-gray-100">
            <span className="text-base font-medium text-gray-600">
              Previous credit memos for this order
            </span>
            <div className="px-1 flex flex-col gap-y-0.5 text-base text-gray-900">
              {creditMemoData.searchCreditMemos.map((creditMemo) => (
                <div key={creditMemo.id} className="inline-flex items-center gap-x-2">
                  <span>({MoneyM.format(MoneyM.abs(creditMemo.amount))})</span>
                  {creditMemo.description && (
                    <Tooltip>
                      <TooltipTrigger>
                        <InformationCircleIcon className="w-6 h-6 inline-flex shrink-0 text-gray-600" />
                      </TooltipTrigger>
                      <TooltipContent className="max-w-prose p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900 whitespace-pre-wrap">
                        {creditMemo.description}
                      </TooltipContent>
                    </Tooltip>
                  )}
                  <LinkNext.T
                    className="ml-2"
                    to={`/invoices${QueryString.invoicesStoreOrderId(id)}`}
                  >
                    View Memo
                  </LinkNext.T>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Controller
            control={form.control}
            name="amount"
            render={({ field }) => (
              <Field label="Amount" error={form.formState.errors.amount?.message}>
                <div className="flex items-center gap-x-4">
                  <PriceInput
                    value={field.value ? MoneyM.fromDecimal(field.value, 'USD') : null}
                    setValue={(value) => field.onChange(value ? MoneyM.toDecimal(value) : null)}
                  />
                  <span className="font-medium flex-shrink-0">of {MoneyM.format(total)}</span>
                </div>
              </Field>
            )}
          />

          <TextArea
            {...form.register('description')}
            placeholder="(optional) Add a description here"
            rows={4}
            maxLength={255}
            className="mt-4"
          />

          <div className="flex gap-x-4 mt-6">
            <Action.S onClick={onClose} className="w-1/2" disabled={saving}>
              Cancel
            </Action.S>

            <Action.P type="submit" color="blue" className="w-1/2" performing={saving}>
              Save
            </Action.P>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default LogCreditMemoModal
