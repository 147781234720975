import { useClaimAccountMutation } from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import ClaimAccountModal from '@/gf/components/ClaimAccount/Modal'
import { App } from '@/retail/_gen/gql'
import { CheckCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import pluralize from 'pluralize'

const QuoteSubmittedClaimAccountModal = ({
  open,
  onClose,
  onAccountClaimed,
  rfqCreatedAt,
}: {
  open: boolean
  onClose: () => void
  onAccountClaimed: () => void
  rfqCreatedAt: DateTime
}) => {
  const { user } = useSession()
  const [claimAccountMutation] = useClaimAccountMutation()

  const quotePerformance = (() => {
    const now = DateTime.now()
    const timeToQuoteMin = Math.floor(now.diff(rfqCreatedAt, 'minutes').minutes)
    if (timeToQuoteMin <= 5) return { timeToQuote: timeToQuoteMin, group: '5%' }
    if (timeToQuoteMin <= 15) return { timeToQuote: timeToQuoteMin, group: '15%' }
    if (timeToQuoteMin <= 30) return { timeToQuote: timeToQuoteMin, group: '25%' }
    if (timeToQuoteMin <= 60) return { timeToQuote: timeToQuoteMin, group: '50%' }
    return null
  })()

  return (
    <ClaimAccountModal
      open={open}
      onClose={onClose}
      onSuccess={onAccountClaimed}
      email={user.email}
      claimAccount={(values) => claimAccountMutation({ variables: values })}
      customHeader={
        <header
          className={classNames(
            'bg-green-100 flex justify-center items-center relative mb-4',
            quotePerformance ? 'h-52' : 'h-40'
          )}
        >
          <div className="flex flex-col items-center gap-y-2 text-green-700">
            <h3 className="text-3xl font-medium flex gap-x-3">
              <CheckCircleIcon className="inline-block h-8 w-8 mt-0.5" /> Quote Submitted!
            </h3>

            {quotePerformance && (
              <p className="text-lg text-center max-w-96">
                You completed the request in{' '}
                <span className="font-medium">
                  {quotePerformance.timeToQuote < 1
                    ? 'less than 1 minute'
                    : `${quotePerformance.timeToQuote} ${pluralize(
                        'minute',
                        quotePerformance.timeToQuote
                      )}`}
                </span>{' '}
                which is in the <span className="font-medium">top {quotePerformance.group}</span> of
                vendors. Well done!
              </p>
            )}
          </div>
        </header>
      }
      cancelButtonEnabled
      app={App.SalesHub}
    />
  )
}

export default QuoteSubmittedClaimAccountModal
