import StoreOrderM from '@/gf/modules/StoreOrder'
import { StoreOrderState } from '../_gen/gql'

const canDisplayCustomerContactInfo = (storeOrder: {
  user: { isCustomerContact: boolean }
  state: StoreOrderState
}) => {
  if (storeOrder.user.isCustomerContact) {
    return true
  }

  return StoreOrderM.isApprovedBySupplier({ state: storeOrder.state })
}

export default { ...StoreOrderM, canDisplayCustomerContactInfo }
