import { Category } from '@/dealers/_gen/gql'
import CategorySelect from '@/gf/components/CategorySelect'
import { XIcon } from '@heroicons/react/solid'
import keyBy from 'lodash/keyBy'
import { useState } from 'react'

const Categories = ({
  categories,
  categoryIds,
  setCategoryIds,
}: {
  categories: Pick<Category, 'id' | 'parentId' | 'name'>[] | undefined
  categoryIds: string[]
  setCategoryIds: (catIds: string[]) => void
}) => {
  const [categoryId, setCategoryId] = useState<string | null>(null)
  const categoryById = keyBy(categories, 'id')

  const display = (id: string) => {
    if (!categoryById) return '&hellip;'

    const category = categoryById[id]
    if (!category.parentId) return category.name
    return [display(category.parentId), category.name].join(' / ')
  }

  const add = (catId: string) => {
    setCategoryId(null)
    setCategoryIds([...new Set([...categoryIds, catId])])
  }

  const remove = (catId: string) => setCategoryIds(categoryIds.filter((cid) => cid !== catId))

  return (
    <div className="border-1 border-gray-300 rounded-md">
      <div className="py-3 px-4 border-b-1 border-gray-300">
        {categoryIds.length > 0 ? (
          <div className="space-y-1">
            {categoryIds.map((id) => (
              <div className="text-sm flex justify-between items-center gap-2" key={id}>
                <div>{display(id)}</div>

                <button
                  onClick={() => remove(id)}
                  type="button"
                  className="text-gray-300 hover:text-red-500"
                >
                  <XIcon className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-sm text-gray-400 italic">None added.</div>
        )}
      </div>

      <div className="p-0.5">
        <CategorySelect
          blur
          placeholder="Select&hellip;"
          selectedId=""
          onSelect={(catId) => setCategoryId(categoryId !== catId ? catId : null)}
          categories={categories}
          onAdd={add}
          className="border-0 shadow-none"
        />
      </div>
    </div>
  )
}

export default Categories
