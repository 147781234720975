import { RequestForQuoteStep as Step } from '@/buyers/_gen/gql'

import Badge from '@/gf/components/Badge'

type Props = Parameters<typeof Badge>[0]

const propsByStep: Record<Step, Props> = {
  [Step.Inbound]: {
    color: 'bg-yellow-200',
    textColor: 'text-yellow-900',
    title: 'Inbound',
  },
  [Step.Submitted]: {
    color: 'bg-blue-200',
    textColor: 'text-blue-900',
    title: 'Submitted',
  },
  [Step.Quoted]: {
    color: 'bg-orange-200',
    textColor: 'text-orange-900',
    title: 'Quoted',
  },
  [Step.PoSent]: {
    color: 'bg-gray-200',
    textColor: 'text-gray-900',
    title: 'PO Sent',
  },
  [Step.Fulfilling]: {
    color: 'bg-green-200',
    textColor: 'text-green-900',
    title: 'Fulfilling',
  },
  [Step.Fulfilled]: {
    color: 'bg-green-600',
    textColor: 'text-white',
    title: 'Fulfilled',
  },
  [Step.Canceled]: {
    color: 'bg-red-400',
    textColor: 'text-white',
    title: 'Canceled',
  },
}

const RfqStepBadge = ({ step }: { step: Step }) => <Badge {...propsByStep[step]} />

export default RfqStepBadge
