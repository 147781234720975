import Action from '@/gf/components/Action'
import Form from '@/gf/components/Form'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { PencilIcon } from '@heroicons/react/solid'
import { H2, Section } from '../WorkspaceSettings'
import Table from './ShippingRates/Table'
import useForm from './ShippingRates/useForm'

const ShippingRates = () => {
  const form = useForm()
  const { editing, error, cannotSave, saving, startEditing, cancel, save } = form

  return (
    <Section>
      <div className="flex gap-8 items-center">
        <H2>Shipping Table Rates</H2>

        {!editing && (
          <Action.T onClick={startEditing}>
            <div className="flex gap-1 items-center text-sm">
              <PencilIcon className="w-4 h-4" />
              Edit
            </div>
          </Action.T>
        )}
      </div>

      <div className="flex">
        <Form onSubmit={save} className="space-y-6">
          {error && (
            <div className="flex gap-2 items-center bg-gray-50 text-red-500 px-4 py-2">
              <ExclamationCircleIcon className="w-5 h-5" />
              Make sure subtotals are increasing in price.
            </div>
          )}

          <Table {...form} />

          {editing && (
            <div className="flex gap-2 justify-end">
              <Action.S onClick={cancel}>Cancel</Action.S>

              <Action.P performing={saving} disabled={cannotSave} type="submit">
                Save
              </Action.P>
            </div>
          )}
        </Form>
      </div>
    </Section>
  )
}

export default ShippingRates
