const InlineAddress = ({ lineOne, city, state, postalCode }) => (
  <span className="inline-flex flex-col">
    <span className="text-sm">{lineOne}</span>
    <span className="text-xs text-slate-400">
      {[city, state].filter((p) => !!p).join(', ')} {postalCode}
    </span>
  </span>
)

export default InlineAddress
