import { SelectedPaymentMethod, useApproveQuoteMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import { useState } from 'react'
import { useOrderContext } from '../context'

const useApproveForm = ({
  selectedPaymentMethod,
  onApproved,
}: {
  selectedPaymentMethod: SelectedPaymentMethod | null | undefined
  onApproved: (paymentMethod: SelectedPaymentMethod) => void
}) => {
  const [_, msgr] = useMsgs()
  const { storeOrder, org, user } = useOrderContext()
  const [approving, setApproving] = useState(false)
  const [purchaseOrder, setPurchaseOrder] = useState('')
  const [errors, setErrors] = useState<{ purchaseOrder: string | null }>({ purchaseOrder: null })
  const [approveQuote] = useApproveQuoteMutation({ client: useGqlClient() })

  const validate = () =>
    new Promise<void>((resolve, reject) => {
      if (org.showPurchaseOrder && !org.generatePurchaseOrderNumber && !purchaseOrder) {
        setErrors({ purchaseOrder: 'Required' })
        reject()
      } else {
        resolve()
      }
    })

  const approve = (args?: { useTerms?: boolean }) => {
    if (!selectedPaymentMethod) return // shouldn't happen; button is disabled
    setApproving(true)
    const useTerms = args?.useTerms === undefined ? false : args?.useTerms

    approveQuote({
      variables: {
        storeOrderId: storeOrder.id,
        useTerms,
        autoClose: !user.claimed,
        purchaseOrder,
        paymentMethod: selectedPaymentMethod,
      },
    })
      .then(() => {
        msgr.add('Quote accepted.', 'positive')
        onApproved(selectedPaymentMethod)
      })
      .catch(() => msgr.addUnknownError())
      .finally(() => setApproving(false))
  }

  return { purchaseOrder, approving, errors, setPurchaseOrder, validate, approve }
}

export default useApproveForm
