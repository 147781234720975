import classNames from 'classnames'
import { useMemo } from 'react'

const UserInitials = ({
  name,
  className,
  size = 'md',
}: {
  name: string
  size: 'sm' | 'md'
  className?: string
}) => {
  const initials = useMemo(() => {
    const parts = name.trim().split(' ')
    if (parts.length === 1) {
      return parts[0][0]
    }

    return parts[0][0] + parts[parts.length - 1][0]
  }, [name])
  return (
    <span
      className={classNames(
        'rounded-full bg-blue-100 border border-blue-200 text-blue-700 uppercase font-medium flex items-center justify-center flex-shrink-0',
        size === 'sm' && 'h-[1.875rem] w-[1.875rem] text-sm',
        size === 'md' && 'h-9 w-9 text-lg',
        className
      )}
    >
      {initials}
    </span>
  )
}

export default UserInitials
