import classNames from 'classnames'

const Section = ({
  title,
  subtitle = null,
  className,
  children,
}: {
  title: React.ReactNode
  subtitle?: React.ReactNode
  children: React.ReactNode
  className?: string
}) => {
  const subtitleIsString = typeof subtitle === 'string'
  return (
    <div className={classNames('flex gap-x-4', className)}>
      <div className="w-64 flex-shrink-0">
        <h3 className="font-medium">{title}</h3>

        {subtitle && (
          <div className="mt-2 leading-4 text-slate-500 text-sm">
            {subtitleIsString ? <p>{subtitle}</p> : subtitle}
          </div>
        )}
      </div>
      <div className="space-y-4 flex-grow">{children}</div>
    </div>
  )
}

export default Section
