import classNames from 'classnames'
import { Link } from 'react-router-dom'

import Button from './ButtonOld'

import { Action } from '../../types'
import YellowAlert from './YellowAlert'

/**
 * Page Component
 * --------------
 * Following component structure I'd set up for LiveView
 * Page sets the title of the page, the width of the space
 * and any page level call to actions. It also sets the child
 * contents to a 6 column width grid.
 *
 * --------------
 * title - the page title
 * titleMetadata - things like badges, etc that go next to the title
 * actions - list of actions
 * width - the size of the view, default full, unless "narrow" is given
 */

const Page = ({
  title,
  subtitle,
  actions: actionsOld = [],
  actionsNext = [],
  width,
  titleMetadata,
  titleExtra,
  titleNavBack,
  titleRightContent,
  previousPage,
  yellowAlert,
  yellowAlertChildren,
  className,
  children,
}: {
  title?: string | JSX.Element
  subtitle?: string | JSX.Element
  titleExtra?: string | JSX.Element
  titleNavBack?: JSX.Element
  titleMetadata?: string | JSX.Element
  titleRightContent?: string | JSX.Element | Array<JSX.Element>
  previousPage?: string
  actions?: Array<Action>
  actionsNext?: React.ReactNode[]
  width?: string
  yellowAlert?: string
  yellowAlertChildren?: string | JSX.Element
  className?: string
  children?: React.ReactNode
}) => {
  const actions = [
    ...actionsNext,
    ...actionsOld.map((a) => (
      <Button onClick={a.callBack} title={a.title} primary={a.type === 'primary'} key={a.id} />
    )),
  ]

  return (
    <div
      className={classNames(
        'flex flex-col grow px-2 sm:px-4 lg:px-8 pt-2 pb-4',
        width === 'narrow' ? 'max-w-4xl' : '',
        className
      )}
    >
      {(yellowAlert || yellowAlertChildren) && (
        <YellowAlert className="mb-4" title={yellowAlert}>
          {yellowAlertChildren}
        </YellowAlert>
      )}

      <div className="flex items-center justify-between">
        <div className="flex-1 min-w-0 align-stretch">
          <div className="flex items-center gap-4 justify-between flex-wrap">
            <div className="flex items-center gap-4">
              {previousPage && (
                <Link
                  to={previousPage}
                  className="h-10 w-10 rounded flex justify-center items-center text-gray-500 cursor-pointer hover:bg-gray-200 transition duration-300"
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3789 19.4353L3.37891 12.4353M3.37891 12.4353L10.3789 5.4353M3.37891 12.4353H21.3789"
                      stroke="#3F3F46"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              )}

              {titleExtra && <>{titleExtra}</>}

              <div className="flex flex-row flex-wrap gap-2 sm:gap-4">
                {titleNavBack && <div className="flex items-center">{titleNavBack}</div>}
                {title && <h2 className="text-xl sm:truncate">{title}</h2>}
                <div>{titleMetadata}</div>
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center gap-4">{titleRightContent}</div>
          </div>
        </div>

        {actions.length > 0 && <div className="flex gap-2">{actions}</div>}
      </div>

      {subtitle && <h4 className="mt-1">{subtitle}</h4>}
      {children}
    </div>
  )
}

export default Page
