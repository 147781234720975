import Link from '@/gf/components/Link'
import { Maybe } from '@/types'
import { List, Row } from '../List'
import { Section } from '../YourOrder'

type MachineInformation = {
  id: string
  name: string
  serialNumber: Maybe<string>
  machine: {
    make: Maybe<string>
    model: Maybe<string>
    year: Maybe<number>
  }
}

const MachineInformation = ({
  machines,
  allowEdit = false,
  disableLinks = false,
}: {
  machines: MachineInformation[]
  allowEdit?: boolean
  disableLinks: boolean
}) => (
  <Section className="space-y-2">
    <div className="flex gap-2 items-center">
      <div className="font-medium text-lg text-gray-900">Machine</div>
      {allowEdit && <Link.T to="edit-machine">Edit</Link.T>}
    </div>

    {machines.length === 0 && (
      <div className="italic text-gray-500 text-base">No machine involved</div>
    )}

    <div>
      {machines.map((machine) => (
        <List key={machine.id}>
          <Row>
            <>Unit #</>
            {disableLinks ? (
              machine.name
            ) : (
              <Link.T to={`/machines/${machine.id}`} key={machine.id}>
                {machine.name}
              </Link.T>
            )}
          </Row>

          <Row>Serial number {machine.serialNumber}</Row>
          <Row>Make {machine.machine.make}</Row>
          <Row>Model {machine.machine.model}</Row>
          <Row>Year {machine.machine.year}</Row>
        </List>
      ))}
    </div>
  </Section>
)

export default MachineInformation
