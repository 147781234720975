import classNames from 'classnames'

type BadgeColor = 'blue' | 'green' | 'yellow'

const themes: Record<BadgeColor, string> = {
  blue: 'bg-blue-50 text-blue-700 border-blue-600',
  green: 'bg-green-50 text-green-600 border-green-600',
  yellow: 'bg-yellow-50 text-yellow-600 border-yellow-600',
}

const BadgeNext = ({ children, color }: { children: React.ReactNode; color: BadgeColor }) => (
  <span
    className={classNames(
      'inline-flex items-center gap-x-1 px-2 py-1 text-xs  border  border-opacity-10 rounded-[0.25rem] flex-shrink-0',
      themes[color]
    )}
  >
    {children}
  </span>
)

export default BadgeNext
