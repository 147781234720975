import { InformationCircleIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

const VendorDeliveryInfo = ({ className }: { className?: string }) => (
  <div
    className={classNames(
      'p-2 flex flex-row gap-x-1 bg-gray-50 border border-gray-200 rounded',
      className
    )}
  >
    <InformationCircleIcon className="h-5 w-5 flex shrink-0 text-gray-700" aria-hidden="true" />
    <div className="flex flex-col gap-y-1">
      <p className="text-sm text-gray-700">
        If you have a tracking number, please use the Shipping Carrier tab. This tab is for
        shipments you are delivering without a carrier.
      </p>
    </div>
  </div>
)

export default VendorDeliveryInfo
