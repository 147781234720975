const InputLabel: React.FC<{ label: string; htmlFor?: string; children?: React.ReactNode }> = ({
  label,
  htmlFor,
  children,
}) => (
  <div className="mt-2.5">
    <div className="relative">
      <label
        htmlFor={htmlFor}
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs text-gray-700 font-semibold"
      >
        {label}
      </label>
      {children}
    </div>
  </div>
)

export default InputLabel
