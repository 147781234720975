import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

import MoneyM from '@/gf/modules/Money'
import { Money } from '@/types'
import { ReactNode } from 'react'

type Colors = {
  textColor: string
  backgroundColor: string
  borderColor: string
}
const goodColors: Colors = {
  textColor: 'text-green-700',
  backgroundColor: 'bg-green-50',
  borderColor: 'border-green-600/20',
}
const badColors: Colors = {
  textColor: 'text-red-700',
  backgroundColor: 'bg-red-50',
  borderColor: 'border-red-600/10',
}
const neutralColors: Colors = {
  textColor: 'text-gray-700',
  backgroundColor: 'bg-gray-50',
  borderColor: 'border-gray-600/10',
}

type ValueT = number | Money

const Comparison = <T extends ValueT>({
  value,
  comparisonValue,
  className,
  formatValue = (v) => v.toString(),
  diffDisplay,
  downIsGood = false,
}: {
  value: number | Money
  comparisonValue: number | Money
  className?: string
  formatValue?: (value: T) => string
  diffDisplay?: ReactNode
  downIsGood?: boolean
}) => {
  const valueNum = typeof value === 'number' ? value : MoneyM.toDecimal(value)
  const comparisonValueNum =
    typeof comparisonValue === 'number' ? comparisonValue : MoneyM.toDecimal(comparisonValue)

  const diff = valueNum - comparisonValueNum
  const absoluteDiff = Math.abs(diff)

  const diffString =
    diffDisplay !== undefined
      ? diffDisplay
      : formatValue(
          (typeof value === 'number' ? absoluteDiff : MoneyM.fromDecimal(absoluteDiff, 'USD')) as T
        )

  const { textColor, backgroundColor, borderColor, Icon } =
    diff > 0
      ? { ...(downIsGood ? badColors : goodColors), Icon: ArrowUpIcon }
      : diff < 0
        ? { ...(downIsGood ? goodColors : badColors), Icon: ArrowDownIcon }
        : { ...neutralColors, Icon: MinusIcon }

  return (
    <div
      className={classNames(
        'px-2 py-1 inline-flex items-baseline gap-x-1 rounded border',
        textColor,
        backgroundColor,
        borderColor,
        className
      )}
    >
      <Icon className="w-3.5 h-3.5 flex shrink-0 self-center" aria-hidden="true" />

      <span className="sr-only">
        {diff > 0 ? 'Increased' : diff < 0 ? 'Decreased' : 'Changed'} by
      </span>

      <span className="text-xs lg:text-sm font-medium">{diffString}</span>
    </div>
  )
}

export default Comparison
