import { type StoreOrderProblem, StoreOrderProblemType } from '@/buyers/_gen/gql'

import StoreOrderM from '@/gf/modules/StoreOrder'

export type StoreOrderProblemT = Pick<StoreOrderProblem, 'id' | 'details' | 'type'>
const StoreOrderProblem = ({ problem }: { problem: StoreOrderProblemT }) => (
  <div className="flex flex-col gap-y-1">
    {problem.type !== StoreOrderProblemType.Other && (
      <p className="font-medium">{StoreOrderM.getStoreOrderProblemTypeDisplay(problem.type)}</p>
    )}
    <p className="whitespace-pre-wrap leading-tight">{problem.details}</p>
  </div>
)

export default StoreOrderProblem
