import Urls from '@/dealers/modules/Urls'
import Box from '@/gf/components/Box'
import Link from '@/gf/components/Link'
import customerMap from './customer-map.png'

const ValuePropBanner = () => (
  <Box className="rounded-xl flex flex-col sm:flex-row sm:items-center  bg-white sm:bg-gray-50 overflow-hidden lg:h-[22rem] xl:h-[25rem] relative">
    <img
      src={customerMap}
      alt=""
      className="sm:absolute order-2 top-0 right-0 h-full object-cover object-right z-10"
    />

    <div className="sm:max-w-[18rem] lg:max-w-[20.6rem] space-y-4 p-4 lg:p-2 lg:ml-12 z-20 relative order-1">
      <h2 className="text-xl lg:text-2xl xl:text-3xl font-medium">
        Unlimited quotes to ALL of your customers with Sales Hub Pro
      </h2>
      <p className="text-sm lg:text-base xl:text-lg">
        Manage all of your existing customers through Gearflow for seamless quoting and order
        fulfillment, and reporting on your entire sales and service performance.
      </p>
      <Link.S to={Urls.planAndBilling()}>Learn More</Link.S>
    </div>
  </Box>
)

export default ValuePropBanner
