import { ModalSize } from '@/types'

import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import FreeProTrialSplashContent from './FreeProTrialSplashContent'

const FreeProTrialModal = ({
  open,
  onClose,
  onClaimed,
}: {
  open?: boolean
  onClose: () => void
  onClaimed: () => Promise<unknown>
}) => (
  <Modal open={open} onClose={onClose} size={ModalSize.LG}>
    <div className="p-12 relative">
      <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />
      <FreeProTrialSplashContent onClaimed={onClaimed} onClose={onClose} />
    </div>
  </Modal>
)

export default FreeProTrialModal
