import { Maybe } from '@/types'
import Badge from './Badge'
import { DateTime } from 'luxon'

const getBadgeProps = (receivedAt: Maybe<DateTime>) =>
  receivedAt
    ? {
        title: 'Received',
        color: 'bg-green-200',
        textColor: 'text-green-900',
      }
    : {
        title: 'Not Received',
        color: 'bg-red-200',
        textColor: 'text-red-900',
      }

const CreditMemoStateBadge = ({ receivedAt }: { receivedAt: Maybe<DateTime> }) => (
  <Badge {...getBadgeProps(receivedAt)} />
)

export default CreditMemoStateBadge
