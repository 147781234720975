import { createContext, useContext as useReactContext } from 'react'
import { Org, StoreOrder, UpdateArgs } from '../OpenQuotes'

export const Context = createContext<
  | {
      storeOrders: StoreOrder[] | undefined
      orgById: Record<string, Org> | undefined
      days: string | null
      orgId: string | null
      breakdownBy: string | null
      breakdownCustomerSort: string[] | null
      ordersSort: string[] | null
      update: (args: UpdateArgs) => void
    }
  | undefined
>(undefined)

export const useContext = () => {
  const context = useReactContext(Context)
  if (!context) throw new Error('must be used within provider')
  return context
}
