import { ExclamationIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

const YellowAlert = ({
  title = 'Attention',
  action,
  className,
  children,
}: {
  title?: string
  action?: React.ReactNode
  className?: string | undefined
  children?: React.ReactNode
}) => (
  <div className={classNames('rounded-md bg-yellow-50 p-4', className)}>
    <div className="flex items-start">
      <div className="flex-shrink-0">
        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div>

      <div className="ml-3 flex flex-col grow">
        <div className="flex flex-row grow justify-between items-center">
          <h3
            className={classNames(
              'text-sm text-yellow-800',
              children ? 'text-yellow-800 font-medium' : 'text-yellow-700'
            )}
          >
            {title}
          </h3>
        </div>

        {children && <div className="mt-2 text-sm text-yellow-700">{children}</div>}
      </div>

      {action && <div className="self-center">{action}</div>}
    </div>
  </div>
)

export default YellowAlert
