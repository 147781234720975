import { Event, EventPayload } from '../../types'

import RfqEvent from './RfqEvent'
import StoreOrderEvent from './StoreOrderEvent'

const fromPayload = (payload: EventPayload): Event =>
  payload.storeOrderEvent
    ? {
        storeOrderEvent: StoreOrderEvent.fromPayload(payload.storeOrderEvent),
        requestForQuoteEvent: undefined,
      }
    : {
        storeOrderEvent: undefined,
        requestForQuoteEvent: RfqEvent.fromPayload(payload.requestForQuoteEvent),
      }

export default {
  fromPayload,
}
