import { AccountMachine, AccountMachinePayload } from '../../types'

const fromPayload = (payload: AccountMachinePayload): AccountMachine => ({
  id: payload.id,
  name: payload.name,
  serialNumber: payload.serialNumber,
  description: payload.description,
  owned: payload.owned,
  engineHours: payload.engineHours,
  operator: payload.operator,
  lat: payload.lat,
  lng: payload.lng,
  engineMake: payload.engineMake,
  engineModel: payload.engineModel,
  engineSerialNumber: payload.engineSerialNumber,
  ...payload.machine,
})

const getSearchDisplayName = (
  accountMachine: Pick<AccountMachine, 'name' | 'make' | 'model' | 'serialNumber'>
) =>
  `${accountMachine.name}, ${accountMachine.make}, ${accountMachine.model}, ${accountMachine.serialNumber}`

const getSearchDisplayNameFromPayload = (accountMachinePayload: AccountMachinePayload) =>
  getSearchDisplayName(fromPayload(accountMachinePayload))

export default {
  fromPayload,
  getSearchDisplayName,
  getSearchDisplayNameFromPayload,
}
