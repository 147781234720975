import RadioInput from '@/gf/components/next/forms/RadioInput'
import { Maybe } from '@/types'
import { Urgency } from './useForm'

const options = [
  {
    label: 'High Priority - needed within 24 hrs',
    value: Urgency.HIGH,
  },
  {
    label: 'Medium Priority - needed within 3 days',
    value: Urgency.MEDIUM,
  },
  {
    label: 'Low Priority - needed in 1 week or more',
    value: Urgency.LOW,
  },
]

const UrgencySelector = ({
  value,
  onChange,
}: {
  value: Maybe<Urgency>
  onChange: (value: Urgency) => void
}) => (
  <div className="text-sm space-y-2">
    {options.map((o) => (
      <label className="flex items-center gap-x-2" key={o.value}>
        <RadioInput
          name="urgency"
          checked={value === o.value}
          onChange={(e) => e.target.checked && onChange(o.value)}
        />
        {o.label}
      </label>
    ))}
  </div>
)

export default UrgencySelector
