import { Refund, RefundPayload } from '../../types'

import Money from './Money'

const fromPayload = (payload: RefundPayload): Refund => ({
  ...payload,
  amount: Money.fromPayload(payload.amount),
})

export default {
  fromPayload,
}
