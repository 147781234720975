import classNames from 'classnames'

const BalanceTermsAndConditions = ({
  buttonText = 'Place your order',
  className,
}: {
  buttonText?: string
  className?: string | undefined
}) => (
  <span className={classNames('text-sm text-gray-500 text-center leading-none', className)}>
    We use Balance to process payments. By clicking &quot;{buttonText}&quot;, you agree to
    Balance&apos;s{' '}
    <a
      className="underline"
      href="https://www.getbalance.com/legal/privacy-policy"
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>{' '}
    and{' '}
    <a
      className="underline"
      href="https://www.getbalance.com/legal/end-user-terms"
      target="_blank"
      rel="noreferrer"
    >
      End User Terms
    </a>{' '}
    (last updated on July 12, 2022)
  </span>
)

export default BalanceTermsAndConditions
