import ClaimAccountBanner from './ClaimAccountBanner'
import { Portal } from '@headlessui/react'
import useMsgs from '@/gf/hooks/useMsgs'
import Message from '@/gf/components/Message'

const Messages = () => {
  const [msgs, msgsMgr] = useMsgs()

  return (
    <Portal>
      {/* region for incoming messages */}
      <div
        aria-live="assertive"
        className="z-30 fixed inset-0 flex flex-col space-y-4 items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        {msgs.length > 0 && (
          <>
            {msgs.map((msg) => (
              <Message msg={msg} removeMsg={msgsMgr.remove} key={msg.id} />
            ))}
          </>
        )}
      </div>
    </Portal>
  )
}

const LimitedUserFrame = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col min-h-screen border-t-[0.5rem] border-orange-500 bg-gray-50">
    <ClaimAccountBanner />

    {children}

    <Messages />
  </div>
)

export default LimitedUserFrame
