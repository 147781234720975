import { ApolloErrorWithMessages } from '@/types'

// Removes the __typename field before sending the request through graphql
export const cleanGraphQlInput = <T extends object>(object: T) => {
  if ('__typename' in object) {
    const { __typename, ...rest } = object
    return rest
  }

  return object
}

const parseErrorList = (err: unknown) => {
  const isGraphQLError = !!(err as ApolloErrorWithMessages).graphQLErrors

  if (!isGraphQLError) {
    return [(err as Error).message]
  }

  const apolloError = err as ApolloErrorWithMessages
  return apolloError.graphQLErrors.map((gqlErr) => gqlErr.fields.map((f) => f.value).flat()).flat()
}

export default {
  cleanGraphQlInput,
  parseErrorList,
}
