import Action from '@/gf/components/Action'
import { useNavigate } from 'react-router-dom'
import Urls from '../modules/Urls'

import {
  deletePersistedForm,
  DIRECT_QUOTE_KEY,
  persistForm,
} from '../pages/Request/QuoteBuilder/useForm'

const SendQuoteButton = ({ customerContactId }: { customerContactId?: string }) => {
  const navigate = useNavigate()

  const onClick = () => {
    if (customerContactId) persistForm(DIRECT_QUOTE_KEY, { customerContactId })
    else deletePersistedForm(DIRECT_QUOTE_KEY)

    navigate(Urls.createQuote())
  }

  return <Action.S onClick={onClick}>Send Quote</Action.S>
}

export default SendQuoteButton
