import A from '@/gf/components/A'
import Box from '@/gf/components/Box'
import Link from '@/gf/components/Link'
import { ExternalLinkIcon, XIcon as XIconSolid } from '@heroicons/react/solid'

const ProofOfDeliveries = ({
  uploadPath,
  proofOfDeliveries,
  remove,
}: {
  uploadPath: string
  proofOfDeliveries: string[]
  remove: (pod: string) => void
}) => (
  <Box className="shadow-base p-6 space-y-4">
    <div className="flex gap-x-2 items-center justify-between">
      <div className="font-medium">Proof of Delivery</div>
      <Link.T to={uploadPath}>Upload</Link.T>
    </div>

    <div>
      {proofOfDeliveries.map((pod) => (
        <div
          className="group p-1 flex gap-x-4 items-center justify-between hover:bg-gray-50"
          key={pod}
        >
          <A.T href={pod} target="_blank" className="text-base flex gap-1 items-center">
            {decodeURIComponent(pod.split('/').at(-1) || '')}
            <ExternalLinkIcon className="w-5 h-5 shrink-0" />
          </A.T>

          <button
            onClick={() => remove(pod)}
            type="button"
            className="shrink-0 invisible group-hover:visible"
          >
            <XIconSolid className="h-5 w-5 text-red-500 text-opacity-20 hover:text-opacity-100" />
          </button>
        </div>
      ))}
    </div>
  </Box>
)

export default ProofOfDeliveries
