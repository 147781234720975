import { ApproveBalanceQuery } from '@/buyers/_gen/gql'
import type { Balance } from '@/types'
import { useCallback, useEffect } from 'react'

type StoreOrder = Exclude<ApproveBalanceQuery['storeOrder'], null>

type BalanceTransaction = StoreOrder['balanceTransactions'][number]

declare global {
  interface Window {
    balanceSDK: Balance
  }
}

const balance = window.balanceSDK

const useBalanceModal = ({
  approve,
  balanceTransactions,
}: {
  approve: () => void
  balanceTransactions: BalanceTransaction[] | undefined
}) => {
  useEffect(() => {
    if (balance.checkout) {
      balance.checkout.init({
        hideDueDate: true,
        hideDueDateText: 'vendor confirmation',
        skipSuccessPage: true,
        allowedPaymentMethods: ['creditCard', 'bank'],
        isAuth: true,
        onError: (error) => console.log('onError', error),
        onClose: () => console.log('onClose'),
        onSuccess: approve,
      })
    }

    return () => balance.checkout.destroy()
  }, [approve])

  const open = useCallback(() => {
    const joinedToken = balanceTransactions
      ?.map((txn) => txn.token)
      .filter((token) => token)
      .join(',')

    if (joinedToken) balance.checkout.render(joinedToken, '#balance-checkout')
  }, [balanceTransactions, balance])

  return { open }
}

export default useBalanceModal
