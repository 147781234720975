import { PartsSalesQuery, SortOrder, StoreOrderStep, usePartsSalesQuery } from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import ChartBreakdownLayout from '@/gf/components/Reports/ChartBreakdownLayout'
import Money from '@/gf/modules/Money'
import { ReportDuration } from '@/gf/modules/ReportDuration'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import { DateTime } from 'luxon'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import DurationInput from './DurationInput'
import BreakdownTable from './PartsSales/BreakdownTable'
import Chart from './PartsSales/Chart'
import Context from './PartsSales/Context'
import HistoryTable from './PartsSales/HistoryTable'
import TitleMetric from './PartsSales/TitleMetric'
import { useDuration } from './useDuration'

export type StoreOrder = PartsSalesQuery['storeOrders'][number]
export type ComparisonStoreOrder = PartsSalesQuery['comparisonStoreOrders'][number]
export type Org = PartsSalesQuery['storeOrders'][number]['customer']['organization']

export type UpdateArgs = {
  fromDate?: DateTime | null
  toDate?: DateTime | null
  duration?: ReportDuration | null
  date?: DateTime | null
  orgId?: string | null
  mpn?: string | null
  chartTab?: string | null
  breakdownBy?: string | null
  breakdownPartSort?: { field: string; order: SortOrder } | null
  breakdownCustomerSort?: { field: string; order: SortOrder } | null
  ordersSort?: { field: string; order: SortOrder } | null
}

const useQueryParamsOpts = {
  date: StringParam,
  'orders.sort': StringParam,
  'chart.tab': withDefault(StringParam, 'activity'),
  'breakdown.by': StringParam,
  'breakdown.customer.sort': StringParam,
  'breakdown.part.sort': StringParam,
  orgId: StringParam,
  mpn: StringParam,
}

export const formatMoneyValue = (value: number) =>
  Money.format(Money.fromInt(value, 'USD'), { maximumFractionDigits: 0 })

export const sumStoreOrdersTotal = (storeOrders: (StoreOrder | ComparisonStoreOrder)[]) =>
  storeOrders.map((so) => so.totals.total).reduce(Money.add, Money.fromDecimal(0, 'USD'))

export const filterStoreOrdersReceivedBetween = (
  storeOrders: StoreOrder[],
  [start, end]: DateTime<true>[]
) =>
  storeOrders.filter(
    (so) => so.request && start <= so.request.receivedAt && so.request.receivedAt <= end
  )

const stepFilter = [
  'step_in',
  [StoreOrderStep.PoReceived, StoreOrderStep.Fulfilling, StoreOrderStep.Fulfilled],
]

const PartsSales = () => {
  const [query, setQuery] = useQueryParams(useQueryParamsOpts)

  const {
    fromDate,
    toDate,
    prevToDate,
    prevFromDate,
    duration,
    dates,
    update: updateDuration,
  } = useDuration()

  const update = ({
    date,
    orgId,
    mpn,
    chartTab,
    breakdownBy,
    breakdownPartSort,
    breakdownCustomerSort,
    ordersSort,
    ...updates
  }: UpdateArgs) => {
    updateDuration(updates)

    if (date !== undefined) setQuery({ date: date ? date.toISODate() : undefined })
    if (orgId !== undefined) setQuery({ orgId: orgId || undefined })
    if (mpn !== undefined) setQuery({ mpn: mpn || undefined })
    if (chartTab !== undefined) setQuery({ 'chart.tab': chartTab || undefined })
    if (breakdownBy !== undefined) setQuery({ 'breakdown.by': breakdownBy || undefined })

    if (breakdownPartSort !== undefined)
      setQuery({
        'breakdown.part.sort': breakdownPartSort
          ? `${breakdownPartSort.field}.${breakdownPartSort.order.toLowerCase()}`
          : undefined,
      })

    if (breakdownCustomerSort !== undefined)
      setQuery({
        'breakdown.customer.sort': breakdownCustomerSort
          ? `${breakdownCustomerSort.field}.${breakdownCustomerSort.order.toLowerCase()}`
          : undefined,
      })

    if (ordersSort !== undefined)
      setQuery({
        'orders.sort': ordersSort
          ? `${ordersSort.field}.${ordersSort.order.toLowerCase()}`
          : undefined,
      })
  }

  const chartTab = query['chart.tab']
  const breakdownBy = query['breakdown.by'] || null
  const breakdownPartSort = query['breakdown.part.sort']?.split('.') || null
  const breakdownCustomerSort = query['breakdown.customer.sort']?.split('.') || null
  const ordersSort = query['orders.sort']?.split('.') || null
  const date = query.date ? DateTime.fromISO(query.date) : null
  const orgId = query.orgId || null
  const mpn = query.mpn || null

  const [storeOrdersFilter, comparisonStoreOrdersFilter] = [
    [fromDate, toDate],
    [prevFromDate, prevToDate],
  ].map(([d1, d2]) =>
    JSON.stringify(['and', ['created_between', d1.toISODate(), d2.toISODate()], stepFilter])
  )

  const { storeOrders, comparisonStoreOrders } =
    usePartsSalesQuery({
      variables: { storeId: useSession().store.id, storeOrdersFilter, comparisonStoreOrdersFilter },
    }).data || {}

  const filteredStoreOrders = (() => {
    if (!storeOrders) return undefined

    let result = storeOrders
    result = orgId ? result.filter(({ customer }) => customer.organization.id === orgId) : result

    result = mpn
      ? result.filter(({ lineItems }) => lineItems.some(({ product }) => product.mpn === mpn))
      : result

    return result
  })()

  const orgById =
    storeOrders &&
    keyBy(
      storeOrders.map((so) => so.customer.organization),
      (o) => o.id
    )

  // [ [ startDate, endDate ], ... ]
  const ranges = (() => {
    if (chartTab === 'trend' && dates.length > 27)
      return map(
        groupBy(dates, (d) => d.startOf('week', { useLocaleWeeks: true }).toISODate()),
        (_, isoDate) => {
          const start = DateTime.fromISO(isoDate) as DateTime<true>
          const end = start.endOf('week', { useLocaleWeeks: true })
          return [start, end]
        }
      )

    return dates.map((start, index) => {
      const end = dates.at(index + 1) || start
      return [start, end]
    })
  })()

  return (
    <Context.Provider
      value={{
        storeOrders,
        comparisonStoreOrders,
        filteredStoreOrders,
        orgById,
        fromDate,
        toDate,
        duration,
        date,
        orgId,
        mpn,
        chartTab,
        breakdownBy,
        breakdownPartSort,
        breakdownCustomerSort,
        ordersSort,
        ranges,
        update,
      }}
    >
      <ChartBreakdownLayout
        titleMetric={<TitleMetric />}
        durationInput={
          <DurationInput fromDate={fromDate} toDate={toDate} duration={duration} update={update} />
        }
        chart={<Chart />}
        breakdownTable={<BreakdownTable />}
        historyTable={<HistoryTable />}
      />
    </Context.Provider>
  )
}
export default PartsSales
