import classNames from 'classnames'
import { ReactNode } from 'react'
import { MultiButton } from '../MultiButton'

export interface FirstAction {
  hasMultipleActions: boolean
}

export const ContentWrapper = ({
  firstAction,
  children,
}: {
  firstAction: FirstAction | undefined
  children: ReactNode
}) => (
  <div
    className={classNames(
      'inline-flex justify-stretch bg-white group',
      firstAction ? 'hover:bg-gray-50 border-gray-300' : 'hover:bg-gray-100',
      !firstAction
        ? 'first:rounded-t-md last:rounded-b-md'
        : firstAction.hasMultipleActions
          ? 'border-y border-l rounded-l-md'
          : 'border rounded-md'
    )}
  >
    {children}
  </div>
)

const Content = ({
  item,
  firstAction,
}: {
  item: MultiButton
  firstAction: FirstAction | undefined
}) => (
  <div
    className={classNames(
      'px-4 py-2 w-full inline-flex flex-col items-stretch gap-y-0.5 text-left text-sm',
      !firstAction
        ? 'first:rounded-t-md last:rounded-b-md'
        : firstAction.hasMultipleActions
          ? 'rounded-l-md'
          : 'rounded-md'
    )}
  >
    <div
      className={classNames(
        'inline-flex flex-row gap-x-1 text-gray-700',
        !firstAction && 'font-medium group-hover:text-gray-900'
      )}
    >
      {item.icon && (
        <item.icon
          className={classNames('w-5 h-5 min-w-5 min-h-5 inline-block', item.iconClassName)}
        />
      )}

      <span>{item.display}</span>
    </div>

    {item.description && !firstAction && (
      <span
        className={classNames(
          'block text-xs whitespace-pre-wrap text-gray-500',
          !firstAction && 'group-hover:text-gray-700'
        )}
      >
        {item.description}
      </span>
    )}
  </div>
)

export default Content
