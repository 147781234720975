import { Navigate, useParams } from 'react-router-dom'
import { useOrderQuery } from '@/dealers/_gen/gql'
import NotFound from './NotFound'

const Order = () => {
  const { storeOrderId } = useParams<{ storeOrderId: string }>() as { storeOrderId: string }
  const params = useParams<{ ['*']: string }>()
  const storeOrder = useOrderQuery({ variables: { storeOrderId } }).data?.storeOrder

  if (storeOrder === undefined) return null
  if (!storeOrder?.request) return <NotFound />

  return (
    <Navigate
      to={`/rfqs/${storeOrder.request.id}${params['*'] ? `/${params['*']}` : ''}`}
      replace
    />
  )
}

export default Order
