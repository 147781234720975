const defaultAllowedFileTypes = ['image/*', '.jpg', '.jpeg', '.png', '.gif']

const nameFromUrl = (attachmentUrl: string) =>
  decodeURIComponent(attachmentUrl).split('/').reverse()[0]

const extensionFromUrl = (attachmentUrl: string) =>
  decodeURIComponent(attachmentUrl).split('.').reverse()[0].split('?')[0]

export default {
  defaultAllowedFileTypes,
  nameFromUrl,
  extensionFromUrl,
}
