import Link from '@/gf/components/Link'
import { useNavigate } from 'react-router-dom'
import Frame from './Frame'

const Welcome = () => {
  const navigate = useNavigate()

  return (
    <Frame className="space-y-6" saveAndExit={() => navigate('/dashboard')}>
      <div className="space-y-4">
        <h2 className="text-2xl font-medium">
          Welcome to Gearflow!
          <br />
          Let&apos;s begin by setting up your account.
        </h2>

        <div className="prose">
          We&apos;ll start with a few details about you and your business to set up your workspace.
          This will take about 5 minutes to complete.
        </div>
      </div>

      <div>
        <Link.P
          to="/setup/business-details"
          className="w-full bg-blue-600 hover:bg-blue-700 text-center text-base"
        >
          Begin Setup
        </Link.P>
      </div>
    </Frame>
  )
}

export default Welcome
