import { v4 as uuid } from 'uuid'

import { NotContainsFilter } from '../../../types'

const build = ({
  id,
  fieldId,
  text = '',
}: {
  id?: string
  fieldId: string
  text?: string
}): NotContainsFilter => ({
  id: id || uuid(),
  typeId: 'not_contains',
  fieldId,
  text,
})

const toApiFilter = (filter: NotContainsFilter) => ['not_contains', filter.fieldId, filter.text]

export default { build, toApiFilter }
