import { Navigate, Route, Routes, useMatch } from 'react-router-dom'

import Frame from '@/dealers/components/Frame'
import Page from '@/gf/components/Page'
import { LinkTabs } from '@/gf/components/next/Tabs'
import OpenQuotes from './Reports/OpenQuotes'
import PartsSales from './Reports/PartsSales'
import Performance from './Reports/Performance'
import Service from './Reports/Service'

const tabs = [
  { name: 'Parts Sales', to: 'parts-sales' },
  { name: 'Open Quotes', to: 'open-quotes' },
  { name: 'Service', to: 'service' },
  { name: 'Performance', to: 'performance' },
]

const Reports = () => {
  const match = useMatch(`reports/:tab`)
  const tab = tabs.find((t) => t.to === match?.params.tab) || { name: '' }

  return (
    <Frame className="bg-gray-50">
      <Page className="!p-12 bg-gray-50">
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-row gap-x-8 items-center">
            <LinkTabs tabs={tabs} selectedTabName={tab.name} />
          </div>

          <Routes>
            <Route path="" element={<Navigate to="parts-sales" replace />} />
            <Route path="parts-sales" element={<PartsSales />} />
            <Route path="open-quotes" element={<OpenQuotes />} />
            <Route path="service" element={<Service />} />
            <Route path="performance" element={<Performance />} />
          </Routes>
        </div>
      </Page>
    </Frame>
  )
}

export default Reports
