import Time from './Time'
import {
  ApproveNetTerm,
  ApproveNetTermPayload,
  Organization,
  OrganizationPayload,
} from '../../types'

const fromApproveNetTermPayload = (payload: ApproveNetTermPayload): ApproveNetTerm => ({
  ...payload,
  finalizedAt: payload.finalizedAt ? Time.fromPayload(payload.finalizedAt) : undefined,
  insertedAt: Time.fromPayload(payload.insertedAt),
})

const fromPayload = (payload: OrganizationPayload): Organization => ({
  ...payload,
  approveNetTerm: payload.approveNetTerm && fromApproveNetTermPayload(payload.approveNetTerm),
  lastActivityDate: Time.fromPayload(payload.lastActivityDate),
  buyerDashboardAccessExpiresAt: payload.buyerDashboardAccessExpiresAt
    ? Time.fromPayload(payload.buyerDashboardAccessExpiresAt)
    : undefined,
})

export default {
  fromPayload,
}
