import type { StoreOrder } from '../../LimitedOrder'

import { StoreOrderProblemType } from '@/buyers/_gen/gql'
import { useOrderContext } from '../context'

import Box from '@/buyers/components/Box'
import Link from '@/gf/components/Link'

const title = (problem: StoreOrder['problems'][number]) => {
  if (problem.type === StoreOrderProblemType.WrongPart) return 'Wrong part(s)'
  if (problem.type === StoreOrderProblemType.MissingPart) return 'Missing part(s)'
  if (problem.type === StoreOrderProblemType.Fitment) return 'Fitment issue'
  if (problem.type === StoreOrderProblemType.Other) return 'Problem with Order'
  throw new Error(`Unexpected type: ${problem.type}`)
}

const Problems = () => {
  const { storeOrder } = useOrderContext()
  const unresolvedProblems = storeOrder.problems.filter((p) => !p.resolvedAt)

  return (
    <>
      {unresolvedProblems.map((problem) => (
        <Box
          className="bg-red-100 border-red-200 text-center flex flex-col space-y-4"
          key={problem.id}
        >
          <div className="text-lg font-medium text-red-700">{title(problem)}</div>
          <div className="text-base whitespace-pre-wrap">{problem.details}</div>
          <Link.S to={`problems/${problem.id}/mark-resolved`}>Mark Resolved</Link.S>
        </Box>
      ))}
    </>
  )
}

export default Problems
