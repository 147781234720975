import { RefObject } from 'react'

import Action from '@/gf/components/Action'
import Button from '@/gf/components/ButtonOld'
import Link from '@/gf/components/Link'

const SubmitButtons = ({
  storeOrderId,
  spinnerLive,
  hasPermissionToApproveOrder,
  isUpdatingShippingLocation,
  onDenyClick,
  submitButtonsRef,
}: {
  storeOrderId: string
  hasPermissionToApproveOrder: boolean
  isUpdatingShippingLocation?: boolean
  spinnerLive: boolean
  onDenyClick: () => void
  submitButtonsRef?: RefObject<HTMLDivElement>
}) => (
  <div className="grid grid-cols-2 items-start gap-x-4">
    <div ref={submitButtonsRef} className="flex flex-col">
      <Button
        className="justify-center"
        type="button"
        title="Deny"
        primary={false}
        onClick={onDenyClick}
      >
        Deny
      </Button>
    </div>

    {!hasPermissionToApproveOrder ? (
      <div className="flex flex-col">
        <Button className="justify-center" disabled>
          Confirm
        </Button>

        <p className="pt-2 text-sm text-gray-500">This order requires additional approval</p>

        <Link.T className="text-sm" to={`/orders/${storeOrderId}/messages/internalOrg`}>
          Message my team
        </Link.T>
      </div>
    ) : isUpdatingShippingLocation ? (
      <div className="flex flex-col">
        <Button className="justify-center" disabled>
          Confirm
        </Button>

        <p className="pt-2 text-sm text-gray-500">Updating shipping location</p>
      </div>
    ) : (
      <Action.P type="submit" performing={spinnerLive}>
        Confirm
      </Action.P>
    )}
  </div>
)

export default SubmitButtons
