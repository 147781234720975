import { RequestForQuoteStep } from '@/dealers/_gen/gql'
import classNames from 'classnames'

const badgeByStep = {
  [RequestForQuoteStep.Inbound]: {
    title: <>Inbound</>,
    className: 'bg-blue-100 border-blue-200 text-blue-700',
  },
  [RequestForQuoteStep.Quoted]: {
    title: <>Quoted</>,
    className: 'bg-green-100 border-green-200 text-green-700',
  },
  [RequestForQuoteStep.PoReceived]: {
    title: <>PO Received</>,
    className: 'bg-green-100 border-green-200 text-green-700',
  },
  [RequestForQuoteStep.Fulfilling]: {
    title: <>Fulfilling</>,
    className: 'bg-yellow-100 border-yellow-200 text-yellow-600',
  },
  [RequestForQuoteStep.Fulfilled]: {
    title: <>Fulfilled</>,
    className: 'bg-orange-100 border-orange-200 text-orange-500',
  },
  [RequestForQuoteStep.Canceled]: {
    title: <>Canceled</>,
    className: 'bg-red-100 border-red-200 text-red-700',
  },
}

const RfqStepBadgeNext = ({ step }: { step: RequestForQuoteStep }) => {
  const badge = badgeByStep[step]
  return (
    <div
      className={classNames(
        'px-2 py-1 rounded border font-medium inline-block text-sm text-center whitespace-nowrap',
        badge.className
      )}
    >
      {badge.title}
    </div>
  )
}

export default RfqStepBadgeNext
