import { ReportDuration } from '@/gf/modules/ReportDuration'
import { DateTime } from 'luxon'
import { createContext, useContext as useReactContext } from 'react'
import { UpdateArgs } from '../Service'
import { ComparisonRfq, Creator, Org, Rfq } from './useQuery'

export const Context = createContext<
  | {
      rfqs: Rfq[] | undefined
      comparisonRfqs: ComparisonRfq[] | undefined
      filteredRfqs: Rfq[] | undefined
      orgById: Record<string, Org> | undefined
      creatorById: Record<string, Creator> | undefined
      fromDate: DateTime
      toDate: DateTime
      duration: ReportDuration
      date: DateTime | null
      orgId: string | null
      userId: string | null
      priority: string | null
      breakdownBy: string | null
      breakdownCustomerSort: string[] | null
      breakdownUserSort: string[] | null
      breakdownUrgencySort: string[] | null
      rfqsSort: string[] | null
      chartTab: string
      ranges: DateTime<true>[][]
      update: (args: UpdateArgs) => void
    }
  | undefined
>(undefined)

export const useContext = () => {
  const context = useReactContext(Context)
  if (!context) throw new Error('must be used within provider')
  return context
}
