import { useCustomerContactsQuery } from '@/dealers/_gen/gql'
import A from '@/gf/components/A'
import Phone from '@/gf/modules/Phone'
import { PhoneIcon, UserIcon } from '@heroicons/react/solid'

const Contacts = ({ customerAccountId }: { customerAccountId: string }) => {
  const { data } = useCustomerContactsQuery({ variables: { id: customerAccountId } })
  const contacts = data?.customer.contacts ?? []
  return (
    <>
      <h3 className="flex items-center font-black text-xl text-slate-600">Contacts</h3>

      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4 mt-4">
        {contacts.map((c) => (
          <li
            key={c?.id}
            className="bg-white rounded-lg border border-slate-200 shadow overflow-x-hidden"
          >
            <div className="flex h-full flex-col gap-y-1 border-l-4 border-gearflow p-2 relative">
              <span className="inline-flex gap-x-1.5">
                <UserIcon className="text-slate-400 inline h-5 flex-shrink-0" />
                <span>
                  <span className="block font-medium text-zinc-600">{c.name}</span>
                  <span className="block capitalize text-xs text-slate-500 italic">{c.email}</span>
                </span>
              </span>

              {c?.phoneNumber && (
                <span className="inline-flex items-center gap-x-1.5">
                  <PhoneIcon className="text-slate-400 inline h-5 flex-shrink-0" />
                  <A.T className="text-sm text-slate-600" href={`tel:${c.phoneNumber}`}>
                    {Phone.format(c.phoneNumber)}
                  </A.T>
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default Contacts
