import { SetupQuery } from '@/dealers/_gen/gql'
import { createContext, useContext } from 'react'

type Context = {
  storeId: string
  refetch: () => Promise<unknown>
} & (
  | { loading: false; store: Exclude<SetupQuery['store'], null> }
  | { loading: true; store: Exclude<SetupQuery['store'], null> | undefined }
)

export const SetupContext = createContext<Context | undefined>(undefined)

export const useSetupContext = () => {
  const context = useContext(SetupContext)
  if (context === undefined) throw new Error('must be used within provider')
  return context
}
