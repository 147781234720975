import Action from '@/gf/components/Action'
import UploadButton from '@/gf/components/UploadButton'
import { UploadIcon } from '@heroicons/react/solid'

export interface QuoteUploadProps {
  onUploadStarted: () => void
  onUploadComplete: (files: { name: string; url: string }[]) => Promise<void>
  onError: (message: string) => void
  rfqId: string | null
}

const QuoteUploadButton = ({
  onError,
  onUploadStarted,
  onUploadComplete,
  rfqId,
}: QuoteUploadProps) => (
  <UploadButton
    allowedFileTypes={['.jpg', '.jpeg', '.png', '.pdf']}
    onError={onError}
    onUploadComplete={onUploadComplete}
    onUploadStarted={onUploadStarted}
    key={rfqId}
  >
    <Action.S className="whitespace-nowrap">
      <UploadIcon className="-ml-0.5 mr-1.5 w-4 h-4 inline-flex shrink-0 text-gray-700" />
      Upload Quote
    </Action.S>
  </UploadButton>
)

export default QuoteUploadButton
