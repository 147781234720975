import { SortOrder } from '@/dealers/_gen/gql'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import Money from '@/gf/modules/Money'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { total } from '../../OpenQuotes'
import { useContext } from '../Context'

const Customer = () => {
  const context = useContext()
  const { storeOrders, orgById, breakdownCustomerSort: sort, update } = context
  const sortField = sort ? sort[0] : 'conv-rate'
  const sortOrder = sort ? (sort[1] === 'desc' ? 'desc' : 'asc') : 'desc'
  const storeOrdersByOrgId =
    storeOrders && groupBy(storeOrders, (so) => so.customer.organization.id)

  const unsortedRows =
    storeOrdersByOrgId &&
    map(storeOrdersByOrgId, (subStoreOrders, orgId) => ({
      orgId,
      orgName: orgById && orgById[orgId].name,
      quoteCount: subStoreOrders.length,
      total: total(subStoreOrders),
    }))

  const orderByField = (() => {
    if (sortField === 'customer') return 'orgName'
    if (sortField === 'quotes') return 'quoteCount'
    if (sortField === 'total') return 'total.amount'
    return sortField
  })()

  const rows =
    unsortedRows && orderBy(unsortedRows, orderByField, sortOrder === 'desc' ? 'desc' : 'asc')

  return (
    <ReportingTable
      data={rows}
      getRowKey={(row) => row.orgId}
      checkbox={{
        getChecked: ({ orgId }) => orgId === context.orgId,
        onToggleRow: ({ orgId }) => update({ orgId: orgId !== context.orgId ? orgId : null }),
        onClear: () => update({ orgId: null }),
      }}
      sortBy={{
        sortBy: { field: sortField, order: sortOrder === 'desc' ? SortOrder.Desc : SortOrder.Asc },
        setSortBy: (sortBy) => update({ breakdownCustomerSort: sortBy }),
      }}
      columns={[
        { header: 'Customer', getValue: (row) => row.orgName, sortByField: 'customer' },
        { header: 'Quotes', getValue: (row) => row.quoteCount, sortByField: 'quotes' },
        {
          header: 'Total',
          getValue: (row) => Money.format(row.total, { maximumFractionDigits: 0 }),
          sortByField: 'total',
        },
      ]}
    />
  )
}

export default Customer
