import { ClockIcon } from '@heroicons/react/outline'
import { CheckCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { DateTime, Interval } from 'luxon'
import pluralize from 'pluralize'

const formatTimeOpen = (createdAt: DateTime, completedAt: DateTime | null) => {
  const end = completedAt
    ? completedAt.toSeconds() <= createdAt.toSeconds()
      ? createdAt.plus({ minutes: 1 })
      : completedAt
    : DateTime.now()

  const duration = Interval.fromDateTimes(createdAt, end).toDuration().rescale().toObject()
  const durationParts = Object.entries(duration)

  let formatted = `${durationParts[0][1]} ${pluralize(durationParts[0][0], durationParts[0][1])}`

  // less than a minute
  if (durationParts[0][0] === 'seconds') return '1 minute'

  // less than an hour
  if (durationParts[0][0] === 'minutes') return formatted

  if (durationParts[1])
    formatted += `, ${durationParts[1][1]} ${pluralize(durationParts[1][0], durationParts[1][1])}`

  return formatted
}

const timeOpenColor = (createdAt: DateTime) => {
  const minutesOpen = Interval.fromDateTimes(createdAt, DateTime.now()).toDuration().as('minutes')
  const twoHours = 2 * 60
  const twentyFourHours = 24 * 60

  if (minutesOpen < twoHours) {
    return 'text-green-500'
  }

  if (minutesOpen < twentyFourHours) {
    return 'text-amber-500'
  }

  return 'text-red-500'
}

const TimeOpen = ({
  insertedAt,
  completedAt,
  className,
  showIcon = true,
}: {
  insertedAt: DateTime
  completedAt?: DateTime | null
  className?: string
  showIcon?: boolean
}) => (
  <span className={classNames('inline-flex items-center gap-x-1', className)}>
    {showIcon ? (
      completedAt ? (
        <CheckCircleIcon className="h-5 w-5 inline-block text-green-500" />
      ) : (
        <ClockIcon
          className={classNames('h-5 w-5 inline-block stroke-2', timeOpenColor(insertedAt))}
        />
      )
    ) : null}

    {formatTimeOpen(insertedAt, completedAt || null)}
  </span>
)

export default TimeOpen
