import { ChangeEvent, TextareaHTMLAttributes, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & { setValue?: (value: string) => void }

const TextArea = forwardRef<HTMLTextAreaElement, Props>(({ setValue, ...props }, ref) => {
  const className = twMerge(
    'w-full border border-gray-300 placeholder:text-gray-400 text-sm text-gray-900 focus:border-blue-600 focus:ring-blue-600 px-3 py-2 rounded-md',
    props.disabled && 'bg-gray-50',
    props.className
  )

  const onChange = setValue
    ? ({ target }: ChangeEvent<HTMLTextAreaElement>) => setValue(target.value)
    : props.onChange

  return <textarea {...props} onChange={onChange} className={className} ref={ref} />
})

export default TextArea
