import { TransactionsQuery } from '@/dealers/_gen/gql'
import { useEffect } from 'react'
import useParams from './useParams'

const usePagination = (
  paginatedTxns: Exclude<TransactionsQuery['store'], null>['paginatedTransactions'] | undefined
) => {
  const {
    params: { startingAfter, endingBefore },
    toUpdatedSearch,
    updateParams,
  } = useParams()

  const { transactions, hasMore } = paginatedTxns || {}

  useEffect(() => {
    const onFirstPage = endingBefore !== undefined && !hasMore
    if (transactions && onFirstPage) updateParams({ endingBefore: undefined })
  }, [!transactions, startingAfter, endingBefore])

  const firstTxnId = transactions?.at(0)?.id
  const lastTxnId = transactions?.at(-1)?.id

  const hasPrev =
    firstTxnId && ((endingBefore !== undefined && hasMore) || startingAfter !== undefined)

  const hasNext = lastTxnId && (endingBefore === undefined ? hasMore : endingBefore !== undefined)
  const prevPath = hasPrev ? { search: toUpdatedSearch({ endingBefore: firstTxnId }) } : null
  const nextPath = hasNext ? { search: toUpdatedSearch({ startingAfter: lastTxnId }) } : null

  return { prevPath, nextPath }
}

export default usePagination
