import {
  LocationsSelectDocument,
  LocationsSelectQuery,
  LocationsSelectQueryVariables,
  useUpdateOrderShippingAddressMutation,
  useUpdateRfqShippingAddressMutation,
} from '@/buyers/_gen/gql'
import AutocompleteSingleSelectInputNext from '@/buyers/components/AutocompleteSingleSelectInputNext'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Field from '@/gf/components/Field'
import RadioInput from '@/gf/components/inputs/Radio'
import ModalForm from '@/gf/components/ModalForm'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import ShippingLocationM from '@/gf/modules/ShippingLocation'
import { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOrderContext } from '../context'

const EditShippingAddress = () => {
  const { orgId } = useSession()
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const client = useGqlClient()
  const [_, msgr] = useMsgs()
  const [spinnerLive, spinner] = useToggle()
  const [locationId, setLocationId] = useState<string>()
  const [updateRfqShippingAddress] = useUpdateRfqShippingAddressMutation({ client })
  const [updateOrderShippingAddress] = useUpdateOrderShippingAddressMutation({ client })
  const navigate = useNavigate()
  const rfqId = storeOrder.order.requestForQuote?.id
  const [updateRfq, setUpdateRfq] = useState(!!rfqId)
  const close = () => navigate(`/orders/${storeOrder.id}`)

  const editShippingLocation = async (event: FormEvent<HTMLFormElement>) => {
    if (!locationId) return
    event.preventDefault()
    msgr.clear()
    spinner.on()

    if (updateRfq && rfqId) {
      await updateRfqShippingAddress({ variables: { rfqId, locationId } })
    } else {
      await updateOrderShippingAddress({ variables: { storeOrderId: storeOrder.id, locationId } })
    }

    msgr.add('Shipping location updated.', 'positive')
    close()
    refetchStoreOrder()
    spinner.off()
  }

  return (
    <ModalForm
      open
      onClose={close}
      title="Edit Shipping Address"
      submitButtonDisabled={!locationId}
      submitButtonShowSpinner={spinnerLive}
      onSubmit={editShippingLocation}
    >
      <div className="space-y-4">
        <Field label="Location">
          <AutocompleteSingleSelectInputNext<
            LocationsSelectQuery,
            LocationsSelectQueryVariables,
            NonNullable<LocationsSelectQuery['org']>['locations'][number]
          >
            gqlClient={client}
            query={LocationsSelectDocument}
            queryVars={(val) => ({ orgId, value: val })}
            onChange={(shippingLocation) => setLocationId(shippingLocation?.id ?? null)}
            transformQueryToData={(queryData) => queryData.org?.locations || []}
            transformDatumToOption={(shippingLocation) => ({
              value: shippingLocation?.id ?? '',
              label: shippingLocation ? ShippingLocationM.label(shippingLocation) : '',
            })}
          />
        </Field>

        {rfqId && (
          <Field label="Update the shipping address for&hellip;">
            {['rfq', 'quote'].map((option) => (
              <div className="flex items-center gap-2" key={option}>
                <RadioInput
                  checked={option === 'rfq' ? updateRfq : !updateRfq}
                  onChange={() => setUpdateRfq(option === 'rfq')}
                  id={option}
                />

                <label htmlFor={option} className="block text-sm">
                  {option === 'rfq' ? <>this request and its quotes</> : <>this quote only</>}
                </label>
              </div>
            ))}
          </Field>
        )}
      </div>
    </ModalForm>
  )
}

export default EditShippingAddress
