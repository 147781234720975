import Money from './Money'
import LineItemAuditModule from './LineItemAudit'
import {
  LineItemAudit,
  LineItemAuditDiff,
  StoreOrderAudit,
  StoreOrderAuditPayload,
  StoreOrderAuditDiff,
} from '../../types'

// Returns a list of line item audit diffs for the given line items
const getLineItemAuditDiffs = (
  oldLineItemAudits: LineItemAudit[],
  newLineItemAudits: LineItemAudit[]
): LineItemAuditDiff[] => {
  let diffs: Record<string, LineItemAuditDiff> = {}
  diffs = newLineItemAudits.reduce(
    (acc, newLineItemAudit) => ({
      ...acc,
      [newLineItemAudit.productId]: { new: newLineItemAudit },
    }),
    diffs
  )
  diffs = oldLineItemAudits.reduce(
    (acc, oldLineItemAudit) => ({
      ...acc,
      [oldLineItemAudit.productId]: {
        ...acc[oldLineItemAudit.productId],
        old: oldLineItemAudit,
      },
    }),
    diffs
  )
  return Object.values(diffs)
}

// Returns a store order audit diff for the given store order audits
const getStoreOrderAuditDiff = (
  oldStoreOrderAudit: StoreOrderAudit,
  newStoreOrderAudit: StoreOrderAudit
): StoreOrderAuditDiff => ({
  oldShippingCost: oldStoreOrderAudit.shippingCost,
  newShippingCost: newStoreOrderAudit.shippingCost,
  oldTimingDetails: oldStoreOrderAudit.timingDetails,
  newTimingDetails: newStoreOrderAudit.timingDetails,
  oldPickup: oldStoreOrderAudit.pickup,
  newPickup: newStoreOrderAudit.pickup,
  lineItemDiffs: getLineItemAuditDiffs(
    oldStoreOrderAudit.lineItemAudits,
    newStoreOrderAudit.lineItemAudits
  ),
})

function fromPayload(payload: StoreOrderAuditPayload): StoreOrderAudit {
  return {
    ...payload,
    shippingCost: Money.fromPayload(payload.shippingCost),
    taxCost: Money.fromPayload(payload.taxCost),
    lineItemAudits: payload.lineItemAudits.map(LineItemAuditModule.fromPayload),
  }
}

export default { getStoreOrderAuditDiff, fromPayload }
