import ConversationM from '@/gf/modules/Conversation'
import { useConversationMessagesQuery, ConversationMessagesDocument } from '../_gen/gql'

const useMessagesForConversation = ({
  conversationId,
  refetchOnCompleted,
}: {
  conversationId: string
  refetchOnCompleted?: () => void
}) => {
  const { loading, error, data, refetch } = useConversationMessagesQuery({
    variables: { conversationId },
    pollInterval: ConversationM.MESSAGES_POLL_INTERVAL,
    fetchPolicy: 'no-cache',
    onCompleted: refetchOnCompleted,
  })

  return { loading, error, refetch, data: data?.conversationMessages ?? [] }
}

useMessagesForConversation.Query = ConversationMessagesDocument

export default useMessagesForConversation
