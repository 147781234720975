import { v4 as uuid } from 'uuid'

import { EqualsFilter } from '../../../types'

const build = ({
  id,
  fieldId,
  text = '',
}: {
  id?: string
  fieldId: string
  text?: string
}): EqualsFilter => ({
  id: id || uuid(),
  typeId: 'equals',
  fieldId,
  text,
})

const toApiFilter = (filter: EqualsFilter) => ['equals', filter.fieldId, filter.text]

export default { build, toApiFilter }
