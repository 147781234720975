import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import useMsgs from '@/gf/hooks/useMsgs'
import Phone from '@/gf/modules/Phone'
import { Maybe } from '@/types'
import { FormEvent, ReactNode, useMemo, useState } from 'react'
import Checkbox from '../next/forms/Checkbox'
import Processing from './Processing'

type Form = {
  values: { password: string; passwordConfirmation: string; emailOptIn: boolean }
  errors: { password: string; passwordConfirmation: string }
}

const empty = { password: '', passwordConfirmation: '', emailOptIn: true }

const Form = ({
  onClose,
  email,
  phone = null,
  success,
  customHeader,
  showSubtitle = true,
  cancelButtonEnabled = false,
  closeButtonEnabled = true,
  // Use this to navigate to another page after success
  showSpinnerOnSuccess = false,
  claimAccount,
}: {
  onClose: () => void
  email: string
  phone?: Maybe<string>
  success: JSX.Element
  customHeader?: ReactNode
  showSubtitle?: boolean
  cancelButtonEnabled?: boolean
  closeButtonEnabled?: boolean
  showSpinnerOnSuccess?: boolean
  claimAccount: (form: Form['values']) => Promise<unknown>
}) => {
  const [viewState, setViewState] = useState<'form' | 'in_progress' | 'success'>('form')
  const [form, setForm] = useState<Form>({ errors: empty, values: empty })
  const [_, msgs] = useMsgs()

  const obfuscatedEmail = useMemo(() => {
    const [username, domain] = email.split('@')

    if (username.length < 5) return email

    const asterisks = new Array(username.length - 4).fill('*').join('')
    const start = username.slice(0, 2)
    const end = username.slice(username.length - 2, username.length)

    return `${start}${asterisks}${end}@${domain}`
  }, [email])

  const updateForm = (changes: Partial<Form['values']>) =>
    setForm((prev) => ({
      ...prev,
      values: { ...prev.values, ...changes },
      errors: { password: '', passwordConfirmation: '' },
    }))

  const addError = (errors: Partial<Form['errors']>) =>
    setForm((prev) => ({
      ...prev,
      errors: { ...prev.errors, ...errors },
    }))

  const claim = async () => {
    try {
      await claimAccount(form.values)

      if (!showSpinnerOnSuccess) msgs.add('Congrats, you are now signed in!', 'positive')

      setViewState('success')
    } catch (err) {
      msgs.add('Something went wrong!', 'negative')
      console.error(err)
      setViewState('form')
    }
  }

  const submit = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (form.values.password !== form.values.passwordConfirmation) {
      addError({ passwordConfirmation: 'passwords must match' })
      return
    }

    const pwLength = 8
    const invalidPwLengthMsg = `must have at least ${pwLength} characters`

    if (form.values.password.length < pwLength) {
      addError({ password: invalidPwLengthMsg })
      return
    }

    setViewState('in_progress')
    setTimeout(claim, 2500)
  }

  return (
    <>
      {viewState === 'form' ? (
        <form onSubmit={submit}>
          <hgroup className="relative">
            {customHeader ?? (
              <h2 className="font-medium text-2xl px-6 pt-6 mt-4">Welcome to Gearflow!</h2>
            )}

            {closeButtonEnabled && (
              <CloseModalButton onClick={onClose} className="absolute top-2 right-2" />
            )}
          </hgroup>

          <div className="px-6 pb-6 pt-2 flex flex-col gap-y-6">
            {showSubtitle && <p className="text-lg">Claim your account to get started.</p>}

            <div className="space-y-4">
              <Field label="Email">
                <div className="font-medium text-base">{obfuscatedEmail}</div>
              </Field>

              {phone && (
                <Field label="Phone">
                  <div className="font-medium text-base">{Phone.format(phone)}</div>
                </Field>
              )}

              <Field label="Password" error={form.errors.password}>
                <TextInput
                  type="password"
                  value={form.values.password}
                  onChange={(e) => updateForm({ password: e.target.value })}
                />
              </Field>

              <Field label="Confirm Password" error={form.errors.passwordConfirmation}>
                <TextInput
                  type="password"
                  value={form.values.passwordConfirmation}
                  onChange={(e) => updateForm({ passwordConfirmation: e.target.value })}
                />
              </Field>

              <label className="text-sm flex items-center gap-x-2">
                <Checkbox
                  checked={form.values.emailOptIn}
                  onChange={() => updateForm({ emailOptIn: !form.values.emailOptIn })}
                />

                <span>Keep me up to date on Gearflow news and updates</span>
              </label>
            </div>

            <div className="flex gap-x-3">
              {cancelButtonEnabled && (
                <Action.S onClick={onClose} className="w-1/2">
                  Cancel
                </Action.S>
              )}

              <Action.P
                color="blue"
                type="submit"
                className={cancelButtonEnabled ? 'w-1/2' : 'w-full'}
              >
                Claim Account
              </Action.P>
            </div>

            <p className="text-xs text-gray-500">
              By signing up, you authorize Gearflow to send communications (including text messages
              if applicable) with offers & other information, possibly using automated technology.
              Consent is not a condition of purchase. Message/data rates apply.
            </p>
          </div>
        </form>
      ) : viewState === 'in_progress' || (viewState === 'success' && showSpinnerOnSuccess) ? (
        <Processing />
      ) : (
        success
      )}
    </>
  )
}
export default Form
