import { UpdateProfileError, useUpdateProfileMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import TextInput from '@/gf/components/inputs/Text'
import useMsgs from '@/gf/hooks/useMsgs'
import Phone from '@/gf/modules/Phone'
import { useState } from 'react'

type Fields = {
  name: string
  email: string
  phoneNumber: string
}

const UpdateProfileForm = ({
  hideEmail = false,
  buttonText = 'Save',
}: {
  hideEmail?: boolean
  buttonText?: string
}) => {
  const msgr = useMsgs()[1]
  const { user } = useSession()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState<UpdateProfileError>()

  const [updateProfile] = useUpdateProfileMutation({
    client: useGqlClient(),
    refetchQueries: 'active',
  })

  const [fields, setFields] = useState<Fields>({
    name: user.name || '',
    email: user.email,
    phoneNumber: user.phoneNumber || '',
  })

  const updateFields = (updates: Partial<Fields>) => setFields({ ...fields, ...updates })

  const save = async () => {
    setSaving(true)

    const { data } = await updateProfile({
      variables: {
        userId: user.id,
        name: fields.name,
        email: fields.email,
        phoneNumber: Phone.format(fields.phoneNumber),
      },
    })

    if (data?.updateProfile) {
      setErrors(data.updateProfile)
    } else {
      msgr.add('Information updated.', 'positive')
      setErrors(undefined)
    }

    setSaving(false)
  }

  return (
    <Form onSubmit={save} className="grow max-w-sm space-y-6">
      <div className="space-y-4">
        <Field label="Name" errors={errors?.name}>
          <TextInput value={fields.name} setValue={(name) => updateFields({ name })} />
        </Field>

        {!hideEmail && (
          <Field label="Email" errors={errors?.email}>
            <TextInput value={fields.email} setValue={(email) => updateFields({ email })} />
          </Field>
        )}

        <Field label="Phone Number" errors={errors?.phoneNumber}>
          <TextInput
            value={fields.phoneNumber}
            setValue={(phoneNumber) => updateFields({ phoneNumber })}
            type="tel"
          />
        </Field>
      </div>

      <div className="flex justify-end">
        <Action.P type="submit" performing={saving}>
          {buttonText}
        </Action.P>
      </div>
    </Form>
  )
}

export default UpdateProfileForm
