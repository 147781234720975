import { SortOrder, StoreOrderStep } from '@/dealers/_gen/gql'
import Link from '@/gf/components/Link'
import { getDurationDatesString, getDurationDisplay } from '@/gf/components/Reports/DurationInput'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import Tag from '@/gf/components/Reports/Tag'
import Money from '@/gf/modules/Money'
import { ReportDuration, toParam } from '@/gf/modules/ReportDuration'
import { CheckIcon, ClockIcon, XIcon } from '@heroicons/react/outline'
import orderBy from 'lodash/orderBy'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import {
  filterRfqsReceivedBetween,
  formatTimeToQuote,
  rfqToTimeToQuote,
  urgencyByPriority,
} from '../Service'
import { useContext } from './context'
import { Rfq } from './useQuery'

const HistoryTable = () => {
  const {
    filteredRfqs,
    fromDate,
    toDate,
    orgById,
    creatorById,
    duration,
    date,
    rfqsSort,
    orgId,
    userId,
    priority,
    chartTab,
    ranges,
    update,
  } = useContext()

  const sortField = rfqsSort ? rfqsSort[0] : 'send-quote'
  const sortOrder = rfqsSort ? (rfqsSort[1] === 'desc' ? 'desc' : 'asc') : 'desc'
  const range = date && ranges.filter(([start, _]) => start.toISODate() === date.toISODate())[0]

  const unsortedRows = (
    filteredRfqs ? (range ? filterRfqsReceivedBetween(filteredRfqs, range) : filteredRfqs) : []
  )?.map((rfq) => ({ ...rfq, timeToQuote: rfqToTimeToQuote(rfq) }))

  const orderByField = (() => {
    if (sortField === 'quote-created') return 'quote.insertedAt'
    if (sortField === 'send-quote') return 'timeToQuote'
    if (sortField === 'amount') return 'quote.totals.total.amount'
    if (sortField === 'user') return 'quote.creation.creator.name'
    if (sortField === 'customer') return 'quote.customer.organization.name'
    return sortField
  })()

  const rows =
    unsortedRows && orderBy(unsortedRows, orderByField, sortOrder === 'desc' ? 'desc' : 'asc')
  const xIcon = useMemo(() => <XIcon className="w-5 h-5  text-red-500" />, [])
  const checkIcon = useMemo(() => <CheckIcon className="w-5 h-5 text-green-500" />, [])
  const clockIcon = useMemo(() => <ClockIcon className="w-5 h-5 text-gray-400" />, [])
  const link = useCallback((rfq: Rfq) => <Link.T to={`/rfqs/${rfq.id}`}>{rfq.shortId}</Link.T>, [])

  return (
    <div className="pt-6 flex flex-col gap-y-3 bg-white border border-gray-300 rounded-lg shadow-sm">
      <div className="flex gap-2 items-center px-4 text-lg text-gray-900 font-medium">
        <span>Requests</span>
        <span>&mdash;</span>

        {date ? (
          <Tag onRemove={() => update({ date: null })}>
            {chartTab === 'trend' && <>Week of</>} {date.toLocaleString(DateTime.DATE_MED)}
          </Tag>
        ) : duration !== ReportDuration.Custom ? (
          getDurationDisplay(toParam(duration))
        ) : (
          getDurationDatesString({ durationStart: fromDate, durationEnd: toDate })
        )}

        {orgId && (
          <Tag onRemove={() => update({ orgId: null })}>{orgById && orgById[orgId].name}</Tag>
        )}

        {userId && (
          <Tag onRemove={() => update({ userId: null })}>
            {creatorById && creatorById[userId].displayName}
          </Tag>
        )}

        {priority && (
          <Tag onRemove={() => update({ priority: null })}>
            Urgency: {urgencyByPriority[priority]}
          </Tag>
        )}
      </div>

      <div className="px-4 pb-3 max-h-96 overflow-x-auto overflow-y-auto">
        <ReportingTable
          data={rows}
          getRowKey={({ id }) => id}
          sortBy={{
            sortBy: {
              field: sortField,
              order: sortOrder === 'desc' ? SortOrder.Desc : SortOrder.Asc,
            },
            setSortBy: (sortBy) => update({ rfqsSort: sortBy }),
          }}
          columns={[
            { header: 'Request', getValue: link },
            {
              header: 'Quote Created',
              getValue: ({ quote }) => quote.insertedAt.toLocaleString(DateTime.DATETIME_MED),
              sortByField: 'quote-created',
            },
            {
              header: 'Send Quote',
              getValue: ({ timeToQuote }) => formatTimeToQuote(timeToQuote),
              sortByField: 'send-quote',
            },
            {
              header: 'Amount',
              getValue: ({ quote }) =>
                Money.format(quote.totals.total, { maximumFractionDigits: 0 }),
              sortByField: 'amount',
            },
            {
              header: 'Conversion',
              getValue: ({ quote }) => {
                if (quote.step === StoreOrderStep.Quoted) return clockIcon
                if (quote.step === StoreOrderStep.Canceled) return xIcon
                return checkIcon
              },
            },
            {
              header: 'User',
              getValue: ({ quote }) => quote.creation.creator.displayName,
              sortByField: 'user',
            },
            {
              header: 'Customer',
              getValue: ({ quote }) => quote.customer.organization.name,
              sortByField: 'customer',
            },
          ]}
        />
      </div>
    </div>
  )
}

export default HistoryTable
