import {
  StripeCheckoutSessionStatus,
  useCreateCheckoutSessionMutation,
  useStripeCheckoutQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Action from '@/gf/components/Action'
import Modal from '@/gf/components/ModalNext'
import Spinner from '@/gf/components/Spinner'
import useConfig from '@/gf/hooks/useConfig'
import { ModalSize } from '@/types'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOrderContext } from '../context'

const StripeCheckout = ({ ...props }: { approve: () => void }) => {
  const { storeOrderId, refetchStoreOrder } = useOrderContext()
  const navigate = useNavigate()
  const { stripePublicKey } = useConfig()
  const stripePromise = useMemo(() => loadStripe(stripePublicKey), [])
  const client = useGqlClient()
  const [createCheckoutSession] = useCreateCheckoutSessionMutation({ client })
  const [showCompleteButton, setShowCompleteButton] = useState(false)

  const query = useStripeCheckoutQuery({
    variables: { storeOrderFilter: JSON.stringify(['id_eq', storeOrderId]) },
    client,
  })

  const checkoutSession = query.data?.storeOrder?.stripeCheckoutSession

  useEffect(() => {
    if (
      checkoutSession !== undefined &&
      (checkoutSession === null || checkoutSession?.status === StripeCheckoutSessionStatus.Expired)
    )
      createCheckoutSession({
        variables: {
          storeOrderId,
          returnUrl: `${window.location.origin}/orders/${storeOrderId}/accept`,
        },
      }).then(() => {
        query.refetch()
      })
  }, [checkoutSession === undefined])

  // EmbeddedCheckoutProvider requires props don't change
  const approve = useCallback(props.approve, [])

  const close =
    checkoutSession?.status === StripeCheckoutSessionStatus.Complete || showCompleteButton
      ? () => navigate(`/orders/${storeOrderId}/close-request`)
      : () => navigate(`/orders/${storeOrderId}`)

  return (
    <Modal
      onClose={() => {
        close()
        refetchStoreOrder()
      }}
      size={ModalSize.XL}
    >
      {checkoutSession && checkoutSession.status !== StripeCheckoutSessionStatus.Expired ? (
        <div className="flex flex-col items-stretch">
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret: checkoutSession.clientSecret,
              onComplete: () => {
                setShowCompleteButton(true)
                approve()
              },
            }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
          {showCompleteButton && (
            <div className="p-8 flex justify-center">
              <Action.P
                className="w-1/3"
                onClick={() => {
                  navigate(`/orders/${storeOrderId}/close-request`)
                  refetchStoreOrder()
                }}
              >
                Done
              </Action.P>
            </div>
          )}
        </div>
      ) : (
        <div className="p-8">
          <Spinner />
        </div>
      )}
    </Modal>
  )
}

export default StripeCheckout
