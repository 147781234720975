import { v4 as uuid } from 'uuid'

import { NotBlankFilter } from '../../../types'

const build = ({ id, fieldId }: { id?: string; fieldId: string }): NotBlankFilter => ({
  id: id || uuid(),
  typeId: 'not_blank',
  fieldId,
})

const toApiFilter = (filter: NotBlankFilter) => ['not_blank', filter.fieldId]

export default { build, toApiFilter }
