import { useEffect, useState } from 'react'
import { useSetupContext } from '../context'

export type Fields = {
  description: string
  returnPolicy: string
  shippingInfo: string
}

const useForm = () => {
  const { loading, store } = useSetupContext()

  const [fields, setFields] = useState<Fields>({
    description: '',
    returnPolicy: '',
    shippingInfo: '',
  })

  const updateFields = (updates: Partial<Fields>) => setFields({ ...fields, ...updates })

  useEffect(() => {
    if (!loading)
      updateFields({
        description: store.description || '',
        returnPolicy: store.returnPolicy || '',
        shippingInfo: store.shippingInfo || '',
      })
  }, [loading])

  return { fields, updateFields }
}

export default useForm
