import { ReactNode, useState } from 'react'

import type { MultiButtonModal } from '../MultiButton'

const ModalWrapper = ({
  multiButton,
  setMultiOpen,
  children,
}: {
  multiButton: MultiButtonModal
  setMultiOpen: (multiOpen: boolean) => void
  children: ReactNode
}) => {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)

  return (
    <>
      <button
        className="w-full"
        onClick={() => {
          setMultiOpen(false)
          setOpen(true)
        }}
        type="button"
      >
        {children}
      </button>
      {multiButton.modal({ open, onClose })}
    </>
  )
}

export default ModalWrapper
