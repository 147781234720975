import { StoreOrderStep as Step } from '@/dealers/_gen/gql'

import SlimBadge from '@/gf/components/SlimBadge'

type Props = Parameters<typeof SlimBadge>[0]
type Size = Parameters<typeof SlimBadge>[0]['size']

const propsByStep: Record<Step, Props> = {
  [Step.Quoted]: {
    color: 'bg-orange-200',
    textColor: 'text-orange-900',
    title: 'Quoted',
  },
  [Step.PoReceived]: {
    color: 'bg-gray-200',
    textColor: 'text-gray-900',
    title: 'PO Received',
  },
  [Step.Fulfilling]: {
    color: 'bg-green-200',
    textColor: 'text-green-900',
    title: 'Fulfilling',
  },
  [Step.Fulfilled]: {
    color: 'bg-green-600',
    textColor: 'text-white',
    title: 'Fulfilled',
  },
  [Step.Canceled]: {
    color: 'bg-red-400',
    textColor: 'text-white',
    title: 'Canceled',
  },
}

const StoreOrderStepBadge = ({
  step,
  size,
  className,
}: {
  step: Step
  size?: Size
  className?: string
}) => <SlimBadge {...propsByStep[step]} size={size} className={className} />

export default StoreOrderStepBadge
