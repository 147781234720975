import { Product, ProductAvailability } from '@/dealers/_gen/gql'
import { availabilityLabel } from '@/dealers/modules/Product'
import { CheckCircleIcon, ClockIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import pluralize from 'pluralize'

const AvailabilityStatus = ({
  availability,
  leadTime,
  leadTimeDate,
  oneLiner = false,
}: Pick<Product, 'availability' | 'leadTime' | 'leadTimeDate'> & { oneLiner?: boolean }) =>
  availability ? (
    <span className="flex flex-row gap-x-1">
      {availability === ProductAvailability.InStock ? (
        <CheckCircleIcon className="h-4 text-teal-400 mt-0.5" />
      ) : availability === ProductAvailability.OutOfStock ? (
        <ExclamationCircleIcon className="h-4 text-rose-500 mt-0.5" />
      ) : availability === ProductAvailability.BackOrdered ? (
        <ClockIcon className="h-4 text-amber-500 mt-0.5" />
      ) : null}
      <span className="flex flex-col items-end">
        {oneLiner && availability === ProductAvailability.BackOrdered
          ? null
          : availabilityLabel(availability)}

        {leadTimeDate && (
          <span className={classNames(' text-amber-600', oneLiner ? 'text-sm' : 'text-xs')}>
            Available {leadTimeDate.toUTC().toFormat('LLL dd, yyyy')}
          </span>
        )}

        {leadTime && (
          <span className={oneLiner ? 'text-sm' : 'text-xs'}>
            {leadTime}
            {!Number.isNaN(parseInt(leadTime, 10)) &&
              ` ${pluralize('day', parseInt(leadTime, 10))}`}
          </span>
        )}
      </span>
    </span>
  ) : null

export default AvailabilityStatus
