import { useEffect } from 'react'
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom'
import { SessionDocument, useSetupQuery, useSetupStartedMutation } from '../_gen/gql'
import useSession from '../hooks/useSession'
import NotFound from './NotFound'
import BusinessDetails from './Setup/BusinessDetails'
import ClaimAccount from './Setup/ClaimAccount'
import CompanyDetails from './Setup/CompanyDetails'
import { SetupContext } from './Setup/context'
import GearflowSupplierNetwork from './Setup/GearflowSupplierNetwork'
import Parts from './Setup/Parts'
import Stripe from './Setup/Stripe'
import TerritoryRestrictions from './Setup/TerritoryRestrictions'
import Welcome from './Setup/Welcome'

const Setup = () => {
  const { user, storeId } = useSession()
  const navigate = useNavigate()
  const onClaimAccount = !!useMatch('/setup/claim-account')

  const { data, loading, refetch } = useSetupQuery({
    variables: { storeId },
    notifyOnNetworkStatusChange: true,
  })

  const [markSetupStarted] = useSetupStartedMutation({ refetchQueries: [SessionDocument] })

  const { store } = data || {}

  useEffect(() => {
    if (!onClaimAccount && !user.claimed) navigate('/setup/claim-account', { replace: true })
  }, [user.claimed])

  useEffect(() => {
    if (!store?.setupStartedAt) {
      markSetupStarted()
    }
  }, [store?.setupStartedAt])

  if (store === null) throw new Error('store not found')

  const context = (() => {
    if (loading) return { loading, store }
    if (!store) throw new Error('no store')
    return { loading, store }
  })()

  return (
    <SetupContext.Provider value={{ storeId, refetch, ...context }}>
      <Routes>
        <Route path="claim-account" element={<ClaimAccount />} />
        <Route path="welcome" element={<Welcome />} />
        <Route path="business-details" element={<BusinessDetails />} />
        <Route path="company-details" element={<CompanyDetails />} />
        <Route path="gearflow-supplier-network" element={<GearflowSupplierNetwork />} />
        <Route path="territory-restrictions" element={<TerritoryRestrictions />} />
        <Route path="parts" element={<Parts />} />
        <Route path="stripe" element={<Stripe />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </SetupContext.Provider>
  )
}

export default Setup
