import { useUploadQuoteMutation } from '@/dealers/_gen/gql'
import { trackUploadQuote } from '@/gf/modules/Analytics'
import MoneyM from '@/gf/modules/Money'
import { Maybe } from '@/types'
import React, { useCallback, useMemo, useState } from 'react'
import { FormValues } from '../QuoteBuilder/useForm'
import { QuoteUploadProps } from './QuoteUploadButton'

export type UploadStage = 'processing' | 'error' | 'finished' | null

type UploadQuoteContextValue = {
  uploadStage: UploadStage
  error: Maybe<string>
  lineItems: FormValues['items']
  isReady: boolean
  rfqId: Maybe<string>
  onDismiss: () => void
  onUploadStarted: QuoteUploadProps['onUploadStarted']
  onUploadComplete: QuoteUploadProps['onUploadComplete']
  onError: QuoteUploadProps['onError']
}

const initialValue = {
  uploadStage: null,
  error: null,
  lineItems: [],
  isReady: false,
  rfqId: null,
  onDismiss: () => undefined,
  onUploadStarted: () => undefined,
  onUploadComplete: (_files: { name: string; url: string }[]) => Promise.resolve(undefined),
  onError: (_message: string) => undefined,
}

export const QuoteUploadContext = React.createContext<UploadQuoteContextValue>(initialValue)

const QuoteUploadProvider = ({
  children,
  rfqId,
}: {
  children?: React.ReactNode
  rfqId: Maybe<string>
}) => {
  const [uploadStage, setUploadStage] = useState<UploadStage>(initialValue.uploadStage)
  const [error, setError] = useState<Maybe<string>>(initialValue.error)
  const [lineItems, setLineItems] = useState<FormValues['items']>(initialValue.lineItems)

  const [uploadQuote] = useUploadQuoteMutation()

  const onUploadStarted = () => {
    setUploadStage('processing')
    setError(null)
    setLineItems([])
  }

  const onError = (message: string) => {
    setUploadStage('error')
    setError(message)
  }

  const onDismiss = () => {
    if (uploadStage !== 'processing') {
      setUploadStage(null)
      setError(null)
    }
  }

  const onUploadComplete = useCallback(
    async (uploadedFiles) => {
      try {
        setError(null)
        setUploadStage('processing')

        const attachmentUrls = uploadedFiles.map((file) => file.url)
        const variables = { requestId: rfqId, attachmentUrls }
        const { data } = await uploadQuote({ variables })

        if (!data || data?.uploadQuote.length === 0) {
          onError('Unable to find line items')

          return
        }

        const detectedLineItems = data?.uploadQuote.map((li) => ({
          partNumber: li.partNumber,
          inStock: true,
          quantity: li.quantity,
          unitPrice: li.unitPrice === null ? null : MoneyM.toDecimal(li.unitPrice),
          description: li.description,
          externalId: li.externalId,
          taskNumber: li.taskNumber,
          suggestion: li.suggestion,
          availabilityDate: null,
          rfqPartId: null,
          rfqPartNumber: null,
        }))

        trackUploadQuote(attachmentUrls, lineItems.length)

        setLineItems(detectedLineItems)
        setUploadStage('finished')
      } catch (err) {
        // setUploadStage('finished')
        onError('Unable to find line items')

        console.error(err)
      }
    },
    [rfqId]
  )

  const value = useMemo<UploadQuoteContextValue>(
    () => ({
      uploadStage,
      lineItems,
      error,
      isReady: uploadStage === 'finished' && lineItems.length > 0,
      rfqId,
      onUploadStarted,
      onUploadComplete,
      onError,
      onDismiss,
    }),
    [lineItems, error, uploadStage, rfqId]
  )

  return <QuoteUploadContext.Provider value={value}>{children}</QuoteUploadContext.Provider>
}

export default QuoteUploadProvider
