import { Maybe } from '@/types'
import { useEffect, useRef } from 'react'
import { Location, useLocation } from 'react-router-dom'

// executes callback every time the pathname or search string changes
const useLocationChange = (callback: () => void) => {
  const location = useLocation()
  const lastLocation = useRef<Maybe<Location>>(null)

  useEffect(() => {
    if (
      location.pathname !== lastLocation.current?.pathname ||
      location.search !== lastLocation.current?.search
    ) {
      lastLocation.current = location
      callback()
    }
  }, [location])
}

export default useLocationChange
