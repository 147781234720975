import { DateTime } from 'luxon'
import { useMemo } from 'react'

const NeededBy = ({
  date,
  className,
  prefix,
}: {
  date: DateTime
  className?: string
  prefix?: string
}) => {
  const formatted = useMemo(() => {
    if (date.toISODate() === DateTime.now().toISODate()) {
      return 'Today'
    }

    return date.toLocaleString({ weekday: 'long', month: 'short', day: '2-digit' })
  }, [date])

  return (
    <span className={className}>
      {prefix}
      {formatted}
    </span>
  )
}

export default NeededBy
