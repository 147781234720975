import Shipment from './Shipment'

import { ShipmentItemPayload, ShipmentItem } from '../../types'

const fromPayload = (payload: ShipmentItemPayload): ShipmentItem => ({
  ...payload,
  shipment: Shipment.fromPayload(payload.shipment),
})

export default { fromPayload }
