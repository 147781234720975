import { ChevronDownIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import React from 'react'
import { Category } from '../../types'
import useClickOutside from '../hooks/useClickOutside'
import useToggle from '../hooks/useToggle'
import { cn } from '../modules/utils'
import CategoryTree from './CategoryTree'

const CategorySelect = ({
  selectedId,
  placeholder,
  blur = false,
  categories,
  className,
  onAdd,
  onSelect,
}: {
  selectedId?: string | null
  placeholder?: string
  blur?: boolean | null
  categories?: Category[]
  className?: string
  onAdd?: (catId: string) => void
  onSelect: (catid: string | null) => void
}) => {
  const treeRef = React.useRef<HTMLDivElement>(null)
  const [visible, visibilityToggler] = useToggle(false)

  useClickOutside(treeRef, () => {
    if (visible && blur && treeRef?.current) {
      Promise.resolve().then(() => {
        visibilityToggler.off()
        treeRef?.current?.scrollIntoView({ behavior: 'auto', block: 'center' })
      })
    }
  })

  if (!categories) return null

  const categoriesByParentId = groupBy(categories, 'parentId')
  const categoryById = keyBy(categories, 'id')

  const selectedCategory = selectedId ? categoryById[selectedId] : undefined

  const onClear = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    onSelect(null)
    visibilityToggler.off()
  }

  return (
    <div className={cn('border rounded-md border-gray-300 shadow-sm', className)} ref={treeRef}>
      <div
        className="px-3 py-2 text-sm flex items-center justify-between cursor-pointer"
        onClick={visibilityToggler.toggle}
      >
        <div>{selectedCategory?.name || placeholder || '(not set)'}</div>

        {selectedId ? (
          <button
            onClick={onClear}
            type="button"
            className="text-gray-400 hover:text-gray-600 shrink-0"
          >
            <XIcon className="w-5 h-5" />
          </button>
        ) : (
          <ChevronDownIcon className="h-4 w-4 shrink-0 text-gray-500" />
        )}
      </div>

      <div className={cn('text-sm pb-2', !visible && 'hidden')}>
        <CategoryTree
          categoryId="null"
          categoriesByParentId={categoriesByParentId}
          onSelect={onSelect}
          onAdd={onAdd}
          visible={visible}
          selectedId={selectedId}
          root
        />
      </div>
    </div>
  )
}

export default CategorySelect
