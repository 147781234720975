import AddressC from '@/gf/components/Address'
import AddressModalForm from '@/gf/components/AddressModalForm'
import { PencilIcon } from '@heroicons/react/solid'
import { Address, Maybe } from '@/types'
import useToggle from '@/gf/hooks/useToggle'

const AddressInput = ({
  value,
  onChange,
  disabled = false,
  hideCompany = true,
}: {
  value: Maybe<Address>
  onChange: (address: Address) => void
  disabled?: boolean
  hideCompany?: boolean
}) => {
  const [open, toggle] = useToggle()

  const onClick = () => {
    if (disabled) return
    toggle.on()
  }

  return (
    <>
      <AddressModalForm
        onClose={toggle.off}
        open={open}
        initialAddress={value}
        onSubmit={(address) => {
          onChange(address)
          toggle.off()
        }}
        hideCompany={hideCompany}
        hideFirstLastName
      />

      <div
        className={`flex justify-between border ${
          disabled ? 'bg-gray-50' : 'cursor-pointer'
        } w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-sm px-3 py-2`}
        onClick={onClick}
      >
        {value ? (
          <AddressC
            address={value}
            hideCompany={hideCompany}
            hideName
            className="text-sm text-slate-500 max-w-prose"
          />
        ) : (
          <div className="text-gray-400 text-sm">Enter address</div>
        )}

        {!disabled && <PencilIcon className="h-5 text-slate-400 flex-shrink-0" />}
      </div>
    </>
  )
}

export default AddressInput
