import Page from '@/gf/components/Page'
import Frame from '../components/Frame'
import { ProductAvailability, useProductDetailsQuery, useUpdateProductMutation } from '../_gen/gql'
import Urls from '../modules/Urls'
import Box from '@/gf/components/Box'
import { useNavigate, useParams } from 'react-router-dom'
import ProductForm, { ProductFormValues } from '../components/ProductForm'
import Ghost from '@/gf/components/Ghost'
import useMsgs from '@/gf/hooks/useMsgs'

const Loading = () => (
  <div className="flex flex-col md:flex-row gap-x-6 gap-y-4">
    <div className="w-1/2 flex flex-col gap-y-4">
      {[1, 2, 3, 4, 5].map((i) => (
        <Ghost className="block h-10 w-full" key={i} />
      ))}
    </div>

    <div className="w-1/2 flex flex-col gap-y-4">
      {[1, 2, 3].map((i) => (
        <Ghost className="block h-10 w-full" key={i} />
      ))}
    </div>
  </div>
)

const Product = () => {
  const navigate = useNavigate()
  const [_, msgs] = useMsgs()
  const { productId } = useParams<{ productId: string }>() as { productId: string }
  const { data, loading } = useProductDetailsQuery({
    variables: { id: productId },
    fetchPolicy: 'network-only',
  })

  const product = data?.product ?? null

  const [updateProduct, { loading: saveInProgress }] = useUpdateProductMutation()

  const onSubmit = async (values: ProductFormValues) => {
    try {
      await updateProduct({
        variables: {
          id: productId,
          name: values.name,
          mpn: values.mpn,
          altMpn: values.altMpn,
          shortDescription: values.shortDescription,
          availability: values.availability,
          salePrice: values.salePrice as number,
          enabled: values.enabled,
          autoQuoteEnabled: values.autoQuoteEnabled,
          leadTime:
            values.availability === ProductAvailability.BackOrdered ? values.leadTime : null,
          leadTimeDate: null,
        },
      })

      msgs.add('Product Updated', 'positive')

      navigate(Urls.products())
    } catch (err) {
      console.error(err)
      msgs.add('Something went wrong', 'negative')
    }
  }

  return (
    <Frame
      breadcrumbs={{
        copy: 'Back to Dashboard',
        crumbs: [
          { name: 'Catalog', href: Urls.products() },
          ...(product ? [{ name: product.name, href: Urls.product(product.id) }] : []),
        ],
      }}
    >
      <Page title="Edit Product">
        <Box className="p-6 mt-6 max-w-screen-lg">
          {loading ? (
            <Loading />
          ) : product ? (
            <ProductForm
              onSubmit={onSubmit}
              saveInProgress={saveInProgress}
              defaultValues={{
                name: product.name,
                mpn: product.mpn ?? '',
                altMpn: product.altMpn,
                shortDescription: product.shortDescription ?? '',
                availability: product.availability ?? ProductAvailability.InStock,
                salePrice: product.salePrice,
                enabled: product.enabled,
                autoQuoteEnabled: product.autoQuoteEnabled ?? true,
                leadTime: product.leadTime,
              }}
            />
          ) : null}
        </Box>
      </Page>
    </Frame>
  )
}

export default Product
