import Page from '@/gf/components/Page'
import Banner from './Banner'
import Showcase from './Showcase'

const FullPagePaywall = ({ title }: { title: string }) => (
  <Page title={title}>
    <div className="space-y-8 mt-6">
      <Banner />
      <Showcase />
    </div>
  </Page>
)

export default FullPagePaywall
