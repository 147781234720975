import Box from '@/gf/components/Box'
import { LockClosedIcon } from '@heroicons/react/solid'
import ActionButtons from './ActionButtons'

const Banner = () => (
  <Box className="shadow-base p-6 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
    <div>
      <h3 className="text-orange-500 text-lg font-medium flex gap-x-1 items-center">
        <LockClosedIcon className="h-5 w-5 " /> Pro Account Feature
      </h3>
      <p className="text-lg">To unlock this feature, upgrade to Sales Hub Pro</p>
    </div>
    <ActionButtons />
  </Box>
)

export default Banner
