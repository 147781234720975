import { ArrowRightIcon } from '@heroicons/react/solid'

import useToggle from '@/gf/hooks/useToggle'

import ClaimAccountModal from '../ClaimAccount/Modal'

const ClaimAccountBanner = () => {
  const [claimModalOpen, claimModalToggle] = useToggle()

  return (
    <>
      <div className="flex items-center justify-center gap-x-12 bg-orange-300 py-2 text-orange-900 font-medium min-h-[3rem] px-3">
        <p>
          Claim your free Gearflow account in 1 click to easily track and manage your orders across
          your team.
        </p>

        <button
          type="button"
          onClick={claimModalToggle.on}
          className="flex-shrink-0 flex items-center gap-x-2 rounded-md hover:bg-orange-900 hover:bg-opacity-10 px-3 py-2"
        >
          Claim Account <ArrowRightIcon className="inline-block h-4 w-4 mt-0.5" />
        </button>
      </div>

      <ClaimAccountModal open={claimModalOpen} onClose={claimModalToggle.off} />
    </>
  )
}

export default ClaimAccountBanner
