import Modal from '@/gf/components/ModalNext'
import { useNavigate } from 'react-router-dom'
import { useOrderContext } from '../context'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Link from '@/gf/components/Link'

const Confetti = () => <span className="text-5xl">🎊</span>

const TutorialComplete = () => {
  const navigate = useNavigate()
  const { storeOrder } = useOrderContext()
  const onClose = () => navigate(`/orders/${storeOrder.id}`)

  return (
    <Modal open onClose={onClose}>
      <div className="relative bg-green-100 flex justify-between px-8 py-10 items-center">
        <Confetti />
        <h3 className="text-green-700 text-4xl font-medium text-center">Tutorial Complete!</h3>
        <Confetti />
        <CloseModalButton className="absolute top-2 right-2" onClick={onClose} />
      </div>

      <div className="px-8 py-6 space-y-3 text-lg">
        <p>
          Awesome! You&apos;ve completed the tutorial. Creating requests will be a breeze for you.
        </p>
        <p>Remember, we&apos;re here to help if you need any assistance.</p>

        <div className="flex justify-center">
          <Link.P
            to={`/rfqs/create?source.path=${encodeURIComponent(
              document.location.pathname
            )}&source.button=create-request`}
            size="lg"
          >
            Create Request
          </Link.P>
        </div>
      </div>
    </Modal>
  )
}

export default TutorialComplete
