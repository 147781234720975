import useSession from '@/dealers/hooks/useSession'
import useTimelineEvents from '@/dealers/hooks/useTimelineEvents'
import Urls from '@/dealers/modules/Urls'
import Box from '@/gf/components/Box'
import RfqEvents from '@/gf/components/RfqEvents'
import StoreOrderEventM from '@/gf/modules/StoreOrderEvent'
import { useNavigate } from 'react-router-dom'
import TimelineNoteForm from './Timeline/TimelineNoteForm'
import { useRfqContext } from './context'
import SidebarLayout from './SidebarLayout'

const Timeline = () => {
  const { rfq } = useRfqContext()
  const navigate = useNavigate()
  const { user } = useSession()
  const result = useTimelineEvents({ requestForQuoteId: rfq.id })

  const events = result.data?.events

  return (
    <SidebarLayout className="flex flex-grow flex-col sm:flex-row gap-x-6">
      <Box className="shadow-base flex-grow py-8 px-6 ">
        <div className="max-w-xl flex flex-col gap-y-6">
          <TimelineNoteForm
            rfqId={rfq.id}
            onNoteAdded={result.refetch}
            onGoToMessagesClick={() => navigate(Urls.requestForQuoteMessages(rfq.id))}
          />

          <RfqEvents
            events={events?.requestEvents}
            storeOrderEvents={events?.orderEvents}
            storeOrderEventMapper={StoreOrderEventM.getSupplierEventDisplayData(user)}
            showStoreNames={false}
          />
        </div>
      </Box>
    </SidebarLayout>
  )
}

export default Timeline
