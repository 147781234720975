import { AutocompleteOption, ShippingLocation, ShippingLocationPayload } from '../../types'
import Address from './Address'
import Time from './Time'

const fromPayload = (payload: ShippingLocationPayload): ShippingLocation => {
  const result = {
    ...payload,
    address: payload.address ? Address.fromPayload(payload.address) : null,
    insertedAt: Time.fromPayload(payload.insertedAt),
  }

  return result
}

const label = (
  location:
    | Pick<ShippingLocation, 'name' | 'code' | 'branch'>
    | {
        name: string
        code: string | null
        branch: { name: string | null } | null
      },
  withBranchName = true
) => {
  const branchName = location.branch ? ` • ${location.branch?.name}` : ''
  const locationCode = location.code ? location.code : ''
  return `${location.name} ${locationCode}${withBranchName ? branchName : ''}`
}

const toAutocompleteOption = (
  location:
    | Pick<ShippingLocation, 'id' | 'name' | 'code' | 'branch'>
    | {
        id: string
        name: string
        code: string | null
        branch: { name: string | null } | null
      },
  withBranchName = true
): AutocompleteOption => ({
  value: location.id,
  label: label(location, withBranchName),
})

export default {
  fromPayload,
  label,
  toAutocompleteOption,
}
