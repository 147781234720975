import { ApproveBalanceQuery, LimitedOrderQuery, SelectedPaymentMethod } from '@/buyers/_gen/gql'
import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import Money from '@/gf/modules/Money'
import { RadioGroup } from '@headlessui/react'
import { CalendarIcon, CreditCardIcon, UserCircleIcon } from '@heroicons/react/outline'

export const PaymentMethodField = ({
  selectedPaymentMethod,
  paymentMethods,
  balanceCreditLimit,
  vendorLink,
  setSelectedPaymentMethod,
}: {
  selectedPaymentMethod: SelectedPaymentMethod | null | undefined
  paymentMethods: SelectedPaymentMethod[]
  balanceCreditLimit:
    | Exclude<ApproveBalanceQuery['storeOrder'], null>['balanceCreditLimit']
    | undefined
  vendorLink:
    | Exclude<
        Exclude<LimitedOrderQuery['storeOrder'], null>['order']['requestForQuote'],
        null
      >['vendorLinks'][number]
    | null
  setSelectedPaymentMethod: (pm: SelectedPaymentMethod | null) => void
}) => (
  <Field label="Payment Method" className="py-4">
    {!selectedPaymentMethod ? (
      <RadioGroup
        onChange={(paymentMethod) => {
          setSelectedPaymentMethod(paymentMethod as SelectedPaymentMethod)
        }}
      >
        <div className="flex flex-col items-stretch gap-y-1.5">
          {paymentMethods.map((paymentMethod) => (
            <RadioGroup.Option key={paymentMethod} value={paymentMethod}>
              <div className="py-2 px-4 border border-gray-300 rounded-lg shadow-sm text-gray-900 text-sm cursor-pointer">
                {paymentMethod === SelectedPaymentMethod.Direct && (
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center gap-x-0.5">
                      <UserCircleIcon className="w-5 h-5 inline-flex shrink-0 text-gray-800" />
                      <RadioGroup.Label as="span">Pay on Acount</RadioGroup.Label>
                    </div>

                    {vendorLink && (
                      <RadioGroup.Description as="span">
                        <span className="text-gray-500">
                          Account {vendorLink.accountNumber || 'N/A'}
                        </span>
                      </RadioGroup.Description>
                    )}
                  </div>
                )}

                {paymentMethod === SelectedPaymentMethod.Stripe && (
                  <div className="flex flex-row items-center gap-x-0.5">
                    <CreditCardIcon className="w-5 h-5 inline-flex shrink-0 text-gray-800" />
                    <RadioGroup.Label as="span">Credit card</RadioGroup.Label>
                  </div>
                )}

                {paymentMethod === SelectedPaymentMethod.BalanceTerms && (
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center gap-x-0.5">
                      <CalendarIcon className="w-5 h-5 inline-flex shrink-0 text-gray-800" />
                      <RadioGroup.Label as="span">Net 30 terms</RadioGroup.Label>
                    </div>

                    {balanceCreditLimit && (
                      <RadioGroup.Description as="span">
                        <span className="text-gray-500">
                          {Money.format(balanceCreditLimit.creditLimit)} of{' '}
                          {Money.format(balanceCreditLimit.maxCreditLimit)} available
                        </span>
                      </RadioGroup.Description>
                    )}
                  </div>
                )}

                {paymentMethod === SelectedPaymentMethod.Balance && (
                  <div className="flex flex-row items-center gap-x-0.5">
                    <CreditCardIcon className="w-5 h-5 inline-flex shrink-0 text-gray-800" />
                    <RadioGroup.Label as="span">Credit card/Bank debit</RadioGroup.Label>
                  </div>
                )}
              </div>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    ) : (
      <div className="flex flex-row justify-between items-start gap-x-2">
        <div className="text-sm text-gray-900">
          {selectedPaymentMethod === SelectedPaymentMethod.Direct && (
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-x-0.5">
                <UserCircleIcon className="w-5 h-5 inline-flex shrink-0 text-gray-800" />
                <span>Pay on account</span>
              </div>

              {vendorLink && (
                <span className="text-gray-500">Account {vendorLink.accountNumber || 'N/A'}</span>
              )}
            </div>
          )}

          {selectedPaymentMethod === SelectedPaymentMethod.Stripe && (
            <div className="flex flex-row items-center gap-x-0.5">
              <CreditCardIcon className="w-5 h-5 inline-flex shrink-0 text-gray-800" />
              Credit card
            </div>
          )}

          {selectedPaymentMethod === SelectedPaymentMethod.BalanceTerms && (
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-x-0.5">
                <CalendarIcon className="w-5 h-5 inline-flex shrink-0 text-gray-800" />
                <span>Net 30 terms</span>
              </div>

              {balanceCreditLimit && (
                <span className="text-gray-500">
                  {Money.format(balanceCreditLimit.creditLimit)} of{' '}
                  {Money.format(balanceCreditLimit.maxCreditLimit)} available
                </span>
              )}
            </div>
          )}

          {selectedPaymentMethod === SelectedPaymentMethod.Balance && (
            <div className="flex flex-row items-center gap-x-0.5">
              <CreditCardIcon className="w-5 h-5 inline-flex shrink-0 text-gray-800" />
              Credit card/Bank debit
            </div>
          )}
        </div>

        {/* Only show the edit button when there are multiple payment methods available */}
        {paymentMethods.length > 1 && (
          <Action.T className="text-sm" onClick={() => setSelectedPaymentMethod(null)}>
            Edit
          </Action.T>
        )}
      </div>
    )}
  </Field>
)
