import { useJsApiLoader } from '@react-google-maps/api'
import useConfig from './useConfig'

// USE THIS HOOK INSTEAD OF useJsApiLoader DIRECTLY!
// IT WILL LIKELY THROW AN ERROR WHEN YOU TRY LOAD THE SCRIPT WITH DIFFERENT OPTIONS

const libraries = ['places'] as 'places'[]

const useGoogleMapsScriptLoader = () => {
  const config = useConfig()

  return useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsApiKey,
    libraries,
  })
}

export default useGoogleMapsScriptLoader
