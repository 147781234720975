import Spinner from '@/gf/components/Spinner'

const Processing = () => (
  <div className="flex justify-center items-center py-12 px-8">
    <div className="flex flex-col gap-y-2 text-center max-w-[16rem]">
      <h4 className="text-2xl font-medium">Setting up your Gearflow account</h4>
      <Spinner />

      <p className="text-lg">One moment while we set up your new Gearflow account.</p>
    </div>
  </div>
)

export default Processing
