import { cn } from '../modules/utils'
import Action from './Action'

const FilterAction = ({
  selected,
  onSelect,
  children,
}: {
  selected: boolean
  onSelect: () => void
  children: React.ReactNode
}) => {
  const props = { onClick: () => onSelect(), size: 'sm', children } as const
  const className = 'whitespace-nowrap'

  return (
    <>
      <Action.P {...props} className={cn(className, !selected && 'hidden')} />
      <Action.S {...props} className={cn(className, selected && 'hidden')} />
    </>
  )
}

export default FilterAction
