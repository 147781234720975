import { App, useTokenSignInMutation } from '@/dealers/_gen/gql'
import GfTokenSignInProvider from '@/gf/providers/TokenSignInProvider'

const TokenSignInProvider = ({ children }: { children: React.ReactNode }) => {
  const [tokenSignIn] = useTokenSignInMutation()
  const signIn = (token: string) => tokenSignIn({ variables: { app: App.SalesHub, token } })

  return <GfTokenSignInProvider signIn={signIn}>{children}</GfTokenSignInProvider>
}

export default TokenSignInProvider
