import { StoreOrderDiscount, StoreOrderDiscountPayload } from '../../types'

const fromPayload = (payload: StoreOrderDiscountPayload): StoreOrderDiscount => ({
  ...payload,
  percent: parseFloat(payload.percent),
})

export default {
  fromPayload,
}
