import LinkNext from '@/gf/components/Link'
import Modal from '@/gf/components/ModalNext'
import { useNavigate } from 'react-router-dom'

const SetupComplete = () => {
  const navigate = useNavigate()

  return (
    <Modal open onClose={() => navigate('/dashboard')}>
      <h3 className="bg-blue-100 text-blue-900 py-10 px-6 text-center text-3xl font-medium">
        Welcome to the Gearflow Supplier Network!
      </h3>

      <div className="p-6 space-y-6 text-lg">
        <div className="space-y-2">
          <p className="prose">
            You will now start receiving requests for quotes when a parts request match where and
            what you sell.
          </p>

          <p className="prose">
            To change any of your sales and bank details, you can access your info under Settings.
          </p>
        </div>

        <div className="flex justify-center">
          <LinkNext.P to="/dashboard">Get Started</LinkNext.P>
        </div>
      </div>
    </Modal>
  )
}

export default SetupComplete
