import { useAddCustomerMutation } from '../../_gen/gql'
import AddressM from '../../modules/Address'

import Form from './Form'
import { CustomerForm } from './types'
import { ModalSize } from '@/types'
import { useNavigate } from 'react-router-dom'
import Urls from '@/dealers/modules/Urls'
import Modal from '@/gf/components/ModalNext'
import CloseModalButton from '@/gf/components/CloseModalButton'

const AddCustomerModal = ({
  open,
  onClose,
  onContactSelected,
}: {
  open: boolean
  onClose: () => void
  onContactSelected?: (contactId: string) => void
}) => {
  const navigate = useNavigate()
  const [addCustomer, { loading: addInProgress }] = useAddCustomerMutation()

  const onSubmit = async (values: CustomerForm) => {
    if (!values || addInProgress) {
      return
    }

    const { data } = await addCustomer({
      variables: {
        companyName: values.companyName,
        accountNumber: values.accountNumber,
        address: values.address ? AddressM.addressToAddressInput(values.address) : null,
        phoneNumber: values.companyPhoneNumber,
        contactName: values.contactName,
        email: values.contactEmail,
        contactPhoneNumber: values.contactPhoneNumber,
        message: '',
      },
    })

    const customer = data?.addCustomer

    if (onContactSelected && customer) {
      onClose()
      onContactSelected(customer)
    } else {
      navigate(Urls.customerAccounts())
    }
  }

  return (
    <Modal onClose={onClose} open={open} size={ModalSize.SM}>
      <div className="relative p-6">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />
        <h2 className="text-2xl font-medium pb-4">Add New Customer</h2>

        <Form onNext={onSubmit} performing={addInProgress} />
      </div>
    </Modal>
  )
}

export default AddCustomerModal
