import {
  AdditionalCharge,
  Refund,
  RfqEventData,
  RfqEventType,
  Store,
  StoreOrderAudit,
  StoreOrderEventData,
  StoreOrderEventPrivacy,
  StoreOrderEventType,
  Transaction,
  User,
} from '@/types'

import Id from '@/gf/modules/Id'
import { useTimelineEventsQuery } from '@/dealers/_gen/gql'

const useTimelineEvents = ({
  storeOrderId,
  requestForQuoteId,
}: {
  storeOrderId?: string
  requestForQuoteId?: string
}) => {
  const result = useTimelineEventsQuery({
    variables: { requestForQuoteId: requestForQuoteId ?? null, storeOrderId: storeOrderId ?? null },
  })

  const data = result.data && {
    ...result.data,
    events: {
      ...result.data.events,
      orderEvents: result.data.events.orderEvents.map((soe) => ({
        ...soe,
        type: soe.type as StoreOrderEventType,
        note: soe.note as string,
        storeOrderShortId: Id.shorten(soe.storeOrderId),
        transaction: soe.transaction ? (soe.transaction as Transaction) : undefined,
        refund: soe.refund as Refund,
        additionalCharge: soe.additionalCharge
          ? (soe.additionalCharge as AdditionalCharge)
          : undefined,
        shipment: soe.shipment
          ? {
              ...soe.shipment,
              link: soe.shipment.trackingLink ?? undefined,
              carrier: soe.shipment.shippingCarrier ?? undefined,
              tracking: soe.shipment.trackingNumber ?? undefined,
              shipmentItems: soe.shipment.items,
            }
          : undefined,
        storeOrderAudit: soe.storeOrderAudit as StoreOrderAudit,
        diffStoreOrderAudit: soe.diffStoreOrderAudit
          ? (soe.diffStoreOrderAudit as unknown as StoreOrderAudit)
          : undefined,
        data: soe.data as StoreOrderEventData,
        user: (soe.user as User) ?? undefined,
        emailSent: soe.emailSent as boolean,
        privacy: soe.privacy as StoreOrderEventPrivacy,
        storeName: soe.storeName as string,
      })),
      requestEvents: result.data.events.requestEvents.map((rfqe) => ({
        ...rfqe,
        note: rfqe.note as string,
        requestForQuoteShortId: Id.shorten(rfqe.requestForQuoteId),
        user: (rfqe.user as User) ?? undefined,
        store: rfqe.store as Store,
        data: rfqe.data as RfqEventData,
        type: rfqe.type as RfqEventType,
        emailSent: rfqe.emailSent as boolean,
        privacy: rfqe.privacy as string,
      })),
    },
  }

  return { ...result, data }
}

export default useTimelineEvents
