import React from 'react'
import { cn } from '../modules/utils'

export type CheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string
  setChecked?: (checked: boolean) => void
  inputClassName?: string | undefined
  color?: 'orange' | 'blue'
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      children,
      setChecked,
      inputClassName: extraInputClassName,
      className,
      color = 'orange',
      ...props
    },
    ref
  ) => {
    const onChange = setChecked
      ? (event: React.ChangeEvent<HTMLInputElement>) => setChecked(event.target.checked)
      : props.onChange

    const labelClassName = cn('block flex gap-2 items-center text-sm', className, {
      'cursor-pointer': !props.disabled,
      'cursor-not-allowed text-gray-500': props.disabled,
    })

    const inputClassName = cn(
      'h-4 w-4  border-gray-300 rounded',
      color === 'orange' ? 'focus:ring-gearflow' : 'focus:ring-blue-600',
      extraInputClassName,
      {
        'text-gearflow': !props.disabled && color === 'orange',
        'text-blue-500': !props.disabled && color === 'blue',
        'cursor-pointer ': !props.disabled,
        'cursor-not-allowed text-gray-400': props.disabled,
      }
    )

    return (
      <label className={labelClassName} htmlFor={props.id}>
        <input
          {...props}
          onChange={onChange}
          type="checkbox"
          className={inputClassName}
          ref={ref}
        />

        {(children || label) && <span>{children || label}</span>}
      </label>
    )
  }
)

type BlueCheckboxProps = {
  checked: boolean
  onChange: (e) => void
  label: string
  id: string
}

export function BlueCheckbox({ checked, onChange, label, id }: BlueCheckboxProps) {
  return (
    <div className="relative flex items-start my-2">
      <div className="flex items-center h-5">
        <input
          checked={checked}
          onChange={onChange}
          id={id}
          name={id}
          type="checkbox"
          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="text-gray-700">
          {label}
        </label>
      </div>
    </div>
  )
}

export default Checkbox
