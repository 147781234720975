import type { RequestForQuote } from '../Requests'

import { RequestForQuoteStep, StoreOrderStep } from '@/dealers/_gen/gql'

import GfPartsCell from '@/gf/components/PartsCell'

const rfqSteps = [
  RequestForQuoteStep.PoReceived,
  RequestForQuoteStep.Fulfilling,
  RequestForQuoteStep.Fulfilled,
]

const storeOrderSteps = [
  StoreOrderStep.PoReceived,
  StoreOrderStep.Fulfilling,
  StoreOrderStep.Fulfilled,
]

const PartsCell = ({ rfq, children }: { rfq: RequestForQuote; children: React.ReactNode }) => {
  const areLineItems = rfqSteps.includes(rfq.step)

  const parts = areLineItems
    ? rfq.quote && storeOrderSteps.includes(rfq.quote.step)
      ? rfq.quote.lineItems.map((li) => ({
          id: li.id,
          backordered: !li.inStock,
          availableAt: li.availableAt,
          description: li.product.name,
          mpn: li.product.mpn,
          quantity: li.quantity,
        }))
      : []
    : rfq.partRequests.map((p) => ({
        id: p.id,
        backordered: false,
        availableAt: null,
        description: p.description,
        mpn: p.mpn,
        quantity: p.quantity,
      }))

  return (
    <GfPartsCell parts={parts} areLineItems={areLineItems}>
      {children}
    </GfPartsCell>
  )
}

export default PartsCell
