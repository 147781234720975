import { Portal } from '@headlessui/react'
import { motion } from 'framer-motion'
import useMsgs from '@/gf/hooks/useMsgs'
import Message from '@/gf/components/Message'

const Messages = () => {
  const [msgs, msgr] = useMsgs()

  return (
    <Portal>
      <div
        aria-live="assertive"
        className="w-96 z-30 fixed right-6 top-4 flex flex-col space-y-4 items-end pointer-events-none sm:items-start"
      >
        {msgs.map((msg) => (
          <motion.div
            key={msg.id}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.3, type: 'spring' }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 100 },
            }}
            className="self-stretch"
          >
            <Message msg={msg} removeMsg={msgr.remove} />
          </motion.div>
        ))}
      </div>
    </Portal>
  )
}

export default Messages
