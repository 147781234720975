import { useStoreOrderBillingAddressQuery } from '@/dealers/_gen/gql'
import Urls from '@/dealers/modules/Urls'
import Box from '@/gf/components/Box'
import Link from '@/gf/components/Link'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import Phone from '@/gf/modules/Phone'
import { ChatAltIcon, GlobeAltIcon } from '@heroicons/react/outline'
import { useRfqContext } from './context'
import SidebarLayout from './SidebarLayout'

const InfoLine = ({ label, content }) => (
  <div className="flex gap-x-8 text-sm">
    <div className="w-full max-w-[8.125rem] leading-5">{label}</div>
    <div className="leading-5">{content}</div>
  </div>
)
const CustomerDetails = () => {
  const { rfq } = useRfqContext()

  const { data: billingAddressData } = useStoreOrderBillingAddressQuery({
    variables: { storeOrderId: rfq.quote?.id ?? '' },
    skip: !rfq.quote?.id,
  })

  const billingAddress = billingAddressData?.storeOrderBillingAddress ?? null

  const customer = rfq.user.organization

  return (
    <SidebarLayout className="flex-grow flex flex-col sm:flex-row gap-x-6">
      <Box className="shadow-base flex-grow py-8 px-6 flex flex-col gap-y-6">
        <div className="flex gap-x-4 items-center">
          {customer.logoUrl && (
            <img
              src={customer.logoUrl}
              alt=""
              className="h-12 w-12 rounded-full p-1 object-contain bg-white shadow-base"
            />
          )}
          <div>
            <h5 className="text-lg font-medium">{customer.name}</h5>
            {customer.billingAddress && (
              <p>
                {customer.billingAddress.city}, {customer.billingAddress.state}
              </p>
            )}
          </div>
          <Link.S to={Urls.requestForQuoteMessages(rfq.id, { customer: true })}>
            <ChatAltIcon className="inline-block w-4 h-4" /> Send Message
          </Link.S>
        </div>

        <div>
          <div className="mb-2 flex flex-row items-center gap-x-2">
            <h4 className="text-lg font-medium">Customer Info</h4>
            {/* Request details is the RFQ Vendor link */}
            {!rfq.requestDetails && (
              <Tooltip>
                <TooltipTrigger>
                  <div className="p-1 inline-flex bg-gray-100 border border-gray-200 rounded-full">
                    <GlobeAltIcon className="w-4 h-4 inline-flex shrink-0 text-gray-600" />
                  </div>
                </TooltipTrigger>
                <TooltipContent className="max-w-prose p-3 z-50 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                  This request is from the Gearflow network.
                </TooltipContent>
              </Tooltip>
            )}
          </div>
          <InfoLine label="Customer:" content={customer.name} />
          <InfoLine label="Account number:" content={rfq.requestDetails?.accountNumber ?? 'N/A'} />
          <InfoLine label="Requested by:" content={rfq.user.name} />
          <InfoLine
            label="Phone:"
            content={rfq.user.phoneNumber ? Phone.format(rfq.user.phoneNumber) : ''}
          />
        </div>

        <div>
          <h4 className="text-lg font-medium mb-2">Request Status</h4>
          <InfoLine label="Requested date:" content={rfq.insertedAt.toFormat('D')} />
          <InfoLine label="Needed by:" content={rfq.neededBy?.toFormat('D')} />
        </div>

        {billingAddress && (
          <div>
            <h4 className="text-lg font-medium mb-2">Bill To</h4>
            <p className="text-sm leading-5">
              {billingAddress?.lineOne}
              <br />
              {billingAddress?.city}, {billingAddress?.state} {billingAddress?.postalCode}
            </p>
          </div>
        )}
      </Box>
    </SidebarLayout>
  )
}

export default CustomerDetails
