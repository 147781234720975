import { useMemo } from 'react'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient as createWsClient } from 'graphql-ws'

import useConfig from '@/gf/hooks/useConfig'
import ApolloWsClientContext from '@/dealers/contexts/ApolloWsClient'
import useSession from '@/dealers/hooks/useSession'

const ApolloWsClientProvider = ({ children }: { children: React.ReactNode }) => {
  const config = useConfig()
  const { token } = useSession()

  const client = useMemo(() => {
    const link = new GraphQLWsLink(
      createWsClient({ url: config.dealersGqlWsUrl, connectionParams: { token } })
    )

    return new ApolloClient({ link, cache: new InMemoryCache() })
  }, [token])

  if (!token) {
    return <>{children}</>
  }

  return <ApolloWsClientContext.Provider value={client}>{children}</ApolloWsClientContext.Provider>
}

export default ApolloWsClientProvider
