import { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { useLocation } from 'react-router-dom'

import { Msg, MsgAction, MsgTone } from '@/types'

import MsgsContext from '@/gf/contexts/MsgsContext'

export const UNKNOWN_ERROR = 'Whoops, something went wrong. Please contact support.'

// Remove messages after 10 seconds
const MSG_TIME = 10000

const MsgsProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const [msgs, setMsgs] = useState<Msg[]>([])

  const remove = (msg: Msg) =>
    setMsgs((prevMsgs) => prevMsgs.filter((prevMsg) => prevMsg.id !== msg.id))

  const clear = () => {
    const now = new Date().getTime()
    // Don't clear msgs created less than 1s ago
    setMsgs((prevMsgs) => prevMsgs.filter((m) => now - m.createdAt.getTime() < 1000))
  }

  const add = (text: string, tone: MsgTone = 'neutral') => {
    const newMsg = { id: uuid(), text, tone, createdAt: new Date() }
    setMsgs((prevMsgs) => [...prevMsgs, newMsg])
    setTimeout(() => remove(newMsg), MSG_TIME)
  }

  const addWithAction = (text: string, action: MsgAction, tone: MsgTone = 'neutral') => {
    const newMsg = { id: uuid(), text, tone, action, createdAt: new Date() }
    setMsgs((prevMsgs) => [...prevMsgs, newMsg])
    setTimeout(() => remove(newMsg), MSG_TIME)
  }

  const addUnknownError = () => add(UNKNOWN_ERROR, 'negative')

  useEffect(() => {
    clear()
  }, [location.pathname])

  return (
    <MsgsContext.Provider value={[msgs, { add, addUnknownError, remove, clear, addWithAction }]}>
      {children}
    </MsgsContext.Provider>
  )
}

export default MsgsProvider
