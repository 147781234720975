import { createContext, useContext } from 'react'
import classNames from 'classnames'

type TitleProps = JSX.IntrinsicElements['dt']
type DescProps = JSX.IntrinsicElements['dd']

const ListDescContext = createContext<{ desc?: DescProps; title?: TitleProps }>({})

export const List = ({
  children,
  title,
  desc,
}: {
  children: React.ReactNode
  title?: TitleProps
  desc?: DescProps
}) => (
  <dl className="space-y-4 sm:space-y-0">
    <ListDescContext.Provider value={{ desc, title }}>{children}</ListDescContext.Provider>
  </dl>
)

const Title = (props: TitleProps) => {
  const contextProps = useContext(ListDescContext).title || {}
  const { className, ...otherProps } = { ...contextProps, ...props }
  return (
    <dt
      className={classNames('font-medium sm:font-normal text-sm xl:text-base', className)}
      {...otherProps}
    />
  )
}

const Desc = (props: DescProps) => {
  const contextProps = useContext(ListDescContext).desc || {}
  const { className, ...otherProps } = { ...contextProps, ...props }
  return (
    <dd className={classNames('sm:col-span-2 text-sm xl:text-base', className)} {...otherProps} />
  )
}

export const Row = ({
  className,
  children,
  title,
  desc,
}: {
  className?: string
  children: [React.ReactNode, React.ReactNode]
  title?: TitleProps
  desc?: DescProps
}) => (
  <div className={classNames('sm:grid sm:grid-cols-3 text-base', className)}>
    <Title {...title}> {children[0]}</Title>
    <Desc {...desc}>{children[1]}</Desc>
  </div>
)
