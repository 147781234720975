import { v4 as uuid } from 'uuid'

import { FalseFilter } from '../../../types'

const build = ({ id, fieldId }: { id?: string; fieldId: string }): FalseFilter => ({
  id: id || uuid(),
  typeId: 'false',
  fieldId,
})

const toApiFilter = (filter: FalseFilter) => ['false', filter.fieldId]

export default { build, toApiFilter }
