import { OmniSearchQuery, useOmniSearchQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import MachineDownIcon from '@/gf/components/MachineDownIcon'
import { CogIcon } from '@heroicons/react/solid'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import RfqStepBadge from '../../RfqStepBadge'
import StoreOrderStepBadge from '../../StoreOrderStepBadge'

type SearchResults = {
  requests: OmniSearchQuery['searchRequests']['requests']
  storeOrders: Exclude<OmniSearchQuery['org'], null>['paginatedStoreOrders']['storeOrders']
  vendors: Exclude<OmniSearchQuery['org'], null>['paginatedVendors']['vendors']
  locations: OmniSearchQuery['shippingLocationsSearch']['shippingLocations']
  machines: OmniSearchQuery['paginatedOrgMachines']['machines']
}

const ResultsCategory = ({ title, children }) => (
  <div className="relative">
    <h4 className="font-medium text-gray-700 pt-3 pb-2 px-2 sticky top-0 bg-white">{title}</h4>
    <ul className="space-y-2 pb-3">{children}</ul>
  </div>
)

const Item = ({ children, href, onClick }) => (
  <li className="block group hover:bg-slate-50 text-gray-700 rounded">
    <Link to={href} onClick={onClick} className="block px-2 py-1">
      {children}
    </Link>
  </li>
)

const Request = ({
  data,
  onClick,
}: {
  data: SearchResults['requests'][number]
  onClick: () => void
}) => {
  const orgMachine = data.orgMachines?.[0]

  return (
    <Item href={`/rfqs/${data.id}`} onClick={onClick}>
      <span className="flex items-center">
        <span className="block space-y-0.5 flex-grow">
          {orgMachine && (
            <span className="text-sm flex items-center gap-x-1">
              {orgMachine.machine.make} {orgMachine.machine.model} {orgMachine.machine.year}{' '}
              {data.machineDown && <MachineDownIcon />}
            </span>
          )}

          <ul className="text-sm flex gap-x-2">
            {data.parts.map((part) => (
              <li key={part.id} className="rounded border border-gray-100 py-0.5 px-1 leading-4">
                {part.description || part.mpn} x {part.quantity}
              </li>
            ))}
          </ul>

          <span className="flex gap-x-2">
            <span className="text-xs text-gray-500 italic">
              Created {data.insertedAt.toRelative()} by {data.fullName}
            </span>
          </span>
        </span>
        <RfqStepBadge step={data.step} />
      </span>
    </Item>
  )
}

const StoreOrder = ({
  data,
  onClick,
}: {
  data: SearchResults['storeOrders'][number]
  onClick: () => void
}) => {
  const user = data.order.user

  return (
    <Item href={`/orders/${data.id}`} onClick={onClick}>
      <span className="flex items-center">
        <span className="block space-y-0.5 flex-grow">
          <span className="block text-sm">{data.store.name}</span>
          {data.purchaseOrder && <span className="text-sm">PO: {data.purchaseOrder}</span>}

          <span className="flex gap-x-2">
            <span className="text-xs text-gray-500 italic">
              Created {data.insertedAt.toRelative()} {user && `by ${user.displayName}`}
            </span>
          </span>
        </span>

        <StoreOrderStepBadge step={data.step} />
      </span>
    </Item>
  )
}

const Vendor = ({
  data,
  onClick,
}: {
  data: SearchResults['vendors'][number]
  onClick: () => void
}) => (
  <Item href={`/vendors/${data.id}`} onClick={onClick}>
    <span className="flex text-sm gap-x-2 items-center tex-sm">{data.name}</span>
    <span className="italic text-xs text-gray-500">Account # {data.accountNumbers.join(', ')}</span>
  </Item>
)

const Location = ({
  data,
  onClick,
}: {
  data: SearchResults['locations'][number]
  onClick: () => void
}) => (
  <Item href={`/locations/${data.id}`} onClick={onClick}>
    <span className="flex flex-col gap-y-1">
      <span className="text-sm">
        {data.name} {data.defaultLocation && '(default)'}{' '}
        {data.code && <span className="text-gray-500">{data.code}</span>}
      </span>
      {data.address && (
        <span className="text-xs text-gray-500 italic">
          {data.address.lineOne}, {data.address.city} - {data.address.state}
        </span>
      )}
    </span>
  </Item>
)

const Machine = ({
  data,
  onClick,
}: {
  data: SearchResults['machines'][number]
  onClick: () => void
}) => (
  <Item href={`/machines/${data.id}`} onClick={onClick}>
    <span className="text-sm block">
      {data.machine.make} {data.machine.model} {data.machine.year}
    </span>
    <span className="block text-xs text-gray-500">Serial # {data.serialNumber}</span>
  </Item>
)

const SearchResults = ({
  searchTerm,
  onItemSelected,
}: {
  searchTerm: string
  onItemSelected: () => void
}) => {
  const { orgId } = useSession()

  const { data, loading } = useOmniSearchQuery({
    client: useGqlClient(),
    variables: { orgId, searchTerm, requestsSearch: JSON.stringify(['search', searchTerm]) },
    skip: !searchTerm,
  })

  const results = useMemo(() => {
    if (!data?.org) return null

    return {
      requests: data.searchRequests.requests,
      storeOrders: data.org.paginatedStoreOrders.storeOrders,
      vendors: data.org.paginatedVendors.vendors,
      locations: data.shippingLocationsSearch.shippingLocations,
      machines: data.paginatedOrgMachines.machines || [],
    } as SearchResults
  }, [data])

  if (loading) {
    return (
      <p className="text-gray-700 p-4">
        <CogIcon className="w-5 h-5 text-orange-500 animate-spin inline-block" /> Searching...
      </p>
    )
  }

  if (results && Object.values(results).flat().length === 0) {
    return (
      <p className="text-gray-700 p-4">
        No results found for <span className="font-medium">{searchTerm}</span>
      </p>
    )
  }

  return (
    <>
      {results && (
        <>
          {results.requests.length > 0 && (
            <ResultsCategory title="Requests">
              {results.requests.slice(0, 10).map((request) => (
                <Request key={request.id} data={request} onClick={onItemSelected} />
              ))}
            </ResultsCategory>
          )}

          {results.storeOrders.length > 0 && (
            <ResultsCategory title="Orders">
              {results.storeOrders.map((order) => (
                <StoreOrder key={order.id} data={order} onClick={onItemSelected} />
              ))}
            </ResultsCategory>
          )}
          {results.machines.length > 0 && (
            <ResultsCategory title="Fleet">
              {results.machines.map((machine) => (
                <Machine key={machine.id} data={machine} onClick={onItemSelected} />
              ))}
            </ResultsCategory>
          )}
          {results.vendors.length > 0 && (
            <ResultsCategory title="Vendors">
              {results.vendors.map((vendor) => (
                <Vendor key={vendor.id} data={vendor} onClick={onItemSelected} />
              ))}
            </ResultsCategory>
          )}
          {results.locations.length > 0 && (
            <ResultsCategory title="Locations">
              {results.locations.map((location) => (
                <Location key={location.id} data={location} onClick={onItemSelected} />
              ))}
            </ResultsCategory>
          )}
        </>
      )}
    </>
  )
}

export default SearchResults
