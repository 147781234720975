import { SortOrder } from '@/dealers/_gen/gql'
import Link from '@/gf/components/Link'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import Tag from '@/gf/components/Reports/Tag'
import Money from '@/gf/modules/Money'
import orderBy from 'lodash/orderBy'
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { StoreOrder } from '../OpenQuotes'
import { useContext } from './Context'

const HistoryTable = () => {
  const { orgId, ordersSort, days, orgById, update, ...context } = useContext()

  const sortField = ordersSort ? ordersSort[0] : 'send-quote'
  const sortOrder = ordersSort ? (ordersSort[1] === 'desc' ? 'desc' : 'asc') : 'desc'
  const now = DateTime.now()
  const sevenDaysAgo = now.minus({ days: 7 })
  const thirtyDaysAgo = now.minus({ days: 30 })
  const sixtyDaysAgo = now.minus({ days: 60 })

  const unsortedRows = (() => {
    if (!context.storeOrders) return context.storeOrders

    const result =
      context.storeOrders &&
      (orgId
        ? context.storeOrders.filter((so) => so.customer.organization.id === orgId)
        : context.storeOrders)

    if (days === '< 7') return result.filter((so) => so.originatedAt > sevenDaysAgo)

    if (days === '7 - 30')
      return result.filter(
        (so) => so.originatedAt > thirtyDaysAgo && so.originatedAt <= sevenDaysAgo
      )

    if (days === '30 - 60')
      return result.filter(
        (so) => so.originatedAt > sixtyDaysAgo && so.originatedAt <= thirtyDaysAgo
      )

    if (days === '> 60') return result.filter((so) => so.originatedAt <= sixtyDaysAgo)

    return result
  })()

  const orderByField = (() => {
    if (sortField === 'created') return 'originatedAt'
    if (sortField === 'total') return 'totals.total.amount'
    if (sortField === 'customer') return 'customer.organization.name'
    return sortField
  })()

  const rows = unsortedRows && orderBy(unsortedRows, orderByField, sortOrder)

  const link = useCallback(
    ({ request }: StoreOrder) =>
      request ? <Link.T to={`/rfqs/${request.id}`}>Request {request.shortId}</Link.T> : null,
    []
  )

  return (
    <div className="pt-6 flex flex-col gap-y-3 bg-white border border-gray-300 rounded-lg shadow-sm">
      <div className="flex gap-2 items-center px-4 text-lg text-gray-900 font-medium">
        <span>Orders</span>
        {(days || orgId) && <span>&mdash;</span>}
        {days && <Tag onRemove={() => update({ days: null })}>Days Open: {days}</Tag>}

        {orgId && (
          <Tag onRemove={() => update({ orgId: null })}>{orgById && orgById[orgId].name}</Tag>
        )}
      </div>

      <div className="px-4 pb-3 max-h-96 overflow-x-auto overflow-y-auto">
        <ReportingTable
          data={rows}
          getRowKey={(row) => row.id}
          sortBy={{
            sortBy: {
              field: sortField,
              order: sortOrder === 'desc' ? SortOrder.Desc : SortOrder.Asc,
            },
            setSortBy: (sortBy) => update({ ordersSort: sortBy }),
          }}
          columns={[
            { header: 'Quote', getValue: link },
            {
              header: 'Created',
              getValue: ({ originatedAt }) => originatedAt.toLocaleString(DateTime.DATETIME_MED),
              sortByField: 'created',
            },
            {
              header: 'Total',
              getValue: ({ totals }) => Money.format(totals.total, { maximumFractionDigits: 0 }),
              sortByField: 'total',
            },
            {
              header: 'Customer',
              getValue: ({ customer }) => customer.organization.name,
              sortByField: 'customer',
            },
          ]}
        />
      </div>
    </div>
  )
}

export default HistoryTable
