import { DateTime } from 'luxon'
import { ChangeEvent, forwardRef, InputHTMLAttributes, useImperativeHandle, useRef } from 'react'
import TimeM from '../../modules/Time'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value' | 'onChange'> & {
  value?: DateTime | null
  onChange: (date: DateTime | undefined) => void
}

const DateInput = forwardRef(({ value, onChange, className, ...props }: Props, ref) => {
  const inputRef = useRef(null)

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        const currentInputRef = inputRef.current as HTMLInputElement
        currentInputRef.focus()
      }
    },
  }))

  return (
    <input
      {...props}
      ref={inputRef}
      value={value ? TimeM.toDateInputFormat(value) : ''}
      onChange={({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) =>
        onChange(newValue !== '' ? TimeM.fromDateInputFormat(newValue) : undefined)
      }
      type="date"
      className={`text-sm shadow-sm border border-gray-300 rounded-md text-gray-700 ${className}`}
    />
  )
})

export default DateInput
