import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

// If an `auth_token` query param is present, it will sign in using that
// token, then clear the token from query params.
const TokenSignInProvider = ({
  signIn,
  children,
}: {
  signIn: (token: string) => Promise<unknown>
  children: React.ReactNode
}) => {
  const [query, setQuery] = useSearchParams()
  const token = query.get('auth_token')

  const clearToken = () => {
    query.delete('auth_token')
    setQuery(query)
  }

  useEffect(() => {
    if (token) signIn(token).then(clearToken)
    else clearToken()
  }, [])

  return token ? null : <>{children}</>
}

export default TokenSignInProvider
