import { v4 as uuid } from 'uuid'

import { DateTimeInRangeFilter } from '../../../types'

const build = ({
  id,
  fieldId,
  from,
  to,
}: {
  id?: string
  fieldId: string
  from?: string | null
  to?: string | null
}): DateTimeInRangeFilter => ({
  id: id || uuid(),
  typeId: 'date_time_in_range',
  fieldId,
  from: from || null,
  to: to || null,
})

const toApiFilter = (filter: DateTimeInRangeFilter) => [
  'date_time_in_range',
  filter.fieldId,
  filter.from ?? '',
  filter.to ?? '',
]

export default { build, toApiFilter }
