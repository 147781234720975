import ConversationM from '@/gf/modules/Conversation'
import { ConversationsQuery, useConversationsQuery } from '../_gen/gql'

const useConversations = ({
  filters,
  page = 1,
  pageSize = ConversationM.PAGE_SIZE,
  onCompleted,
}: {
  filters: string[][]
  page?: number
  pageSize?: number
  vendorId?: string
  onCompleted?: (data: ConversationsQuery) => void
}) => {
  const { loading, error, data, refetch } = useConversationsQuery({
    variables: { filters, page, pageSize },
    pollInterval: ConversationM.CONVERSATION_POLL_INTERVAL,
    fetchPolicy: 'network-only',
    onCompleted,
  })

  return { loading, error, data: data?.conversations ?? null, refetch }
}

export default useConversations
