import classNames from 'classnames'

const Box = ({
  pad = true,
  dim = false,
  className,
  ...props
}: JSX.IntrinsicElements['div'] & { pad?: boolean; dim?: boolean }) => (
  <div
    className={classNames(
      'rounded-lg shadow-sm border',
      className,
      pad && 'p-4 md:p-6',
      dim ? 'bg-gray-50' : 'bg-white'
    )}
    {...props}
  />
)

export default Box
