import {
  useFinishedStripeOnboardingMutation,
  useStartStripeOnboardingMutation,
} from '@/dealers/_gen/gql'
import { useEffect, useState } from 'react'
import { BooleanParam, useQueryParams } from 'use-query-params'

const queryOpts = { 'stripe-refresh': BooleanParam, 'stripe-return': BooleanParam }

const useStripeOnboarding = ({
  storeId,
  onFinished,
}: {
  storeId: string
  onFinished: () => void
}) => {
  const [starting, setStarting] = useState(false)
  const [startStripeOnboarding] = useStartStripeOnboardingMutation()
  const [finished] = useFinishedStripeOnboardingMutation()
  const [query, setQuery] = useQueryParams(queryOpts)

  const start = async () => {
    setStarting(true)
    const { origin, pathname } = window.location

    const { data } = await startStripeOnboarding({
      variables: {
        storeId,
        refreshUrl: `${origin}${pathname}?stripe-refresh=1`,
        returnUrl: `${origin}${pathname}?stripe-return=1`,
      },
    })

    if (data?.startStripeOnboarding) window.location.href = data.startStripeOnboarding // onboarding URL
  }

  useEffect(() => {
    // https://docs.stripe.com/api/account_links/create

    if (query['stripe-refresh']) {
      setQuery({ 'stripe-refresh': undefined })
      start()
    }

    if (query['stripe-return']) {
      setQuery({ 'stripe-return': undefined })
      finished({ variables: { storeId } }).then(() => onFinished())
    }
  }, [])

  return { start, starting }
}
export default useStripeOnboarding
