import { RequestForQuoteQuery, StoreOrderProblemType } from '@/dealers/_gen/gql'
import Urls from '@/dealers/modules/Urls'
import Link from '@/gf/components/Link'
import StoreOrderM from '@/gf/modules/StoreOrder'
import { ChatAltIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

const Problem = ({
  rfqId,
  problem,
}: {
  rfqId: string
  problem: NonNullable<
    NonNullable<RequestForQuoteQuery['requestForQuote']>['quote']
  >['problems'][number]
}) => (
  <div
    className={classNames('p-6 rounded-xl border space-y-4 text-center bg-red-100 border-red-200')}
  >
    <div className="space-y-2">
      {problem.type !== StoreOrderProblemType.Other && (
        <h3 className="text-lg font-medium text-red-700">
          {StoreOrderM.getStoreOrderProblemTypeDisplay(problem.type)}
        </h3>
      )}

      <p className="leading-6">{problem.details}</p>
    </div>

    <Link.S to={Urls.requestForQuoteMessages(rfqId, { customer: true })} className="font-medium">
      <ChatAltIcon className="inline-block w-4 h-4" /> Message Customer
    </Link.S>
  </div>
)

export default Problem
