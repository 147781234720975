import {
  ConversationMessagesDocument,
  ConversationsDocument,
  RequestForQuoteQuery,
  useCreateConversationMutation,
  useCreateMessageMutation,
  useReadReceiptsQuery,
  useRequestScheduledMessagesQuery,
} from '@/dealers/_gen/gql'
import Inbox from '@/dealers/components/Inbox'
import { Conversation } from '@/dealers/components/Inbox/types'
import useConversations from '@/dealers/hooks/useConversations'
import useMessagesForConversation from '@/dealers/hooks/useMessagesForConversation'
import useSession from '@/dealers/hooks/useSession'
import ConversationM from '@/dealers/modules/Conversation'
import RequestForQuote from '@/dealers/modules/RequestForQuote'
import Urls from '@/dealers/modules/Urls'
import { useCallback, useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import InboxDetails from '../Inbox/InboxDetails'

const Messages = ({
  rfq,
  customerSelected,
  adminSelected,
}: {
  rfq: NonNullable<RequestForQuoteQuery['requestForQuote']>
  customerSelected?: boolean
  adminSelected?: boolean
}) => {
  const navigate = useNavigate()
  const { store } = useSession()
  const { conversationId } = useParams()
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))

  const { data, refetch: refetchConversations } = useConversations({
    filters: [['equals', 'request_for_quote_id', rfq.id]],
    page: 1,
    pageSize: 30,
  })

  const { data: scheduledMessagesData } = useRequestScheduledMessagesQuery({
    variables: { requestForQuoteId: rfq.id },
  })

  const [createMessage] = useCreateMessageMutation({
    refetchQueries: [ConversationMessagesDocument, ConversationsDocument],
  })
  const [createConversation] = useCreateConversationMutation({
    refetchQueries: [ConversationsDocument],
  })

  const createInbox = useCallback((props) => <InboxDetails {...props} />, [])

  const onSelectedConversationIdChange = (selectedConversationId: string | undefined) =>
    navigate(Urls.requestForQuoteMessages(rfq.id, { conversationId: selectedConversationId }), {
      replace: true,
    })

  const scheduledMessage = useMemo(
    () =>
      scheduledMessagesData?.scheduledMessages
        .filter((sm) => !sm.cancelledAt && !sm.sentAt)
        .map((sm) => ({ ...sm, viewMorePath: Urls.requestForQuoteAutomations(rfq.id) }))[0] ?? null,
    [scheduledMessagesData]
  )

  if (!data || !scheduledMessagesData || !store) {
    return null
  }

  const displayCustomerContactInfo = RequestForQuote.canDisplayCustomerContactInfo({
    user: { isCustomerContact: rfq.user.isCustomerContact },
    step: rfq.step,
    storeOrder: rfq.quote ? { state: rfq.quote.state } : null,
  })

  const newConversationsUnfiltered = [
    ConversationM.newNonAdminConversationRfq(
      rfq,
      store.id,
      displayCustomerContactInfo,
      scheduledMessage
    ),
    // Admin conversations
    ConversationM.newAdminConversationRfq(rfq, store.id),
  ]

  const conversationName = (isAdminConversation: boolean) =>
    isAdminConversation
      ? ConversationM.ADMIN_CONVERSATION_NAME
      : ConversationM.conversationNameForRfq(rfq, displayCustomerContactInfo)

  const conversations = data.entries.map(
    (c) =>
      ({
        id: c.id,
        admin: c.admin,
        name: conversationName(c.admin),
        lastMessage: c.lastMessage,
        unreadMessages: c.unreadMessages,
        storeId: c.store?.id,
        source: c.storeOrder
          ? {
              type: 'store_order',
              id: c.storeOrder.id,
              user: c.storeOrder.customer,
            }
          : c.requestForQuote && {
              type: 'request',
              id: c.requestForQuote.id,
              user: c.requestForQuote.user,
            },
        scheduledMessage,
      }) as Conversation
  )

  // Filter out existing conversations
  const newConversations = ConversationM.filterNewConversations(
    newConversationsUnfiltered,
    conversations
  )

  if (!conversationId && customerSelected) {
    const customerConversation =
      conversations.find((conversation) => !conversation.admin) ??
      newConversations.find((conversation) => !conversation.admin)
    if (customerConversation) {
      return (
        <Navigate
          to={Urls.requestForQuoteMessages(rfq.id, { conversationId: customerConversation.id })}
          replace
        />
      )
    }
  }

  if (!conversationId && adminSelected) {
    const adminConversation =
      conversations.find((conversation) => conversation.admin) ??
      newConversations.find((conversation) => conversation.admin)
    if (adminConversation) {
      return (
        <Navigate
          to={Urls.requestForQuoteMessages(rfq.id, { conversationId: adminConversation.id })}
          replace
        />
      )
    }
  }

  return (
    <Inbox
      className="!mt-0"
      conversations={conversations}
      selectedConversationId={conversationId}
      onSelectedConversationIdChange={onSelectedConversationIdChange}
      useMessagesForConversation={useMessagesForConversation}
      useReadReceiptsForConversation={useReadReceiptsQuery}
      refetchConversations={refetchConversations}
      page={page}
      setPage={setPage}
      pagination={data.pagination}
      newConversations={newConversations}
      DetailsForConversation={createInbox}
      displayCustomerContactInfo={displayCustomerContactInfo}
      conversationRowContent={(conversation) => ({
        name: {
          type: conversation.admin ? 'admin' : 'buyer',
          name: conversation.admin || displayCustomerContactInfo ? conversation.name : 'Customer',
        },
      })}
      onCreateMessage={({ messageInput }) =>
        createMessage({
          variables: {
            conversationId: messageInput.conversationId,
            text: messageInput.text,
            attachmentUrls: messageInput.attachmentUrls ?? [],
          },
        })
      }
      onCreateConversation={async ({ conversationInput, messageText, attachmentUrls }) => {
        const { data: createConvoData } = await createConversation({
          variables: {
            conversation: {
              requestForQuoteId: conversationInput.requestForQuoteId ?? null,
              storeOrderId: conversationInput.storeOrderId ?? null,
              storeId: conversationInput.storeId as string,
              admin: conversationInput.admin,
            },
            attachmentUrls: attachmentUrls ?? null,
            messageText: messageText ?? null,
          },
        })

        return createConvoData?.createConversation
      }}
    />
  )
}

export default Messages
