const CircleProgress = ({ progress, className }: { progress: number; className?: string }) => (
  <div className={className}>
    <svg className="size-full -rotate-90" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      {/* <!-- Background Circle --> */}
      <circle
        cx="20"
        cy="20"
        r="16"
        fill="none"
        className="stroke-current text-gray-300"
        strokeWidth="3"
      />

      {/* <!-- Progress Circle --> */}
      <circle
        cx="20"
        cy="20"
        r="16"
        fill="none"
        className="stroke-current text-blue-600"
        strokeWidth="6"
        strokeDasharray="100"
        strokeDashoffset={Math.round(100 - progress)}
        strokeLinecap="round"
      />
    </svg>
  </div>
)

export default CircleProgress
