import useSession from '@/dealers/hooks/useSession'
import Action from '@/gf/components/Action'
import useToggle from '@/gf/hooks/useToggle'
import { OfficeBuildingIcon, PhotographIcon } from '@heroicons/react/outline'
import ChangeLogoModal from './ChangeLogoModal'

const ChangeStoreLogo = () => {
  const { store } = useSession()
  const [modalOpen, modalToggle] = useToggle()

  return (
    <>
      <div className="flex gap-x-3">
        {store?.logo?.url ? (
          <img src={store.logo?.url} alt={store.name} className="max-w-56 max-h-24 rounded-lg" />
        ) : (
          <figure className="border border-slate-300 rounded-lg p-3">
            <OfficeBuildingIcon className="text-slate-300 w-40 h-20" />
          </figure>
        )}
        <Action.S className="self-start" onClick={modalToggle.on}>
          <PhotographIcon className="w-5 inline-block text-gearflow" /> Change Logo
        </Action.S>
      </div>
      <ChangeLogoModal open={modalOpen} onClose={modalToggle.off} />
    </>
  )
}

export default ChangeStoreLogo
