import pluralize from 'pluralize'
import { StringParam, useQueryParam } from 'use-query-params'

import Money from '@/gf/modules/Money'
import { useOrderContext } from '../context'

import Box from '@/buyers/components/Box'
import ReviewCharge from '@/buyers/pages/StoreOrder/Order/ReviewCharge'
import Action from '@/gf/components/Action'
import { List, Row } from '../List'
import { Section } from '../YourOrder'

const AdditionalCharges = () => {
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const [selectedId, setSelectedId] = useQueryParam('additionalChargeId', StringParam)
  const pendingAddlChrgs = storeOrder.additionalCharges.filter((ac) => ac.state === 'pending')

  if (pendingAddlChrgs.length === 0) return null

  return (
    <Box className="space-y-4">
      <div className="text-lg font-medium text-center">
        Additional {pluralize('Charge', pendingAddlChrgs.length)}
      </div>

      <div className="divide-y divide-gray-200">
        {pendingAddlChrgs.map((addlChrg) => (
          <Section className="space-y-4 flex flex-col items-center">
            <List
              title={{ className: 'sm:col-span-2' }}
              desc={{ className: 'sm:text-right sm:col-span-1' }}
            >
              <Row>
                {addlChrg.name}
                {Money.format(addlChrg.price)}
              </Row>
            </List>

            <Action.P onClick={() => setSelectedId(addlChrg.id)}>Review Charge</Action.P>
          </Section>
        ))}
      </div>

      <ReviewCharge
        additionalChargeId={selectedId || null}
        open={!!selectedId}
        onClose={() => setSelectedId(undefined)}
        onSuccess={refetchStoreOrder}
      />
    </Box>
  )
}

export default AdditionalCharges
