import { InputHTMLAttributes, ChangeEvent, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import MoneyM from '@/gf/modules/Money'

import { Money } from '@/types'

type Value = Money | null // Do not change.

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'min'> {
  value?: Value
  setValue: (newValue: Value) => void
  min?: number
}

const PriceInput = forwardRef<HTMLInputElement, Props>(({ value, setValue, ...props }, ref) => {
  const cast = (val: string) => {
    const floatValue = parseFloat(val)

    let newValue = !Number.isNaN(floatValue) ? MoneyM.fromDecimal(floatValue, 'USD') : null
    newValue = newValue && MoneyM.isNeg(newValue) ? MoneyM.fromDecimal(0, 'USD') : newValue

    return newValue
  }

  const onChange =
    props.onChange || setValue
      ? (event: ChangeEvent<HTMLInputElement>) => {
          if (props.onChange) props.onChange(event)
          if (setValue) setValue(cast(event.target.value))
        }
      : undefined

  const className = twMerge(
    'border border-gray-300 placeholder:text-gray-400 text-sm text-gray-900 focus:border-blue-600 focus:ring-blue-600 px-3 py-2 rounded-md w-full pl-7',
    props.disabled && 'bg-gray-50',
    props.className
  )

  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <span>$</span>
      </div>

      <input
        {...props}
        value={value ? MoneyM.toString(value) : ''}
        onChange={onChange}
        step={0.01}
        min={0}
        ref={ref}
        className={className}
        type="number"
      />
    </div>
  )
})

export default PriceInput
