import { twMerge } from 'tailwind-merge'

interface Props {
  className: string
}
const Ghost: React.FC<Props> = ({ className = '' }) => (
  <span className={twMerge('bg-slate-100 rounded animate animate-pulse', className)} />
)

export default Ghost
