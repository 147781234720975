import Box from '@/gf/components/Box'
import ActionButtons from './ActionButtons'
import activity from './activity.png'
import locations from './locations.png'
import time from './time.png'

const Info = ({ title, text, image }) => (
  <div className="space-y-4">
    <img src={image} alt="" />
    <div className="text-center space-y-2 pz-1.5 sm:px-3">
      <h5 className="text-lg md:text-xl font-medium">{title}</h5>
      <p className="text-sm md:text-base leading-5 md:leading-6">{text}</p>
    </div>
  </div>
)

const Showcase = () => (
  <Box className="py-8 px-6 space-y-12 shadow-base">
    <hgroup className="max-w-[25rem] space-y-2 mx-auto text-center">
      <h3 className="text-2xl font-medium">Do even more with Sales Hub Pro</h3>
      <p className="text-lg">
        Manage all of your quotes and parts orders in one place across new and existing customers.
      </p>
    </hgroup>
    <div className="grid grid-cols-3 gap-12 max-w-5xl mx-auto">
      <Info
        title="Unlimited quotes to ALL of your customers"
        text="Bring all of your customers onto Gearflow through easy quoting."
        image={locations}
      />
      <Info
        title="Manage every order and close more business as a team"
        text="Know exactly where every order is at with timely alerts and team activity."
        image={activity}
      />
      <Info
        title="Measure your entire sales & service performance"
        text="Get full insights on pricing and fulfillment time on all your orders."
        image={time}
      />
    </div>
    <div className="max-w-lg mx-auto">
      <ActionButtons />
    </div>
  </Box>
)

export default Showcase
