/**
 * Address Metadata Component
 * -----------------
 * displays metadata information about an address; for example, if the address is deliverable
 *
 * props
 * -----------------
 * address - the address to display metadata for
 */

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  HomeIcon,
  OfficeBuildingIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/solid'
import classNames from 'classnames'
import Tooltip from '@/gf/components/Tooltip'

import { Address } from '../../types'

interface AddressMetadata
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  address: Address
}

interface DisplayData {
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element // heroicon type
  color: string
  title: string
  tooltip: string
  details?: string
}

const AddressMetadata = ({ address, className }: AddressMetadata) => {
  const deliverableDisplayData: DisplayData = address.deliverable
    ? {
        icon: CheckCircleIcon,
        color: 'text-green-500',
        title: 'Deliverable',
        tooltip: 'Our system verified this address as deliverable.',
      }
    : {
        icon: ExclamationCircleIcon,
        color: 'text-yellow-500',
        title: 'Not deliverable',
        tooltip: 'Our system flagged this address as not deliverable.',
        details: 'Please verify that this address is correct before shipping.',
      }
  const rdiDisplayData: DisplayData =
    address.rdi === 'residential'
      ? {
          icon: HomeIcon,
          color: 'text-gray-500',
          title: 'Residential',
          tooltip: 'Our system marked this address as residential.',
          details:
            'Some shipping companies charge higher fees for residential delivery. Note: the US Postal Service always marks PO Boxes as "Residential."',
        }
      : address.rdi === 'commercial'
        ? {
            icon: OfficeBuildingIcon,
            color: 'text-gray-500',
            title: 'Commercial',
            tooltip: 'Our system marked this address as commercial.',
          }
        : {
            icon: QuestionMarkCircleIcon,
            color: 'text-gray-500',
            title: 'RDI unknown',
            tooltip:
              'Our system was not able to verify if this address is commercial or residential.',
          }

  const metadataLine = (displayData: DisplayData) => (
    <div className="mb-1">
      <Tooltip
        element={
          <div className="flex flex-row">
            <displayData.icon
              className={classNames('w-5 h-5 mr-1', displayData.color)}
              aria-hidden="true"
            />
            <span className="text-sm text-gray-700 mr-2">{displayData.title}</span>
          </div>
        }
        text={displayData.tooltip}
        size="small"
      />
      {displayData.details && <p className="text-sm text-gray-500">{displayData.details}</p>}
    </div>
  )

  return (
    <div className={className}>
      {/* Deliverable */}
      {metadataLine(deliverableDisplayData)}
      {/* RDI */}
      {metadataLine(rdiDisplayData)}
    </div>
  )
}

export default AddressMetadata
