import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useResolveStoreOrderProblemMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import { useOrderContext } from '../context'

import MarkResolvedModal from '@/buyers/pages/StoreOrder/Order/MarkResolvedModal'

const MarkProblemResolved = () => {
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const [_, msgr] = useMsgs()
  const { problemId } = useParams() as { problemId: string }
  const [performing, setPerforming] = useState(false)
  const navigate = useNavigate()
  const [resolveStoreOrderProblem] = useResolveStoreOrderProblemMutation({ client: useGqlClient() })
  const close = () => navigate(`/orders/${storeOrder.id}`)
  const problem = storeOrder.problems.find((p) => p.id === problemId)

  useEffect(() => {
    if (!problem) close()
  }, [])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPerforming(true)

    resolveStoreOrderProblem({ variables: { id: problemId } })
      .then(() => {
        msgr.add('Problem resolved.', 'positive')
        refetchStoreOrder()
      })
      .catch(() => msgr.addUnknownError())
      .finally(() => {
        close()
        setPerforming(true)
      })
  }

  if (!problem) return null

  return (
    <MarkResolvedModal
      open
      onClose={close}
      onSubmit={onSubmit}
      submitting={performing}
      storeOrderProblem={problem}
    />
  )
}

export default MarkProblemResolved
