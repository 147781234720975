const Text: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>
> = ({ children, ...props }) => {
  const className = `leading-normal ${props.className}`
  return (
    <p className={className} {...props}>
      {children}
    </p>
  )
}

export default Text
