import useFrame from '@/buyers/hooks/useFrame'
import UpdateProfileForm from '@/buyers/pages/Settings/Profile/UpdateForm'
import { Dialog, DialogContent, DialogDescription, DialogHeader } from '@/gf/components/ui/dialog'
import { DialogTitle } from '@radix-ui/react-dialog'

const RequiredInfo = () => {
  const frame = useFrame()

  if (!frame) return null

  return (
    <Dialog open={!frame.user.name || !frame.user.phoneNumber}>
      <DialogContent className="sm:max-w-sm">
        <DialogHeader>
          <DialogTitle className="text-lg font-medium">Welcome to Gearflow</DialogTitle>

          <DialogDescription className="text-base">
            We just need a few more details.
          </DialogDescription>
        </DialogHeader>

        <UpdateProfileForm hideEmail buttonText="Let&rsquo;s Go" />
      </DialogContent>
    </Dialog>
  )
}

export default RequiredInfo
