import {
  forwardRef,
  HTMLAttributes,
  TableHTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from 'react'
import { cn } from '../modules/utils'

const Table = forwardRef<HTMLDivElement, TableHTMLAttributes<HTMLTableElement>>(
  ({ className, onScroll, ...props }, ref) => (
    <div ref={ref} className="overflow-auto" onScroll={onScroll}>
      <table className={cn('divide-y divide-gray-200 bg-white min-w-full', className)} {...props} />
    </div>
  )
)

const TableV2 = forwardRef<HTMLTableElement, TableHTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => (
    <table className={cn('bg-white divide-y-1 divide-gray-200', className)} ref={ref} {...props} />
  )
)

const Thead = ({ className, ...props }: HTMLAttributes<HTMLTableSectionElement>) => (
  <thead className={cn('bg-gray-50', className)} {...props} />
)

const Tr = ({ className, ...props }: HTMLAttributes<HTMLTableRowElement>) => (
  <tr className={cn(className, props.onClick && 'hover:bg-blue-50 cursor-pointer')} {...props} />
)

const Th = ({ className, ...props }: ThHTMLAttributes<HTMLTableCellElement>) => (
  <th
    className={cn(
      'text-left text-xs font-bold text-gray-500 uppercase tracking-wider px-6 py-3 whitespace-nowrap',
      className
    )}
    {...props}
  />
)

const Tbody = ({ className, ...props }: HTMLAttributes<HTMLTableSectionElement>) => (
  <tbody className={cn('divide-y-1 divide-gray-200', className)} {...props} />
)

const Td = ({ className, ...props }: TdHTMLAttributes<HTMLTableCellElement>) => (
  <td className={cn('text-sm align-middle whitespace-nowrap px-6 py-3', className)} {...props} />
)

const TableWrapper = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('min-w-full rounded border border-gray-300 overflow-x-auto', className)}
    {...props}
  />
)

export { Table, TableV2, TableWrapper, Tbody, Td, Th, Thead, Tr }
