import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import useConfig from '../hooks/useConfig'

import RfqEventModule from '../modules/RfqEvent'
import Time from '../modules/Time'

import A from './A'

import { EventDisplayData, EventType, RfqEvent, StoreOrderEvent } from '../../types'

const RfqEvents = ({
  events,
  storeOrderEvents,
  storeOrderEventMapper,
  showStoreNames,
}: {
  events?: RfqEvent[]
  storeOrderEvents?: Omit<StoreOrderEvent, 'problem'>[]
  storeOrderEventMapper: (soe: StoreOrderEvent) => EventDisplayData
  showStoreNames: boolean
}) => {
  const config = useConfig()
  const { hash } = useLocation()
  const highlightedEventId = hash.replace('#', '')

  if (!events || !storeOrderEvents) return <p className="text-sm text-gray-500">Loading ...</p>
  if (events.length === 0) return <p className="text-sm text-gray-500">No events yet.</p>

  const rfqEvents = events.map(RfqEventModule.getEventDisplayData(showStoreNames, config))
  const soEvents = storeOrderEvents.map(storeOrderEventMapper)
  const allEvents = rfqEvents.concat(soEvents).sort((a, b) => Time.sortDesc(a.datetime, b.datetime))
  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {allEvents.map((eventDisplayData: EventDisplayData, index) => (
          <li
            key={eventDisplayData.id}
            id={eventDisplayData.id}
            className={classNames('rounded-md pr-1', {
              'bg-gray-100 bg-opacity-75': eventDisplayData.id === highlightedEventId,
            })}
          >
            <div className="relative pb-8">
              {index !== allEvents.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      eventDisplayData.iconBackground,
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-400'
                    )}
                  >
                    <eventDisplayData.icon
                      className={classNames('h-5 w-5 text-white', eventDisplayData.iconClassName)}
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="flex flex-col w-full items-start">
                  <div className="min-w-0 w-full pt-1.5 flex flex-row justify-between space-x-4 text-sm">
                    <div>
                      <p className="text-gray-500">{eventDisplayData.content}</p>
                      {eventDisplayData.subContent && (
                        <p className="text-gray-500">{eventDisplayData.subContent}</p>
                      )}
                    </div>

                    <div className="text-right whitespace-nowrap text-gray-500">
                      <time dateTime={Time.formatSimpleDateTime(eventDisplayData.datetime)}>
                        {Time.formatSimpleDateTime(eventDisplayData.datetime)}
                      </time>
                    </div>
                  </div>

                  {eventDisplayData.details && (
                    <div className="text-sm text-gray-500 mt-1">{eventDisplayData.details}</div>
                  )}
                  {eventDisplayData.sourceLink &&
                    eventDisplayData.sourceLinkMessage &&
                    eventDisplayData.type === EventType.StoreOrder && (
                      <div className="text-sm">
                        <A.T href={eventDisplayData.sourceLink}>
                          {eventDisplayData.sourceLinkMessage}
                        </A.T>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default RfqEvents
