import LinkNext from '@/gf/components/Link'
import { ArrowRightIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import pluralize from 'pluralize'
import { useLocation } from 'react-router-dom'
import { SubscriptionDetails } from './types'

const TrialBanner = ({
  toggleExpired,
  subscriptionDetails,
}: {
  toggleExpired: () => void
  subscriptionDetails: SubscriptionDetails
}) => {
  if (!subscriptionDetails.inTrialPeriod || !subscriptionDetails.trialExpirationDate) return null

  const location = useLocation()
  const expiresAt = DateTime.fromISO(`${subscriptionDetails.trialExpirationDate}`).endOf('day')
  const { days } = expiresAt.diff(DateTime.now().endOf('day'), ['days'])

  const totalDays = days > 0.1 ? Math.ceil(days) : Math.round(days)
  const expired = totalDays <= 0

  const text =
    totalDays > 0
      ? `Your ${subscriptionDetails.productName} Pro trial expires in ${totalDays} ${pluralize(
          'day',
          totalDays
        )}.`
      : `Your ${subscriptionDetails.productName} Pro trial expired.`

  return (
    <div
      className={classNames(
        'flex flex-col sm:flex-row items-center justify-between px-4 lg:px-8 py-2 text-sm sm:text-base w-full leading-6 h-auto sm:h-12',
        expired ? 'bg-red-500 text-red-50' : 'bg-orange-400 text-orange-50'
      )}
    >
      <p className="flex-grow">{text}</p>
      {location.pathname !== subscriptionDetails.upgradePlanHref && (
        <>
          {expired && (
            <button type="button" className="pr-8" onClick={toggleExpired}>
              View Details
            </button>
          )}
          <LinkNext.T
            to={subscriptionDetails.upgradePlanHref}
            className={classNames(
              'flex items-center gap-x-1.5 no-underline font-medium',
              expired ? 'text-red-50' : 'text-orange-50'
            )}
          >
            Upgrade Now <ArrowRightIcon className="inline-block w-3 sm:w-5 h-3 sm:h-5" />
          </LinkNext.T>
        </>
      )}
    </div>
  )
}

export default TrialBanner
