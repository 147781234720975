import { ProductAvailability } from '../_gen/gql'

const AVAILABILITY_OPTIONS = [
  { label: 'In Stock', value: ProductAvailability.InStock },
  { label: 'Special Order', value: ProductAvailability.BackOrdered },
  { label: 'Out of Stock', value: ProductAvailability.OutOfStock },
]

const availabilityLabel = (value: ProductAvailability) => {
  switch (value) {
    case ProductAvailability.InStock:
      return 'In Stock'

    case ProductAvailability.BackOrdered:
      return 'Special Order'

    case ProductAvailability.OutOfStock:
      return 'Out of Stock'

    default:
      return ''
  }
}

export { availabilityLabel, AVAILABILITY_OPTIONS }
