import Action from '@/gf/components/Action'
import { Maybe } from '@/types'
import classNames from 'classnames'

type Props = {
  address: Maybe<{
    lineOne: Maybe<string>
    city: Maybe<string>
    state: Maybe<string>
    postalCode: Maybe<string>
  }>
  textSize?: 'sm' | 'md'
  titleSize?: 'md' | 'lg'
  title: string
  onChangeClicked?: () => void
}

const Address = ({ address, textSize = 'sm', titleSize = 'lg', title, onChangeClicked }: Props) => (
  <div>
    <h4 className={classNames('font-medium', titleSize === 'md' ? 'text-base' : 'text-lg')}>
      {title}
    </h4>
    <div className="flex flex-col gap-y-2 mt-2">
      {address && (
        <p
          className={classNames(
            textSize === 'sm' && 'leading-5 text-sm',
            textSize === 'md' && 'leading-6 text-base'
          )}
        >
          {address?.lineOne}
          <br />
          {address?.city}, {address?.state} {address?.postalCode}
        </p>
      )}

      {onChangeClicked && (
        <Action.T onClick={onChangeClicked} className="text-left">
          {address ? 'Change' : 'Set Address'}
        </Action.T>
      )}
    </div>
  </div>
)

export default Address
