import { useClaimAccountMutation } from '@/dealers/_gen/gql'
import useReloadSession from '@/dealers/hooks/useReloadSession'
import useSession from '@/dealers/hooks/useSession'
import ClaimAccountForm from '@/gf/components/ClaimAccount/Form'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Frame from './Frame'

const ClaimAccount = () => {
  const reloadSession = useReloadSession()
  const { user } = useSession()
  const navigate = useNavigate()
  const [claimAccountMutation] = useClaimAccountMutation()

  useEffect(() => {
    if (user.claimed) navigate('/setup/welcome', { replace: true })
  }, [user.claimed])

  return (
    <Frame>
      {null}

      <ClaimAccountForm
        onClose={() => navigate('/')}
        email={user.email}
        success={<></>}
        claimAccount={(variables) =>
          claimAccountMutation({ variables }).then(() => reloadSession())
        }
        closeButtonEnabled={false}
        cancelButtonEnabled
      />
    </Frame>
  )
}

export default ClaimAccount
