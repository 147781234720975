import { GpTransactionType } from '@/dealers/_gen/gql'
import { cn } from '@/gf/modules/utils'

const colors = (type: GpTransactionType) => {
  if (type === GpTransactionType.PaymentRefund) return 'bg-red-100 border-red-200 text-red-700'
  if (type === GpTransactionType.Payment) return 'bg-green-100 border-green-200 text-green-700'
  if (type === GpTransactionType.Payout) return 'bg-blue-100 border-blue-200 text-blue-700'
  return 'bg-gray-100 border-gray-300'
}

const display = (type: GpTransactionType) => {
  if (type === GpTransactionType.PaymentRefund) return 'Refund'

  const str = type.replace('_', ' ').toLocaleLowerCase()
  return str[0].toLocaleUpperCase() + str.slice(1)
}

const Type = ({ type }: { type: GpTransactionType }) => (
  <div
    className={cn('-my-1 text-sm inline-block px-2 py-1 font-medium rounded border', colors(type))}
  >
    {display(type)}
  </div>
)

export default Type
