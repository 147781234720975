import { RequestForQuote, RequestForQuoteStep, usePerformanceReportQuery } from '@/dealers/_gen/gql'

type Options = Parameters<typeof usePerformanceReportQuery>[0]

const applyConverted = <T extends Pick<RequestForQuote, 'step'>>(rfq: T) => ({
  ...rfq,
  converted: [
    RequestForQuoteStep.PoReceived,
    RequestForQuoteStep.Fulfilling,
    RequestForQuoteStep.Fulfilled,
  ].includes(rfq.step),
})

const useQuery = (options: Options) => {
  const { data } = usePerformanceReportQuery(options)
  const rfqs = data?.rfqs.map(applyConverted)
  const comparisonRfqs = data?.comparisonRfqs.map(applyConverted)

  return { rfqs, comparisonRfqs }
}

type Result = ReturnType<typeof useQuery>

export type Rfq = Exclude<Result['rfqs'], undefined>[number]

export type ComparisonRfq = Exclude<Result['comparisonRfqs'], undefined>[number]

export type Org = Exclude<Rfq['quote'], null>['customer']['organization']

export type Creator = Exclude<Rfq['quote'], null>['creation']['creator']

export default useQuery
