import { forwardRef } from 'react'
import classNames from 'classnames'

type Props = Omit<JSX.IntrinsicElements['input'], 'value' | 'type' | 'step' | 'min'> & {
  value?: number | null
  setValue?: (value: number | null) => void
  min?: number
}

const classes = {
  base: 'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm',
  disabled: 'cursor-not-allowed bg-gray-100 opacity-50',
}

const cast = (value: number, min: number) => {
  if (Number.isNaN(value) || value < min) return null
  return Math.round(value)
}

// Do not change. Wrap if needed.
const QuantityInput = forwardRef<HTMLInputElement, Props>(
  ({ setValue, min = 0, ...props }, ref) => {
    if (setValue && props.onChange) throw new Error('cannot provide both setValue and onChange')

    const onChange = setValue
      ? ({ target }: React.ChangeEvent<HTMLInputElement>) =>
          setValue(cast(target.valueAsNumber, min))
      : props.onChange

    const value =
      typeof props.value === 'undefined'
        ? undefined
        : Number.isNaN(props.value)
          ? ''
          : (props.value ?? '')

    const className = classNames(
      classes.base,
      { [classes.disabled]: props.disabled },
      props.className
    )

    return (
      <input
        {...props}
        value={value}
        onChange={onChange}
        className={className}
        min={min}
        step={1}
        ref={ref}
        type="number"
      />
    )
  }
)

export default QuantityInput
