import BaseTitleMetric from '@/gf/components/next/TitleMetric'
import { toParam } from '@/gf/modules/ReportDuration'
import { formatMoneyValue, sumStoreOrdersTotal } from '../PartsSales'
import { useContext } from './Context'

const TitleMetric = () => {
  const { storeOrders, comparisonStoreOrders, fromDate, toDate, duration } = useContext()

  return (
    <BaseTitleMetric
      title="Parts Sales"
      value={storeOrders && sumStoreOrdersTotal(storeOrders).amount}
      comparisonValue={comparisonStoreOrders && sumStoreOrdersTotal(comparisonStoreOrders).amount}
      valueToDisplay={formatMoneyValue}
      duration={{ durationId: toParam(duration), durationStart: fromDate, durationEnd: toDate }}
    />
  )
}

export default TitleMetric
