import Action from '@/gf/components/Action'
import useToggle from '@/gf/hooks/useToggle'
import StartTrialModal from './StartTrialModal'
import A from '@/gf/components/A'
import useSession from '@/dealers/hooks/useSession'
import Urls from '@/dealers/modules/Urls'
import Link from '@/gf/components/Link'

const ActionButtons = () => {
  const { store } = useSession()
  const [trialModalOpen, trialModalToggle] = useToggle()

  return (
    <>
      <StartTrialModal open={trialModalOpen} onClose={trialModalToggle.off} />

      <div className="flex gap-x-4 flex-shrink-0">
        <A.S
          href="https://meetings.hubspot.com/peter-raisch/gearflow-demo"
          target="_blank"
          size="lg"
          className="w-1/2 font-medium text-blue-500 flex items-center justify-center flex-shrink-0"
        >
          Book Demo
        </A.S>
        {store.subscription.trialExpired ? (
          <Link.P
            to={Urls.upgradeToPro()}
            size="lg"
            className="w-1/2 font-medium flex items-center justify-center flex-shrink-0"
          >
            Upgrade to Pro
          </Link.P>
        ) : (
          <Action.P
            onClick={trialModalToggle.on}
            size="lg"
            className="w-1/2 font-medium flex items-center justify-center flex-shrink-0"
          >
            Try it Now!
          </Action.P>
        )}
      </div>
    </>
  )
}

export default ActionButtons
