import { XIcon } from '@heroicons/react/solid'
import { ReactNode } from 'react'

const Tag = ({ children, onRemove }: { children: ReactNode; onRemove: () => void }) => (
  <span className="p-1 flex items-center rounded bg-blue-50 border border-blue-700/10 text-sm font-medium text-blue-700 whitespace-nowrap">
    <span
      className="overflow-hidden text-ellipsis max-w-72 px-1"
      title={typeof children === 'string' ? children : undefined}
    >
      {children}
    </span>

    <button type="button" className="group rounded-sm hover:bg-blue-500/20 p-1" onClick={onRemove}>
      <span className="sr-only">Remove</span>
      <XIcon className="w-3.5 h-3.5 text-blue-600 group-hover:text-blue-800" />
    </button>
  </span>
)

export default Tag
