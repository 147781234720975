import {
  RequestScheduledMessagesQuery,
  ScheduledMessageCancelationReason,
} from '@/dealers/_gen/gql'
import ConversationRowIcon from '@/dealers/components/Inbox/ConversationRowIcon'
import Time from '@/gf/modules/Time'
import { CheckCircleIcon, ClockIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import CancelScheduleButton from './CancelScheduleButton'
import UpdateScheduleButton from './UpdateScheduleButton'

const cancellationReasonText = (reason: ScheduledMessageCancelationReason) => {
  switch (reason) {
    case ScheduledMessageCancelationReason.CancelledByUser:
      return 'Cancelled by User'
    case ScheduledMessageCancelationReason.InboxInteraction:
      return 'Cancelled due to message exchange'
    case ScheduledMessageCancelationReason.RequestStatusChanged:
      return 'Cancelled by Request status change'
    default:
      return 'Cancelled'
  }
}

const ScheduledMessageListItem = ({
  message,
  customerName,
}: {
  message: RequestScheduledMessagesQuery['scheduledMessages'][number]
  customerName: string
}) => (
  <li className="px-4 py-3 flex gap-x-2 border rounded-lg w-full text-gray-900">
    <div className="block flex-shrink-0">
      <ConversationRowIcon type="buyer" />
    </div>
    <div className="flex-grow">
      <div className="flex justify-between">
        <p className="font-medium text-base">{customerName}</p>
        <span
          className={classNames(
            'italic text-sm flex gap-x-1 items-center',
            message.cancelledAt && 'line-through'
          )}
        >
          {message.sentAt ? (
            <div className="p-1 flex justify-center items-center bg-green-100 border border-green-200 rounded-full">
              <CheckCircleIcon className="-m-0.5 text-green-700 h-4 w-4 inline-block" />
            </div>
          ) : !message.cancelledAt ? (
            <div className="p-1 flex justify-center items-center bg-gray-100 border border-gray-200 rounded-full">
              <ClockIcon className="-m-0.5 text-gray-600 h-4 w-4 inline-block" />
            </div>
          ) : null}{' '}
          {message.sentAt ? 'Sent ' : 'Send '}
          {Time.formatRelativeDay(message.scheduledFor, DateTime.DATE_MED)},{' '}
          {message.scheduledFor.toFormat('t')}
        </span>
      </div>
      <p className="mt-1.5 text-sm text-gray-500 whitespace-pre-wrap leading-tight">
        {message.text}
      </p>
      <div className="mt-2.5">
        {!message.cancelledAt && !message.sentAt && (
          <div className="flex gap-x-4">
            <UpdateScheduleButton message={message} />
            <CancelScheduleButton id={message.id} />
          </div>
        )}
        {message.cancelledAt && message.cancellationReason && (
          <div className="px-2 py-0.5 inline-flex gap-x-1 items-center text-xs text-red-700 font-medium bg-red-100 border border-red-200 rounded-full">
            {cancellationReasonText(message.cancellationReason)} on{' '}
            {message.cancelledAt.toLocaleString()}
          </div>
        )}
      </div>
    </div>
  </li>
)

export default ScheduledMessageListItem
