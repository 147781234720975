import { useState } from 'react'
import { titleCase } from 'title-case'

import ModalForm from '@/gf/components/ModalForm'
import useConfig from '@/gf/hooks/useConfig'

const ExportProductsModal = ({ show, close, label }) => {
  const config = useConfig()
  const title = titleCase(`Export ${label}`)
  const action = `/csv/download/${label}_`
  const [performing, setPerforming] = useState(false)

  return (
    <ModalForm
      open={show}
      onClose={close}
      title={title}
      action={action}
      submitButtonDisabled={performing}
      submitButtonShowSpinner={performing}
      method="POST"
      onSubmit={(_e) => {
        setPerforming(true)
        return null
      }}
    >
      <p className="pt-4">
        <input name="_csrf_token" type="hidden" value={config.csrfToken} />
        <span>Click export to download a CSV with your {label}</span>
      </p>
    </ModalForm>
  )
}

export default ExportProductsModal
