import Action from '@/gf/components/Action'
import MagicIcon from '@/gf/components/MagicIcon'
import Modal from '@/gf/components/ModalNext'
import Spinner from '@/gf/components/Spinner'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { UploadStage } from './QuoteUploadProvider'

const Processing = () => (
  <div className="flex justify-center items-center py-12">
    <div className="flex flex-col gap-y-2 text-center max-w-[16rem]">
      <h4 className="text-2xl font-medium">Processing Quote File</h4>

      <Spinner />

      <p className="text-lg">
        One moment while we process the quote file, this can take up to 10 seconds...
      </p>
    </div>
  </div>
)

const Error = ({ error, onClose }: { error: string | null; onClose: () => void }) => (
  <div className="flex flex-col items-center py-12">
    <div className="flex flex-col gap-y-2 text-center max-w-72">
      <h4 className="text-2xl font-medium">Something went wrong</h4>

      <p className="flex text-lg text-rose-600 gap-x-0.5 items-center">
        <ExclamationCircleIcon className="w-6 h-6 flex shrink-0 text-rose-600" />
        {error}
      </p>
    </div>

    <div className="w-full flex justify-center mt-6">
      <Action.P onClick={onClose} className="w-3/4">
        Close
      </Action.P>
    </div>
  </div>
)

const Finished = ({ onClose }: { onClose: () => void }) => (
  <div className="flex flex-col items-center p-8">
    <div className="flex flex-col gap-y-2 text-center">
      <h4 className="text-2xl font-medium">
        <MagicIcon className="h-8 inline-block" /> Quote uploaded!
      </h4>

      <p className="text-lg">
        If applicable, please add the line item availability, shipping cost, and sales tax manually.
      </p>
    </div>

    <div className="w-full flex justify-center mt-6">
      <Action.P onClick={onClose} className="w-3/4">
        Got it!
      </Action.P>
    </div>
  </div>
)

const QuoteUploadModal = ({
  uploadStage,
  error,
  onClose,
}: {
  uploadStage: UploadStage
  error: string | null
  onClose: () => void
}) =>
  uploadStage ? (
    <Modal open onClose={onClose}>
      {uploadStage === 'processing' && <Processing />}
      {uploadStage === 'error' && <Error error={error} onClose={onClose} />}
      {uploadStage === 'finished' && <Finished onClose={onClose} />}
    </Modal>
  ) : null

export default QuoteUploadModal
