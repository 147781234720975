import { CustomerAccountDetailsQuery } from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import Address from '@/gf/components/Address'
import LabeledText from '@/gf/components/LabeledText'
import SidebarProfileView from '@/gf/components/Layout/SidebarProfileView'
import useToggle from '@/gf/hooks/useToggle'
import Phone from '@/gf/modules/Phone'
import { PencilIcon } from '@heroicons/react/solid'
import UpdateAccountNumbersForm from './UpdateAccountNumbersForm'
import UpdateDiscountForm from './UpdateDiscountForm'

interface Props {
  customerAccount: CustomerAccountDetailsQuery['customer']
  onDataChanged: () => void
}

const Sidebar = ({ customerAccount, onDataChanged }: Props) => {
  const [discountFormOpen, discountFormToggle] = useToggle()
  const [editAccountNumbersOpen, editAccountNumberToggle] = useToggle()

  const onAfterSave = () => {
    discountFormToggle.off()
    onDataChanged()
  }

  return (
    <SidebarProfileView
      title={customerAccount.name}
      imageUrl={customerAccount.logoUrl}
      className="w-full md:w-64"
    >
      <UpdateAccountNumbersForm
        initialValue={customerAccount.accountNumbers}
        customerAccountId={customerAccount.id}
        onAfterSave={onAfterSave}
        open={editAccountNumbersOpen}
        onClose={editAccountNumberToggle.off}
      />
      {customerAccount.accountNumbers.length > 0 ? (
        <>
          <LabeledText
            label="Account Number"
            value={<>{customerAccount.accountNumbers.join(', ')}</>}
            labelAction={
              <Action.T
                onClick={editAccountNumberToggle.on}
                className="p-2 rounded-full hover:bg-slate-50"
              >
                <PencilIcon className="h-5 text-zinc-400" />
              </Action.T>
            }
          />
        </>
      ) : null}

      {customerAccount.address && (
        <LabeledText
          label="Address"
          value={
            <Address
              className="text-slate-700 font-semibold text-sm"
              address={customerAccount.address}
              hideCompany
              hideName
            />
          }
        />
      )}

      {customerAccount.phoneNumber && (
        <LabeledText label="Phone" value={Phone.format(customerAccount.phoneNumber)} />
      )}

      <section
        className={customerAccount.address || customerAccount.phoneNumber ? 'border-t pt-3' : ''}
      >
        <h4 className="font-medium">Custom Pricing</h4>
        <p className="text-sm text-slate-500 mt-1">
          Automatically add the discount below to quotes to this customer
        </p>

        {discountFormOpen ? (
          <UpdateDiscountForm
            initialValue={customerAccount.discount ?? 0}
            customerAccountId={customerAccount.id}
            onAfterSave={onAfterSave}
          />
        ) : (
          <LabeledText
            label="Discount"
            value={customerAccount.discount ? `${customerAccount.discount}%` : 'Not set'}
            labelAction={
              <Action.T
                onClick={discountFormToggle.on}
                className="p-2 rounded-full hover:bg-slate-50"
              >
                <PencilIcon className="h-5 text-zinc-400" />
              </Action.T>
            }
          />
        )}
      </section>
    </SidebarProfileView>
  )
}

export default Sidebar
