import { v4 as uuid } from 'uuid'

import { TooLongFilter } from '../../../types'

const build = ({ id, fieldId }: { id?: string; fieldId: string }): TooLongFilter => ({
  id: id || uuid(),
  typeId: 'too_long',
  fieldId,
})

const toApiFilter = (filter: TooLongFilter) => [filter.typeId, filter.fieldId]

export default { build, toApiFilter }
