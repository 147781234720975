import { useState } from 'react'
import useToggle from './useToggle'

const useModal = <T>() => {
  const [props, setProps] = useState<T | null>(null)
  const [isOpen, openToggle] = useToggle()

  const open = (_props: T) => {
    setProps(_props)
    openToggle.on()
  }

  const close = () => {
    setProps(null)
    openToggle.off()
  }

  return { isOpen, props, open, close }
}

export default useModal
