import { RequestScheduledMessagesQuery } from '@/dealers/_gen/gql'
import ScheduleMessageForm from '@/dealers/components/ScheduleMessageForm'
import Action from '@/gf/components/Action'
import Modal from '@/gf/components/ModalNext'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { DateTime } from 'luxon'

const UpdateScheduleButton = ({
  message,
}: {
  message: RequestScheduledMessagesQuery['scheduledMessages'][number]
}) => {
  const [_, msgs] = useMsgs()
  const [open, toggle] = useToggle()

  const onScheduleSuccess = (scheduledFor: DateTime) => {
    msgs.add(
      `Scheduled message updated! Reminder will be sent on ${scheduledFor.toFormat('ff')}`,
      'positive'
    )

    toggle.off()
  }

  return (
    <>
      <Modal open={open} onClose={toggle.off}>
        <ScheduleMessageForm
          scheduledMessageId={message.id}
          initialValues={{ scheduledFor: message.scheduledFor.toLocal(), message: message.text }}
          onSuccess={onScheduleSuccess}
          onError={() => msgs.addUnknownError()}
          onCancel={toggle.off}
        />
      </Modal>
      <Action.T onClick={toggle.on} className="flex items-center gap-x-2 text-sm">
        Update
      </Action.T>
    </>
  )
}

export default UpdateScheduleButton
