import { gql, useMutation } from '@apollo/client'

interface Data {
  createPresignedS3URL: string
  createPresignedS3AdminURL: string
}

interface Vars {
  key: string
}

const mutation = gql`
  mutation CreatePresignedS3URL($key: String!) {
    createPresignedS3URL(key: $key)
  }
`
const adminMutation = gql`
  mutation CreatePresignedS3AdminURL($key: String!) {
    createPresignedS3AdminURL(key: $key)
  }
`

const useCreatePresignedS3URL = (adminOnly: boolean) => {
  const mutate = useMutation<Data, Vars>(adminOnly ? adminMutation : mutation)[0]

  const create = (variables: Vars) => mutate({ variables })

  return create
}

export default useCreatePresignedS3URL
