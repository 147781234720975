import { useEffect, useState } from 'react'

const save = <T>(key: string, value: T) => localStorage.setItem(key, JSON.stringify(value))

const load = <T>(key: string, defaultValue: T, postProcess?: (value: T) => T) => {
  const loadedValue = localStorage.getItem(key)

  try {
    if (!loadedValue) return defaultValue

    const value = JSON.parse(loadedValue)
    return postProcess ? postProcess(value) : value
  } catch (err) {
    return defaultValue
  }
}

const useLocalStorage = <T>(key: string, initialValue: T, postProcess?: (rawValue: T) => T) => {
  const [value, setValue] = useState<T>(() => load<T>(key, initialValue, postProcess))

  useEffect(() => {
    save(key, value)
  }, [key, value])

  const reset = () => {
    setValue(initialValue)
    save(key, initialValue)
  }

  return { value, setValue, reset }
}

export default useLocalStorage
