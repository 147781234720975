import BaseTabs from '@/gf/components/next/Tabs'

const tabs = [
  { param: 'activity', name: 'Activity' },
  { param: 'trend', name: 'Trend' },
]

export const ChartTabs = ({
  chartTab,
  update,
}: {
  chartTab: string
  update: (args: { chartTab?: string | null }) => void
}) => {
  const tab = tabs.filter((t) => t.param === chartTab)[0]

  return (
    <div className="flex gap-6 items-center">
      <BaseTabs
        tabs={tabs}
        selectedTabName={tab.name}
        onTabSelect={(name) => {
          const newTab = tabs.filter((t) => t.name === name)[0]
          update({ chartTab: newTab !== tabs[0] ? newTab.param : null })
        }}
      />
    </div>
  )
}
