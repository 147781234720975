/**
 * Return query strings that can be appended to table page urls
 */

import {
  encodeQueryParams,
  withDefault,
  NumberParam,
  StringParam,
  JsonParam,
} from 'use-query-params'
import qs from 'query-string'

import { Filter } from '../../types'

import True from '@/gf/modules/filters/True'
import NotEqualsFilter from '@/gf/modules/filters/NotEquals'
import ContainsFilter from '@/gf/modules/filters/Contains'
import RequestForQuoteM from '@/gf/modules/RequestForQuote'
import { queryParamConfigMap } from '../pages/Invoices'

// Returns the open store order query string
// An order is "open" when it has not been "cancelled" or "shipped"
const openStoreOrder = () => {
  const encodedQuery = encodeQueryParams(
    {
      page: withDefault(NumberParam, 1),
      filters: withDefault<Filter[], Filter[]>(JsonParam, []),
    },
    {
      filters: [
        // Not "shipped"
        NotEqualsFilter.build({
          fieldId: 'state',
          text: 'shipped',
        }),
        // Not "ready for pickup"
        NotEqualsFilter.build({
          fieldId: 'state',
          text: 'ready_for_pickup',
        }),
        // Not "cancelled"
        NotEqualsFilter.build({
          fieldId: 'state',
          text: 'cancelled',
        }),
        // Not "refunded"
        NotEqualsFilter.build({
          fieldId: 'state',
          text: 'refunded',
        }),
      ],
    }
  )
  return `?${qs.stringify(encodedQuery)}`
}

// Returns the open RFQs query string
// An RFQ is "open" when it has 0 "quotes_approved"
// (when none of the Store Orders has a state other than "open_quote" or "cancelled")
const openRfqs = () => {
  const encodedQuery = encodeQueryParams(
    {
      page: withDefault(NumberParam, 1),
      filters: withDefault<Filter[], Filter[]>(JsonParam, []),
    },
    { filters: RequestForQuoteM.buyerOpenRFQsFilters() }
  )
  return `?${qs.stringify(encodedQuery)}`
}

const directPayWithStoreOrderId = (storeOrderShortId: string) => {
  const encodedQuery = encodeQueryParams(
    {
      page: withDefault(NumberParam, 1),
      filters: withDefault<Filter[], Filter[]>(JsonParam, []),
    },
    {
      filters: [
        ContainsFilter.build({
          fieldId: 'search_order_id',
          text: storeOrderShortId,
        }),
      ],
    }
  )
  return `?${qs.stringify(encodedQuery)}`
}

const invoicesStoreOrderId = (storeOrderShortId: string) => {
  const encodedQuery = encodeQueryParams(queryParamConfigMap, {
    search: storeOrderShortId,
  })

  return `?${qs.stringify(encodedQuery)}`
}

const orderMarkReceived = () => '?markReceived=1'

const orderNeedsAttn = () => '?needsAttention=1'

const requestsNeedApproval = () => {
  const encodedQuery = encodeQueryParams(
    {
      page: withDefault(NumberParam, 1),
      filters: withDefault<Filter[], Filter[]>(JsonParam, []),
    },
    {
      filters: [
        True.build({
          fieldId: 'request_needs_approval',
        }),
      ],
    }
  )

  return `?${qs.stringify(encodedQuery)}`
}

const quotesNeedApproval = () => {
  const encodedQuery = encodeQueryParams(
    {
      page: withDefault(NumberParam, 1),
      filters: withDefault<Filter[], Filter[]>(JsonParam, []),
    },
    {
      filters: [
        True.build({
          fieldId: 'quote_needs_approval',
        }),
      ],
    }
  )

  return `?${qs.stringify(encodedQuery)}`
}

const inlineRequestsNeedApproval = () => {
  const encodedQuery = encodeQueryParams(
    {
      page: withDefault(NumberParam, 1),
      selected: withDefault(StringParam, 'inbound'),
    },
    { selected: 'inbound' }
  )

  return `?${qs.stringify(encodedQuery)}`
}

const inlineQuotesNeedApproval = () => {
  const encodedQuery = encodeQueryParams(
    {
      page: withDefault(NumberParam, 1),
      selected: withDefault(StringParam, 'quoted'),
    },
    { selected: 'quoted' }
  )

  return `?${qs.stringify(encodedQuery)}`
}

export default {
  openStoreOrder,
  openRfqs,
  invoicesStoreOrderId,
  requestsNeedApproval,
  quotesNeedApproval,
  inlineQuotesNeedApproval,
  inlineRequestsNeedApproval,
  orderNeedsAttn,
  orderMarkReceived,
  directPayWithStoreOrderId,
}
