import { useEffect, useState } from 'react'

const save = <T>(
  key: string,
  value: T,
  preProcess: (value: T) => string | null = JSON.stringify
) => {
  const rawValue = preProcess(value)
  if (rawValue === null) localStorage.removeItem(key)
  else localStorage.setItem(key, rawValue)
}
const load = <T>(
  key: string,
  defaultValue: T,
  postProcess: (rawValue: string) => T = JSON.parse
) => {
  const rawValue = localStorage.getItem(key)
  try {
    if (!rawValue) {
      return defaultValue
    }

    return postProcess(rawValue)
  } catch (err) {
    return defaultValue
  }
}

const useLocalStorage = <T>(
  key: string,
  initialValue: T,
  postProcess?: (rawValue: string) => T,
  preProcess?: (value: T) => string | null
) => {
  const [value, setValue] = useState<T>(() => load(key, initialValue, postProcess))

  useEffect(() => {
    save(key, value, preProcess)
  }, [key, value])

  return { value, setValue }
}

export default useLocalStorage
