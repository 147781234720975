import Action from '@/gf/components/Action'
import { BellIcon, ThumbUpIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { browserName } from 'react-device-detect'

const NotifyMe = ({ stepId }: { stepId: string }) => {
  const [justGranted, setJustGranted] = useState(false)

  if (
    stepId !== 'inbound' ||
    !('Notification' in window) ||
    (Notification.permission !== 'default' && !justGranted) ||
    browserName !== 'Chrome'
  )
    return null

  const notifyMe = () => {
    // eslint-disable-next-line compat/compat
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        setJustGranted(true)
        setTimeout(() => window.location.reload(), 1000)
      }
    })
  }

  return justGranted ? (
    <ThumbUpIcon className="w-10 h-10 animate-bounce text-green-500" />
  ) : (
    <Action.T onClick={notifyMe} key="enable-browser-notifications">
      <div className="flex gap-1 items-center text-base">
        <BellIcon className="w-5 h-5" />
        Show a notification for new requests
      </div>
    </Action.T>
  )
}

export default NotifyMe
