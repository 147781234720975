import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useOrderContext } from '../context'

const useRedirectLegacyReviewPath = () => {
  const { user } = useOrderContext()
  const [query] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (query.get('storeOrderId') && user.claimed) navigate('review')
  }, [])
}

export default useRedirectLegacyReviewPath
