const Excavator = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="24"
    height="24"
    version="1.1"
    viewBox="0 0 451.92239 332.05624"
    fill="currentColor"
    {...props}
  >
    <defs id="defs74" />
    <g id="g170" transform="translate(-124.319,-114.82577)">
      <path
        d="M 405.44,357.28 H 169.12 c -24.641,0 -44.801,20.16 -44.801,44.801 0,24.641 20.16,44.801 44.801,44.801 h 236.32 c 24.641,0 44.801,-20.16 44.801,-44.801 -0.5625,-25.203 -20.164,-44.801 -44.801,-44.801 z m 0,75.598 H 169.12 c -17.359,0 -31.359,-14 -31.359,-31.359 0,-17.359 14,-31.359 31.359,-31.359 h 236.32 c 17.359,0 31.359,14 31.359,31.359 0,17.359 -14.562,31.359 -31.359,31.359 z"
        id="path76"
      />
      <path
        d="m 199.36,401.52 c 0,7.4219 -6.0156,13.441 -13.438,13.441 -7.4258,0 -13.441,-6.0195 -13.441,-13.441 0,-7.4215 6.0156,-13.441 13.441,-13.441 7.4219,0 13.438,6.0195 13.438,13.441"
        id="path78"
      />
      <path
        d="m 240.24,401.52 c 0,7.4219 -6.0156,13.441 -13.438,13.441 -7.4224,0 -13.441,-6.0195 -13.441,-13.441 0,-7.4215 6.0195,-13.441 13.441,-13.441 7.4215,0 13.438,6.0195 13.438,13.441"
        id="path80"
      />
      <path
        d="m 280.56,401.52 c 0,7.4219 -6.0156,13.441 -13.438,13.441 -7.4224,0 -13.441,-6.0195 -13.441,-13.441 0,-7.4215 6.0195,-13.441 13.441,-13.441 7.4215,0 13.438,6.0195 13.438,13.441"
        id="path82"
      />
      <path
        d="m 320.88,401.52 c 0,7.4219 -6.0156,13.441 -13.438,13.441 -7.4258,0 -13.441,-6.0195 -13.441,-13.441 0,-7.4215 6.0156,-13.441 13.441,-13.441 7.4219,0 13.438,6.0195 13.438,13.441"
        id="path84"
      />
      <path
        d="m 361.2,401.52 c 0,7.4219 -6.0156,13.441 -13.438,13.441 -7.4258,0 -13.441,-6.0195 -13.441,-13.441 0,-7.4215 6.0156,-13.441 13.441,-13.441 7.4219,0 13.438,6.0195 13.438,13.441"
        id="path86"
      />
      <path
        d="m 401.52,401.52 c 0,7.4219 -6.0156,13.441 -13.441,13.441 -7.4219,0 -13.438,-6.0195 -13.438,-13.441 0,-7.4215 6.0156,-13.441 13.438,-13.441 7.4258,0 13.441,6.0195 13.441,13.441"
        id="path88"
      />
      <path
        d="m 557.2,304.64 c 0.55859,-1.1211 0.55859,-2.2383 0.55859,-3.3594 v -6.1602 c 0,-3.3594 -1.6797,-6.1602 -3.9219,-7.8398 l 1.6797,-147.28 c 4.4805,-3.3594 6.7188,-8.9609 5.6016,-14.559 -1.6797,-7.2812 -9.5195,-12.32 -16.801,-10.078 l -12.879,3.3594 c -5.0391,1.1211 -8.9609,5.6016 -10.078,10.078 l -117.6,28 -29.68,54.879 -16.801,-36.961 h -108.64 v 91.84 h -40.32 v -17.922 c 0,-6.1602 -5.0391,-11.199 -11.199,-11.199 -6.1599,0 -11.199,5.0391 -11.199,11.199 v 17.922 h -21.281 v 78.398 h 238.56 v -67.199 l -8.3984,-20.156 28,-68.32 103.6,-46.48 c 1.6797,1.6797 3.9219,2.2383 6.7188,2.8008 l 8.3984,142.24 c -2.2383,1.6797 -3.3594,4.4805 -3.3594,7.2812 v 6.1602 0.55859 l -48.719,43.68 56,11.199 30.801,-17.359 z M 267.12,265.999 v -75.602 h 76.719 l 34.16,75.602 z"
        id="path90"
      />
    </g>
  </svg>
)

export default Excavator
