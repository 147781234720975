import { LockClosedIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

const ProFeatureLabel = ({ size = 'sm' }: { size?: 'sm' | 'md' }) => (
  <p
    className={classNames(
      'font-medium text-orange-500 flex items-center gap-x-1',
      size === 'sm' && 'text-sm',
      size === 'md' && 'text-base'
    )}
  >
    <LockClosedIcon
      className={classNames(
        'inline-block ',
        size === 'sm' && 'h-5 w-5',
        size === 'md' && 'h-6 w-6'
      )}
    />
    Pro Account Feature
  </p>
)

export default ProFeatureLabel
