import { PencilIcon, PlusIcon } from '@heroicons/react/outline'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { CategoriesDictionary, Category } from '../../types'
import { cn } from '../modules/utils'
import EditCategory from './EditCategory'

const Item = ({
  category,
  categoriesByParentId,
  selectedId,
  CategoryTree: CategoryTreeComponent,
  editable,
  onAdd,
  onSelect,
  ...props
}: {
  category: Category
  categoriesByParentId: CategoriesDictionary
  selectedId?: string | null
  CategoryTree: typeof CategoryTree
  editable: boolean
  onSelect: (catId: string) => void
  onAdd?: (catId: string) => void
  onUpdated?: () => void
}) => {
  const [listVisible, setListVisible] = useState(false)
  const [editing, setEditing] = useState(false)

  const onClick = () => {
    if (editing) return
    setListVisible((prev) => !prev)
    onSelect(category.id)
  }

  const onUpdated = () => {
    setEditing(false)
    if (props.onUpdated) props.onUpdated()
  }

  const actionClassName =
    'p-1 text-black text-opacity-20 shrink-0 hover:text-gearflow hover:ring-1 hover:ring-indigo-500'

  return (
    <>
      <div
        className={cn(
          `flex gap-1 items-center hover:bg-gray-100`,
          selectedId === category.id && 'bg-blue-200 hover:bg-blue-200'
        )}
      >
        <button
          onClick={onClick}
          className="flex items-center gap-1 text-left text-sm grow p-1"
          type="button"
        >
          {categoriesByParentId[category.id] ? (
            listVisible ? (
              <ChevronDownIcon className="h-5 w-5 shrink-0" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 shrink-0" />
            )
          ) : (
            <div className="h-5 w-5 shrink-0">&nbsp;</div>
          )}

          {editable && editing ? (
            <EditCategory
              category={category}
              onUpdated={onUpdated}
              onCancel={() => setEditing(false)}
            />
          ) : (
            <div>{category.name}</div>
          )}
        </button>

        {editable && !editing && (
          <button onClick={() => setEditing(true)} type="button" className={actionClassName}>
            <PencilIcon className="h-5 w-5" />
          </button>
        )}

        {onAdd && (
          <button onClick={() => onAdd(category.id)} type="button" className={actionClassName}>
            <PlusIcon className="h-5 w-5" />
          </button>
        )}
      </div>

      <CategoryTreeComponent
        categoryId={category.id}
        categoriesByParentId={categoriesByParentId}
        onAdd={onAdd}
        onSelect={onSelect}
        visible={listVisible}
        selectedId={selectedId}
        root={false}
        editable={editable}
        onUpdated={onUpdated}
      />
    </>
  )
}

const CategoryTree = ({
  categoryId,
  categoriesByParentId,
  visible,
  selectedId,
  root,
  editable = false,
  onSelect,
  onAdd,
  onUpdated,
}: {
  categoryId: string
  categoriesByParentId: CategoriesDictionary
  visible: boolean
  selectedId?: string | null
  root: boolean
  editable?: boolean
  onSelect: (catId: string) => void
  onAdd?: (catId: string) => void
  onUpdated?: () => void
}) => {
  const categories = categoriesByParentId[categoryId]

  if (!categories) return null

  return (
    <div className={cn(!root && 'ml-3', !visible && 'hidden')}>
      {categories.map((category) => (
        <Item
          category={category}
          categoriesByParentId={categoriesByParentId}
          onSelect={onSelect}
          key={category.id}
          selectedId={selectedId}
          CategoryTree={CategoryTree}
          editable={editable}
          onUpdated={onUpdated}
          onAdd={onAdd}
        />
      ))}
    </div>
  )
}

export default CategoryTree
