import { useState } from 'react'

import { SelectStoreOrderProblem, SelectStoreOrderProblemType } from '@/types'

import { StoreOrderProblemType, useReportStoreOrderProblemMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import StoreOrder from '@/gf/modules/StoreOrder'

import Field from '@/gf/components/Field'
import ModalForm from '@/gf/components/ModalForm'
import Select from '@/gf/components/Select'
import TextArea from '@/gf/components/TextArea'

const ReportProblemModal = ({
  storeOrderId,
  shipmentId,
  onSuccess,
  open = true,
  close,
}: {
  storeOrderId: string
  shipmentId?: string
  onSuccess: () => void
  open?: boolean
  close: () => void
}) => {
  const [_, msgr] = useMsgs()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [submitting, submitToggle] = useToggle()
  const [accuracyDetails, setAccuracyDetails] = useState('')

  const [accuracyOptionId, setAccuracyOptionId] = useState<SelectStoreOrderProblem>(
    StoreOrder.noneAccuracyOption.id
  )

  const [reportStoreOrderProblem] = useReportStoreOrderProblemMutation({ client: useGqlClient() })
  const accuracyOptionError = accuracyOptionId === SelectStoreOrderProblemType.None

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (accuracyOptionError) {
      setFormSubmitted(true)
      return
    }

    submitToggle.on()

    reportStoreOrderProblem({
      variables: {
        storeOrderProblem: {
          storeOrderId,
          shipmentId: shipmentId || null,
          type: accuracyOptionId,
          details: accuracyDetails,
        },
      },
    })
      .then(() => {
        msgr.add('Problem reported.', 'positive')
        setAccuracyDetails('')
        setFormSubmitted(false)
        setAccuracyOptionId(StoreOrder.noneAccuracyOption.id)
        onSuccess()
      })
      .catch(() => msgr.addUnknownError())
      .finally(() => {
        close()
        submitToggle.off()
      })
  }

  return (
    <ModalForm
      title="Report a Problem"
      open={open}
      onClose={close}
      onSubmit={onSubmit}
      submitButtonShowSpinner={submitting}
      submitButtonDisabled={submitting}
    >
      <div className="mt-4 flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-1">
          <h3 className="text-base text-gray-900">Accuracy</h3>
          <p>Is there a problem with your Order?</p>

          <div className="flex flex-col">
            <Field
              className={formSubmitted && accuracyOptionError ? 'mb-2' : undefined}
              errors={
                formSubmitted && accuracyOptionError
                  ? 'Please select the type of problem'
                  : undefined
              }
            >
              <Select
                currentId={accuracyOptionId}
                placeholder="(Select)"
                options={StoreOrder.accuracyOptions}
                onChange={(id) => setAccuracyOptionId(id as StoreOrderProblemType)}
                required
              />
            </Field>
          </div>

          <TextArea
            className="h-32"
            placeholder="Please describe the problem in detail. This information will be sent to your Vendor, and will help them resolve the issue."
            value={accuracyDetails}
            setValue={setAccuracyDetails}
            required
          />
        </div>
      </div>
    </ModalForm>
  )
}

export default ReportProblemModal
