import { RequestForQuoteStep, useRequestScheduledMessagesQuery } from '@/dealers/_gen/gql'
import ScheduleMessageForm from '@/dealers/components/ScheduleMessageForm'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import Ghost from '@/gf/components/Ghost'
import Link from '@/gf/components/Link'
import Modal from '@/gf/components/ModalNext'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { InformationCircleIcon, PlusIcon } from '@heroicons/react/solid'
import { DateTime } from 'luxon'
import ScheduledMessageListItem from './Automations/ScheduledMessageListItem'
import { useRfqContext } from './context'
import SidebarLayout from './SidebarLayout'

const Loading = () => (
  <div className="w-full space-y-4">
    {[1, 2, 3].map((i) => (
      <div key={`ghost-${i}`} className="w-full space-y-1.5">
        <Ghost className="h-6 w-full block" />
        <Ghost className="h-5 w-2/3 block" />
      </div>
    ))}
  </div>
)

const Automations = () => {
  const [_, msgs] = useMsgs()
  const [scheduleMessageOpen, scheduleMessageToggle] = useToggle()
  const { rfq } = useRfqContext()
  const { data } = useRequestScheduledMessagesQuery({
    variables: { requestForQuoteId: rfq.id },
  })

  const scheduledMessages = data?.scheduledMessages ?? []

  const onScheduleSuccess = (scheduledFor: DateTime) => {
    msgs.add(`We'll send a reminder to the customer on ${scheduledFor.toFormat('ff')}`, 'positive')

    scheduleMessageToggle.off()
  }

  return (
    <SidebarLayout className="flex flex-grow flex-col sm:flex-row gap-x-6">
      <Modal open={scheduleMessageOpen} onClose={scheduleMessageToggle.off}>
        <ScheduleMessageForm
          rfqId={rfq.id}
          onCancel={scheduleMessageToggle.off}
          onError={() => msgs.addUnknownError()}
          onSuccess={onScheduleSuccess}
        />
      </Modal>

      <Box className="shadow-base flex-grow p-6">
        <div className="max-w-xl flex flex-col items-start gap-y-8">
          <div className="flex flex-col gap-y-2">
            <h3 className="text-lg font-medium flex gap-x-4 items-center">Automated Messages</h3>
            <p className="text-base text-gray-600 max-w-prose">
              These messages will only be sent if the customer has no activity on this quote.
            </p>
          </div>

          <div className="flex flex-col items-start gap-y-4">
            {![RequestForQuoteStep.Canceled, RequestForQuoteStep.Fulfilled].includes(rfq.step) && (
              <Action.S onClick={scheduleMessageToggle.on} className="flex items-center gap-x-2">
                <PlusIcon className="-ml-0.5 h-4 w-4 inline-block" /> Schedule a new message
              </Action.S>
            )}

            {!data ? (
              <Loading />
            ) : scheduledMessages.length === 0 ? (
              <p className="text-base">No automated messages.</p>
            ) : (
              <ul className="space-y-4">
                {scheduledMessages.map((sm) => (
                  <ScheduledMessageListItem
                    key={sm.id}
                    message={sm}
                    customerName={rfq.user.organization.name}
                  />
                ))}
              </ul>
            )}
          </div>

          <p className="mt-2 bg-gray-100 border border-gray-200 text-gray-600 px-3 py-2 flex items-center rounded-lg gap-x-2 text-sm font-medium">
            <InformationCircleIcon className="-ml-0.5 text-gray-600 inline-block h-5 w-5" />
            <span>
              <Link.T className="text-gray-600" to="../messages/support">
                Let us know
              </Link.T>{' '}
              if there are more automations you&apos;re interested in.
            </span>
          </p>
        </div>
      </Box>
    </SidebarLayout>
  )
}

export default Automations
