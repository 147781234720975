import { Maybe } from '@/types'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export enum ModalTypes {
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  CREATE_SHIPPING = 'CREATE_SHIPPING',
  SEND_INVOICE = 'SEND_INVOICE',
  UPDATE_QUOTE_NUMBER = 'UPDATE_QUOTE_NUMBER',
  UPDATE_SPECIAL_ORDER_NUMBER = 'UPDATE_SPECIAL_ORDER_NUMBER',
  ACCEPT_QUOTE = 'ACCEPT_QUOTE',
}

const PARAM_KEY = 'modal'

/*
  This component is used to allow a modal to be open independently of the url
*/
const useModal = () => {
  const [params, setSearchParams] = useSearchParams({ modal: '' })
  const sidePanel = useMemo<Maybe<ModalTypes>>(() => {
    const paramValue = params.get(PARAM_KEY)
    return paramValue ? ModalTypes[paramValue] : null
  }, [params])

  const close = () => {
    params.delete(PARAM_KEY)
    setSearchParams(params, { replace: true })
  }

  const open = (panel: ModalTypes) => {
    params.set(PARAM_KEY, panel)
    setSearchParams(params, { replace: true })
  }

  return {
    current: sidePanel,
    close,
    open,
    onCreateShipping: () => open(ModalTypes.CREATE_SHIPPING),
    onReadyForPickup: () => open(ModalTypes.READY_FOR_PICKUP),
    onSendInvoice: () => open(ModalTypes.SEND_INVOICE),
    onAcceptQuote: () => open(ModalTypes.ACCEPT_QUOTE),
  }
}

export default useModal
