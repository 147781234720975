import Action from '@/gf/components/Action'
import { cn } from '@/gf/modules/utils'
import logo from '@/retail/components/Layout/logo.png'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

const Frame = ({
  children,
  className,
  progress,
  savingAndExiting = false,
  nexting = false,
  saveAndExit,
  back,
  next,
}: {
  children: ReactNode
  className?: string
  progress?: { name: ReactNode; mins: number; className: string }
  savingAndExiting?: boolean
  nexting?: boolean
  saveAndExit?: () => void
  back?: () => void
  next?: () => void
}) => (
  <div className="min-h-screen bg-white border-t-8 border-gearflow flex flex-col">
    <header className="flex px-8 py-6">
      <div className="basis-1/2 md:basis-1/4">
        <Link to="/">
          <img src={logo} alt="Gearflow" className="w-36" />
        </Link>
      </div>

      <div className="basis-0 hidden md:basis-1/2 md:block">
        {progress && (
          <div className="max-w-xl space-y-2 mx-auto">
            <div className="flex items-center justify-between">
              <div>{progress.name}</div>
              <div className="text-gray-500 text-sm">{progress.mins} min remaining</div>
            </div>

            <div className="bg-gray-100 rounded-full">
              <div className={cn('h-2 bg-blue-600 rounded-full', progress.className)} />
            </div>
          </div>
        )}
      </div>

      <div className="basis-1/2 flex justify-end md:basis-1/4">
        {saveAndExit && (
          <Action.S onClick={saveAndExit} performing={savingAndExiting}>
            Save &amp; Exit
          </Action.S>
        )}
      </div>
    </header>

    <div className="flex-1 pt-8 pb-4">
      <div className={cn('max-w-md mx-auto', className)}>{children}</div>
    </div>

    {(back || next) && (
      <div className="sticky bottom-0 bg-white border-t-2 border-gray-100 px-8 py-2">
        <div className="max-w-xl mx-auto flex justify-between">
          <div>{back && <Action.S onClick={back}>Back</Action.S>}</div>

          <div>
            {next && (
              <Action.P onClick={next} color="blue" performing={nexting}>
                Next
              </Action.P>
            )}
          </div>
        </div>
      </div>
    )}
  </div>
)

export default Frame
