import React from 'react'
import classNames from 'classnames'

import { TextAreaProps } from '../../types'

const className =
  'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm placeholder:text-gray-400 text-gray-900'

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ setValue, prose = true, ...props }, ref) => {
    const onChange = setValue
      ? (event: React.ChangeEvent<HTMLTextAreaElement>) => setValue(event.target.value)
      : props.onChange

    return (
      <div className={prose ? 'max-w-prose' : undefined}>
        <textarea
          {...props}
          onChange={onChange}
          className={classNames(className, props.className)}
          ref={ref}
        />
      </div>
    )
  }
)

export default TextArea
