import Box from '@/buyers/components/Box'
import { RequestForQuoteQuery } from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import MultiButton from '@/gf/components/MultiButton'
import ViewRequestModal from '@/gf/components/ViewRequestModal'
import useToggle from '@/gf/hooks/useToggle'
import { EyeIcon } from '@heroicons/react/outline'

export const viewOriginalRequestAction = (
  request: NonNullable<RequestForQuoteQuery['requestForQuote']>
): Parameters<typeof MultiButton>[0]['actions'][number] => ({
  key: 'view-original-request',
  display: 'View Original Request',
  icon: EyeIcon,
  replace: true,
  modal: ({ open, onClose }: { open: boolean; onClose: () => void }) => (
    <ViewRequestModal
      open={open}
      onClose={onClose}
      title="Original Request"
      disableLinks
      request={{
        ...request,
        parts: request.partRequests,
        orgMachines: request.machines.map((m) => m.details),
      }}
    />
  ),
})

const ViewOriginalRequest = ({
  request,
}: {
  request: NonNullable<RequestForQuoteQuery['requestForQuote']>
}) => {
  const [open, modal] = useToggle()

  return (
    <>
      <Box className="text-center space-y-4 flex flex-col items-center" dim>
        <h4 className="text-lg font-medium">View Your Request</h4>
        <p className="mt-2 text-center leading-6">
          The details of your quote may be different than your customer’s original request. To see
          their request, select View Original Request
        </p>
        <div className="mt-4">
          <Action.S onClick={modal.on} className="flex items-center">
            <EyeIcon className="w-5 h-5 inline-block mr-1.5" />
            View Original Request
          </Action.S>
        </div>
      </Box>
      <ViewRequestModal
        open={open}
        onClose={modal.off}
        title="Original Request"
        disableLinks
        request={{
          ...request,
          parts: request.partRequests,
          orgMachines: request.machines.map((m) => m.details),
        }}
      />
    </>
  )
}

export default ViewOriginalRequest
