import type { AuthenticatedSession as BuyersAuthenticatedSession } from '@/buyers/contexts/SessionContext'
import type { FeatureFlags, Filter, FilterField, FilterTypeId, Option } from '../../types'
import Blank from './filters/Blank'
import Contains from './filters/Contains'
import DateTimeInRange from './filters/DateTimeInRange'
import Equals from './filters/Equals'
import False from './filters/False'
import In from './filters/In'
import Is from './filters/Is'
import IsNot from './filters/IsNot'
import IsNotUnique from './filters/IsNotUnique'
import NotBlank from './filters/NotBlank'
import NotContains from './filters/NotContains'
import NotEquals from './filters/NotEquals'
import TooLong from './filters/TooLong'
import True from './filters/True'

const build = ({
  id,
  fieldId,
  typeId,
  text = '',
  from,
  to,
  list,
  options,
  selectedOption,
}: {
  id?: string
  fieldId: string
  typeId: FilterTypeId
  text?: string
  from?: string | null
  to?: string | null
  list?: string[]
  options?: Option[]
  selectedOption?: string
}) => {
  if (typeId === 'contains') return Contains.build({ id, fieldId, text })
  if (typeId === 'not_contains') return NotContains.build({ id, fieldId, text })
  if (typeId === 'blank') return Blank.build({ id, fieldId })
  if (typeId === 'not_blank') return NotBlank.build({ id, fieldId })
  if (typeId === 'equals') return Equals.build({ id, fieldId, text })
  if (typeId === 'not_equals') return NotEquals.build({ id, fieldId, text })
  if (typeId === 'date_time_in_range') return DateTimeInRange.build({ id, fieldId, from, to })
  if (typeId === 'true') return True.build({ id, fieldId })
  if (typeId === 'false') return False.build({ id, fieldId })
  if (typeId === 'not_unique') return IsNotUnique.build({ id, fieldId })
  if (typeId === 'too_long') return TooLong.build({ id, fieldId })
  if (typeId === 'in') return In.build({ id, fieldId, list })
  if (typeId === 'is') return Is.build({ id, fieldId, options, selectedOption })
  if (typeId === 'is_not') return IsNot.build({ id, fieldId, options, selectedOption })

  throw new Error('Unexpected filter type')
}

const toApiFilter = (filter: Filter) => {
  if (filter.typeId === 'contains') return Contains.toApiFilter(filter)
  if (filter.typeId === 'not_contains') return NotContains.toApiFilter(filter)
  if (filter.typeId === 'blank') return Blank.toApiFilter(filter)
  if (filter.typeId === 'not_blank') return NotBlank.toApiFilter(filter)
  if (filter.typeId === 'equals') return Equals.toApiFilter(filter)
  if (filter.typeId === 'not_equals') return NotEquals.toApiFilter(filter)
  if (filter.typeId === 'date_time_in_range') return DateTimeInRange.toApiFilter(filter)
  if (filter.typeId === 'true') return True.toApiFilter(filter)
  if (filter.typeId === 'false') return False.toApiFilter(filter)
  if (filter.typeId === 'not_unique') return IsNotUnique.toApiFilter(filter)
  if (filter.typeId === 'too_long') return TooLong.toApiFilter(filter)
  if (filter.typeId === 'in') return In.toApiFilter(filter)
  if (filter.typeId === 'is') return Is.toApiFilter(filter)
  if (filter.typeId === 'is_not') return IsNot.toApiFilter(filter)

  throw new Error('Unexpected filter type')
}

const availableFilterFields = (
  fields: FilterField[],
  isAdmin: boolean,
  featureFlags: FeatureFlags | BuyersAuthenticatedSession['featureFlags']
) =>
  fields
    .filter((field) => !field.adminOnly || isAdmin)
    .filter((field) => !field.featureFlag || featureFlags[field.featureFlag])

export default { build, toApiFilter, availableFilterFields }
