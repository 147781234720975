import { v4 } from 'uuid'
import { IsNotFilter, Option } from '../../../types'

const build = ({
  id,
  fieldId,
  options = [],
  selectedOption = '',
}: {
  id?: string
  fieldId: string
  options?: Option[]
  selectedOption?: string
}): IsNotFilter => ({
  id: id || v4(),
  typeId: 'is_not',
  fieldId,
  options,
  selectedOption,
})

const toApiFilter = (filter: IsNotFilter) => ['is_not', filter.fieldId, filter.selectedOption]

export default { build, toApiFilter }
