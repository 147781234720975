import classNames from 'classnames'

const NotificationDot = ({ className }: { className?: string }) => (
  <span
    className={classNames(
      'h-3 w-3 min-w-3 inline-flex bg-gradient-to-t from-blue-500 to-blue-300 rounded-full',
      className
    )}
  />
)

export default NotificationDot
