import { DevMenuQuery, useDevMenuQuery } from '@/admin/_gen/gql'
import useAdminGqlClient from '@/admin/hooks/useGqlClient'
import { App } from '@/buyers/_gen/gql'
import Action from '@/gf/components/Action'
import ThreeDotsDropdown from '@/gf/components/ThreeDotsDropdown'
import useConfig from '../hooks/useConfig'
import { cn } from '../modules/utils'

const DevMenu = ({
  currentUserId,
  className,
  ...props
}: {
  currentUserId: string
  className?: string
  signIn: (app: App, token: string) => Promise<unknown>
}) => {
  const config = useConfig()

  const query = useDevMenuQuery({
    variables: {
      filter: JSON.stringify(['id_not_eq', currentUserId]),
      sort: 'last_active_at',
      limit: 20,
    },
    client: useAdminGqlClient(),
  })

  const users = query.data?.users

  const signIn = async (user: DevMenuQuery['users'][number]) => {
    const [app, token] = user.storeId
      ? [App.SalesHub, user.salesHubAuthToken]
      : [App.PartsHub, user.partsHubAuthToken]

    await props.signIn(app, token)

    if (user.role === 'admin') window.location.href = config.adminUrl
    else if (user.storeId) window.location.href = config.dealersUrl
    else window.location.href = config.buyersUrl
  }

  return (
    <ThreeDotsDropdown className={cn('w-auto', className)}>
      <div className="px-3 py-2 text-sm space-y-2">
        {users?.map((user) => (
          <Action.T onClick={() => signIn(user)} className="block w-full text-left" key={user.id}>
            {user.email}
          </Action.T>
        ))}
      </div>
    </ThreeDotsDropdown>
  )
}

export default DevMenu
