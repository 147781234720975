import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  Placement,
  size,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react'
import classNames from 'classnames'
import isUndefined from 'lodash/isUndefined'
import { forwardRef, ReactNode, RefCallback, useEffect, useRef, useState } from 'react'
import TextInput from './forms/TextInput'

interface ItemProps {
  children: React.ReactNode
  active: boolean
}

const Item = forwardRef<HTMLDivElement, ItemProps & React.HTMLProps<HTMLDivElement>>(
  ({ children, active, ...rest }, ref) => {
    const id = useId()
    return (
      <div
        ref={ref}
        role="option"
        id={id}
        aria-selected={active}
        {...rest}
        style={{
          cursor: 'default',
          ...rest.style,
        }}
        className={classNames('px-3 py-2', active && 'text-blue-600 bg-gray-50')}
      >
        {children}
      </div>
    )
  }
)

const defaultRenderOption = (option) => option
const defaultTranformSelection = (option) => option

interface Option {
  id: string
}

type Props<T> = {
  value: string
  onChange: (newValue: string) => void
  placeholder?: string
  onFetchOptions: (search: string) => Promise<T[]>
  className?: string
  renderOption?: (option: T) => ReactNode
  transformSelection?: (option: T) => string
  afterSelection?: (option: T) => void
  id?: string
  inputRef?: RefCallback<Element>
  menuWidth?: 'reference' | number
  menuHeight?: number
  menuTitle?: string
  menuOpenOnClick?: boolean
  placement?: Placement | undefined
  fetchWithEmptyPrefix?: boolean
  autoFocus?: boolean
}

const Typeahead = <T extends Option>({
  value,
  onChange,
  placeholder,
  onFetchOptions,
  renderOption = defaultRenderOption,
  transformSelection = defaultTranformSelection,
  afterSelection = () => undefined,
  className,
  id,
  inputRef: refCallback,
  menuWidth = 'reference',
  menuHeight,
  menuTitle,
  menuOpenOnClick = false,
  placement,
  fetchWithEmptyPrefix = false,
  autoFocus,
}: Props<T>) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<T[]>([])
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const listRef = useRef<Array<HTMLElement | null>>([])
  const inputRef = useRef<Element | null>(null)

  const fetchOptions = async (prefix: string, menuOpen: boolean) => {
    const requiresPrefixToFetch = !fetchWithEmptyPrefix
    if ((requiresPrefixToFetch && !prefix) || !menuOpen) {
      setOptions([])
      return
    }

    const results = await onFetchOptions(prefix)
    setOptions(results)
  }

  useEffect(() => {
    fetchOptions(value, open)
  }, [value, open])

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    placement,
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      ...(isUndefined(placement) ? [flip({ padding: 10 })] : []),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: menuWidth === 'reference' ? `${rects.reference.width}px` : `${menuWidth}px`,
            ...(menuWidth !== 'reference' && rects.reference
              ? {
                  transform: `translate(${rects.reference.x}px,${
                    (rects.reference.y as number) + (rects.reference.height as number)
                  }px)`,
                }
              : {}),
            maxHeight: isUndefined(menuHeight)
              ? `${availableHeight}px`
              : menuHeight < availableHeight
                ? `${menuHeight}px`
                : `${availableHeight}px`,
          })
        },
        padding: 10,
      }),
    ],
    elements: {
      reference: inputRef.current,
    },
  })

  const role = useRole(context, { role: 'listbox' })
  const dismiss = useDismiss(context)
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    role,
    dismiss,
    listNav,
  ])

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    onChange(newValue)

    if (newValue) {
      setOpen(true)
      setActiveIndex(0)
    } else {
      setOpen(false)
    }
  }

  const onOptionSelected = (option: T) => {
    onChange(transformSelection(option))

    afterSelection(option)
  }

  return (
    <>
      <TextInput
        id={id}
        {...getReferenceProps({
          ref: (e) => {
            if (refCallback) {
              refCallback(e ?? null)
            }

            inputRef.current = e
          },
          onChange: _onChange,
          value,
          placeholder,
          'aria-autocomplete': 'list',
          autoComplete: 'off',
          autoFocus,
          className,
          onClick() {
            if (menuOpenOnClick) {
              setOpen(true)
            }
          },
          onKeyDown(event) {
            if (event.key === 'Enter' && activeIndex != null && options[activeIndex]) {
              event.preventDefault()
              event.stopPropagation()

              onOptionSelected(options[activeIndex])

              setActiveIndex(null)
              setOpen(false)
            }
          },
        })}
      />
      <FloatingPortal>
        {open && options.length > 0 && (
          <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  ...floatingStyles,
                  overflowY: 'auto',
                  zIndex: 50,
                },
                className:
                  'text-base text-gray-900 bg-white border border-gray-300 rounded-md shadow-lg mt-2',
              })}
            >
              {menuTitle && (
                <div className="px-1.5 py-0.5 bg-gray-100 text-gray-500 text-xs font-medium border-b border-gray-200">
                  {menuTitle}
                </div>
              )}
              {options.map((item, index) => (
                <Item
                  {...getItemProps({
                    key: `${item.id}-${index}`,
                    ref(node) {
                      listRef.current[index] = node
                    },
                    onClick() {
                      onOptionSelected(item)

                      setOpen(false)
                      refs.domReference.current?.focus()
                    },
                  })}
                  active={activeIndex === index}
                >
                  {renderOption(item)}
                </Item>
              ))}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  )
}

export default Typeahead
