import ProgressBar from '@/buyers/pages/CreateRequest/ProgressBar'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import Link from '@/gf/components/Link'
import Spinner from '@/gf/components/Spinner'
import UserInitials from '@/gf/components/UserInitials'
import useConfig from '@/gf/hooks/useConfig'
import useMsgs from '@/gf/hooks/useMsgs'
import MoneyM from '@/gf/modules/Money'
import { MinusIcon, PlusIcon, PlusSmIcon } from '@heroicons/react/solid'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { Card, loadStripe } from '@stripe/stripe-js'
import classNames from 'classnames'
import pluralize from 'pluralize'
import { useEffect, useMemo, useState } from 'react'
import { Money } from '../../types'
import RedAlert from './RedAlert'
import Field from './next/forms/Field'

const PaymentMethod = ({
  paymentMethod: { last4, brand },
  size = 'sm',
}: {
  paymentMethod: { last4: string; brand: string }
  size?: 'sm' | 'xl'
}) => (
  <div className="inline-flex flex-row items-center gap-x-2">
    <div className="px-2 py-1 border border-gray-200 rounded-md bg-white">
      <img
        className={size === 'sm' ? 'w-6 h-3' : 'w-7 h-4'}
        src={`/images/${brand}.svg`}
        alt={brand}
      />
    </div>
    <span
      className={classNames('font-medium text-gray-900', size === 'sm' ? 'text-sm' : 'text-xl')}
    >
      •••• {last4}
    </span>
  </div>
)

const BillingMethodInput = () => (
  <div className="grid grid-rows-2 grid-cols-2 gap-x-4 gap-y-3">
    <Field className="col-span-2" label="Card number">
      <CardNumberElement options={{ showIcon: true }} id="stripe-card-number" />
    </Field>
    <Field label="Expiration date">
      <CardExpiryElement id="stripe-expiration-date" />
    </Field>
    <Field label="CVC">
      <CardCvcElement id="stripe-cvc" />
    </Field>
  </div>
)

const StepTitle = ({ title, subtitle }: { title: string; subtitle?: string }) => (
  <div className="flex flex-col gap-y-2">
    <p className="text-xl font-medium text-gray-900">{title}</p>
    {subtitle && <p className="text-sm text-gray-600">{subtitle}</p>}
  </div>
)

interface ConfirmLicensesForm {
  licenses: number
}
const SubscribeConfirmLicensesStep = ({
  form: { licenses },
  onChange,
  onSubmit,
  basePrice,
  baseLicenses,
  users,
  minLicenses,
  licenseUnitPrice,
}: {
  form: ConfirmLicensesForm
  onChange: (form: ConfirmLicensesForm) => void
  onSubmit: () => void
  basePrice: Money
  baseLicenses: number | null
  users: { id: string; displayName: string }[]
  minLicenses: number
  licenseUnitPrice: Money
}) => {
  const baseMinLicenses = baseLicenses ? minLicenses - baseLicenses : minLicenses

  useEffect(() => {
    if (minLicenses && licenses <= minLicenses) {
      const newMin = baseLicenses ? minLicenses - baseLicenses : minLicenses
      const numberToAdd = newMin >= 0 ? newMin : 0
      onChange({ licenses: numberToAdd })
    }
  }, [minLicenses, baseLicenses])

  const totalCost = MoneyM.add(MoneyM.mult(licenseUnitPrice, licenses), basePrice)

  return (
    <div className="flex flex-col gap-y-6">
      <form
        className="p-6 bg-gray-50 border border-gray-200 rounded-lg"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <div className="max-w-lg flex flex-col gap-y-6">
          <StepTitle
            title="Confirm Licenses"
            subtitle="Add the number of licenses you need for your team. You can have more licenses than users, but every user will have their own license."
          />
          <div className="flex flex-col gap-y-4">
            <div className="h-12 min-h-12 inline-flex flex-row items-stretch shadow-sm bg-white">
              <button
                className="w-12 h-12 flex justify-center items-center rounded-l-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                type="button"
                onClick={() => onChange({ licenses: licenses - 1 })}
                disabled={!minLicenses || licenses < 1 || licenses <= baseMinLicenses}
              >
                <MinusIcon className="w-5 h-5 flex shrink-0 text-gray-900" />
              </button>
              {baseLicenses && (
                <span className="px-3 py-2 inline-flex justify-center items-center grow text-base font-medium text-gray-900 border-y border-gray-300">
                  {licenses} {pluralize('license', licenses)}
                </span>
              )}
              {!baseLicenses && (
                <span className="px-3 py-2 inline-flex justify-center items-center grow text-base font-medium text-gray-900 border-y border-gray-300">
                  {licenses} {pluralize('license', licenses)}
                </span>
              )}
              <button
                className="w-12 h-12 flex justify-center items-center rounded-r-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                type="button"
                onClick={() => onChange({ licenses: licenses + 1 })}
              >
                <PlusIcon className="w-5 h-5 flex shrink-0 text-gray-900" />
              </button>
            </div>
            {baseLicenses && (
              <div className="flex flex-row justify-between items-start">
                <div className="flex flex-col">
                  <span className="text-base font-medium text-gray-900">Pro Subscription</span>
                  <span className="text-sm text-gray-600">
                    Includes {baseLicenses} {pluralize('license', licenses)}
                  </span>
                </div>
                <span className="text-base font-medium text-gray-900">
                  {MoneyM.format(basePrice)}/month
                </span>
              </div>
            )}
            <div className="flex flex-row justify-between items-start">
              <div className="flex flex-col">
                {baseLicenses && (
                  <span className="text-base font-medium text-gray-900">
                    Additional {pluralize('license', licenses)}
                  </span>
                )}
                {!baseLicenses && (
                  <span className="text-base font-medium text-gray-900">
                    {licenses} {pluralize('license', licenses)}
                  </span>
                )}
                <span className="text-sm text-gray-600">
                  {MoneyM.format(licenseUnitPrice, { maximumFractionDigits: 0 })} per license per
                  month x {licenses}
                </span>
              </div>
              <span className="text-base font-medium text-gray-900">
                {MoneyM.format(MoneyM.mult(licenseUnitPrice, licenses))}/month
              </span>
            </div>
            {baseLicenses && (
              <div className="flex flex-row justify-between items-start">
                <div className="flex flex-col">
                  <span className="text-base font-medium text-gray-900">Total</span>
                </div>
                <span className="text-base font-medium text-gray-900">
                  {MoneyM.format(totalCost)}/month
                </span>
              </div>
            )}
          </div>
          <div className="h-12 w-full flex flex-row items-stretch">
            <Action.P className="grow" color="blue" type="submit" disabled={!minLicenses}>
              Confirm Licenses
            </Action.P>
          </div>
        </div>
      </form>

      <div className="flex flex-col gap-y-3">
        <StepTitle title="Your Team" />
        {!users ? (
          <Spinner />
        ) : (
          <div className="flex flex-col gap-y-2">
            {users.map((user) => (
              <div key={user.id} className="flex flex-row items-center gap-x-2">
                <UserInitials name={user.displayName} size="sm" />
                {user.displayName}
              </div>
            ))}
          </div>
        )}
        <Link.S
          to="/settings/users/add/details"
          className="inline-flex flex-1 self-start items-center text-gray-700"
        >
          <PlusSmIcon className="-p-0.5 w-5 h-5 flex shrink-0" />
          Add User
        </Link.S>
      </div>
    </div>
  )
}

interface PaymentMethodForm {
  id: string
  card: Pick<Card, 'last4' | 'brand'>
}
interface PaymentDetailsForm {
  paymentMethod: PaymentMethodForm | null
}
const SubscribePaymentDetailsStep = ({
  form: { paymentMethod },
  edit,
  userEmail,
  onEdit,
  onChange,
  onSubmit,
  onBack,
}: {
  form: PaymentDetailsForm
  edit: boolean
  userEmail: string
  onEdit: () => void
  onChange: (form: PaymentDetailsForm) => void
  onSubmit: () => void
  onBack: () => void
}) => {
  const [_, msgr] = useMsgs()
  const stripe = useStripe()
  const elements = useElements()
  const [isPerforming, setIsPerforming] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const editing = edit || !paymentMethod
  return (
    <form
      className="max-w-lg flex flex-col gap-y-6"
      onSubmit={(e) => {
        e.preventDefault()
        if (!editing) {
          onSubmit()
          return
        }
        setIsPerforming(true)
        setError(null)

        if (!stripe || !elements) {
          msgr.add('Error, please contact support.', 'negative')
          return
        }

        const cardNumberElement = elements.getElement('cardNumber')
        if (!cardNumberElement) {
          msgr.add('Error, please contact support.', 'negative')
          return
        }

        stripe
          .createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
            billing_details: { email: userEmail },
          })
          .then((result) => {
            if (result.error) {
              setError(result.error.message ?? 'Error, please contact support.')
            }
            if (!result.paymentMethod?.id) {
              throw new Error('No payment method id')
            }
            if (!result.paymentMethod.card) {
              throw new Error('No card')
            }
            onChange({
              paymentMethod: { id: result.paymentMethod.id, card: result.paymentMethod.card },
            })
            onSubmit()
          })
          .finally(() => setIsPerforming(false))
      }}
    >
      <StepTitle
        title="Enter Payment Details"
        subtitle="The credit card added will be used to pay for your monthly subscription."
      />
      {!editing ? (
        <div className="flex flex-row items-start gap-x-8">
          <div className="flex flex-col gap-y-1">
            <PaymentMethod paymentMethod={paymentMethod.card} />
            <span className="text-xs text-gray-600 hidden">{userEmail}</span>
          </div>
          <Action.T className="text-sm" onClick={onEdit}>
            Edit
          </Action.T>
        </div>
      ) : (
        <div className="flex flex-col items-stretch gap-y-2">
          <BillingMethodInput />
          <div className="text-sm text-gray-900 hidden">
            <Field label="Email">
              <span>{userEmail}</span>
            </Field>
          </div>
        </div>
      )}
      {error && <RedAlert title={error} />}
      <div className="h-12 w-full flex flex-row items-stretch gap-x-3">
        <Action.S onClick={onBack}>Back</Action.S>
        <Action.P className="grow" type="submit" color="blue" performing={isPerforming}>
          Confirm Payment Details
        </Action.P>
      </div>
    </form>
  )
}

interface SubscriptionForm {
  userEmail: string
  licensesForm: ConfirmLicensesForm
  paymentDetailsForm: PaymentDetailsForm
}
const SubscribeReviewStep = ({
  form,
  onSubmit,
  onBack,
  basePrice,
  baseLicenses,
  licenseUnitPrice,
  onEditLicenses,
  onEditPaymentDetails,
}: {
  form: SubscriptionForm
  onSubmit: () => Promise<unknown>
  onBack: () => void
  basePrice: Money
  baseLicenses: number | null
  licenseUnitPrice: Money
  onEditLicenses: () => void
  onEditPaymentDetails: () => void
}) => {
  const [isPerforming, setIsPerforming] = useState(false)
  const newLicenses = form.licensesForm.licenses
  const totalLicenses = baseLicenses
    ? baseLicenses + form.licensesForm.licenses
    : form.licensesForm.licenses
  const totalCost = MoneyM.add(MoneyM.mult(licenseUnitPrice, newLicenses), basePrice)
  return (
    <form
      className="max-w-lg flex flex-col gap-y-6"
      onSubmit={(e) => {
        e.preventDefault()
        setIsPerforming(true)
        onSubmit().finally(() => setIsPerforming(false))
      }}
    >
      <StepTitle title="Review Order" />
      <div className="flex flex-col divide-y divide-gray-200">
        <div className="pb-3 flex flex-row justify-between items-start">
          <div className="flex flex-row gap-x-8">
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-900">Pro Subscription</span>
              {baseLicenses && (
                <span className="text-xs text-gray-600">Includes {totalLicenses} licenses</span>
              )}
              {!baseLicenses && (
                <span className="text-xs text-gray-600">
                  {MoneyM.format(licenseUnitPrice, { maximumFractionDigits: 0 })} per license per
                  month x {form.licensesForm.licenses}
                </span>
              )}
            </div>
            <span className="text-sm font-medium text-gray-900">
              {MoneyM.format(totalCost)}/month
            </span>
          </div>
          <Action.T className="text-sm" onClick={onEditLicenses}>
            Edit
          </Action.T>
        </div>
        <div className="pt-3">
          <div className="pb-3 flex flex-row justify-between items-start">
            <div className="flex flex-row gap-x-8">
              {/* TODO: fix the types so it isn't possibly null */}
              {form.paymentDetailsForm.paymentMethod && (
                <div className="flex flex-col gap-y-1">
                  <PaymentMethod paymentMethod={form.paymentDetailsForm.paymentMethod.card} />
                  <span className="text-xs text-gray-600">{form.userEmail}</span>
                </div>
              )}
            </div>
            <Action.T className="text-sm" onClick={onEditPaymentDetails}>
              Edit
            </Action.T>
          </div>
        </div>
      </div>
      <div className="h-12 w-full flex flex-row items-stretch gap-x-3">
        <Action.S onClick={onBack}>Back</Action.S>
        <Action.P className="grow" type="submit" color="blue" performing={isPerforming}>
          Purchase Subscription
        </Action.P>
      </div>
    </form>
  )
}

// TODO: remember the form info on reload
const Subscribe = ({
  title,
  users,
  minLicenses,
  basePrice,
  baseLicenses,
  licenseUnitPrice,
  email,
  createProSubscription,
  onSubscribed,
}: {
  title: string
  users: { id: string; displayName: string }[]
  minLicenses: number
  basePrice: Money
  baseLicenses: number | null
  licenseUnitPrice: Money
  email: string
  createProSubscription: (variables) => Promise<unknown>
  onSubscribed: () => Promise<unknown>
}) => {
  const [_, msgr] = useMsgs()
  const { stripePublicKey } = useConfig()
  const stripePromise = useMemo(() => loadStripe(stripePublicKey), [])

  const [formStep, setFormStep] = useState(0)

  // Licenses form
  const [licenses, setLicenses] = useState(1)

  // Payment details form
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodForm | null>(null)
  const [editPaymentMethod, setEditPaymentMethod] = useState(true)

  return (
    <div className="flex flex-col gap-y-8">
      <p className="text-2xl font-medium text-gray-900">{title}</p>
      <ProgressBar
        currentStep={formStep}
        steps={['Confirm Licenses', 'Enter Payment Details', 'Review Order']}
        currentStepProgress={0}
      />
      <Box className="p-8 w-full">
        <Elements stripe={stripePromise}>
          <div className="flex flex-col items-stretch">
            {formStep === 0 ? (
              <SubscribeConfirmLicensesStep
                users={users}
                baseLicenses={baseLicenses}
                basePrice={basePrice}
                minLicenses={minLicenses}
                licenseUnitPrice={licenseUnitPrice}
                form={{ licenses }}
                onChange={({ licenses: newLicenses }) => setLicenses(newLicenses)}
                onSubmit={() => setFormStep((prev) => prev + 1)}
              />
            ) : formStep === 1 ? (
              <SubscribePaymentDetailsStep
                form={{ paymentMethod }}
                edit={editPaymentMethod}
                userEmail={email}
                onEdit={() => setEditPaymentMethod(true)}
                onChange={({ paymentMethod: newPaymentMethod }) =>
                  setPaymentMethod(newPaymentMethod)
                }
                onSubmit={() => {
                  setFormStep((prev) => prev + 1)
                  setEditPaymentMethod(false)
                }}
                onBack={() => setFormStep((prev) => prev - 1)}
              />
            ) : (
              <SubscribeReviewStep
                basePrice={basePrice}
                baseLicenses={baseLicenses}
                licenseUnitPrice={licenseUnitPrice}
                form={{
                  userEmail: email,
                  licensesForm: { licenses },
                  paymentDetailsForm: { paymentMethod },
                }}
                onSubmit={async () => {
                  if (!paymentMethod) {
                    msgr.add('Error, please contact support.', 'negative')
                    return Promise.resolve()
                  }

                  return createProSubscription({
                    variables: { licenses, paymentMethodId: paymentMethod.id },
                  })
                    .then((_result) => onSubscribed())
                    .catch((_error) => {
                      msgr.add('Error, please contact support.', 'negative')
                    })
                }}
                onBack={() => setFormStep((prev) => prev - 1)}
                onEditLicenses={() => setFormStep(0)}
                onEditPaymentDetails={() => setFormStep(1)}
              />
            )}
          </div>
        </Elements>
      </Box>
    </div>
  )
}

export default Subscribe
