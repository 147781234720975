import { useState } from 'react'

import {
  useNotificationSubscriptionsQuery,
  useUpdateNotificationSettingsMutation,
  useUpdateNotificationSubscriptionMutation,
} from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import useMsgs from '@/gf/hooks/useMsgs'

import Box from '@/gf/components/Box'
import Spinner from '@/gf/components/Spinner'
import Switch from '@/gf/components/Switch'
import Subscriptions from './Notifications/Subscriptions'

const Notifications = () => {
  const [updateNotifications, { loading: updateInProgress }] =
    useUpdateNotificationSettingsMutation()

  const [_, msgr] = useMsgs()

  const {
    user,
    store: { id: storeId },
  } = useSession()

  const [updateNotifSubMutation] = useUpdateNotificationSubscriptionMutation()
  const queryData = useNotificationSubscriptionsQuery({
    variables: { storeId, userId: user.id },
  }).data
  const notifications = queryData?.notifications
  const notificationSubscriptions = queryData?.store?.users[0].notificationSubscriptions

  const [values, setValues] = useState({
    notify: user.notify ?? false,
    smsEnabled: user.smsEnabled ?? false,
  })

  if (!notificationSubscriptions) return null

  const update = async ({ notify, smsEnabled }) => {
    try {
      setValues({ notify, smsEnabled })
      await updateNotifications({ variables: { notify, smsEnabled: notify ? smsEnabled : false } })
      msgr.add('Notification settings updated.', 'positive')
    } catch (err) {
      msgr.addUnknownError()
    }
  }

  const updateNotifSub = (notificationId: string, channel: string, scope: string) => {
    msgr.add('Subscription saved.', 'positive')

    updateNotifSubMutation({
      variables: { userId: user.id, notificationId, channel, scope },
    }).catch(() => msgr.addUnknownError())
  }

  return (
    <Box className="px-4 py-6 flex flex-col gap-y-6 relative w-full max-w-screen-md">
      <div className="text-lg">Notification Subscriptions</div>

      <Subscriptions
        notifications={notifications}
        notificationSubscriptions={notificationSubscriptions}
        update={updateNotifSub}
      />

      <div className="space-y-4">
        <p className="prose">
          Use the toggle to receive notifications via email for the following notifications:
        </p>

        <ul className="list-disc pl-2 ml-4 text-base">
          <li>New message received from a customer</li>
          <li>New parts request received</li>
          <li>Quote approved by customer</li>
        </ul>

        <label className="flex items-center gap-x-2">
          <Switch
            checked={values.notify}
            onChange={(notify) => update({ ...values, notify })}
            checkedClassName="bg-green-500"
          />

          <span>Email notifications</span>
        </label>
      </div>

      {updateInProgress && (
        <div className="absolute inset-0 flex items-center justify-center bg-slate-200 bg-opacity-50">
          <Spinner />
        </div>
      )}
    </Box>
  )
}

export default Notifications
