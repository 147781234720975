/**
 * Text Field Component
 * -----------------
 * Text input for use in forms
 *
 * props
 * -----------------
 * label - label over the input
 * desc - description of the input
 * type - text input field type
 * errorText - text displayed for an error with this field
 * className - styles for this TextField component
 * ref - input component ref
 */

import { forwardRef } from 'react'

import Field from './Field'
import TextInput from './TextInput'

type Props = Parameters<typeof TextInput>[0] & {
  label: string
  tooltip?: JSX.Element
  desc?: string | JSX.Element
  errors?: string | null
  noErrorText?: boolean
  containerClassName?: string
}

const TextField = forwardRef<HTMLInputElement, Props>(
  ({ label, desc, tooltip, containerClassName, errors, noErrorText = false, ...props }, ref) => {
    const id = label.toLowerCase().split(' ').join('-')

    return (
      <Field
        className={containerClassName}
        tooltip={tooltip}
        label={label}
        errors={!noErrorText ? errors : undefined}
        desc={desc}
        htmlFor={id}
      >
        <TextInput {...props} ref={ref} id={id} />
      </Field>
    )
  }
)

export default TextField
