import { ModalFormProps, Money } from '@/types'

import A from '@/gf/components/A'
import ModalForm from '@/gf/components/ModalForm'
import Id from '@/gf/modules/Id'
import MoneyM from '@/gf/modules/Money'

interface Props extends Omit<ModalFormProps, 'title'> {
  storeOrderId: string
  amount: Money
}

const MarkCreditMemoReceivedModal = ({ storeOrderId, amount, ...props }: Props) => (
  <ModalForm {...props} title="Mark Credit Memo as Received">
    <>
      <span>
        This will mark the Credit Memo of{' '}
        <span className="font-medium">({MoneyM.format(MoneyM.abs(amount))})</span> for{' '}
      </span>
      <A.T href={`/orders/${storeOrderId}`} target="_blank">
        Order {Id.shorten(storeOrderId)}
      </A.T>
      <span> as received.</span>
    </>
  </ModalForm>
)

export default MarkCreditMemoReceivedModal
