import {
  DeliveryMethod,
  ItemsReceivedError,
  StoreOrderProblemType,
  StoreOrderStep,
  useItemsReceivedMutation,
  useReportStoreOrderProblemMutation,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Dot from '@/gf/components/Dot'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import DateTimeInput from '@/gf/components/inputs/DateTime'
import QuantityInput from '@/gf/components/inputs/Quantity'
import Modal from '@/gf/components/ModalNext'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import Select from '@/gf/components/Select'
import { Td as BaseTd } from '@/gf/components/Table'
import TextArea from '@/gf/components/TextArea'
import useMsgs from '@/gf/hooks/useMsgs'
import StoreOrderM from '@/gf/modules/StoreOrder'
import Time from '@/gf/modules/Time'
import { cn } from '@/gf/modules/utils'
import { ModalSize, SelectStoreOrderProblem } from '@/types'
import { findIndex } from 'lodash'
import { DateTime } from 'luxon'
import { Fragment, TdHTMLAttributes, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { StoreOrder } from '../../LimitedOrder'
import { useOrderContext, willSomeNotShip } from '../context'

type Item = {
  lineItemId: string
  quantity: number | null
  shipmentId: string | null
  enabled: boolean
  groupIndex: number
}

type ValidItem = Exclude<Item, 'quantity' | 'enabled'> & { quantity: number; enabled: true }

type Fields = {
  accuracyOptionId: SelectStoreOrderProblem
  accuracyDetails: string
  receivedAt: DateTime<true>
  items: Item[]
}

type Shipment = StoreOrder['shipments'][number]
type ShipmentItem = Shipment['items'][number]

type UnreceivedGroupItem = {
  unreceivedQuantity: number
  lineItem: Pick<ShipmentItem['lineItem'], 'id'> & {
    product: Pick<ShipmentItem['lineItem']['product'], 'mpn' | 'name'>
  }
}

type UnreceivedGroup = {
  shipment: Pick<Shipment, 'id' | 'shippedAt' | 'carrier' | 'tracking'> | null
  items: UnreceivedGroupItem[]
}

const Td = ({ className, ...props }: TdHTMLAttributes<HTMLTableCellElement>) => (
  <BaseTd className={cn('whitespace-normal', className)} {...props} />
)

const MarkItemsReceived = () => {
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const client = useGqlClient()
  const [itemsReceived] = useItemsReceivedMutation({ client })
  const [reportStoreOrderProblem] = useReportStoreOrderProblemMutation({ client })
  const [_, msgr] = useMsgs()
  const [error, setError] = useState<ItemsReceivedError>()
  const close = () => navigate(`/orders/${storeOrder.id}`)
  const groupRefs = useRef<(HTMLInputElement | null)[]>([])

  const shippedGroups = storeOrder.shipments.reduce<UnreceivedGroup[]>((acc, shipment) => {
    const items = shipment.items
      .map((i) => ({ ...i, unreceivedQuantity: i.quantity - i.receivedQuantity }))
      .filter((i) => i.unreceivedQuantity > 0)

    return items.length > 0 ? [...acc, { shipment, items }] : acc
  }, [])

  const someWillNotShip = willSomeNotShip(storeOrder)

  const remainingLineItems = storeOrder.lineItems.reduce<UnreceivedGroupItem[]>((acc, lineItem) => {
    const shippedUnreceivedQuantity = shippedGroups.reduce(
      (qty, group) =>
        group.items
          .filter((i) => i.lineItem.id === lineItem.id)
          .reduce((subQty, item) => subQty + item.unreceivedQuantity, qty),
      0
    )

    const unreceivedQuantity =
      (someWillNotShip ? lineItem.shippedQuantity : lineItem.quantity) -
      lineItem.receivedQuantity -
      shippedUnreceivedQuantity

    return unreceivedQuantity > 0
      ? [...acc, { unreceivedQuantity, id: lineItem.id, lineItem }]
      : acc
  }, [])

  const groups = [
    ...shippedGroups,
    ...(remainingLineItems.length > 0 ? [{ shipment: null, items: remainingLineItems }] : []),
  ]

  const pickupReady =
    storeOrder.deliveryMethod === DeliveryMethod.Pickup &&
    storeOrder.step === StoreOrderStep.Fulfilling

  const [fields, setFields] = useState<Fields>({
    accuracyOptionId: StoreOrderM.noneAccuracyOption.id,
    accuracyDetails: '',
    receivedAt: DateTime.now(),
    items: groups.flatMap((group, groupIndex) =>
      group.items.map((i) => ({
        quantity: i.unreceivedQuantity,
        lineItemId: i.lineItem.id,
        shipmentId: group.shipment?.id || null,
        enabled: !!group.shipment || pickupReady,
        groupIndex,
      }))
    ),
  })

  useEffect(() => {
    groupRefs.current = new Array(groups.length)
  }, [groups.length])

  useEffect(() => {
    groupRefs.current.forEach((_ref, index) => {
      const ref = groupRefs.current[index]
      if (ref) {
        ref.indeterminate =
          // At least one is checked
          fields.items
            .filter(({ groupIndex }) => groupIndex === index)
            .reduce((checkedAcc, item) => checkedAcc || item.enabled, false) &&
          // But not all of them
          !fields.items
            .filter(({ groupIndex }) => groupIndex === index)
            .reduce((checkedAcc, item) => checkedAcc && item.enabled, true)
      }
    })
  }, [fields.items])

  if (fields.items.length === 0) {
    close()
    return null
  }

  const reportProblem = fields.accuracyOptionId !== StoreOrderM.noneAccuracyOption.id

  const submit = async () => {
    setSubmitting(true)
    setError(undefined)

    const items = fields.items
      .filter((i): i is ValidItem => i.enabled && i.quantity !== null && i.quantity > 0)
      .map((i) => ({ quantity: i.quantity, lineItemId: i.lineItemId, shipmentId: i.shipmentId }))

    const { data } = await itemsReceived({
      variables: { storeOrderId: storeOrder.id, receivedAt: fields.receivedAt, items },
    })

    if (data?.itemsReceived) {
      setError(data.itemsReceived)
      setSubmitting(false)
      return
    }

    if (reportProblem)
      await reportStoreOrderProblem({
        variables: {
          storeOrderProblem: {
            storeOrderId: storeOrder.id,
            shipmentId: null,
            type: fields.accuracyOptionId as StoreOrderProblemType,
            details: fields.accuracyDetails,
          },
        },
      })

    msgr.add('Items marked received.', 'positive')
    refetchStoreOrder()
    setSubmitting(false)

    if (storeOrder.createdByTutorial) navigate(`/orders/${storeOrder.id}/tutorial-complete`)
    else close()
  }

  const getItem = (shipmentId: string | null, lineItemId: string) =>
    fields.items.filter((i) => i.shipmentId === shipmentId && i.lineItemId === lineItemId)[0]

  const updateItem = (
    shipmentId: string | null,
    lineItemId: string,
    updates: Partial<Fields['items'][number]>
  ) => {
    const index = findIndex(
      fields.items,
      (i) => i.shipmentId === shipmentId && i.lineItemId === lineItemId
    )

    setFields({
      ...fields,
      items: Object.assign([], fields.items, {
        [index]: { ...fields.items[index], ...updates },
      }),
    })
  }

  const updateGroup = (groupIndex: number, enabled: boolean) => {
    setFields({
      ...fields,
      items: fields.items.map((item) => ({
        ...item,
        enabled: item.groupIndex === groupIndex ? enabled : item.enabled,
      })),
    })
  }

  return (
    <Modal onClose={close} size={ModalSize.MD}>
      <CloseModalButton onClick={close} className="absolute top-3 right-3" />

      <Form onSubmit={submit}>
        <div className="p-8 space-y-8">
          <div className="text-xl">
            {`What parts did you ${
              storeOrder.deliveryMethod === DeliveryMethod.Pickup ? 'pick up' : 'receive'
            }?`}
          </div>

          <Field errors={error?.items}>
            <table className="w-full">
              <tbody>
                {groups.map((group, index) => (
                  <Fragment key={group.shipment?.id || 'remaining'}>
                    <tr>
                      <Td colSpan={99}>
                        <div
                          className={twMerge(
                            'relative flex gap-2 items-center text-base',
                            index !== 0 && 'pt-5'
                          )}
                        >
                          <Checkbox
                            id={group.shipment?.id || 'remaining'}
                            ref={(element) => {
                              groupRefs.current[index] = element
                            }}
                            className="absolute -left-6"
                            checked={fields.items
                              .filter(({ groupIndex }) => groupIndex === index)
                              .reduce((checkedAcc, item) => checkedAcc && item.enabled, true)}
                            onChange={(e) => updateGroup(index, e.target.checked)}
                          />
                          <label
                            className="inline-flex items-center gap-x-2 cursor-pointer"
                            htmlFor={group.shipment?.id || 'remaining'}
                          >
                            <span className="font-medium">
                              {storeOrder.deliveryMethod === DeliveryMethod.Pickup ||
                              storeOrder.deliveryMethod === DeliveryMethod.VendorDelivery ||
                              !group.shipment
                                ? 'Remaining Parts'
                                : `Shipment ${index + 1}`}
                            </span>
                            {storeOrder.deliveryMethod === DeliveryMethod.Pickup ? (
                              storeOrder.readyAt && (
                                <>
                                  <Dot className="bg-gray-700" />
                                  <span>
                                    Ready on {Time.formatDateWithDaySmartYear(storeOrder.readyAt)}
                                  </span>
                                </>
                              )
                            ) : storeOrder.deliveryMethod === DeliveryMethod.VendorDelivery ? (
                              storeOrder.outForDeliveryAt && (
                                <>
                                  <Dot className="bg-gray-700" />
                                  <span>
                                    Sent on{' '}
                                    {Time.formatDateWithDaySmartYear(storeOrder.outForDeliveryAt)}
                                  </span>
                                </>
                              )
                            ) : (
                              <>
                                {group.shipment?.shippedAt && (
                                  <>
                                    <Dot className="bg-gray-700" />
                                    <span>
                                      Sent on{' '}
                                      {Time.formatDateWithDaySmartYear(group.shipment.shippedAt)}
                                    </span>
                                  </>
                                )}
                                {(group.shipment?.carrier || group.shipment?.tracking) && (
                                  <>
                                    <Dot className="bg-gray-700" />
                                    <span>
                                      {group.shipment.carrier} {group.shipment.tracking}
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                          </label>
                        </div>
                      </Td>
                    </tr>

                    {group.items.map((item) => (
                      <tr
                        key={`${group.shipment?.id}-${item.lineItem.id}`}
                        className="border-t border-gray-200"
                      >
                        <Td>
                          <div className="flex gap-4 items-center">
                            <Checkbox
                              id={`${group.shipment?.id}-${item.lineItem.id}`}
                              checked={
                                getItem(group.shipment?.id || null, item.lineItem.id).enabled
                              }
                              onChange={(e) =>
                                updateItem(group.shipment?.id || null, item.lineItem.id, {
                                  enabled: e.target.checked,
                                })
                              }
                            />

                            <label
                              className="cursor-pointer"
                              htmlFor={`${group.shipment?.id}-${item.lineItem.id}`}
                            >
                              {item.lineItem.product.mpn}
                            </label>
                          </div>
                        </Td>

                        <Td>
                          <label
                            className="cursor-pointer"
                            htmlFor={`${group.shipment?.id}-${item.lineItem.id}`}
                          >
                            {item.lineItem.product.name}
                          </label>
                        </Td>

                        <Td>
                          <div className="flex gap-2 items-center justify-end">
                            <QuantityInput
                              className="max-w-32 min-w-20"
                              value={getItem(group.shipment?.id || null, item.lineItem.id).quantity}
                              setValue={(quantity) =>
                                updateItem(group.shipment?.id || null, item.lineItem.id, {
                                  quantity,
                                })
                              }
                              max={item.unreceivedQuantity}
                              disabled={
                                !getItem(group.shipment?.id || null, item.lineItem.id).enabled
                              }
                            />

                            <span className="whitespace-nowrap">
                              of {item.unreceivedQuantity.toLocaleString()}
                            </span>
                          </div>
                        </Td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </Field>

          <Field label="Approximately when did you receive these parts?">
            <DateTimeInput
              dateTime={fields.receivedAt}
              updateDateTime={(receivedAt) => receivedAt && setFields({ ...fields, receivedAt })}
              max={DateTime.now()}
              required
            />
          </Field>

          <Field className="max-w-md" label="Are there any problems with this order?">
            <Select
              currentId={fields.accuracyOptionId}
              options={StoreOrderM.accuracyOptions}
              onChange={(type) =>
                setFields({ ...fields, accuracyOptionId: type as StoreOrderProblemType })
              }
            />
          </Field>

          {reportProblem && (
            <Field
              className="max-w-md"
              label="Please describe the problem in detail"
              desc="This information will help the vendor resolve your issue"
            >
              <TextArea
                className="h-24"
                value={fields.accuracyDetails}
                setValue={(accuracyDetails) => setFields({ ...fields, accuracyDetails })}
                required
              />
            </Field>
          )}
        </div>

        <div className="px-8 py-4 bg-gray-50 flex gap-2 flex-row-reverse">
          <Action.P type="submit" performing={submitting} color="blue">
            Submit
          </Action.P>

          <Action.S onClick={close}>Cancel</Action.S>
        </div>
      </Form>
    </Modal>
  )
}

export default MarkItemsReceived
