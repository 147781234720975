import { DeliveryMethod } from '@/buyers/_gen/gql'
import A from '@/gf/components/A'
import Dot from '@/gf/components/Dot'
import Link from '@/gf/components/Link'
import Time from '@/gf/modules/Time'
import pluralize from 'pluralize'
import { StoreOrder } from '../../LimitedOrder'
import { useOrderContext } from '../context'
import { Section } from '../YourOrder'

const Shipment = ({ shipment }: { shipment: StoreOrder['shipments'][number] }) => {
  const { storeOrder } = useOrderContext()

  const label =
    shipment.carrier || shipment.tracking ? (
      <span>
        {shipment.carrier} {shipment.tracking}
      </span>
    ) : (
      <span className="text-gray-500 text-sm">Tracking not provided</span>
    )

  return (
    <div className="px-4 py-3 space-y-4 text-base bg-gray-50 rounded-lg">
      <div className="space-y-1">
        <div className="flex flex-row items-center gap-x-2">
          {shipment.shippedAt && (
            <span>
              <span>Sent on </span>
              <span className="font-medium">
                {Time.formatDateWithDaySmartYear(shipment.shippedAt)}
              </span>
            </span>
          )}

          <Dot className="bg-gray-700" />

          <Link.T
            to={`/orders/${storeOrder.id}/shipments/${shipment.id}`}
            className="flex whitespace-nowrap text-gray-700"
          >
            {pluralize(
              'part',
              shipment.items.reduce((acc, i) => acc + i.quantity, 0),
              true
            )}
          </Link.T>
        </div>

        <div>
          {shipment.link ? (
            <A.T className="text-base" href={shipment.link} target="_blank">
              {label}
            </A.T>
          ) : (
            <span>{label}</span>
          )}
        </div>
      </div>
    </div>
  )
}

const Shipments = () => {
  const { storeOrder } = useOrderContext()

  if (storeOrder.deliveryMethod !== DeliveryMethod.Shipping || storeOrder.shipments.length === 0)
    return null

  return (
    <Section className="space-y-4">
      <h4 className="text-lg font-medium">Shipments</h4>

      <div className="flex flex-wrap gap-x-4 gap-y-2">
        {storeOrder.shipments.map((s) => (
          <div key={s.id}>
            <Shipment shipment={s} />
          </div>
        ))}
      </div>
    </Section>
  )
}

export default Shipments
