import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

const ProgressBar = ({
  progressPercent,
  bgColorClassName = 'bg-white',
  trackColorClassName = 'bg-blue-600',
  height = '2',
}: {
  progressPercent: number
  bgColorClassName?: string
  trackColorClassName?: string
  height?: '1' | '2'
}) => {
  const progress = useMemo(
    () => (progressPercent > 100 ? 100 : progressPercent < 0 ? 0 : progressPercent),
    [progressPercent]
  )

  return (
    <div
      className={twMerge(
        'block rounded-xl relative w-full overflow-hidden ',
        height === '1' && 'h-1',
        height === '2' && 'h-2',
        bgColorClassName
      )}
    >
      <div
        className={twMerge(
          'inset-y-0 left-0 absolute transition-all duration-500 delay-200 rounded-r-xl',
          progress > 0 ? 'opacity-100' : 'opacity-0',
          trackColorClassName
        )}
        style={{ width: `${progress}%` }}
      />
    </div>
  )
}

export default ProgressBar
