import BaseDurationInput from '@/gf/components/Reports/DurationInput'
import { ReportDuration, fromParam, toParam } from '@/gf/modules/ReportDuration'
import { DateTime } from 'luxon'

const DurationInput = ({
  fromDate,
  toDate,
  duration,
  update,
}: {
  fromDate: DateTime
  toDate: DateTime
  duration: ReportDuration
  update: (args: {
    fromDate?: DateTime | null
    toDate?: DateTime | null
    duration?: ReportDuration | null
  }) => void
}) => (
  <BaseDurationInput
    start={fromDate}
    end={toDate}
    durationId={toParam(duration)}
    onChange={({ start, end, durationId }) => {
      const newDuration = fromParam(durationId)

      update(
        newDuration === ReportDuration.Custom
          ? { fromDate: start, toDate: end, duration: null }
          : { fromDate: null, toDate: null, duration: newDuration }
      )
    }}
  />
)

export default DurationInput
