import { useCreateStripeAccountMutation } from '@/dealers/_gen/gql'
import { useState } from 'react'

const useCreateStripeAccount = ({
  storeId,
  startOnboarding,
}: {
  storeId: string
  startOnboarding: () => void
}) => {
  const [creating, setCreating] = useState(false)
  const [createMutation] = useCreateStripeAccountMutation()

  const create = async () => {
    setCreating(true)
    await createMutation({ variables: { storeId } })
    startOnboarding()
    setCreating(false)
  }

  return { create, creating }
}

export default useCreateStripeAccount
