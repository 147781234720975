import { v4 as uuid } from 'uuid'

import { IsNotUniqueFilter } from '../../../types'

const build = ({ id, fieldId }: { id?: string; fieldId: string }): IsNotUniqueFilter => ({
  id: id || uuid(),
  typeId: 'not_unique',
  fieldId,
})

const toApiFilter = (filter: IsNotUniqueFilter) => ['not_unique', filter.fieldId]

export default { build, toApiFilter }
