import {
  StripeAccountCapabilityStatus,
  useSetupCompletedMutation,
  useSetupGearflowSupplierNetworkQuery,
} from '@/dealers/_gen/gql'
import useCreateStripeAccount from '@/dealers/hooks/useCreateStripeAccount'
import useStripeOnboarding from '@/dealers/hooks/useStripeOnboarding'
import Action from '@/gf/components/Action'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Frame from './Frame'
import { useSetupContext } from './context'
import stripeGearflow from './stripe-gearflow.png'

const Parts = () => {
  const navigate = useNavigate()
  const { storeId, refetch } = useSetupContext()
  const [setupCompleted] = useSetupCompletedMutation()
  const [savingAndExiting, setSavingAndExiting] = useState(false)
  const { start, starting } = useStripeOnboarding({ storeId, onFinished: refetch })
  const { create, creating } = useCreateStripeAccount({ storeId, startOnboarding: start })
  const { store } = useSetupGearflowSupplierNetworkQuery({ variables: { storeId } }).data || {}

  useEffect(() => {
    if (store?.stripeAccount?.capabilities.transfers === StripeAccountCapabilityStatus.Active)
      navigate('/dashboard/setup-complete')
  }, [!store])

  const saveAndExit = async () => {
    setSavingAndExiting(true)
    await setupCompleted({ variables: { storeId } })
    navigate('/dashboard')
    setSavingAndExiting(false)
  }

  const back = () => navigate('/setup/parts')

  return (
    <Frame
      className="space-y-6"
      progress={{ name: <>Stripe Setup</>, mins: 3, className: 'w-4/5' }}
      savingAndExiting={savingAndExiting}
      saveAndExit={saveAndExit}
      back={back}
    >
      <div className="space-y-4 max-w-md">
        <h2 className="text-2xl font-medium">Get setup to receive payments</h2>

        <p className="prose">
          Setting up your Stripe account will allow you to receive payments for your orders.
        </p>
      </div>

      <div className="flex flex-col items-center gap-6 p-6 border rounded-md bg-gray-50">
        <img src={stripeGearflow} alt="Stripe-Gearflow" className="w-44" />
        <p className="prose">Gearflow partners with Stripe for secure payments.</p>

        {store && (
          <Action.P
            onClick={async () => {
              if (!store.stripeAccount) {
                await create()
                refetch()
              } else start()
            }}
            performing={creating || starting}
            color="blue"
            size="lg"
          >
            Setup Account
          </Action.P>
        )}
      </div>
    </Frame>
  )
}

export default Parts
