import Action from '@/gf/components/Action'
import Form from '@/gf/components/Form'
import Modal from '@/gf/components/ModalNext'
import { Tbody, Td, Th, Tr } from '@/gf/components/Table'
import { useState } from 'react'

type UnshippedItem = {
  id: string
  product: { mpn: string | null; name: string }
  unshippedQty: number
}

const CompleteOrder = ({
  unshippedItems,
  close,
  ...props
}: {
  unshippedItems: UnshippedItem[]
  submit: () => Promise<unknown>
  close: () => void
}) => {
  const [submitting, setSubmitting] = useState(false)

  const submit = async () => {
    setSubmitting(true)
    await props.submit()
    setSubmitting(false)
    close()
  }

  return (
    <Modal open onClose={close}>
      <Form onSubmit={submit} className="p-6 space-y-6">
        <div className="text-xl">Complete Order</div>
        <p className="text-base prose">Please confirm these parts will not be shipped.</p>

        <div className="border-1 border-gray-300 rounded-md bg-gray-50 overflow-auto">
          <table className="divide-y divide-gray-200 min-w-full">
            <thead>
              <tr>
                <Th className="align-bottom">Part Number</Th>
                <Th className="align-bottom">Description</Th>
                <Th className="text-center align-bottom whitespace-normal">Unshipped Quantity</Th>
              </tr>
            </thead>

            <Tbody className="bg-white">
              {unshippedItems.map((item) => (
                <Tr key={item.id}>
                  <Td>{item.product.mpn}</Td>
                  <Td>{item.product.name}</Td>
                  <Td className="text-center">{item.unshippedQty}</Td>
                </Tr>
              ))}
            </Tbody>
          </table>
        </div>

        <div className="flex gap-2 flex-row-reverse">
          <Action.P type="submit" performing={submitting}>
            Confirm &amp; Complete Order
          </Action.P>

          <Action.S onClick={close}>Cancel</Action.S>
        </div>
      </Form>
    </Modal>
  )
}

export default CompleteOrder
