import classNames from 'classnames'

import { EventDisplayData } from '../../types'

const EventIcon = ({
  eventDisplayDataIcon,
}: {
  eventDisplayDataIcon: Pick<EventDisplayData, 'iconBackground' | 'icon'>
}) => (
  <span
    className={classNames(
      eventDisplayDataIcon.iconBackground,
      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-400'
    )}
  >
    <eventDisplayDataIcon.icon className="h-5 w-5 text-white" aria-hidden="true" />
  </span>
)
export default EventIcon
