import type { Option } from './types'

import OptionC from './Option'

const Options = ({
  options,
  selectOption,
  selected,
}: {
  options: Option[] | undefined
  selectOption: (opt: Option) => void
  selected: Option | null
}) => {
  if (!options) return <li className="text-gray-400 px-3 py-2">Searching&hellip;</li>

  if (options.length === 0) return <li className="text-gray-400 px-3 py-2">No options found.</li>

  return (
    <>
      {options.map((option) => (
        <OptionC
          selected={selected?.value === option.value}
          select={() => selectOption(option)}
          key={option.value}
        >
          {option.label}
        </OptionC>
      ))}
    </>
  )
}

export default Options
