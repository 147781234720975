import { useRef, useState } from 'react'
import classNames from 'classnames'
import useToggle from '../hooks/useToggle'
import Lightbox from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Download from 'yet-another-react-lightbox/plugins/download'

const ImageGallery = ({ className, images }: { className?: string; images: string[] }) => {
  const [open, openToggle] = useToggle()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const zoomRef = useRef(null)
  const thumbnailsRef = useRef(null)

  return (
    <div className={classNames('flex gap-2 flex-wrap', className)}>
      {images.map((imageUrl, i) => (
        <a
          key={imageUrl}
          href={imageUrl}
          target="_blank"
          rel="noreferrer"
          className="w-24 h-24 block border border-slate-100 rounded-lg overflow-hidden hover:shadow transition duratio-200 group"
          onClick={(e) => {
            e.preventDefault()
            setSelectedIndex(i)
            openToggle.on()
          }}
        >
          <img
            src={imageUrl}
            alt={imageUrl}
            className="object-cover h-full w-full group-hover:opacity-95"
          />
        </a>
      ))}
      <Lightbox
        open={open}
        close={() => openToggle.off()}
        slides={images.map((src) => ({
          src,
        }))}
        index={selectedIndex}
        plugins={[Zoom, Thumbnails, Download]}
        zoom={{ ref: zoomRef, scrollToZoom: true, maxZoomPixelRatio: 6 }}
        thumbnails={{ ref: thumbnailsRef }}
      />
    </div>
  )
}

export default ImageGallery
