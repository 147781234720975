import { useServiceReportQuery } from '@/dealers/_gen/gql'

type Options = Parameters<typeof useServiceReportQuery>[0]

const useQuery = (options: Options) => {
  const { data } = useServiceReportQuery(options)

  const rfqs = data?.rfqs
    // hack to convince TS the quote is not null
    ?.flatMap((rfq) => (rfq.quote ? { ...rfq, quote: rfq.quote } : []))
    // this shouldn't happen anyways
    .filter((rfq) => rfq.quote.insertedAt >= rfq.receivedAt)

  const comparisonRfqs = data?.comparisonRfqs
    // hack to convince TS the quote is not null
    ?.flatMap((rfq) => (rfq.quote ? { ...rfq, quote: rfq.quote } : []))
    // this shouldn't happen anyways
    .filter((rfq) => rfq.quote.insertedAt >= rfq.receivedAt)

  return { rfqs, comparisonRfqs }
}

type Result = ReturnType<typeof useQuery>

export type Rfq = Exclude<Result['rfqs'], undefined>[number]

export type ComparisonRfq = Exclude<Result['comparisonRfqs'], undefined>[number]

export type Org = Rfq['quote']['customer']['organization']

export type Creator = Rfq['quote']['creation']['creator']

export default useQuery
