import { Maybe } from '@/types'
import {
  ClipboardCheckIcon,
  InboxIcon,
  PaperAirplaneIcon,
  TruckIcon,
  CheckCircleIcon,
} from '@heroicons/react/outline'

import StoreOrder from '@/gf/modules/StoreOrder'
import { StoreOrderState, RequestForQuoteStep } from '@/dealers/_gen/gql'

const canDisplayCustomerContactInfo = (rfq: {
  user: { isCustomerContact: boolean }
  step: RequestForQuoteStep
  storeOrder: Maybe<{ state: StoreOrderState }>
}) => {
  if (rfq.user.isCustomerContact) return true
  if (rfq.step === RequestForQuoteStep.Inbound) return false

  return rfq.storeOrder?.state
    ? StoreOrder.isApprovedBySupplier({ state: rfq.storeOrder?.state })
    : false
}

const allSteps = [
  { id: 'all', name: 'All' },
  { id: 'inbound', name: 'Inbound', icon: InboxIcon },
  { id: 'quoted', name: 'Quoted', icon: PaperAirplaneIcon, iconClassName: 'rotate-90' },
  { id: 'po_received', name: 'PO Received', icon: ClipboardCheckIcon },
  { id: 'fulfilling', name: 'Fulfilling', icon: TruckIcon },
  { id: 'fulfilled', name: 'Fulfilled', icon: CheckCircleIcon },
]

type Step = (typeof allSteps)[number]

const stepApiFilters = (step: Step) => {
  if (step.id === 'all') return []
  return [['step_eq', step.id]]
}

export default { canDisplayCustomerContactInfo, allSteps, stepApiFilters }
