import Tooltip from '@/gf/components/Tooltip'
import { ExclamationIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

const MachineDownIcon = ({
  className,
  tooltipLeft,
}: {
  className?: string
  tooltipLeft?: boolean
}) => (
  <Tooltip
    className={classNames('p-1 rounded-full text-red-800 bg-red-100', className)}
    text="Machine down"
    left={tooltipLeft}
  >
    <ExclamationIcon className="w-5 h-5" />
  </Tooltip>
)

export default MachineDownIcon
