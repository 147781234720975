import {
  Conversation,
  ConversationRowContent,
  ConversationScheduledMessage,
  NewConversation,
} from '@/dealers/components/Inbox/types'
import ConversationM from '@/gf/modules/Conversation'
import { Maybe } from '@/types'

const conversationNameForUser = (userName: Maybe<string>, userOrgName: Maybe<string>) =>
  // Don't show org name if it contains an email
  (!userOrgName?.includes('@') && userOrgName) || userName

const conversationNameForRfq = (
  requestForQuote: {
    user: { name: string; organization: { name: string } }
    fullName: Maybe<string>
  },
  displayCustomerContactInfo: boolean
) =>
  displayCustomerContactInfo
    ? conversationNameForUser(
        requestForQuote.user?.name,
        requestForQuote.user?.organization.name
      ) ||
      (requestForQuote.fullName ?? ConversationM.getHiddenCustomerOrgName())
    : ConversationM.getHiddenCustomerOrgName()

const conversationNameForStoreOrder = (
  storeOrder: { customer: { name: string; organization: { name: string } } },
  displayCustomerContactInfo: boolean
) =>
  (displayCustomerContactInfo &&
    conversationNameForUser(storeOrder.customer.name, storeOrder.customer.organization.name)) ||
  ConversationM.getHiddenCustomerOrgName()

// Non-admin Conversations
const newNonAdminConversationRfq = (
  requestForQuote: {
    id: string
    fullName: Maybe<string>
    user: {
      id: string
      name: string
      organization: {
        id: string
        name: string
      }
    }
  },
  storeId: string,
  displayCustomerContactInfo: boolean,
  scheduledMessage: Maybe<ConversationScheduledMessage>
): NewConversation => ({
  ...ConversationM.nonAdminConversationCommon,
  id: `new-conversation-${requestForQuote.id}-${storeId}`,
  name: conversationNameForRfq(requestForQuote, displayCustomerContactInfo),
  source: {
    type: 'request',
    id: requestForQuote.id,
    user: requestForQuote.user,
  },
  storeId,
  lastMessage: null,
  scheduledMessage,
})
const newNonAdminConversationStoreOrder = (
  storeOrder: {
    id: string
    customer: {
      id: string
      name: string
      organization: {
        id: string
        name: string
      }
    }
    store: {
      id: string
    }
  },
  displayCustomerContactInfo: boolean
): NewConversation => ({
  ...ConversationM.nonAdminConversationCommon,
  id: `new-conversation-${storeOrder.id}-${storeOrder.store.id}`,
  name: conversationNameForStoreOrder(storeOrder, displayCustomerContactInfo),
  source: {
    type: 'store_order',
    id: storeOrder.id,
    user: storeOrder.customer,
  },
  storeId: storeOrder.store.id,
  lastMessage: null,
  scheduledMessage: null,
})
// Admin Conversations
const newAdminConversationRfq = (
  requestForQuote: {
    id: string
    user: {
      id: string
      name: string
      organization: {
        id: string
        name: string
      }
    }
  },
  storeId: string
): NewConversation => ({
  ...ConversationM.adminConversationCommon,
  id: `new-conversation-${requestForQuote.id}-${storeId}-admin`,
  source: {
    type: 'request',
    id: requestForQuote.id,
    user: requestForQuote.user,
  },
  storeId,
  lastMessage: null,
  scheduledMessage: null,
})
const newAdminConversationStoreOrder = (storeOrder: {
  id: string
  store: { id: string }
  customer: {
    id: string
    name: string
    organization: {
      id: string
      name: string
    }
  }
}): NewConversation => ({
  ...ConversationM.adminConversationCommon,
  id: `new-conversation-${storeOrder.id}-${storeOrder.store.id}-admin`,
  source: {
    type: 'store_order',
    id: storeOrder.id,
    user: storeOrder.customer,
  },
  storeId: storeOrder.store.id,

  lastMessage: null,
  scheduledMessage: null,
})

const defaultConversationRowContent = (
  conversation: Conversation | NewConversation
): ConversationRowContent => ({
  name: { type: conversation.admin ? 'admin' : 'buyer', name: conversation.name },
})

// Filter out new conversations in the existing conversation list
const filterNewConversations = (
  newConversations: NewConversation[],
  conversations: Conversation[]
) =>
  newConversations.filter(
    (newConversation) =>
      !conversations.find(
        (conversation) =>
          conversation.source.id === newConversation.source.id &&
          conversation.admin === newConversation.admin &&
          conversation.storeId === newConversation.storeId
      )
  )

export default {
  ...ConversationM,
  filterNewConversations,
  conversationNameForRfq,
  conversationNameForStoreOrder,
  newNonAdminConversationRfq,
  newNonAdminConversationStoreOrder,
  newAdminConversationRfq,
  newAdminConversationStoreOrder,
  defaultConversationRowContent,
}
