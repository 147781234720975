import ANext from '@/gf/components/A'
import LinkNext from '@/gf/components/Link'
import Dropdown from '@/gf/components/next/Dropdown'
import useConfig from '@/gf/hooks/useConfig'
import { ChevronDownIcon } from '@heroicons/react/solid'

const UserMenu = ({ name }: { name: string }) => {
  const config = useConfig()

  return (
    <Dropdown
      trigger={
        <span className="inline-flex gap-x-1 items-center text-base hover:text-blue-600 hover:bg-gray-100 transition-colors duration-300 py-2 pl-3 pr-1.5 rounded">
          <span className="inline-block leading-5 md:leading-6">{name}</span>
          <ChevronDownIcon className="h-5 w-5 mt-0.5 flex-shrink-0" />
        </span>
      }
      placement="bottom-end"
    >
      <ul className="bg-white p-1 rounded-md border border-gray-200 shadow-md mt-2">
        <li>
          <ANext.T
            href={config.gfBaseUrl}
            className="p-2 rounded hover:bg-gray-100 !text-gray-700 hover:text-blue-600 no-underline block cursor-pointer transition-colors duration-300"
          >
            Back to gearflow.com
          </ANext.T>
        </li>
        <li>
          <LinkNext.T
            to="/settings"
            className="p-2 rounded hover:bg-gray-100 !text-gray-700 hover:text-blue-600 no-underline block cursor-pointer transition-colors duration-300"
          >
            Settings
          </LinkNext.T>
        </li>
        <li>
          <ANext.T
            href={`${config.gfBaseUrl}/logout`}
            className="p-2 rounded hover:bg-gray-100 !text-gray-700 hover:text-blue-600 no-underline block cursor-pointer transition-colors duration-300"
          >
            Logout
          </ANext.T>
        </li>
      </ul>
    </Dropdown>
  )
}

export default UserMenu
