import { useAddRequestTimelineNoteMutation } from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import TextArea from '@/gf/components/TextArea'
import { ArrowRightIcon } from '@heroicons/react/solid'
import { useState } from 'react'

const TimelineNoteForm = ({
  rfqId,
  onGoToMessagesClick,
  onNoteAdded,
}: {
  rfqId: string
  onGoToMessagesClick: () => void
  onNoteAdded: () => void
}) => {
  const [note, setNote] = useState('')
  const [addNote, { loading: savingNote }] = useAddRequestTimelineNoteMutation()

  const onAddNote = async () => {
    await addNote({ variables: { requestForQuoteId: rfqId, note } })
    setNote('')

    onNoteAdded()
  }

  return (
    <form className="space-y-2">
      <div className="relative max-w-prose">
        <TextArea
          placeholder="Leave a comment"
          required
          value={note}
          onChange={(e) => setNote(e.target.value)}
          className="placeholder:text-gray-500 w-full pr-28"
        />

        <Action.P
          className="absolute inset-y-1.5 right-1.5 font-medium"
          disabled={!note || savingNote}
          performing={savingNote}
          onClick={onAddNote}
        >
          Post <ArrowRightIcon className="inline-block w-5 h-5" />
        </Action.P>
      </div>

      <p className="text-xs text-gray-500">
        Only you and your team can see comments. To contact your customer, select the{' '}
        <Action.T onClick={onGoToMessagesClick}>Messages</Action.T> Tab above.
      </p>
    </form>
  )
}

export default TimelineNoteForm
