import {
  RequestForQuoteQuery,
  RequestForQuoteStep,
  useCannotParticipateMutation,
} from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import Action from '@/gf/components/Action'
import Form from '@/gf/components/Form'
import Modal from '@/gf/components/ModalNext'
import SelectField from '@/gf/components/SelectField'
import TextAreaField from '@/gf/components/TextAreaField'
import useMsgs from '@/gf/hooks/useMsgs'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const reasons = [
  'Out of stock item',
  'Backordered item',
  "We don't sell the item",
  'Customer ordered incorrect item',
  'Other',
]

const CannotParticipate = ({
  rfq,
  onSubmitted,
}: {
  rfq: NonNullable<RequestForQuoteQuery['requestForQuote']>
  onSubmitted: () => void
}) => {
  const {
    store: { id: storeId },
  } = useSession()

  const [_, msgr] = useMsgs()
  const navigate = useNavigate()
  const [fields, setFields] = useState({ reason: reasons[0], details: '' })
  const { rfqId } = useParams() as { rfqId: string }
  const [cannotParticipate] = useCannotParticipateMutation()
  const [submitting, setSubmitting] = useState(false)
  const close = () => navigate(`/rfqs/${rfqId}`)

  useEffect(() => {
    if (rfq.step !== RequestForQuoteStep.Inbound) close()
  }, [])

  const updateFields = (updates: Partial<typeof fields>) => setFields({ ...fields, ...updates })

  const submit = () => {
    setSubmitting(true)
    const details = fields.reason === 'Other' ? fields.details : null

    cannotParticipate({ variables: { storeId, rfqId, reason: fields.reason, details } })
      .then(() => {
        msgr.add('Request canceled.', 'positive')
        close()
        onSubmitted()
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal open onClose={close}>
      <Form onSubmit={submit} className="p-6 space-y-4">
        <div className="text-xl">Can&apos;t Participate in Request</div>

        <p className="text-base">
          Let your customer know why you aren&apos;t able to participate in this request.
        </p>

        <SelectField
          label="Reason"
          options={reasons.map((r) => ({ id: r, display: r }))}
          currentId={fields.reason}
          onChange={(reason) => updateFields({ reason })}
        />

        {fields.reason === 'Other' && (
          <TextAreaField
            label="What is the reason why you can't participate?"
            value={fields.details}
            onChange={({ target }) => updateFields({ details: target.value })}
            required
          />
        )}

        <div className="flex gap-2 flex-row-reverse">
          <Action.P type="submit" performing={submitting}>
            Submit
          </Action.P>

          <Action.S onClick={close}>Cancel</Action.S>
        </div>
      </Form>
    </Modal>
  )
}

export default CannotParticipate
