import { SearchIcon, XIcon } from '@heroicons/react/solid'

import { Option } from './types'

const classNames = {
  input:
    'focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300',
  icon: '-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50',
}

const Search = ({
  search,
  term,
  selected,
  clearSelected,
}: {
  search: (searchTerm: string) => void
  term: string | null
  selected: Option | null
  clearSelected: () => void
}) => {
  const display = term !== null ? term : selected?.label || ''

  const onFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    event.target.select()
    search(event.target.value)
  }

  return (
    <div className="flex rounded-md shadow-sm">
      <div className="relative flex items-stretch flex-grow focus-within:z-10">
        <input
          value={display}
          placeholder="Search&hellip;"
          onChange={(e) => search(e.target.value)}
          onFocus={onFocus}
          onClick={() => search(term || '')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
          type="text"
          className={classNames.input}
          role="combobox"
          aria-controls="search-results"
          aria-expanded={term !== null}
        />

        {selected ? (
          <button
            className={`${classNames.icon} hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
            onClick={clearSelected}
            type="button"
          >
            <XIcon className="h-5 w-5 text-gray-400" />
          </button>
        ) : (
          <div className={classNames.icon}>
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
      </div>
    </div>
  )
}

export default Search
