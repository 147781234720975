import { StoreOrderStep } from '@/buyers/_gen/gql'
import QueryString from '@/buyers/modules/QueryString'
import Money from '@/gf/modules/Money'
import { useOrderContext } from '../context'

import Box from '@/buyers/components/Box'
import Link from '@/gf/components/Link'
import { List, Row } from '../List'

const DeliveryFees = () => {
  const { storeOrder } = useOrderContext()

  const isDelivery = storeOrder.curriDeliveries.length > 0
  const deliveryTotals = storeOrder.curriDeliveries.map((ac) => ac.price)
  const deliveryTotal = isDelivery ? deliveryTotals.reduce(Money.add) : null

  if (!deliveryTotal || Money.isZero(deliveryTotal) || storeOrder.step === StoreOrderStep.Canceled)
    return null

  const txnPath = `/settings/billing/transactions${QueryString.directPayWithStoreOrderId(
    storeOrder.shortId
  )}`

  return (
    <Box className="space-y-4">
      <div className="text-lg font-medium text-center">Delivery by Gearflow Courier</div>

      <List
        title={{ className: 'sm:col-span-2' }}
        desc={{ className: 'sm:text-right sm:col-span-1' }}
      >
        <Row>Total {Money.format(deliveryTotal)}</Row>
      </List>

      <p className="text-sm text-gray-500 text-center">
        This amount is charged separately to your{' '}
        <Link.T className="text-sm" to={txnPath}>
          Gearflow plan
        </Link.T>
        .
      </p>
    </Box>
  )
}

export default DeliveryFees
