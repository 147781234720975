import { DeliveryMethod } from '@/dealers/_gen/gql'
import useUnloadConfirmation from '@/dealers/hooks/useUnloadConfirmation'
import useConfig from '@/gf/hooks/useConfig'
import Id from '@/gf/modules/Id'
import TimeM from '@/gf/modules/Time'
import { Address, Maybe } from '@/types'
import { yupResolver } from '@hookform/resolvers/yup'
import isUndefined from 'lodash/isUndefined'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { DeepPartial, useForm } from 'react-hook-form'
import * as Yup from 'yup'

export enum Urgency {
  HIGH = 3,
  MEDIUM = 2,
  LOW = 1,
}

export type FormValues = {
  customerContactId: Maybe<string>
  taxCost: Maybe<number>
  shippingCost: Maybe<number>
  customerDiscount: number | null
  quoteNumber: Maybe<string>
  deliveryMethod: DeliveryMethod
  items: {
    partNumber: string
    description: string
    unitPrice: Maybe<number>
    quantity: number
    inStock: boolean
    externalId: Maybe<string>
    taskNumber: Maybe<string>
    suggestion: Maybe<boolean>
    availabilityDate: Maybe<string>
    rfqPartId: string | null
    rfqPartNumber: string | null
  }[]
  pickupAddress: Maybe<Address>
  shippingAddress: Maybe<Address>
  urgency: Maybe<Urgency>
  neededByDate: Maybe<DateTime>
  comments: Maybe<string>
}

export const DIRECT_QUOTE_KEY = 'new'

const quoteFormName = (uniqueKey: string) => `sh-quote-form-v2-${uniqueKey}`

export const persistForm = (uniqueKey: string, values: DeepPartial<FormValues>) => {
  const json = JSON.stringify(values)
  localStorage.setItem(quoteFormName(uniqueKey), json)
}

export const deletePersistedForm = (uniqueKey: string) =>
  localStorage.removeItem(quoteFormName(uniqueKey))

const loadForm = (uniqueKey: string, initialValues: FormValues) => {
  const json = localStorage.getItem(quoteFormName(uniqueKey))

  if (!json) return initialValues

  const persistedValues = JSON.parse(json) as DeepPartial<FormValues>

  return {
    customerContactId: isUndefined(persistedValues.customerContactId)
      ? initialValues.customerContactId
      : persistedValues.customerContactId,
    shippingCost: isUndefined(persistedValues.shippingCost)
      ? initialValues.shippingCost
      : persistedValues.shippingCost,
    taxCost: isUndefined(persistedValues.taxCost) ? initialValues.taxCost : persistedValues.taxCost,
    customerDiscount: isUndefined(persistedValues.customerDiscount)
      ? initialValues.customerDiscount
      : persistedValues.customerDiscount,
    quoteNumber: isUndefined(persistedValues.quoteNumber)
      ? initialValues.quoteNumber
      : persistedValues.quoteNumber,
    deliveryMethod: isUndefined(persistedValues.deliveryMethod)
      ? initialValues.deliveryMethod
      : persistedValues.deliveryMethod,
    items: isUndefined(persistedValues.items)
      ? initialValues.items
      : (persistedValues.items as FormValues['items']),
    pickupAddress: isUndefined(persistedValues.pickupAddress)
      ? initialValues.pickupAddress
      : (persistedValues.pickupAddress as FormValues['pickupAddress']),
    shippingAddress: isUndefined(persistedValues.shippingAddress)
      ? initialValues.shippingAddress
      : (persistedValues.shippingAddress as FormValues['shippingAddress']),
    urgency: isUndefined(persistedValues.urgency) ? initialValues.urgency : persistedValues.urgency,
    neededByDate: isUndefined(persistedValues.neededByDate)
      ? initialValues.neededByDate
      : DateTime.fromISO(persistedValues.neededByDate as unknown as string),
    comments: isUndefined(persistedValues.comments)
      ? initialValues.comments
      : persistedValues.comments,
  }
}

const validationSchema = (isDirectQuote: boolean) =>
  Yup.object().shape({
    customerContactId: isDirectQuote
      ? Yup.string().nullable().required('Customer is required')
      : Yup.string().nullable(),
    taxCost: Yup.number().nullable(),
    customerDiscount: Yup.number().nullable(),
    shippingCost: Yup.number().when('deliveryMethod', (deliveryMethod, schema) =>
      deliveryMethod === DeliveryMethod.Pickup
        ? schema.nullable().optional()
        : schema.nullable().required('Shipping Cost is required')
    ),
    quoteNumber: Yup.string().nullable(),
    deliveryMethod: Yup.string()
      .oneOf([DeliveryMethod.Pickup, DeliveryMethod.Shipping, DeliveryMethod.VendorDelivery])
      .required('Delivery method is required'),
    items: Yup.array(
      Yup.object().shape({
        partNumber: Yup.string().trim().required('Part Number is required'),
        description: Yup.string()
          .trim()
          .max(255)
          .required('Part Description is required')
          .label('Part Description'),
        externalId: Yup.string().nullable(),
        taskNumber: Yup.string().nullable(),
        suggestion: Yup.boolean().nullable(),
        unitPrice: Yup.number()
          .nullable()
          .min(0.01, 'Should be greater than 0.00')
          .required('Price is required'),
        quantity: Yup.number()
          .required('Quantity is required')
          .min(0, 'Quantity should be greater than 0'),
        inStock: Yup.boolean().required(),
        availabilityDate: Yup.date().when('inStock', {
          is: false,
          then: (schema) =>
            schema
              .nullable()
              .required('required field')
              .min(TimeM.toDateInputFormat(TimeM.now()), 'Cannot be earlier than today'),
          otherwise: (schema) => schema.nullable(),
        }),
      })
    ).min(1),
    pickupAddress: Yup.object().nullable().optional(),
    shippingAddress: Yup.object().nullable().required('Please add the shipping address'),
    urgency: isDirectQuote
      ? Yup.number()
          .oneOf([Urgency.HIGH, Urgency.MEDIUM, Urgency.LOW])
          .nullable()
          .required('Select the urgency')
      : Yup.number().nullable().optional(),
    neededByDate: isDirectQuote
      ? Yup.string().nullable().required('Selected the date the needs the parts')
      : Yup.string().nullable().optional(),
    comments: Yup.string().nullable().optional(),
  })

const useQuoteForm = ({
  rfqId,
  storeOrderId,
  initialValues,
  onTouched,
}: {
  rfqId: string | undefined
  storeOrderId: string | undefined
  initialValues: FormValues
  onTouched: (() => void) | undefined
}) => {
  const config = useConfig()
  const [touched, setTouched] = useState(false)

  const storageUniqueKey = useMemo(
    () =>
      rfqId || storeOrderId ? Id.shorten((rfqId ?? storeOrderId) as string) : DIRECT_QUOTE_KEY,
    [rfqId, storeOrderId]
  )

  const isDirectQuote = !storeOrderId && !rfqId

  const { control, handleSubmit, register, formState, getValues, setValue, watch } =
    useForm<FormValues>({
      shouldFocusError: true,
      shouldUnregister: false,
      // reValidateMode: 'onChange',
      resolver: yupResolver(validationSchema(isDirectQuote)),
      defaultValues: loadForm(storageUniqueKey, initialValues),
    })

  useEffect(() => {
    const subscription = watch(
      (data: DeepPartial<FormValues>) => persistForm(storageUniqueKey, data),
      initialValues
    )

    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (formState.isDirty && !touched) {
      setTouched(true)
      if (onTouched) onTouched()
    }
  }, [formState.isDirty])

  // TODO: use a proper env variable
  useUnloadConfirmation(formState.isDirty && config.gfBaseUrl.indexOf('local.') === -1)

  return { control, handleSubmit, register, formState, getValues, setValue, storageUniqueKey }
}

export default useQuoteForm
