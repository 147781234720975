const format = (string: string) => {
  const matchCountryCode = /^(1)(\d{0,3})(\d{0,3})(\d{0,4})/.exec(string.replace(/\D/g, ''))
  // Try to match a phone number with the country code first
  if (matchCountryCode) {
    const countryCode =
      matchCountryCode[1] && matchCountryCode[2] ? `+${matchCountryCode[1]} ` : matchCountryCode[1]
    const areaCode =
      matchCountryCode[2] && matchCountryCode[3] ? `(${matchCountryCode[2]}) ` : matchCountryCode[2]
    const telephonePrefix = matchCountryCode[3] ? `${matchCountryCode[3]}` : ''
    const lineNumber = matchCountryCode[4] ? `-${matchCountryCode[4]}` : ''
    return `${countryCode}${areaCode}${telephonePrefix}${lineNumber}`
  }
  // Try to match a phone number without the country code second
  const match = /^(\d{0,3})(\d{0,3})(\d{0,4})/.exec(string.replace(/\D/g, ''))
  if (match) {
    const areaCode = match[1] && match[2] ? `(${match[1]}) ` : match[1]
    const telephonePrefix = match[2] ? `${match[2]}` : ''
    const lineNumber = match[3] ? `-${match[3]}` : ''
    return `${areaCode}${telephonePrefix}${lineNumber}`
  }
  return string
}

export default { format }
