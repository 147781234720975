import ModalForm from '@/gf/components/ModalForm'
import StoreOrderProblem, { StoreOrderProblemT } from '../StoreOrderProblem'

const MarkResolvedModal = ({
  open,
  onClose,
  onSubmit,
  submitting,
  storeOrderProblem,
}: {
  open: boolean
  onClose: () => void
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  submitting: boolean
  storeOrderProblem: StoreOrderProblemT
}) => (
  <ModalForm
    title="Mark Problem Resolved"
    submitButtonText="Resolved"
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
    submitButtonShowSpinner={submitting}
    submitButtonDisabled={submitting}
  >
    <StoreOrderProblem problem={storeOrderProblem} />
  </ModalForm>
)

export default MarkResolvedModal
