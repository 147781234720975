import { ChevronDownIcon, PencilIcon, TruckIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import useToggle from '@/gf/hooks/useToggle'

interface SidebarProfileViewProps {
  title: string
  detail?: React.ReactNode
  imageUrl?: string | null
  showImagePlaceholder?: boolean
  children: React.ReactNode
  onEditClick?: () => void
  className?: string
}

const SidebarProfileView = ({
  title,
  detail,
  imageUrl,
  showImagePlaceholder = true,
  children,
  onEditClick,
  className = '',
}: SidebarProfileViewProps) => {
  const [viewOpen, { toggle: toggleViewOpen }] = useToggle(false)

  return (
    <div className={className}>
      <div className="flex flex-col items-center justify-center p-2 gap-2 border-b border-slate-200 relative min-h-14">
        {showImagePlaceholder && (
          <div>
            <div
              className={classNames(
                'block rounded-full h-20 w-20 border-2 border-white shadow overflow-hidden',
                imageUrl ? 'bg-slate-50' : 'bg-slate-200'
              )}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  className="object-contain object-center block h-full w-full"
                  alt="Profile detail"
                />
              ) : (
                <TruckIcon className="transform scale-50 text-slate-400" />
              )}
            </div>
          </div>
        )}

        <div>
          <h3 className="text-lg tracking-wide font-semibold text-slate-600 leading-5 text-center">
            {title}
          </h3>
          {detail && <h4 className="text-sm text-zinc-500 text-center">{detail}</h4>}
        </div>
        {onEditClick && (
          <button
            type="button"
            className="flex rounded-full items-center justify-center h-8 w-8 hover:bg-slate-50 absolute top-1 right-1"
            onClick={onEditClick}
          >
            <PencilIcon className="h-5 text-zinc-400" />
          </button>
        )}
      </div>

      <div className={classNames('p-2 md:flex flex-col gap-y-2', viewOpen ? 'flex' : 'hidden')}>
        {children}
      </div>

      <button
        type="button"
        className="flex md:hidden w-full p-2 items-center justify-center text-slate-500"
        onClick={toggleViewOpen}
      >
        <ChevronDownIcon
          className={classNames('h-7 transform duration-300', {
            'rotate-180': viewOpen,
          })}
        />
        {viewOpen ? 'Close' : 'More details'}
      </button>
    </div>
  )
}

export default SidebarProfileView
