import { useRefundMutation } from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import Checkbox from '@/gf/components/Checkbox'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import Modal from '@/gf/components/ModalNext'
import PriceInput from '@/gf/components/inputs/PriceV2'
import useMsgs from '@/gf/hooks/useMsgs'
import MoneyM from '@/gf/modules/Money'
import StoreOrderM from '@/suppliers/modules/StoreOrder'
import type { Money } from '@/types'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Quote, useRfqContext } from '../context'

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

export const calcRefundableAmount = (storeOrder: Quote) => {
  const subtotal = StoreOrderM.subtotal(storeOrder)
  const shipping = storeOrder.shippingAmount || zeroPrice
  const totalBeforeCustomerFee = [subtotal, shipping, storeOrder.salesTax].reduce(MoneyM.add)

  const customerFee =
    storeOrder.customerFeeRate !== null
      ? MoneyM.mult(totalBeforeCustomerFee, storeOrder.customerFeeRate)
      : zeroPrice

  const total = [totalBeforeCustomerFee, customerFee].reduce(MoneyM.add)
  const refunded = storeOrder.refunds.map((r) => r.amount).reduce(MoneyM.add, zeroPrice)
  return MoneyM.sub(total, refunded)
}

type Fields = { amount: Money | null; closeOrder: boolean }

const Refund = () => {
  const { rfq, refetch } = useRfqContext()
  const [_, msgr] = useMsgs()
  const [refunding, setRefunding] = useState(false)
  const navigate = useNavigate()
  const [refundMutation] = useRefundMutation()
  const [fields, setFields] = useState<Fields>({ amount: null, closeOrder: true })
  const storeOrder = rfq?.quote

  if (!storeOrder) return null

  const refundableAmount = calcRefundableAmount(storeOrder)
  const fullRefund = !!fields.amount && MoneyM.equals(fields.amount, refundableAmount)
  const updateFields = (updates: Partial<Fields>) => setFields({ ...fields, ...updates })
  const close = () => navigate(`/rfqs/${rfq.id}`, { replace: true })

  const refund = async () => {
    if (!fields.amount) return
    setRefunding(true)

    await refundMutation({
      variables: {
        storeOrderId: storeOrder.id,
        amount: fields.amount,
        closeOrder: fullRefund && fields.closeOrder,
      },
    })

    msgr.add('Refunded.', 'positive')
    close()
    refetch()
    setRefunding(false)
  }

  return (
    <Modal open onClose={close}>
      <Form onSubmit={refund} className="relative p-8 flex flex-col gap-y-6">
        <CloseModalButton onClick={close} className="absolute top-2 right-2" />
        <h2 className="text-2xl font-medium">Refund</h2>

        <div className="space-y-4">
          <Field label="Amount">
            <div className="flex gap-2 items-center">
              <div className="w-40">
                <PriceInput
                  required
                  price={fields.amount}
                  onChange={(amount) => updateFields({ amount: amount || null })}
                  min={0.01}
                  max={MoneyM.toDecimal(refundableAmount)}
                />
              </div>

              <span>
                of <span className="font-medium">{MoneyM.format(refundableAmount)}</span> total
              </span>
            </div>
          </Field>

          {fullRefund && (
            <div className="space-y-2">
              <Checkbox
                label="Close order"
                value="Close order"
                checked={fields.closeOrder}
                onChange={({ target }) => updateFields({ closeOrder: target.checked })}
              />

              <div className="px-2 py-1 inline-flex items-center gap-x-2 text-sm bg-gray-50 rounded border border-gray-200">
                <InformationCircleIcon className="w-5 h-5 shrink-0" />
                Don&apos;t check this box if more shipments need to be created.
              </div>
            </div>
          )}
        </div>

        <div className="flex gap-3">
          <Action.S className="w-1/2" type="button" onClick={close}>
            Cancel
          </Action.S>

          <Action.P className="w-1/2" type="submit" performing={refunding}>
            Refund
          </Action.P>
        </div>
      </Form>
    </Modal>
  )
}

export default Refund
