import Typeahead from '@/gf/components/next/Typeahead'
import useCurrentPosition from '@/gf/hooks/useCurrentPosition'
import useGoogleMapsScriptLoader from '@/gf/hooks/useGoogleMapsScriptLoader'
import Address from '@/gf/modules/Address'
import GoogleMaps, { GoogleBusinessDetails } from '@/gf/modules/GoogleMaps'
import { Maybe } from '@/types'

const Option = ({ place }: { place: SearchResult }) => (
  <div>
    <div className="flex gap-x-3 truncate">
      <h5 className="font-medium text-base flex-grow truncate min-w-0 text-ellipsis">
        {place.name}
      </h5>
      <span className="flex-shrink-0 text-xs text-gray-600">
        {place.distance && `${place.distance.toFixed(1)} mi`}
      </span>
    </div>
    <p className="text-gray-600 text-sm">
      {} {place.address}
    </p>
  </div>
)

type SearchResult = {
  name: string
  address: string
  distance: Maybe<number>
  placeId: string
  id: string
}

const GoogleBusinessTypeahead = ({
  value,
  onChange,
  onDetailsFound,
  autoFocus,
}: {
  value: string
  onChange: (newValue: string) => void
  onDetailsFound: (businessDetails: GoogleBusinessDetails) => void
  autoFocus?: boolean
}) => {
  const { isLoaded } = useGoogleMapsScriptLoader()
  const { coords } = useCurrentPosition()

  const searchPlaces = async (searchTerm: string) => {
    if (!searchTerm || !isLoaded) {
      return []
    }

    const predictions = await GoogleMaps.autocompletePlaces(searchTerm, {
      country: ['US'],
      types: ['establishment'],
      ...(coords ? { origin: { lat: coords.latitude, lng: coords.longitude } } : {}),
    })

    return predictions.map((p) => ({
      name: p.structured_formatting.main_text,
      address: p.structured_formatting.secondary_text,
      placeId: p.place_id,
      id: p.place_id,
      distance: p.distance_meters ? Address.metersToMiles(p.distance_meters) : null,
    }))
  }

  const onPlaceSelected = async (placeId: string) => {
    const businessDetails = await GoogleMaps.getPlaceBusinessDetails(placeId)

    onDetailsFound(businessDetails)
  }

  return (
    <Typeahead
      value={value}
      onChange={onChange}
      onFetchOptions={searchPlaces}
      placeholder=""
      className="w-full"
      renderOption={(place) => <Option place={place} />}
      transformSelection={(o) => o.name}
      afterSelection={(o) => onPlaceSelected(o.placeId)}
      autoFocus={autoFocus}
    />
  )
}

export default GoogleBusinessTypeahead
