import { BaseSelect } from './Select'

import states from '../data/states'

import { StateCode } from '../../types'

const StateSelect = ({
  value,
  onSelect,
  placeholder = '--Select--',
  className,
}: {
  value: StateCode | null
  onSelect: (code: StateCode | null) => void
  placeholder?: string
  className?: string
}) => {
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === '') return onSelect(null)

    const code = event.target.value as StateCode
    return onSelect(code)
  }

  return (
    <BaseSelect value={value || ''} onChange={onChange} className={className}>
      <option value="">{placeholder}</option>

      {states.map((state) => (
        <option key={state.code} value={state.code}>
          {state.name}
        </option>
      ))}
    </BaseSelect>
  )
}

export default StateSelect
