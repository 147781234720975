import Tabs from '@/gf/components/next/Tabs'
import Customer from './BreakdownTable/Customer'
import Part from './BreakdownTable/Part'
import { useContext } from './Context'

const tabs = [
  { param: 'customer', name: 'Customer' },
  { param: 'part', name: 'Part' },
]

const BreakdownTable = () => {
  const { breakdownBy, update } = useContext()
  const tab = tabs.find((t) => t.param === breakdownBy) || tabs[0]

  const updateTab = (name: string) =>
    update({ breakdownBy: tabs.filter((t) => t.name === name)[0].param })

  return (
    <div className="flex grow flex-col gap-y-3 bg-white border border-gray-300 rounded-xl shadow-sm overflow-hidden">
      <div className="px-4 flex flex-col gap-y-3">
        <div className="pt-6 flex flex-row items-center gap-x-6">
          <Tabs tabs={tabs} selectedTabName={tab.name} onTabSelect={updateTab} />
        </div>
      </div>

      <div className="px-4 pb-0.5 overflow-x-auto overflow-y-auto">
        {tab.param === 'customer' && <Customer />}
        {tab.param === 'part' && <Part />}
      </div>
    </div>
  )
}

export default BreakdownTable
