import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import { Td as BaseTd, Th } from '@/gf/components/Table'
import { cn } from '@/gf/modules/utils'
import { TdHTMLAttributes } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrderContext } from '../context'
import { ModalSize } from '@/types'
import CircleProgress from './CircleProgress'
import { CheckCircleIcon } from '@heroicons/react/solid'

const Td = ({ className, ...props }: TdHTMLAttributes<HTMLTableCellElement>) => (
  <BaseTd className={cn('whitespace-normal align-top', className)} {...props} />
)

const Shipment = () => {
  const { storeOrder } = useOrderContext()
  const { shipmentId } = useParams<{ shipmentId: string }>()
  const navigate = useNavigate()
  const close = () => navigate(`/orders/${storeOrder.id}`, { replace: true })
  const shipment = storeOrder.shipments.find((s) => s.id === shipmentId)

  if (!shipment) return null

  return (
    <Modal open onClose={close} size={ModalSize.MD}>
      <div className="p-8 pt-10 space-y-4">
        <CloseModalButton onClick={close} className="absolute top-2 right-2" />

        <div className="rounded-lg border overflow-auto">
          <table className="divide-y-1 divide-gray-200 w-full">
            <thead className="bg-gray-50">
              <tr>
                <Th>Part number</Th>
                <Th>Description</Th>
                <Th className="text-right">Shipped</Th>
                <Th className="text-right">Received</Th>
              </tr>
            </thead>

            <tbody className="divide-y-1 divide-gray-200">
              {shipment.items.map((item) => (
                <tr key={item.id}>
                  <Td>{item.lineItem.product.mpn}</Td>
                  <Td>{item.lineItem.product.name}</Td>
                  <Td className="text-right">{item.quantity.toLocaleString()}</Td>
                  <Td className="text-right">
                    {item.receivedQuantity >= item.quantity ? (
                      <div className="flex gap-1 items-center justify-end text-sm">
                        <CheckCircleIcon className="-p-0.5 w-5 h-5 inline-flex shrink-0 text-blue-600" />
                        {item.receivedQuantity.toLocaleString()}
                      </div>
                    ) : (
                      <div className="flex gap-1.5 items-center justify-end text-sm">
                        <CircleProgress
                          progress={(item.receivedQuantity / item.quantity) * 100}
                          className="w-4 h-4"
                        />
                        {item.receivedQuantity.toLocaleString()}
                      </div>
                    )}
                  </Td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}

export default Shipment
