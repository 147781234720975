import { ReportDuration } from '@/gf/modules/ReportDuration'
import { DateTime } from 'luxon'
import { createContext, useContext as useReactContext } from 'react'
import { ComparisonStoreOrder, Org, StoreOrder, UpdateArgs } from '../PartsSales'

const Context = createContext<
  | {
      storeOrders: StoreOrder[] | undefined
      comparisonStoreOrders: ComparisonStoreOrder[] | undefined
      filteredStoreOrders: StoreOrder[] | undefined
      orgById: Record<string, Org> | undefined
      fromDate: DateTime
      toDate: DateTime
      duration: ReportDuration
      date: DateTime | null
      orgId: string | null
      mpn: string | null
      chartTab: string
      breakdownBy: string | null
      breakdownPartSort: string[] | null
      breakdownCustomerSort: string[] | null
      ordersSort: string[] | null
      ranges: DateTime<true>[][]
      update: (args: UpdateArgs) => void
    }
  | undefined
>(undefined)

export const useContext = () => {
  const context = useReactContext(Context)
  if (!context) throw new Error('must be used within provider')
  return context
}

export default Context
