import { useContext } from 'react'
import { QuoteUploadContext } from './QuoteUploadProvider'

const useQuoteUpload = () => {
  const context = useContext(QuoteUploadContext)

  if (context === undefined) throw new Error('must be used within provider')

  return context
}

export default useQuoteUpload
