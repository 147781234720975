import {
  InvoicesAndCreditMemosListDocument,
  InvoicesAndCreditMemosListQuery,
  PaymentMethod,
  useInvoiceMarkAsBilledMutation,
  useInvoiceMarkAsPaidMutation,
  useMarkCreditMemoReceivedMutation,
} from '@/buyers/_gen/gql'
import StoreOrderStepBadge from '@/buyers/components/StoreOrderStepBadge'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import FilterDropdown from '@/gf/components/FilterDropdown'
import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Badge from '@/gf/components/Badge'
import CreditMemoStateBadge from '@/gf/components/CreditMemoStateBadge'
import InvoiceMarkAsBilledModal from '@/gf/components/InvoiceMarkAsBilledModal'
import InvoiceMarkAsPaidModal from '@/gf/components/InvoiceMarkAsPaidModal'
import InvoiceStateBadge from '@/gf/components/InvoiceStateBadge'
import Layout from '@/gf/components/Layout'
import OrderByHeaderButton from '@/gf/components/OrderByHeaderButton'
import PaginationC from '@/gf/components/Pagination'
import SearchInput from '@/gf/components/SearchInput'
import Spinner from '@/gf/components/Spinner'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import TableLineItemsDropdown from '@/gf/components/TableLineItemsDropdown'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import useConfig from '@/gf/hooks/useConfig'
import useModal from '@/gf/hooks/useModal'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import * as GE from '@/gf/modules/GrammarEvents'
import InvoiceM from '@/gf/modules/Invoice'
import MoneyM from '@/gf/modules/Money'
import Time from '@/gf/modules/Time'
import type { OrderBy, Pagination, Product } from '@/types'
import { ApolloError } from '@apollo/client'
import { InformationCircleIcon } from '@heroicons/react/outline'
import omit from 'lodash/omit'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import MarkCreditMemoReceivedModal from './MarkCreditMemoReceivedModal'

type Record = NonNullable<
  InvoicesAndCreditMemosListQuery['invoicesAndCreditMemos']
>['entries'][number]
type Invoice = NonNullable<Record['invoice']>
type CreditMemo = NonNullable<Record['creditMemo']>

const PayDirectActions = ({
  invoice,
  onMarkAsPaid,
}: {
  invoice: Invoice
  onMarkAsPaid: () => void
}) => (
  <>
    {invoice.link && (
      <A.S href={invoice.link} size="sm" target="_blank" rel="noreferrer">
        Download
      </A.S>
    )}

    {InvoiceM.isPaymentRequired(invoice) && (
      <Action.P type="button" onClick={onMarkAsPaid} size="sm">
        Mark as Paid
      </Action.P>
    )}
  </>
)

const BalanceActions = ({
  invoice,
  dashboardUrl,
  canPay,
}: {
  invoice: Invoice
  dashboardUrl: string
  canPay: boolean
}) => (
  <>
    <A.S href={`${dashboardUrl}/pdf/download/invoice/${invoice.id}`} size="sm">
      {invoice.isFinanced ? 'Download' : 'Receipt'}
    </A.S>

    {invoice.isFinanced && InvoiceM.isPaymentRequired(invoice) && canPay && invoice.link && (
      <A.P href={invoice.link} size="sm" target="_blank" rel="noreferrer">
        Pay Online
      </A.P>
    )}
  </>
)

const InvoiceDueDateBadge = ({
  dueDate,
  paymentRequired,
}: {
  dueDate: DateTime
  paymentRequired: boolean
}) => {
  const timeElement = (
    <time dateTime={Time.formatDateNoTime(dueDate)}>{Time.formatDateNoTime(dueDate)}</time>
  )
  if (paymentRequired && dueDate < DateTime.now().plus({ days: 7 })) {
    return (
      <Badge
        title={timeElement}
        {...(dueDate < DateTime.now()
          ? { color: 'bg-red-200', textColor: 'text-red-900' }
          : { color: 'bg-yellow-200', textColor: 'text-yellow-900' })}
      />
    )
  }
  return timeElement
}

const InvoiceRow = ({
  invoice,
  storeOrder,
  buyersUrl,
  canPay,
  onMarkAsPaid,
  onMarkAsBilled,
}: {
  invoice: Invoice
  storeOrder: NonNullable<Record['storeOrder']>
  buyersUrl: string
  canPay: boolean
  onMarkAsPaid: () => void
  onMarkAsBilled: () => void
}) => {
  const { organization } = useSession()
  const status = useMemo(() => InvoiceM.getStatus(invoice), [invoice])

  return (
    <Tr>
      <Td className="py-1.5">
        <div className="flex space-x-2 justify-start">
          {InvoiceM.isBalanceInvoice(invoice) ? (
            <BalanceActions invoice={invoice} dashboardUrl={buyersUrl} canPay={canPay} />
          ) : (
            <PayDirectActions invoice={invoice} onMarkAsPaid={onMarkAsPaid} />
          )}

          {storeOrder?.paymentMethod === PaymentMethod.Stripe && (
            <A.S href={`${buyersUrl}/pdf/download/invoice/${invoice.id}`} size="sm">
              Download
            </A.S>
          )}

          {organization.customerBillingStatusEnabled &&
            (status === 'paid' || status === 'processing') &&
            !invoice.customerBilledAt && (
              <Action.P type="button" onClick={onMarkAsBilled} size="sm" color="blue">
                Mark as Billed
              </Action.P>
            )}
        </div>
      </Td>
      <Td>
        <span className="flex gap-x-1.5">
          <InvoiceStateBadge invoice={invoice} />

          {organization.customerBillingStatusEnabled ? (
            invoice.customerBilledAt ? (
              <Badge color="bg-blue-100" textColor="text-blue-700" title="Billed" />
            ) : (
              <Badge color="bg-gray-100" textColor="text-gray-700" title="Not Billed" />
            )
          ) : null}
        </span>
      </Td>
      <Td>
        {invoice.dueDate && (
          <InvoiceDueDateBadge
            dueDate={invoice.dueDate}
            paymentRequired={InvoiceM.isPaymentRequired(invoice)}
          />
        )}
      </Td>
      <Td>
        {storeOrder && (
          <time dateTime={Time.formatDateNoTime(storeOrder.insertedAt)}>
            {Time.formatDateNoTime(storeOrder.insertedAt)}
          </time>
        )}
      </Td>
      <Td>{invoice.totalPrice ? MoneyM.format(invoice.totalPrice) : null}</Td>
      <Td>{storeOrder?.order.requestForQuote?.workOrderNumber}</Td>
      <Td>{invoice.additionalCharge?.purchaseOrder || storeOrder?.purchaseOrder}</Td>
      <Td>
        {storeOrder && (
          <div className="space-y-1">
            <div className="flex gap-x-2 items-center">
              <div className="w-20">
                <StoreOrderStepBadge step={storeOrder?.step} />
              </div>

              <A.T href={`${buyersUrl}/orders/${storeOrder.id}`}>{storeOrder.shortId}</A.T>
            </div>
            {storeOrder.vendor && (
              <p className="text-xs text-gray-500 italic">Vendor: {storeOrder?.vendor?.name}</p>
            )}
          </div>
        )}
      </Td>

      {invoice.additionalCharge ? (
        <Td className="max-w-[13rem] text-ellipsis overflow-hidden">
          {invoice.additionalCharge.name}
        </Td>
      ) : (
        storeOrder && (
          <td className="flex whitespace-nowrap text-sm text-gray-500 hover:text-gray-600">
            <TableLineItemsDropdown
              lineItems={storeOrder.lineItems.map((li) => ({
                ...li,
                product: omit(li.product, ['__typename']) as Product,
              }))}
              linkToMarketplace
            />
          </td>
        )
      )}
    </Tr>
  )
}

const CreditMemoRow = ({
  creditMemo,
  storeOrder,
  buyersUrl,
  onMarkAsReceived,
}: {
  creditMemo: CreditMemo
  storeOrder: NonNullable<Record['storeOrder']>
  buyersUrl: string
  onMarkAsReceived: () => void
}) => (
  <Tr>
    <Td className="py-1.5">
      {!creditMemo.receivedAt && (
        <Action.P type="button" onClick={onMarkAsReceived} size="sm">
          Mark as Received
        </Action.P>
      )}
    </Td>
    <Td>
      <CreditMemoStateBadge receivedAt={creditMemo.receivedAt} />
    </Td>
    <Td />
    <Td>
      {storeOrder && (
        <time dateTime={Time.formatDateNoTime(storeOrder.insertedAt)}>
          {Time.formatDateNoTime(storeOrder.insertedAt)}
        </time>
      )}
    </Td>
    <Td>
      <div className="inline-flex items-center gap-x-2">
        <span>({MoneyM.format(MoneyM.abs(creditMemo.amount))})</span>
        {creditMemo.description && (
          <Tooltip>
            <TooltipTrigger>
              <InformationCircleIcon className="w-6 h-6 inline-flex shrink-0 text-gray-600" />
            </TooltipTrigger>
            <TooltipContent className="max-w-prose p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900 whitespace-pre-wrap">
              {creditMemo.description}
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </Td>
    <Td>{storeOrder?.order.requestForQuote?.workOrderNumber}</Td>
    <Td>{storeOrder?.purchaseOrder}</Td>
    <Td>
      {storeOrder && (
        <div className="space-y-1">
          <div className="flex gap-x-2 items-center">
            <div className="w-20">
              <StoreOrderStepBadge step={storeOrder?.step} />
            </div>

            <A.T href={`${buyersUrl}/orders/${storeOrder.id}`}>{storeOrder.shortId}</A.T>
          </div>
          {storeOrder.vendor && (
            <p className="text-xs text-gray-500 italic">Vendor: {storeOrder?.vendor?.name}</p>
          )}
        </div>
      )}
    </Td>

    <td className="flex whitespace-nowrap text-sm text-gray-500 hover:text-gray-600">
      {storeOrder && (
        <TableLineItemsDropdown
          lineItems={storeOrder.lineItems.map((li) => ({
            ...li,
            product: omit(li.product, ['__typename']) as Product,
          }))}
          linkToMarketplace
        />
      )}
    </td>
  </Tr>
)

interface PropsWithoutFields {
  entries: Record[]
  loading: boolean
  orderBy: OrderBy
  setOrderBy: (newOrderBy: OrderBy) => void
  canPay: boolean
}

const InvoicesTable = ({
  search,
  page,
  updateSearch,
  updatePage,
  entries,
  pagination,
  loading,
  orderBy,
  setOrderBy,
  canPay,
  invoiceStatus,
  updateInvoiceStatus,
  creditMemoStatus,
  updateCreditMemoStatus,
  invoiceBilledStatus,
  updateInvoiceBilledStatus,
}: PropsWithoutFields & {
  search: string
  page: number
  updateSearch: (value: string) => void
  updatePage: (newPage: number) => void
  pagination?: Pagination
  invoiceStatus: string
  updateInvoiceStatus: (newStatus: string | null) => void
  creditMemoStatus: string
  updateCreditMemoStatus: (newStatus: string | null) => void
  invoiceBilledStatus: string
  updateInvoiceBilledStatus: (newStatus: string | null) => void
}) => {
  const { featureFlags } = useSession()
  const client = useGqlClient()
  const { buyersUrl } = useConfig()
  const { organization } = useSession()
  const [spinnerLive, spinnerToggler] = useToggle()
  const [_msgs, msgsMgr] = useMsgs()
  const markInvoicePaidModal = useModal<Record>()
  const markInvoiceBilledModal = useModal<Record>()
  const creditMemoReceivedModal = useModal<Record>()

  const [invoiceMarkAsPaid] = useInvoiceMarkAsPaidMutation({
    client,
    refetchQueries: [InvoicesAndCreditMemosListDocument],
  })
  const [invoiceMarkAsBilled] = useInvoiceMarkAsBilledMutation({
    client,
    refetchQueries: [InvoicesAndCreditMemosListDocument],
  })
  const [creditMemoMarkAsReceived] = useMarkCreditMemoReceivedMutation({
    client,
    refetchQueries: [InvoicesAndCreditMemosListDocument],
  })

  return (
    <>
      {markInvoicePaidModal.props && (
        <InvoiceMarkAsPaidModal
          storeOrderId={markInvoicePaidModal.props.storeOrder?.id ?? ''}
          dashboardUrl={buyersUrl}
          open={markInvoicePaidModal.isOpen}
          submitButtonShowSpinner={spinnerLive}
          onClose={markInvoicePaidModal.close}
          showCustomerBilledCheckbox={organization.customerBillingStatusEnabled}
          onSubmit={(customerBilled) => {
            spinnerToggler.on()
            invoiceMarkAsPaid({
              variables: {
                invoiceId: markInvoicePaidModal.props?.invoice?.id ?? '',
                billed: customerBilled,
              },
            })
              .then(() => msgsMgr.add('Invoice marked as paid', 'positive'))
              .catch((err: ApolloError) =>
                msgsMgr.add(`Error marking Invoice as paid: ${err.message}`, 'negative')
              )
              .finally(() => {
                markInvoicePaidModal.close()
                spinnerToggler.off()
              })
          }}
        />
      )}

      {markInvoiceBilledModal.props && (
        <InvoiceMarkAsBilledModal
          storeOrderId={markInvoiceBilledModal.props.storeOrder?.id ?? ''}
          dashboardUrl={buyersUrl}
          open={markInvoiceBilledModal.isOpen}
          submitButtonShowSpinner={spinnerLive}
          onClose={markInvoiceBilledModal.close}
          onSubmit={(e) => {
            e.preventDefault()
            spinnerToggler.on()
            invoiceMarkAsBilled({
              variables: { invoiceId: markInvoiceBilledModal.props?.invoice?.id ?? '' },
            })
              .then(() => msgsMgr.add('Invoice marked as billed', 'positive'))
              .catch((err: ApolloError) =>
                msgsMgr.add(`Error marking Invoice as billed: ${err.message}`, 'negative')
              )
              .finally(() => {
                markInvoiceBilledModal.close()
                spinnerToggler.off()
              })
          }}
        />
      )}

      {creditMemoReceivedModal.props?.creditMemo && (
        <MarkCreditMemoReceivedModal
          storeOrderId={creditMemoReceivedModal.props.storeOrder?.id ?? ''}
          amount={creditMemoReceivedModal.props.creditMemo.amount}
          open={creditMemoReceivedModal.isOpen}
          submitButtonShowSpinner={spinnerLive}
          onClose={creditMemoReceivedModal.close}
          onSubmit={(e) => {
            e.preventDefault()
            spinnerToggler.on()
            creditMemoMarkAsReceived({
              variables: { creditMemoId: creditMemoReceivedModal.props?.creditMemo?.id ?? '' },
            })
              .then(() => msgsMgr.add('Credit Memo marked as received', 'positive'))
              .catch((err: ApolloError) =>
                msgsMgr.add(`Error marking Credit Memo as received: ${err.message}`, 'negative')
              )
              .finally(() => {
                const creditMemoId = creditMemoReceivedModal.props?.creditMemo?.id

                creditMemoReceivedModal.close()

                if (creditMemoId) {
                  GE.receivesCreditMemo(creditMemoId)
                }

                spinnerToggler.off()
              })
          }}
        />
      )}
      <div className="col-span-6 flex flex-col gap-y-4">
        <SearchInput
          value={search}
          onChange={updateSearch}
          placeholder="Search by po number, work order, vendor name..."
        />

        <div className="flex gap-x-2 items-center">
          <label className="text-sm text-gray-500">Filter by:</label>
          <FilterDropdown
            title="Invoice Status"
            value={invoiceStatus ?? null}
            onChange={updateInvoiceStatus}
            steps={[
              { label: 'All', value: null },
              { label: 'Paid', value: 'paid' },
              { label: 'Not Paid', value: 'not_paid' },
            ]}
          />

          {organization.customerBillingStatusEnabled && (
            <FilterDropdown
              title="Customer Billed Status"
              value={invoiceBilledStatus ?? null}
              onChange={updateInvoiceBilledStatus}
              steps={[
                { label: 'All', value: null },
                { label: 'Billed', value: 'billed' },
                { label: 'Not Billed', value: 'not_billed' },
              ]}
            />
          )}

          {featureFlags.buyersCreditMemos && (
            <FilterDropdown
              title="Credit Memo Status"
              value={creditMemoStatus ?? null}
              onChange={updateCreditMemoStatus}
              steps={[
                { label: 'All', value: null },
                { label: 'Received', value: 'received' },
                { label: 'Not Received', value: 'not_received' },
              ]}
            />
          )}
        </div>
      </div>

      <Layout.Section type="table">
        <Table>
          <Thead>
            <Tr>
              <Th>Actions</Th>
              <Th>Status</Th>
              <Th>
                <OrderByHeaderButton
                  id="due_date"
                  display="Due Date"
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              </Th>
              <Th>
                <OrderByHeaderButton
                  id="store_order_inserted_at"
                  display="Order Date"
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              </Th>
              <Th>Total</Th>
              <Th>
                <OrderByHeaderButton
                  id="work_order"
                  display="Work Order"
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              </Th>
              <Th>
                <OrderByHeaderButton
                  id="purchase_order"
                  display="PO"
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              </Th>
              <Th>Order</Th>
              <Th>Content</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={9}>
                  <Spinner />
                </Td>
              </Tr>
            ) : entries.length === 0 ? (
              <Tr>
                <Td colSpan={9}>
                  No invoices {featureFlags.buyersCreditMemos && <>or credit memos</>} found.
                </Td>
              </Tr>
            ) : (
              entries.map((record) =>
                record.invoice ? (
                  <InvoiceRow
                    key={record.id}
                    invoice={record.invoice}
                    storeOrder={record.storeOrder}
                    onMarkAsPaid={() => markInvoicePaidModal.open(record)}
                    buyersUrl={buyersUrl}
                    canPay={canPay}
                    onMarkAsBilled={() => markInvoiceBilledModal.open(record)}
                  />
                ) : record.creditMemo ? (
                  <CreditMemoRow
                    key={record.id}
                    creditMemo={record.creditMemo}
                    storeOrder={record.storeOrder}
                    buyersUrl={buyersUrl}
                    onMarkAsReceived={() => creditMemoReceivedModal.open(record)}
                  />
                ) : null
              )
            )}
          </Tbody>
        </Table>
      </Layout.Section>
      <Layout.Section type="full">
        <PaginationC pagination={pagination} page={page} updatePage={updatePage} />
      </Layout.Section>
    </>
  )
}

export default InvoicesTable
