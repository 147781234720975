import { useAddProofOfDeliveriesMutation } from '@/dealers/_gen/gql'
import GfUploadPod from '@/gf/components/UploadPod'
import { useNavigate } from 'react-router-dom'
import { useRfqContext } from '../context'

const UploadPod = () => {
  const { rfq, refetch } = useRfqContext()
  const navigate = useNavigate()
  const { quote } = rfq
  const [addPods] = useAddProofOfDeliveriesMutation()
  if (!quote) return null

  return (
    <GfUploadPod
      add={(urls) => addPods({ variables: { storeOrderId: quote.id, urls } })}
      onComplete={refetch}
      close={() => navigate(`/rfqs/${rfq.id}`)}
    />
  )
}

export default UploadPod
