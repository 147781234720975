import { ReactNode, useState } from 'react'

import { Money, PaymentMethod } from '@/types'

import MoneyM from '@/gf/modules/Money'

import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import RadioInput from '@/gf/components/inputs/Radio'

const PaymentMethodOption = ({
  paymentMethod,
  storeName,
  creditLimit,
  maxCreditLimit,
}: {
  paymentMethod: PaymentMethod
  storeName: string
  creditLimit: Money | undefined
  maxCreditLimit: Money | undefined
}) => (
  <div className="flex flex-col text-sm">
    <label className="text-gray-900 cursor-pointer" htmlFor={paymentMethod}>
      {paymentMethod === PaymentMethod.PayDirect && <>Direct Pay</>}
      {paymentMethod === PaymentMethod.PayInvoice && <>Pay invoice</>}
      {paymentMethod === PaymentMethod.NetTerms && <>Net 30 terms</>}
      {paymentMethod === PaymentMethod.CreditCardBank && <>Credit card/Bank debit</>}
    </label>

    {paymentMethod === PaymentMethod.PayDirect && (
      <p className="text-gray-500">
        After receiving your order, request an invoice from {storeName} to pay them directly.
      </p>
    )}

    {paymentMethod === PaymentMethod.PayInvoice && (
      <p className="text-gray-500">Gearflow will send you an invoice to pay {storeName}.</p>
    )}

    {paymentMethod === PaymentMethod.NetTerms && (
      <p className="text-gray-500">
        {creditLimit && maxCreditLimit && (
          <>
            {MoneyM.format(creditLimit)} available of {MoneyM.format(maxCreditLimit)} limit.
          </>
        )}
      </p>
    )}
  </div>
)

const PaymentMethodInputCard = ({
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  storeName,
  creditLimit,
  maxCreditLimit,
  children,
}: {
  paymentMethods: PaymentMethod[]
  selectedPaymentMethod: PaymentMethod
  setSelectedPaymentMethod: (pm: PaymentMethod) => void
  children?: ReactNode
  storeName: string
  creditLimit: Money | undefined
  maxCreditLimit: Money | undefined
}) => {
  const [showSelect, setShowSelect] = useState(paymentMethods.length > 1)
  const hidden = paymentMethods.length === 1 && paymentMethods[0] === PaymentMethod.CreditCardBank

  return (
    <Card
      title={hidden ? 'Approve quote' : 'Pay with'}
      headerButton={
        paymentMethods.length > 1 && !showSelect ? (
          <Action.T onClick={() => setShowSelect(true)}>Change</Action.T>
        ) : undefined
      }
    >
      {!hidden && (
        <Card.Section>
          {!showSelect ? (
            <PaymentMethodOption
              paymentMethod={selectedPaymentMethod}
              storeName={storeName}
              creditLimit={creditLimit}
              maxCreditLimit={maxCreditLimit}
            />
          ) : (
            <fieldset>
              <legend className="sr-only">Pay with</legend>

              <div className="flex flex-col items-start space-y-2.5">
                {paymentMethods.map((pm) => (
                  <div key={pm} className="flex flex-row items-start space-x-2">
                    <div className="flex h-5 items-center">
                      <RadioInput
                        id={pm}
                        className="cursor-pointer"
                        name={pm}
                        checked={pm === selectedPaymentMethod}
                        onChange={(e) => {
                          if (e.target.checked) setSelectedPaymentMethod(pm)
                        }}
                        onClick={() => setShowSelect(false)}
                      />
                    </div>

                    <PaymentMethodOption
                      paymentMethod={pm}
                      storeName={storeName}
                      creditLimit={creditLimit}
                      maxCreditLimit={maxCreditLimit}
                    />
                  </div>
                ))}
              </div>
            </fieldset>
          )}
        </Card.Section>
      )}

      {children}
    </Card>
  )
}

export default PaymentMethodInputCard
