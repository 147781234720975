import Layout from '@/gf/components/Layout'
import Link from '@/gf/components/LinkOld'
import Page from '@/gf/components/Page'
import Frame from '../components/Frame'

const NotFound = () => (
  <Frame>
    <Page title="Oops">
      <Layout>
        <Layout.Section type="full">
          Sorry, looks like we&apos;ve sent you the wrong way. <Link to="/">Back to home.</Link>
        </Layout.Section>
      </Layout>
    </Page>
  </Frame>
)

export default NotFound
