import { useState } from 'react'

import { Tr, Td } from '@/gf/components/Table'
import Select from '@/gf/components/Select'

import type { Notification, NotificationSubscription } from './Subscriptions'

const notificationIdToDisplay = (notificationId: string) =>
  ({
    'new-message': 'New Message',
    'new-request': 'New Request',
    'quote-approved': 'Quote Approved',
    'quote-denied': 'Quote Denied',
  })[notificationId] || notificationId

const scopeToDisplay = (scope: string) =>
  ({ all: 'All', 'urgent-only': 'Urgent only', none: 'None' })[scope] || scope

const Subscription = ({
  notification,
  notificationSubscriptions,
  update,
}: {
  notification: Notification
  notificationSubscriptions: NotificationSubscription[]
  update: (channel: string, scope: string) => void
}) => {
  const smsNotifSub = notificationSubscriptions.filter((ns) => ns.channel === 'sms')[0]
  const [smsScope, setSmsScope] = useState(smsNotifSub.scope)
  const options = notification.scopes.map((s) => ({ id: s, display: scopeToDisplay(s) }))

  const onChange = (channel: string, scope: string) => {
    setSmsScope(scope)
    update(channel, scope)
  }

  return (
    <Tr>
      <Td>{notificationIdToDisplay(notification.id)}</Td>
      <Td>
        <Select
          options={options}
          currentId={smsScope}
          onChange={(scope) => onChange('sms', scope)}
        />
      </Td>
    </Tr>
  )
}

export default Subscription
