/**
 * Modal Form Component
 * -----------------
 * ModalForm is a wrapper for forms that you want to display as a modal. This component includes the <form> element
 * as a parent for all of its children. This component also includes a form submit button, and a cancel button.
 *
 * props
 * -----------------
 * open -- boolean value if modal is open or closed
 * onClose -- funcion callback triggered open/closed state
 * title -- heading title of modal
 * onSubmit -- function that is called when the modal form is submitted
 * submitButtonText -- text for the submit button ('Submit' by default)
 * submitButtonDisabled -- boolean value if submit button is disabled
 * submitButtonShowSpinner -- boolean value if submit button is shows the spinner
 * withIcon -- boolean, if Icon should be shown alongside modal
 * children -- content in the modal (these are all wrapped in the <form>)
 */

import { Dialog, Portal, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { Fragment, useRef } from 'react'
import { ModalSize } from '../../types'
import Action from './Action'

const ModalForm = ({
  open,
  onClose,
  title,
  action,
  method,
  onSubmit,
  cancelText = 'Cancel',
  submitButtonText = 'Submit',
  submitButtonDisabled = false,
  submitButtonShowSpinner = false,
  withIcon,
  children,
  submitButtonClassName,
  cancelButtonClassName,
  size = ModalSize.SM,
}: {
  open: boolean
  onClose: () => void
  title: string
  action?: string
  method?: string
  cancelText?: string
  onSubmit: React.FormEventHandler<HTMLFormElement>
  submitButtonText?: string
  submitButtonDisabled?: boolean
  submitButtonShowSpinner?: boolean
  withIcon?: boolean
  children?: React.ReactNode
  submitButtonClassName?: string | undefined
  cancelButtonClassName?: string | undefined
  size?: ModalSize
}) => {
  const cancelButtonRef = useRef(null)

  return (
    <Portal>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-20 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={onClose}
        >
          <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:pb-0 sm:pt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle w-full',
                  {
                    'sm:max-w-lg': size === ModalSize.SM,
                    'sm:max-w-2xl': size === ModalSize.MD,
                    'sm:max-w-3xl': size === ModalSize.LG,
                    'sm:max-w-5xl': size === ModalSize.XL,
                  }
                )}
              >
                {/* calc(100vh - 60px) */}
                <form onSubmit={onSubmit} action={action} method={method}>
                  <div className="bg-white rounded-t-lg px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      {withIcon && (
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                        </div>
                      )}

                      <div className="mt-3 text-center sm:mt-0 sm:text-left sm:flex-1">
                        <Dialog.Title as="h3" className="text-lg leading-6 text-gray-900">
                          {title}
                        </Dialog.Title>

                        <div className="mt-2">
                          <div className="text-sm">{children}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-50 rounded-b-lg px-4 py-3 sm:px-6 flex flex-col sm:flex-row-reverse">
                    <Action.P
                      type="submit"
                      className={classNames(
                        'justify-center sm:ml-2 mb-2 sm:mb-0',
                        submitButtonClassName && !submitButtonDisabled
                          ? submitButtonClassName
                          : undefined
                      )}
                      disabled={submitButtonDisabled}
                      performing={submitButtonShowSpinner}
                    >
                      {submitButtonText}
                    </Action.P>

                    <Action.S
                      onClick={() => onClose()}
                      className={classNames('justify-center', cancelButtonClassName)}
                      ref={cancelButtonRef}
                    >
                      {cancelText}
                    </Action.S>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Portal>
  )
}

export default ModalForm
