import { useContext } from 'react'

import Context from '../contexts/GqlClient'

const useGqlClient = () => {
  const gqlClient = useContext(Context)

  if (typeof gqlClient === 'undefined')
    throw new Error('useGqlClient must be within GqlClient Provider')

  return gqlClient
}

export default useGqlClient
