import { gql, useMutation } from '@apollo/client'

import { UpdateCategoryForm } from '../../types'

const mutation = gql`
  mutation UpdateCategory($id: ID!, $name: String!) {
    updateCategory(id: $id, name: $name)
  }
`

const useUpdateCategory = () => {
  const [mutate] = useMutation<{ addCategory: boolean }, UpdateCategoryForm>(mutation)

  return (variables: UpdateCategoryForm) => mutate({ variables })
}

export default useUpdateCategory
