/**
 * Badge Component
 * -----------------
 * Badge for visually highlighting certain things
 *
 * props
 * -----------------
 * title - the title of the button
 */

import React from 'react'

// Note: we have to use the entire bg color class name prop for now, because when we used the color name with "bg-"
// added as a prefix with code, Tailwind purged the background color classes and didn't display them.
interface BadgeProps {
  title: string | React.ReactNode
  color?: string
  textColor?: string
  className?: string
}
const Badge = ({
  title,
  color = 'bg-yellow-500',
  textColor = 'text-gray-900',
  className,
}: BadgeProps) => (
  <div
    className={`${color} ${textColor} px-3 py-1 rounded-full text-sm text-center inline-block whitespace-nowrap ${className}`}
  >
    {title}
  </div>
)

export default Badge
