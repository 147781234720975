import { ModalTypes } from '../pages/Request/useModal'

const requestForQuote = (rfqId: string) => `/rfqs/${rfqId}`
const requestForQuotes = () => `/rfqs`
const requestForQuoteMessages = (
  rfqId: string,
  options?: { conversationId?: string; customer?: boolean }
) =>
  `${requestForQuote(rfqId)}/messages${
    options?.conversationId ? `/${options.conversationId}` : options?.customer ? '/customer' : ''
  }`

const requestForQuoteAutomations = (rfqId: string) => `${requestForQuote(rfqId)}/automations`
const requestForQuoteCustomerDetails = (rfqId: string) =>
  `${requestForQuote(rfqId)}/customer-details`
const requestForQuoteTimeline = (rfqId: string) => `${requestForQuote(rfqId)}/timeline`
const requestForQuoteAssignOwner = (rfqId: string) => `${requestForQuote(rfqId)}/assign-owner`
const requestForQuotePrint = (rfqId: string) => `${requestForQuote(rfqId)}/print`
const requestForQuoteModal = (rfqId: string, modal: ModalTypes) =>
  `${requestForQuote(rfqId)}?modal=${modal}`

const customerContacts = () => `/customers`
const customerAccounts = () => `/accounts`
const newAccount = () => `/accounts/new`

const customerAccount = (customerId: string) => `/accounts/${customerId}`
const createQuote = () => '/orders/new'

const settingsProfile = () => '/settings/profile'

const products = () => '/products'
const product = (id: string) => `${products()}/${id}`
const createProduct = () => `${products()}/create`

const inbox = () => '/inbox'
const inboxConversation = (conversationId: string) => `/inbox/messages/${conversationId}`

const planAndBilling = () => '/plan'
const upgradeToPro = () => '/plan/subscribe'

export default {
  requestForQuotes,
  requestForQuote,
  requestForQuoteMessages,
  requestForQuoteCustomerDetails,
  requestForQuoteTimeline,
  requestForQuoteAssignOwner,
  requestForQuotePrint,
  requestForQuoteModal,
  requestForQuoteAutomations,
  customerContacts,
  customerAccounts,
  customerAccount,
  newAccount,
  createQuote,
  settingsProfile,
  products,
  product,
  createProduct,
  inbox,
  inboxConversation,
  planAndBilling,
  upgradeToPro,
}
