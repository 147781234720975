import { SortOrder } from '@/dealers/_gen/gql'
import ChartBreakdownLayout from '@/gf/components/Reports/ChartBreakdownLayout'
import { ReportDuration } from '@/gf/modules/ReportDuration'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import { DateTime } from 'luxon'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import DurationInput from './DurationInput'
import BreakdownTable from './Performance/BreakdownTable'
import Chart from './Performance/Chart'
import HistoryTable from './Performance/HistoryTable'
import TitleMetric from './Performance/TitleMetric'
import { Context } from './Performance/context'
import useQuery, { ComparisonRfq, Rfq } from './Performance/useQuery'
import { useDuration } from './useDuration'

export const rfqsToConversionRate = (rfqs: (Rfq | ComparisonRfq)[]) =>
  rfqs.length !== 0 ? rfqs.filter((r) => r.converted).length / rfqs.length : null

export const formatConversionRate = (rate: number | null) => rate && `${Math.round(rate * 100)}%`

export type UpdateArgs = {
  fromDate?: DateTime | null
  toDate?: DateTime | null
  duration?: ReportDuration | null
  breakdownBy?: string | null
  breakdownCustomerSort?: { field: string; order: SortOrder } | null
  breakdownUserSort?: { field: string; order: SortOrder } | null
  orgId?: string | null
  creatorId?: string | null
  date?: DateTime | null
  rfqsSort?: { field: string; order: SortOrder } | null
  chartTab?: string | null
}

const useQueryParamsOpts = {
  date: StringParam,
  orgId: StringParam,
  userId: StringParam,
  'breakdown.by': StringParam,
  'breakdown.user.sort': StringParam,
  'breakdown.customer.sort': StringParam,
  'rfqs.sort': StringParam,
  'chart.tab': withDefault(StringParam, 'activity'),
}

const Performance = () => {
  const [query, setQuery] = useQueryParams(useQueryParamsOpts)

  const {
    fromDate,
    toDate,
    duration,
    prevFromDate,
    prevToDate,
    dates,
    update: updateDuration,
  } = useDuration()

  const update = ({
    date,
    orgId,
    creatorId,
    breakdownBy,
    breakdownCustomerSort,
    breakdownUserSort,
    rfqsSort,
    chartTab,
    ...updates
  }: UpdateArgs) => {
    updateDuration(updates)

    if (date !== undefined) setQuery({ date: date ? date.toISODate() : undefined })
    if (orgId !== undefined) setQuery({ orgId: orgId || undefined })
    if (creatorId !== undefined) setQuery({ userId: creatorId || undefined })
    if (breakdownBy !== undefined) setQuery({ 'breakdown.by': breakdownBy || undefined })
    if (chartTab !== undefined) setQuery({ 'chart.tab': chartTab || undefined })

    if (breakdownCustomerSort !== undefined)
      setQuery({
        'breakdown.customer.sort': breakdownCustomerSort
          ? `${breakdownCustomerSort.field}.${breakdownCustomerSort.order.toLowerCase()}`
          : undefined,
      })

    if (breakdownUserSort !== undefined)
      setQuery({
        'breakdown.user.sort': breakdownUserSort
          ? `${breakdownUserSort.field}.${breakdownUserSort.order.toLowerCase()}`
          : undefined,
      })

    if (rfqsSort !== undefined)
      setQuery({
        'rfqs.sort': rfqsSort ? `${rfqsSort.field}.${rfqsSort.order.toLowerCase()}` : undefined,
      })
  }

  const chartTab = query['chart.tab']
  const date = query.date ? DateTime.fromISO(query.date) : null
  const orgId = query.orgId || null
  const creatorId = query.userId || null
  const breakdownBy = query['breakdown.by'] || null
  const breakdownCustomerSort = query['breakdown.customer.sort']?.split('.') || null
  const breakdownUserSort = query['breakdown.user.sort']?.split('.') || null
  const rfqsSort = query['rfqs.sort']?.split('.') || null

  const [rfqsFilter, comparisonRfqsFilter] = [
    [fromDate, toDate],
    [prevFromDate, prevToDate],
  ].map(([d1, d2]) => JSON.stringify(['received_between', d1.toISODate(), d2.toISODate()]))

  const { rfqs, comparisonRfqs } = useQuery({
    variables: {
      rfqsSortBy: { field: 'received_at', order: SortOrder.Asc },
      rfqsFilter,
      comparisonRfqsFilter,
    },
  })

  const filteredRfqs = (() => {
    if (!rfqs) return undefined

    let result = rfqs

    result = orgId
      ? result.filter(({ quote }) => quote?.customer.organization.id === orgId)
      : result

    result = creatorId
      ? result.filter(({ quote }) => quote?.creation.creator.id === creatorId)
      : result

    return result
  })()

  const quotedRfqs = rfqs?.flatMap((rfq) => (rfq.quote ? { ...rfq, quote: rfq.quote } : []))

  const orgById =
    quotedRfqs &&
    keyBy(
      quotedRfqs.map((rfq) => rfq.quote.customer.organization),
      (o) => o.id
    )

  const creatorById =
    quotedRfqs &&
    keyBy(
      quotedRfqs.map((rfq) => rfq.quote.creation.creator),
      (c) => c.id
    )

  // [ [ startDate, endDate ], ... ]
  const ranges = (() => {
    if (chartTab === 'trend' && dates.length > 27)
      return map(
        groupBy(dates, (d) => d.startOf('week', { useLocaleWeeks: true }).toISODate()),
        (_, isoDate) => {
          const start = DateTime.fromISO(isoDate) as DateTime<true>
          const end = start.endOf('week', { useLocaleWeeks: true })
          return [start, end]
        }
      )

    return dates.map((start, index) => {
      const end = dates.at(index + 1) || start
      return [start, end]
    })
  })()

  return (
    <Context.Provider
      value={{
        rfqs,
        comparisonRfqs,
        filteredRfqs,
        orgById,
        creatorById,
        fromDate,
        toDate,
        date,
        duration,
        orgId,
        creatorId,
        breakdownBy,
        breakdownCustomerSort,
        breakdownUserSort,
        rfqsSort,
        chartTab,
        ranges,
        update,
      }}
    >
      <ChartBreakdownLayout
        titleMetric={<TitleMetric />}
        durationInput={
          <DurationInput fromDate={fromDate} toDate={toDate} duration={duration} update={update} />
        }
        chart={<Chart />}
        breakdownTable={<BreakdownTable />}
        historyTable={<HistoryTable />}
      />
    </Context.Provider>
  )
}

export default Performance
