import { useNextPurchaseOrderNumberQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Ghost from '@/gf/components/Ghost'

const NextPurchaseOrderNumber = ({ storeOrderId }: { storeOrderId: string }) => {
  const { data, loading } = useNextPurchaseOrderNumberQuery({
    client: useGqlClient(),
    variables: { storeOrderId },
  })

  if (loading || !data) {
    return <Ghost className="inline-block h-4 rounded-lg w-3/4" />
  }

  return <>{data.nextPurchaseOrderNumber}</>
}

export default NextPurchaseOrderNumber
