import { useTokenSignInMutation } from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import GfDevMenu from '@/gf/components/DevMenu'

const DevMenu = () => {
  const {
    user: { id: userId },
    featureFlags,
  } = useSession()

  const [tokenSignIn] = useTokenSignInMutation()

  if (!featureFlags.devMenu) return null

  return (
    <GfDevMenu
      currentUserId={userId}
      signIn={(app, token) => tokenSignIn({ variables: { app, token } })}
    />
  )
}

export default DevMenu
