import { useAddInvoiceToStoreOrderMutation } from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import InvoiceInput from '@/gf/components/inputs/InvoiceInput'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import useUppy from '@/gf/hooks/useUppy'
import { ModalSize } from '@/types'
import { DateTime } from 'luxon'
import { FormEvent, useCallback, useState } from 'react'

const SendInvoiceModal = ({
  open,
  onClose,
  storeOrderId,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  storeOrderId: string
  onSuccess: () => void
}) => {
  const [_, msgsMgr] = useMsgs()

  const uppy = useUppy({ allowedFileTypes: ['.jpg', '.jpeg', '.png', '.pdf'], autoProceed: false })

  const [saveInProgress, saveInProgressToggle] = useToggle()
  const [invoiceDueDate, setInvoiceDueDate] = useState<DateTime>()

  const [addInvoice] = useAddInvoiceToStoreOrderMutation()

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      e.stopPropagation()

      try {
        saveInProgressToggle.on()

        if (!invoiceDueDate) {
          saveInProgressToggle.off()
          msgsMgr.add('Error uploading Invoice. Please supply a Due Date.')
          return
        }

        const files = await uppy.upload()
        if (files.successful.length < 1) {
          throw new Error('Error uploading invoice file')
        }

        const invoiceUrl = files.successful[0].uploadURL
        await addInvoice({
          variables: {
            storeOrderId,
            invoiceUrl,
            invoiceDueDate,
          },
        })

        msgsMgr.add('Invoice uploaded. Email sent to customer.', 'positive')
        onClose()
        onSuccess()
      } catch (err) {
        const error = err as Error
        msgsMgr.add(error.message, 'negative')
      } finally {
        saveInProgressToggle.off()
      }
    },
    [storeOrderId, invoiceDueDate]
  )

  return (
    <>
      <Modal open={open} onClose={onClose} size={ModalSize.SM}>
        <form onSubmit={onSubmit} className="relative p-8 flex flex-col gap-y-6">
          <CloseModalButton onClick={onClose} className="absolute top-2 right-2" />
          <div>
            <h3 className="text-2xl font-medium pt-4 pb-2">Send Invoice</h3>
            <p className="text-lg">
              Upload the invoice for this order and set the due date. Click{' '}
              <span className="text-gray-900 font-medium">Send</span> to email the invoice to your
              customer.
            </p>
          </div>
          <div className="flex flex-col gap-y-3">
            <InvoiceInput
              uppy={uppy}
              dueDate={invoiceDueDate}
              onDueDateChange={setInvoiceDueDate}
              onFileInputError={(error) => msgsMgr.add(error, 'negative')}
            />
          </div>
          <div className="flex gap-x-3">
            <Action.S onClick={onClose} className="w-1/2 font-medium">
              Cancel
            </Action.S>
            <Action.P type="submit" performing={saveInProgress} className="w-1/2 font-medium">
              Send
            </Action.P>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default SendInvoiceModal
