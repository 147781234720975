import { DateTime } from 'luxon'

export enum ReportDuration {
  ThisWeek = 'THISWEEK',
  LastWeek = 'LASTWEEK',
  Last30Days = 'LAST30DAYS',
  Last90Days = 'LAST90DAYS',
  ThisMonth = 'THISMONTH',
  LastMonth = 'LASTMONTH',
  Custom = 'CUSTOM',
}

export const fromParam = (param: string) => {
  if (param === 'this-week') return ReportDuration.ThisWeek
  if (param === 'last-week') return ReportDuration.LastWeek
  if (param === 'last-30-days') return ReportDuration.Last30Days
  if (param === 'last-90-days') return ReportDuration.Last90Days
  if (param === 'this-month') return ReportDuration.ThisMonth
  if (param === 'last-month') return ReportDuration.LastMonth
  if (param === 'custom') return ReportDuration.Custom
  throw new Error(`Unexpected report duration param: ${param}`)
}

export const toDates = (
  duration: ReportDuration,
  custom: () => [DateTime, DateTime]
): [DateTime, DateTime] => {
  const now = DateTime.now()

  if (duration === ReportDuration.ThisWeek) return [now.startOf('week'), now.endOf('week')]

  if (duration === ReportDuration.LastWeek)
    return [now.minus({ weeks: 1 }).startOf('week'), now.minus({ weeks: 1 }).endOf('week')]

  if (duration === ReportDuration.Last30Days)
    return [now.minus({ days: 30 }).startOf('day'), now.endOf('day')]

  if (duration === ReportDuration.Last90Days)
    return [now.minus({ days: 90 }).startOf('day'), now.endOf('day')]

  if (duration === ReportDuration.ThisMonth) return [now.startOf('month'), now.endOf('month')]

  if (duration === ReportDuration.LastMonth)
    return [now.minus({ months: 1 }).startOf('month'), now.minus({ months: 1 }).endOf('month')]

  return custom()
}

export const toParam = (duration: ReportDuration) => {
  if (duration === ReportDuration.ThisWeek) return 'this-week'
  if (duration === ReportDuration.LastWeek) return 'last-week'
  if (duration === ReportDuration.Last30Days) return 'last-30-days'
  if (duration === ReportDuration.Last90Days) return 'last-90-days'
  if (duration === ReportDuration.ThisMonth) return 'this-month'
  if (duration === ReportDuration.LastMonth) return 'last-month'
  return 'custom' // ReportDuration.Custom
}

export const toDisplay = (duration: ReportDuration) => {
  if (duration === ReportDuration.ThisWeek) return 'This week'
  if (duration === ReportDuration.LastWeek) return 'Last week'
  if (duration === ReportDuration.Last30Days) return 'Last 30 days'
  if (duration === ReportDuration.Last90Days) return 'Last 90 days'
  if (duration === ReportDuration.ThisMonth) return 'This month'
  if (duration === ReportDuration.LastMonth) return 'Last month'
  return 'Custom' // ReportDuration.Custom
}
