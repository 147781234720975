/**
 * MessageAttachment
 *
 * Displays message attachments that are not images
 *
 * I recommend adding your own className style to restrict the width; for example,
 * className="max-w-44 xs:max-w-56 sm:max-w-72"
 */
import { DocumentTextIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

const MessageAttachment = ({
  name,
  blue,
  className,
}: {
  name: string
  blue?: boolean
  className?: string
}) => (
  <div
    className={classNames(
      'px-2 h-14 min-w-14 min-h-14 inline-flex items-center gap-x-2 text-sm rounded-lg whitespace-pre-wrap bg-gray-200 text-gray-900',
      blue ? 'bg-blue-500 text-gray-50' : 'bg-gray-200 text-gray-900',
      className
    )}
  >
    <div
      className={classNames(
        'p-2 inline-flex justify-center items-center rounded-full bg-gray-300 text-gray-900',
        blue ? 'bg-blue-600 text-gray-50' : 'bg-gray-300 text-gray-900'
      )}
    >
      <DocumentTextIcon className="w-5 h-5" />
    </div>
    <span className="truncate">{name}</span>
  </div>
)

export default MessageAttachment
