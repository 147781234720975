import { RequestScheduledMessagesDocument, useCancelScheduleMutation } from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import SpinnerSmall from '@/gf/components/SpinnerSmall'

const CancelScheduleButton = ({ id }: { id: string }) => {
  const [cancel, { loading: cancelInProgress }] = useCancelScheduleMutation({
    variables: { scheduledMessageId: id },
    refetchQueries: [RequestScheduledMessagesDocument],
  })

  return (
    <Action.T
      onClick={() => cancel()}
      disabled={cancelInProgress}
      className="flex items-center gap-x-2 text-sm"
    >
      {cancelInProgress && <SpinnerSmall />} Cancel
    </Action.T>
  )
}

export default CancelScheduleButton
