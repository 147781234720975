import { Fragment } from 'react'
// import { Link, LinkProps } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon } from '@heroicons/react/outline'
import { cn } from '../modules/utils'

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

export const ActionItem = ({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}) => (
  <Menu.Item>
    {({ active }) => (
      <button
        onClick={onClick}
        className={classNames(
          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
          'block px-4 py-2 text-sm w-full text-left'
        )}
        type="button"
      >
        {children}
      </button>
    )}
  </Menu.Item>
)

// export const LinkItem = ({ children, to }: { children: React.ReactNode; to: LinkProps['to'] }) => (
//   <Menu.Item>
//     {({ active }) => (
//       <Link
//         to={to}
//         className={classNames(
//           active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
//           'block px-4 py-2 text-sm'
//         )}
//       >
//         {children}
//       </Link>
//     )}
//   </Menu.Item>
// )

// export const AItem = ({ children, href }: { children: React.ReactNode; href?: string }) => (
//   <Menu.Item>
//     {({ active }) => (
//       <a
//         href={href}
//         className={classNames(
//           active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
//           'block px-4 py-2 text-sm'
//         )}
//       >
//         {children}
//       </a>
//     )}
//   </Menu.Item>
// )

const ThreeDotsDropdown = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => (
  <Menu as="div" className="relative inline-block text-left">
    <div>
      <Menu.Button className="p-0.5 flex items-center rounded-full text-gray-500 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
        <span className="sr-only">Open options</span>
        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
    </div>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={cn(
          'absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
          className
        )}
      >
        <div className="py-1">{children}</div>
      </Menu.Items>
    </Transition>
  </Menu>
)

export default ThreeDotsDropdown
