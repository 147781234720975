import classNames from 'classnames'

import Tooltip from '@/gf/components/Tooltip'

const LabeledText = ({
  label,
  value,
  tooltipText,
  labelAction,
  className = '',
}: {
  label: string
  labelAction?: React.ReactNode
  value: React.ReactNode
  tooltipText?: string | null
  className?: string
}) => (
  <div className={classNames('flex flex-col text-sm', className)}>
    <label className="text-slate-600 font-medium flex items-center justify-between">
      {label} {labelAction}
    </label>

    <span className="w-full text-slate-700 p-1 -ml-1 flex items-center">
      {value || <span className="italic text-gray-400 text-sm">(not set)</span>}{' '}
      {tooltipText && <Tooltip className="ml-2 font-normal" text={tooltipText} size="medium" />}
    </span>
  </div>
)

export default LabeledText
