import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import useToggle from '@/gf/hooks/useToggle'
import { ModalSize } from '@/types'

const RemovePartButton = ({
  onRemove,
  part,
}: {
  onRemove: () => void
  part: { partNumber: string; description: string; quantity: number }
}) => {
  const [open, openToggle] = useToggle()

  const isEmpty = !part.partNumber && !part.description && part.quantity === 1

  return (
    <>
      <Action.T onClick={isEmpty ? onRemove : openToggle.on} className="font-medium">
        Remove
      </Action.T>
      <Modal open={open} onClose={openToggle.off} size={ModalSize.SM}>
        <div className="relative p-8">
          <CloseModalButton onClick={openToggle.off} className="absolute top-2 right-2" />

          <h3 className="text-2xl font-medium pt-4 pb-2">Remove Part</h3>
          <p className="text-lg">Confirm you would like to remove:</p>

          <div className="mt-2">
            <div className="flex gap-x-8 ">
              <div className="w-full max-w-36 leading-6">Part Number:</div>
              <div className="leading-6">{part.partNumber}</div>
            </div>
            <div className="flex gap-x-8 ">
              <div className="w-full max-w-36 leading-6">Description:</div>
              <div className="leading-6">{part.description}</div>
            </div>
            <div className="flex gap-x-8 ">
              <div className="w-full max-w-36 leading-6">Quantity:</div>
              <div className="leading-6">{part.quantity}</div>
            </div>
          </div>
          <div className="flex gap-x-3 mt-6">
            <Action.S onClick={openToggle.off} className="w-1/2 font-medium">
              Cancel
            </Action.S>
            <Action.P onClick={onRemove} className="w-1/2 font-medium">
              Remove
            </Action.P>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default RemovePartButton
