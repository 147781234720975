import { StoreOrderOrderPayload, StoreOrderOrder } from '../../types'

const fromPayload = (payload: StoreOrderOrderPayload): StoreOrderOrder => {
  const user = payload.user
    ? {
        id: payload.user.id,
        name: payload.user.name,
        email: payload.user.email,
        phoneNumber: payload.user.phoneNumber,
        organization: payload.user.organization,
      }
    : null

  return {
    ...payload,
    customer: {
      name: payload.name || '',
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      user,
    },
  }
}

export default { fromPayload }
