import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useOrderContext } from '../context'

import CloseRequestModal from '@/buyers/components/CloseRequestModal'

const CloseRequest = () => {
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const navigate = useNavigate()
  const close = () => {
    refetchStoreOrder()
    navigate(`/orders/${storeOrder.id}`)
  }

  useEffect(() => {
    if (!storeOrder.order.requestForQuote || storeOrder.order.requestForQuote.closedAt) close()
  }, [])

  if (!storeOrder.order.requestForQuote) return null

  return (
    <CloseRequestModal
      rfqId={storeOrder.order.requestForQuote.id}
      close={close}
      onOk={refetchStoreOrder}
    />
  )
}

export default CloseRequest
