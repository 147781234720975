import Badge from './Badge'

import { Invoice } from '../../types'
import InvoiceM from '../modules/Invoice'

const getBadgeProps = (
  invoice: Pick<Invoice, 'isPaid' | 'isCanceled' | 'isFinanced' | 'balanceTransactionId'>
) => {
  const status = InvoiceM.getStatus(invoice)

  switch (status) {
    case 'paid':
      return {
        title: 'Paid',
        color: 'bg-green-200',
        textColor: 'text-green-900',
      }

    case 'canceled':
      return {
        title: 'Canceled',
        color: 'bg-gray-200',
        textColor: 'text-gray-900',
      }

    case 'processing':
      return {
        title: 'Processing',
        color: 'bg-blue-200',
        textColor: 'text-blue-900',
      }
    case 'not_paid':
      return {
        title: 'Not Paid',
        color: 'bg-red-200',
        textColor: 'text-red-900',
      }
    default:
      return {
        title: 'Unknown',
        color: 'bg-gray-100',
        textColor: 'text-gray-900',
      }
  }
}

const InvoiceStateBadge = ({
  invoice,
}: {
  invoice: Pick<Invoice, 'isPaid' | 'isCanceled' | 'isFinanced' | 'balanceTransactionId'>
}) => <Badge {...getBadgeProps(invoice)} />

export default InvoiceStateBadge
