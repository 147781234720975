const RestartIcon = () => (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0227 1.98555L20.0227 6.98584L19.4417 6.98584L15.0227 6.98584"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.324 6.63827C17.7276 3.1568 13.8089 1.23086 9.98525 2.25615C5.71721 3.40062 3.18382 7.78883 4.32676 12.0575C5.4697 16.3262 9.85617 18.8588 14.1242 17.7144C15.975 17.2181 17.4996 16.1118 18.5397 14.6699"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RestartIcon
