import { DateTime } from 'luxon'
import qs from 'query-string'
import {
  DateParam,
  encodeQueryParams,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

const GfDateParam = {
  encode: (value: DateTime | null | undefined) => value && value.toISODate(),
  decode: (value: string | (string | null)[] | null | undefined) => {
    const result = DateParam.decode(value)
    return result && DateTime.fromJSDate(result)
  },
}

const opts = {
  startingAfter: withDefault(StringParam, undefined),
  endingBefore: withDefault(StringParam, undefined),
  type: withDefault(StringParam, undefined),
  fromDate: withDefault(GfDateParam, undefined),
  toDate: withDefault(GfDateParam, undefined),
}

const useParams = () => {
  const [query, updateQuery] = useQueryParams(opts)

  const buildUpdates = (updates: Partial<typeof query>) => ({
    startingAfter: undefined,
    endingBefore: undefined,
    ...updates,
  })

  const updateParams = (updates: Partial<typeof query>) => updateQuery(buildUpdates(updates))

  const toUpdatedSearch = (updates: Partial<typeof query>) =>
    qs.stringify(encodeQueryParams(opts, { ...query, ...buildUpdates(updates) }))

  return { params: query, toUpdatedSearch, updateParams }
}

export default useParams
