import { DeliveryMethod, StoreOrderStep } from '@/buyers/_gen/gql'
import { useOrderContext } from '../context'

import { Section } from '../YourOrder'
import Time from '@/gf/modules/Time'

const Deliveries = () => {
  const { storeOrder } = useOrderContext()

  if (
    ![StoreOrderStep.Fulfilling, StoreOrderStep.Fulfilled].includes(storeOrder.step) ||
    storeOrder.deliveryMethod !== DeliveryMethod.VendorDelivery
  )
    return null

  return (
    <Section className="space-y-1">
      <div className="font-medium text-lg text-gray-900">Delivery</div>
      <div className="space-y-3">
        <p className="text-base">
          {storeOrder.store.name} is delivering this to you directly, so there is no tracking
          information.
        </p>
        <div className="flex flex-col gap-y-2">
          {storeOrder.outForDeliveryAt && (
            <div className="flex gap-x-2">
              Out for delivery:
              <span className="font-medium">
                {Time.formatDateTimeWithDaySmartYear(storeOrder.outForDeliveryAt)}
              </span>
            </div>
          )}
          {storeOrder.receivedAt && (
            <div className="flex gap-x-2">
              Received:
              <span className="font-medium">
                {Time.formatDateTimeWithDaySmartYear(storeOrder.receivedAt)}
              </span>
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

export default Deliveries
