import BaseTitleMetric from '@/gf/components/next/TitleMetric'
import { toParam } from '@/gf/modules/ReportDuration'
import { formatTimeToQuoteMins, rfqsToMedianTimeToQuoteMins } from '../Service'
import { useContext } from './context'

const TitleMetric = () => {
  const { rfqs, comparisonRfqs, fromDate, toDate, duration } = useContext()
  const value = rfqs && rfqsToMedianTimeToQuoteMins(rfqs)
  const comparisonValue = comparisonRfqs && rfqsToMedianTimeToQuoteMins(comparisonRfqs)

  const valueDisplay =
    value !== undefined ? (value === null ? 'N/A' : formatTimeToQuoteMins(value)) : undefined

  const diffDisplay = (() => {
    if (
      value === undefined ||
      value === null ||
      comparisonValue === undefined ||
      comparisonValue === null
    )
      return 'N/A'

    return formatTimeToQuoteMins(Math.abs(value - comparisonValue))
  })()

  return (
    <BaseTitleMetric
      title="Send Quote"
      value={value}
      comparisonValue={comparisonValue}
      valueDisplay={valueDisplay}
      diffDisplay={diffDisplay}
      duration={{ durationId: toParam(duration), durationStart: fromDate, durationEnd: toDate }}
      downIsGood
    />
  )
}

export default TitleMetric
