import { SortOrder } from '@/dealers/_gen/gql'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { formatConversionRate, rfqsToConversionRate } from '../../Performance'
import { useContext } from '../context'

const Customer = () => {
  const context = useContext()
  const { rfqs, orgById, breakdownCustomerSort: sort, update } = context
  const sortField = sort ? sort[0] : 'conv-rate'
  const sortOrder = sort ? (sort[1] === 'desc' ? 'desc' : 'asc') : 'desc'
  const quotedRfqs = rfqs?.flatMap((rfq) => (rfq.quote ? { ...rfq, quote: rfq.quote } : []))
  const rfqsByOrgId = quotedRfqs && groupBy(quotedRfqs, (rfq) => rfq.quote.customer.organization.id)

  const unsortedRows =
    rfqsByOrgId &&
    map(rfqsByOrgId, (subRfqs, orgId) => ({
      orgId,
      orgName: orgById && orgById[orgId].name,
      rfqCount: subRfqs.length,
      conversionRate: rfqsToConversionRate(subRfqs),
    }))

  const orderByField = (() => {
    if (sortField === 'customer') return 'orgName'
    if (sortField === 'requests') return 'rfqCount'
    if (sortField === 'conv-rate') return 'conversionRate'
    return sortField
  })()

  const rows = unsortedRows && orderBy(unsortedRows, orderByField, sortOrder)

  return (
    <ReportingTable
      data={rows}
      getRowKey={({ orgId }) => orgId}
      checkbox={{
        getChecked: ({ orgId }) => orgId === context.orgId,
        onToggleRow: ({ orgId }) => update({ orgId: orgId !== context.orgId ? orgId : null }),
        onClear: () => update({ orgId: null }),
      }}
      sortBy={{
        sortBy: {
          field: sortField,
          order: sortOrder === 'desc' ? SortOrder.Desc : SortOrder.Asc,
        },
        setSortBy: (sortBy) => update({ breakdownCustomerSort: sortBy }),
      }}
      columns={[
        { header: 'Customer', getValue: ({ orgName }) => orgName, sortByField: 'customer' },
        { header: 'Requests', getValue: ({ rfqCount }) => rfqCount, sortByField: 'requests' },
        {
          header: 'Conv. Rate',
          getValue: ({ conversionRate }) => formatConversionRate(conversionRate),
          sortByField: 'conv-rate',
        },
      ]}
    />
  )
}

export default Customer
