import { createContext } from 'react'
import { SessionQuery } from '../_gen/gql'

type Session = SessionQuery['session']

export type AuthorizedSession = Exclude<Session, 'user' | 'store' | 'storeId'> & {
  user: Exclude<Session['user'], null>
  store: Exclude<Session['store'], null>
  storeId: Exclude<Session['storeId'], null>
}

const SessionContext = createContext<AuthorizedSession | undefined>(undefined)

export default SessionContext
