import Money from './Money'
import Time from './Time'
import StoreOrder from './StoreOrder'

import { Transaction, TransactionPayload } from '../../types'

const fromPayload = (payload: TransactionPayload): Transaction => ({
  ...payload,
  customer: Money.fromPayload(payload.customer),
  gearflow: Money.fromPayload(payload.gearflow),
  supplier: Money.fromPayload(payload.supplier),
  committedAt: Time.fromPayload(payload.committedAt),
  storeOrder: payload.storeOrder && StoreOrder.fromPayload(payload.storeOrder),
})

const shortenId = (id: string) => id.split('-')[0]

export default { fromPayload, shortenId }
