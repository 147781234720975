import MoneyM from '@/gf/modules/Money'
import { SearchCatalogResults } from '../QuoteBuilder'
import AvailabilityStatus from '@/dealers/components/Products/AvailabilityStatus'

const CatalogAutoCompleteOption = ({ product }: { product: SearchCatalogResults[number] }) => (
  <div className="w-full space-y-0.5 text-sm leading-tight">
    <div className="flex items-center gap-x-2">
      <div className="truncate text-ellipsis flex-grow font-medium">{product.name}</div>
      {product.salePrice && (
        <div className="flex-shrink-0 font-medium">
          {MoneyM.format(MoneyM.fromDecimal(product.salePrice, 'USD'))}
        </div>
      )}
    </div>
    <div className="flex justify-between items-end">
      <div>{product.mpn}</div>
      <div>
        {product.availability && (
          <AvailabilityStatus
            availability={product.availability}
            leadTime={product.leadTime}
            leadTimeDate={product.leadTimeDate}
            oneLiner
          />
        )}
      </div>
    </div>
  </div>
)

export default CatalogAutoCompleteOption
