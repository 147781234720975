import Page from '@/gf/components/Page'
import Frame from '../components/Frame'
import { ProductAvailability, useCreateProductMutation } from '../_gen/gql'
import Urls from '../modules/Urls'
import Box from '@/gf/components/Box'
import { useNavigate } from 'react-router-dom'
import ProductForm, { ProductFormValues } from '../components/ProductForm'
import useMsgs from '@/gf/hooks/useMsgs'
import useSession from '../hooks/useSession'

const CreateProduct = () => {
  const [_, msgs] = useMsgs()
  const { store } = useSession()
  const navigate = useNavigate()
  const [createProduct, { loading: saveInProgress }] = useCreateProductMutation()

  const onSubmit = async (values: ProductFormValues) => {
    try {
      await createProduct({
        variables: {
          name: values.name,
          mpn: values.mpn,
          altMpn: values.altMpn,
          shortDescription: values.shortDescription,
          availability: values.availability,
          salePrice: values.salePrice as number,
          enabled: values.enabled,
          autoQuoteEnabled: values.autoQuoteEnabled,
          leadTime:
            values.availability === ProductAvailability.BackOrdered ? values.leadTime : null,
          leadTimeDate: null,
        },
      })

      msgs.add('Product added to catalog', 'positive')

      navigate(Urls.products())
    } catch (err) {
      console.error(err)
      msgs.add('Something went wrong', 'negative')
    }
  }

  return (
    <Frame
      breadcrumbs={{
        copy: 'Back to Dashboard',
        crumbs: [
          { name: 'Catalog', href: Urls.products() },
          { name: 'Create Product', href: Urls.createProduct() },
        ],
      }}
    >
      <Page title="Create Product">
        <Box className="p-6 mt-6 max-w-screen-lg">
          <ProductForm
            onSubmit={onSubmit}
            saveInProgress={saveInProgress}
            defaultValues={{
              name: '',
              mpn: '',
              altMpn: '',
              shortDescription: '',
              availability: ProductAvailability.InStock,
              salePrice: null,
              enabled: false,
              autoQuoteEnabled: store?.autoQuote === true,
              leadTime: null,
            }}
          />
        </Box>
      </Page>
    </Frame>
  )
}

export default CreateProduct
