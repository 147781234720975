import { useEffect } from 'react'

import { Balance, BalanceConfig } from '@/types'

const useBalanceModal = ({
  balance,
  checkoutTokens,
  onSuccess,
  isAuth = true,
}: {
  balance: Balance
  checkoutTokens: string[]
  onSuccess: () => void
  isAuth?: boolean
}) => {
  const balanceConfig: BalanceConfig = {
    hideDueDate: true,
    hideDueDateText: 'Supplier confirmation',
    skipSuccessPage: true,
    isAuth,
    onError: (error) => {
      console.log('onError', error)
    },
    onSuccess,
    allowedPaymentMethods: ['creditCard', 'bank'],
    onClose: () => console.log('onClose'),
  }

  useEffect(() => {
    if (balance.checkout) balance.checkout.init(balanceConfig)
  }, [])

  const open = () => balance.checkout.render(checkoutTokens.join(','), '#balance-checkout')

  return { open }
}

export default useBalanceModal
