import { XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

const CloseModalButton = ({ className, onClick }: { className?: string; onClick: () => void }) => (
  <button
    type="button"
    onClick={onClick}
    title="Close"
    className={classNames(
      'block h-8 w-8 rounded-full p-1 hover:bg-gray-400 hover:bg-opacity-10 transition duration-300',
      className
    )}
  >
    <XIcon className="text-slate-700 h-6" />
  </button>
)

export default CloseModalButton
