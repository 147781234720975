import { UpdateWorkspaceMutationVariables, WorkspaceSettingsQuery } from '@/dealers/_gen/gql'
import { createContext, useContext as useReactContext } from 'react'

export const WorkspaceSettingsContext = createContext<
  | {
      storeId: string
      store: Exclude<WorkspaceSettingsQuery['store'], null> | undefined
      loading: boolean
      refetch: () => Promise<unknown>
      updateWorkspace: (variables: Partial<UpdateWorkspaceMutationVariables>) => void
      brands: WorkspaceSettingsQuery['brands'] | undefined
      categories: WorkspaceSettingsQuery['categories'] | undefined
    }
  | undefined
>(undefined)

export const useWorkspaceSettingsContext = () => {
  const context = useReactContext(WorkspaceSettingsContext)
  if (!context) throw new Error('must be used within provider')
  return context
}
