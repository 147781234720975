import { AdditionalCharge, AdditionalChargePayload } from '../../types'

import Money from './Money'
import Invoice from './Invoice'

const fromPayload = (payload: AdditionalChargePayload): AdditionalCharge => ({
  ...payload,
  price: Money.fromPayload(payload.price),
  invoice: payload.invoice ? Invoice.fromPayload(payload.invoice) : undefined,
})

export default {
  fromPayload,
}
