import { StateCode } from '@/types'
import { XIcon } from '@heroicons/react/solid'
import keyBy from 'lodash/keyBy'
import states from '../data/states'
import StateSelect from './StateSelect'

const stateByCode = keyBy(states, 'code')

const StateList = ({
  stateCodes,
  setStateCodes,
}: {
  stateCodes: StateCode[]
  setStateCodes: (newStateCodes: StateCode[]) => void
}) => {
  const alphabetizedStates = stateCodes
    .map((sc) => stateByCode[sc])
    .sort((s1, s2) => (s1 < s2 ? -1 : s1 > s2 ? 1 : 0))

  const add = (code: StateCode | null) => {
    if (code) setStateCodes([...new Set([...stateCodes, code])].sort())
  }

  const remove = (code: StateCode) => {
    setStateCodes(stateCodes.filter((c) => c !== code))
  }

  return (
    <div className="border-1 border-gray-300 rounded-md">
      <div className="py-3 px-4 border-b-1 border-gray-300">
        {stateCodes.length > 0 ? (
          <div className="space-y-1">
            {alphabetizedStates.map((state) => (
              <div className="flex gap-4 items-center justify-between text-sm" key={state.code}>
                <div>{state.name}</div>

                <button onClick={() => remove(state.code)} type="button">
                  <XIcon className="h-5 w-5 text-gray-300 hover:text-red-500" />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-sm text-gray-400 italic">None added.</div>
        )}
      </div>

      <div className="p-0.5">
        <StateSelect
          value={null}
          onSelect={add}
          placeholder="Select&hellip;"
          className="block w-full rounded-md border-transparent text-sm"
        />
      </div>
    </div>
  )
}

export default StateList
