import { useReadyForPickupMutation } from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import { FormEvent, useCallback } from 'react'

const ReadyForPickupModal = ({
  open,
  onClose,
  storeOrderId,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  storeOrderId: string
  onSuccess: () => void
}) => {
  const [readyForPickup, { loading: performing }] = useReadyForPickupMutation()

  const onReadyForPickup = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      e.stopPropagation()

      await readyForPickup({
        variables: {
          storeOrderId,
          notifyCustomer: true,
          notes: '',
        },
      })

      onClose()

      onSuccess()
    },
    [storeOrderId]
  )

  return (
    <Modal open={open} onClose={onClose}>
      <div className="relative p-8 space-y-2">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />
        <h2 className="font-medium text-2xl">Will Call Requested</h2>

        <p className="text-lg">
          Let the customer know when their order is ready for pickup by clicking the button below.
        </p>
        <p className="text-lg">
          This will let them know to dispatch a driver to pick up their order.
        </p>

        <div className="flex pt-4 gap-x-3">
          <Action.S onClick={onClose} className="w-1/2">
            Cancel
          </Action.S>
          <Action.P
            type="button"
            onClick={onReadyForPickup}
            performing={performing}
            className="w-1/2"
          >
            Ready for Pickup
          </Action.P>
        </div>
      </div>
    </Modal>
  )
}

export default ReadyForPickupModal
