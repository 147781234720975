import MoneyM from '@/gf/modules/Money'
import { Money } from '@/types'
import { ChangeEvent, forwardRef, InputHTMLAttributes, useEffect, useState } from 'react'
import TextInput from '../TextInput'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> & {
  price?: Money | null
  onChange?: (newPrice?: Money) => void
}

const Price = forwardRef<HTMLInputElement, Props>(({ price, onChange, ...props }, ref) => {
  // When converting the value to a number, we lose trailing 0's after a decimal place.
  // This prevents entering numbers like 0.01. Save the value here, so we can use it.
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    const floatValue = parseFloat(value)
    const cmpValue = !Number.isNaN(floatValue) ? floatValue : null
    const cmpPrice = price ? MoneyM.toDecimal(price) : null
    if (cmpValue !== cmpPrice) setValue(cmpPrice?.toString() ?? '')
  }, [price])

  const inputOnChange =
    onChange &&
    ((event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value)
      const floatValue = event.target.valueAsNumber
      let newPrice = !Number.isNaN(floatValue) ? MoneyM.fromDecimal(floatValue, 'USD') : undefined
      newPrice = newPrice && MoneyM.isNeg(newPrice) ? MoneyM.fromDecimal(0, 'USD') : newPrice
      onChange(newPrice)
    })

  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <span>$</span>
      </div>

      <TextInput
        value={value}
        onChange={inputOnChange}
        {...props}
        type="number"
        step=".01"
        ref={ref}
        leftIconPad
      />
    </div>
  )
})

export default Price
