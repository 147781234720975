import classNames from 'classnames'
import { Link } from 'react-router-dom'
import NotificationDot from '../NotificationDot'

type Tab = {
  param?: string
  name: string
  showNotification?: boolean
}
type LinkTab = {
  name: string
  to: string
  showNotification?: boolean
}

const theme = {
  black_blue: {
    selected: {
      text: 'text-gray-900',
      border: 'bg-blue-600',
    },
    regular: {
      text: 'text-gray-500',
      border: 'bg-trasparent',
    },
  },

  blue: {
    selected: {
      text: 'text-blue-600',
      border: 'bg-blue-600',
    },
    regular: {
      text: 'text-gray-500',
      border: 'bg-trasparent',
    },
  },
}

const textSizes = {
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
}

const Tabs = ({
  tabs,
  selectedTabName,
  onTabSelect,
  tabClassName,
}: {
  tabs: Tab[]
  selectedTabName: string
  onTabSelect: (tabName: string) => void
  tabClassName?: string
}) => (
  <>
    {tabs.map((tab) => (
      <button
        key={tab.name}
        className={classNames(
          'flex flex-col gap-y-1 text-lg font-medium whitespace-nowrap relative',
          tab.name === selectedTabName ? 'text-gray-900' : 'text-gray-500',
          tabClassName
        )}
        type="button"
        onClick={() => onTabSelect(tab.name)}
      >
        {tab.name}
        {tab.showNotification && <NotificationDot className="absolute top-0 -right-3" />}
        <div
          className={classNames(
            'w-full h-1 bg-blue-600',
            tab.name !== selectedTabName && 'invisible'
          )}
        />
      </button>
    ))}
  </>
)

export const LinkTabs = ({
  tabs,
  selectedTabName,
  tabClassName,
  color = 'black_blue',
  textSize = 'lg',
}: {
  tabs: LinkTab[]
  selectedTabName: string
  tabClassName?: string
  color?: 'black_blue' | 'blue'
  textSize?: 'sm' | 'base' | 'lg'
}) => (
  <>
    {tabs.map((tab) => (
      <Link
        key={tab.name}
        to={tab.to}
        className={classNames(
          'flex flex-col gap-y-3 whitespace-nowrap relative',
          textSizes[textSize],
          tab.name === selectedTabName && 'font-medium',
          tab.name === selectedTabName ? theme[color].selected.text : theme[color].regular.text,
          tabClassName
        )}
      >
        {tab.name}
        {tab.showNotification && <NotificationDot className="absolute top-0 -right-3" />}
        <div
          className={classNames(
            'w-full h-[0.1875rem]',
            tab.name === selectedTabName
              ? theme[color].selected.border
              : theme[color].regular.border
          )}
        />
      </Link>
    ))}
  </>
)

export default Tabs
