import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import type { MultiButtonLink } from '../MultiButton'

const LinkWrapper = ({
  link,
  setMultiOpen,
  children,
}: {
  link: MultiButtonLink
  setMultiOpen: (multiOpen: boolean) => void
  children: ReactNode
}) => (
  <>
    {link.to.toString().startsWith('http') ? (
      <a
        className="w-full"
        href={link.to.toString()}
        target={link.target}
        onClick={() => setMultiOpen(false)}
      >
        {children}
      </a>
    ) : (
      <RouterLink
        className="w-full"
        to={link.to}
        target={link.target}
        replace={link.replace}
        onClick={() => setMultiOpen(false)}
      >
        {children}
      </RouterLink>
    )}
  </>
)

export default LinkWrapper
