import classNames from 'classnames'

const AnimatedHamburguerIcon = ({ active }: { active: boolean }) => (
  <span className="block w-7 h-6 relative p-1">
    <span
      className={classNames(
        'block w-5 h-[0.15rem] rounded-lg bg-gray-600 absolute transition duration-400',
        active ? 'rotate-45 top-[50%]' : 'top-[25%]'
      )}
    />
    <span
      className={classNames(
        'block w-5 h-[0.15rem] rounded-lg bg-gray-600 top-[50%] absolute transition duration-200',
        active && 'opacity-0'
      )}
    />
    <span
      className={classNames(
        'block w-5 h-[0.15rem] rounded-lg bg-gray-600 absolute transition duration-400',
        active ? '-rotate-45 top-[50%]' : 'top-[75%]'
      )}
    />
  </span>
)

export default AnimatedHamburguerIcon
