import { ReportDuration, fromParam, toDates, toParam } from '@/gf/modules/ReportDuration'
import { DateTime, Interval } from 'luxon'
import { StringParam, useQueryParams } from 'use-query-params'

const useQueryParamsOpts = {
  'from-date': StringParam,
  'to-date': StringParam,
  duration: StringParam,
}

export const useDuration = () => {
  const [query, setQuery] = useQueryParams(useQueryParamsOpts)

  const duration = (() => {
    if (query.duration) return fromParam(query.duration)

    return query['from-date'] && query['to-date']
      ? ReportDuration.Custom
      : ReportDuration.Last30Days
  })()

  const [fromDate, toDate] = toDates(duration, () => [
    DateTime.fromISO(query['from-date'] as string),
    DateTime.fromISO(query['to-date'] as string),
  ])

  const prevToDate = fromDate.minus({ day: 1 })
  const prevFromDate = prevToDate.minus(toDate.diff(fromDate))

  const dates = Interval.fromDateTimes(fromDate, toDate)
    .splitBy({ day: 1 })
    .map((d: Interval<true>) => d.start)

  const update = (updates: {
    fromDate?: DateTime | null
    toDate?: DateTime | null
    duration?: ReportDuration | null
  }) => {
    if (updates.fromDate !== undefined)
      setQuery({ 'from-date': updates.fromDate ? updates.fromDate.toISODate() : undefined })

    if (updates.toDate !== undefined)
      setQuery({ 'to-date': updates.toDate ? updates.toDate.toISODate() : undefined })

    if (updates.duration !== undefined)
      setQuery({ duration: updates.duration ? toParam(updates.duration) : undefined })
  }

  return { fromDate, toDate, prevToDate, prevFromDate, duration, dates, update }
}
