import { useEffect } from 'react'
import useSession from '../hooks/useSession'
import { setTrackingUser } from '@/gf/modules/Analytics'

const GAUserTracking = () => {
  const { user } = useSession()

  useEffect(() => {
    if (user?.id) {
      setTrackingUser(user.id)
    }
  }, [user?.id])

  return null
}

export default GAUserTracking
