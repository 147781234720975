import { createContext } from 'react'
import { SessionQuery } from '../_gen/gql'

type Session = SessionQuery['session']
type User = Exclude<SessionQuery['session']['user'], null>

export type AuthenticatedSession = Omit<Session, 'user' | 'organization' | 'orgId'> & {
  user: User
  orgId: string
  organization: User['orgs'][number]
  switchOrg: (id: string) => void
}

const SessionContext = createContext<AuthenticatedSession | undefined>(undefined)

export default SessionContext
