import { useUpdateShippingRatesMutation } from '@/dealers/_gen/gql'
import useMsgs from '@/gf/hooks/useMsgs'
import { Money } from '@/types'
import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useWorkspaceSettingsContext } from '../context'

export type EditableShippingRate = {
  id: string
  subtotal: Money | null
  shipping: Money | null
}

const updateItem = <Item>(list: Item[], index: number, updates: Partial<Item>) =>
  Object.assign([], list, { [index]: { ...list[index], ...updates } })

const insertItem = <Item>(list: Item[], index: number, item: Item) => [
  ...list.slice(0, index + 1),
  item,
  ...list.slice(index + 1),
]

const useForm = () => {
  const [_, msgr] = useMsgs()
  const { storeId, store, loading, refetch } = useWorkspaceSettingsContext()
  const [shippingRates, setShippingRates] = useState<EditableShippingRate[]>()
  const [editing, setEditing] = useState(false)
  const [error, setError] = useState(false)
  const [saving, setSaving] = useState(false)
  const [update] = useUpdateShippingRatesMutation()

  const populateShippingRates = () => {
    if (store) setShippingRates(store.shippingRates.map((sr) => ({ ...sr, id: uuid() })))
  }

  useEffect(() => {
    if (!shippingRates) populateShippingRates()
  }, [loading])

  const cannotSave = shippingRates
    ? shippingRates.some((sr, index) => {
        const last = index === shippingRates.length - 1
        return last ? !sr.shipping : !sr.shipping || !sr.subtotal
      })
    : false

  const startEditing = () => setEditing(true)

  const updateSubtotal = (index: number, subtotal: Money | null) => {
    if (shippingRates) setShippingRates(updateItem(shippingRates, index, { subtotal }))
  }

  const updateShipping = (index: number, shipping: Money | null) => {
    if (shippingRates) setShippingRates(updateItem(shippingRates, index, { shipping }))
  }

  const insertBelow = (index: number) => {
    if (shippingRates)
      setShippingRates(
        insertItem(shippingRates, index, { id: uuid(), subtotal: null, shipping: null })
      )
  }

  const deleteRow = (index: number) => {
    if (shippingRates) {
      const newItems = [...shippingRates.slice(0, index), ...shippingRates.slice(index + 1)]
      setShippingRates(updateItem(newItems, newItems.length - 1, { subtotal: null }))
    }
  }

  const cancel = () => {
    populateShippingRates()
    setEditing(false)
    setError(false)
  }

  const save = () => {
    setError(false)
    if (!shippingRates) return
    setSaving(true)

    const updatedShippingRates = shippingRates.map((sr) => ({
      subtotal: sr.subtotal,
      shipping: sr.shipping as Exclude<typeof sr.shipping, null>,
    }))

    update({ variables: { storeId, shippingRates: updatedShippingRates } })
      .then(({ data }) => {
        if (data?.updateShippingRates) {
          setShippingRates(undefined)

          refetch().then(() => {
            setEditing(false)
            msgr.add('Shipping table rates updated.', 'positive')
          })
        } else setError(true)
      })
      .finally(() => setSaving(false))
  }

  return {
    shippingRates,
    editing,
    error,
    cannotSave,
    saving,
    startEditing,
    updateSubtotal,
    updateShipping,
    insertBelow,
    deleteRow,
    cancel,
    save,
  }
}

export default useForm
