// From the npm module country-state-city (removed the module because of its size)
interface Country {
  currency: string
  flag: string
  isoCode: string
  name: string
  phonecode: string
}
interface State {
  name: string
  isoCode: string
  countryCode: string
}

const countries: Country[] = [
  {
    currency: 'CAD',
    flag: '🇨🇦',
    isoCode: 'CA',
    name: 'Canada',
    phonecode: '1',
  },
  {
    currency: 'USD',
    flag: '🇺🇸',
    isoCode: 'US',
    name: 'United States',
    phonecode: '1',
  },
]

const states: Record<string, State[]> = {
  CA: [
    {
      name: 'Alberta',
      isoCode: 'AB',
      countryCode: 'CA',
    },
    {
      name: 'British Columbia',
      isoCode: 'BC',
      countryCode: 'CA',
    },
    {
      name: 'Manitoba',
      isoCode: 'MB',
      countryCode: 'CA',
    },
    {
      name: 'New Brunswick',
      isoCode: 'NB',
      countryCode: 'CA',
    },
    {
      name: 'Newfoundland and Labrador',
      isoCode: 'NL',
      countryCode: 'CA',
    },
    {
      name: 'Northwest Territories',
      isoCode: 'NT',
      countryCode: 'CA',
    },
    {
      name: 'Nova Scotia',
      isoCode: 'NS',
      countryCode: 'CA',
    },
    {
      name: 'Nunavut',
      isoCode: 'NU',
      countryCode: 'CA',
    },
    {
      name: 'Ontario',
      isoCode: 'ON',
      countryCode: 'CA',
    },
    {
      name: 'Prince Edward Island',
      isoCode: 'PE',
      countryCode: 'CA',
    },
    {
      name: 'Quebec',
      isoCode: 'QC',
      countryCode: 'CA',
    },
    {
      name: 'Saskatchewan',
      isoCode: 'SK',
      countryCode: 'CA',
    },
    {
      name: 'Yukon',
      isoCode: 'YT',
      countryCode: 'CA',
    },
  ],
  US: [
    {
      name: 'Alabama',
      isoCode: 'AL',
      countryCode: 'US',
    },
    {
      name: 'Alaska',
      isoCode: 'AK',
      countryCode: 'US',
    },
    {
      name: 'American Samoa',
      isoCode: 'AS',
      countryCode: 'US',
    },
    {
      name: 'Arizona',
      isoCode: 'AZ',
      countryCode: 'US',
    },
    {
      name: 'Arkansas',
      isoCode: 'AR',
      countryCode: 'US',
    },
    {
      name: 'Baker Island',
      isoCode: 'UM-81',
      countryCode: 'US',
    },
    {
      name: 'California',
      isoCode: 'CA',
      countryCode: 'US',
    },
    {
      name: 'Colorado',
      isoCode: 'CO',
      countryCode: 'US',
    },
    {
      name: 'Connecticut',
      isoCode: 'CT',
      countryCode: 'US',
    },
    {
      name: 'Delaware',
      isoCode: 'DE',
      countryCode: 'US',
    },
    {
      name: 'District of Columbia',
      isoCode: 'DC',
      countryCode: 'US',
    },
    {
      name: 'Florida',
      isoCode: 'FL',
      countryCode: 'US',
    },
    {
      name: 'Georgia',
      isoCode: 'GA',
      countryCode: 'US',
    },
    {
      name: 'Guam',
      isoCode: 'GU',
      countryCode: 'US',
    },
    {
      name: 'Hawaii',
      isoCode: 'HI',
      countryCode: 'US',
    },
    {
      name: 'Howland Island',
      isoCode: 'UM-84',
      countryCode: 'US',
    },
    {
      name: 'Idaho',
      isoCode: 'ID',
      countryCode: 'US',
    },
    {
      name: 'Illinois',
      isoCode: 'IL',
      countryCode: 'US',
    },
    {
      name: 'Indiana',
      isoCode: 'IN',
      countryCode: 'US',
    },
    {
      name: 'Iowa',
      isoCode: 'IA',
      countryCode: 'US',
    },
    {
      name: 'Jarvis Island',
      isoCode: 'UM-86',
      countryCode: 'US',
    },
    {
      name: 'Johnston Atoll',
      isoCode: 'UM-67',
      countryCode: 'US',
    },
    {
      name: 'Kansas',
      isoCode: 'KS',
      countryCode: 'US',
    },
    {
      name: 'Kentucky',
      isoCode: 'KY',
      countryCode: 'US',
    },
    {
      name: 'Kingman Reef',
      isoCode: 'UM-89',
      countryCode: 'US',
    },
    {
      name: 'Louisiana',
      isoCode: 'LA',
      countryCode: 'US',
    },
    {
      name: 'Maine',
      isoCode: 'ME',
      countryCode: 'US',
    },
    {
      name: 'Maryland',
      isoCode: 'MD',
      countryCode: 'US',
    },
    {
      name: 'Massachusetts',
      isoCode: 'MA',
      countryCode: 'US',
    },
    {
      name: 'Michigan',
      isoCode: 'MI',
      countryCode: 'US',
    },
    {
      name: 'Midway Atoll',
      isoCode: 'UM-71',
      countryCode: 'US',
    },
    {
      name: 'Minnesota',
      isoCode: 'MN',
      countryCode: 'US',
    },
    {
      name: 'Mississippi',
      isoCode: 'MS',
      countryCode: 'US',
    },
    {
      name: 'Missouri',
      isoCode: 'MO',
      countryCode: 'US',
    },
    {
      name: 'Montana',
      isoCode: 'MT',
      countryCode: 'US',
    },
    {
      name: 'Navassa Island',
      isoCode: 'UM-76',
      countryCode: 'US',
    },
    {
      name: 'Nebraska',
      isoCode: 'NE',
      countryCode: 'US',
    },
    {
      name: 'Nevada',
      isoCode: 'NV',
      countryCode: 'US',
    },
    {
      name: 'New Hampshire',
      isoCode: 'NH',
      countryCode: 'US',
    },
    {
      name: 'New Jersey',
      isoCode: 'NJ',
      countryCode: 'US',
    },
    {
      name: 'New Mexico',
      isoCode: 'NM',
      countryCode: 'US',
    },
    {
      name: 'New York',
      isoCode: 'NY',
      countryCode: 'US',
    },
    {
      name: 'North Carolina',
      isoCode: 'NC',
      countryCode: 'US',
    },
    {
      name: 'North Dakota',
      isoCode: 'ND',
      countryCode: 'US',
    },
    {
      name: 'Northern Mariana Islands',
      isoCode: 'MP',
      countryCode: 'US',
    },
    {
      name: 'Ohio',
      isoCode: 'OH',
      countryCode: 'US',
    },
    {
      name: 'Oklahoma',
      isoCode: 'OK',
      countryCode: 'US',
    },
    {
      name: 'Oregon',
      isoCode: 'OR',
      countryCode: 'US',
    },
    {
      name: 'Palmyra Atoll',
      isoCode: 'UM-95',
      countryCode: 'US',
    },
    {
      name: 'Pennsylvania',
      isoCode: 'PA',
      countryCode: 'US',
    },
    {
      name: 'Puerto Rico',
      isoCode: 'PR',
      countryCode: 'US',
    },
    {
      name: 'Rhode Island',
      isoCode: 'RI',
      countryCode: 'US',
    },
    {
      name: 'South Carolina',
      isoCode: 'SC',
      countryCode: 'US',
    },
    {
      name: 'South Dakota',
      isoCode: 'SD',
      countryCode: 'US',
    },
    {
      name: 'Tennessee',
      isoCode: 'TN',
      countryCode: 'US',
    },
    {
      name: 'Texas',
      isoCode: 'TX',
      countryCode: 'US',
    },
    {
      name: 'United States Minor Outlying Islands',
      isoCode: 'UM',
      countryCode: 'US',
    },
    {
      name: 'United States Virgin Islands',
      isoCode: 'VI',
      countryCode: 'US',
    },
    {
      name: 'Utah',
      isoCode: 'UT',
      countryCode: 'US',
    },
    {
      name: 'Vermont',
      isoCode: 'VT',
      countryCode: 'US',
    },
    {
      name: 'Virginia',
      isoCode: 'VA',
      countryCode: 'US',
    },
    {
      name: 'Wake Island',
      isoCode: 'UM-79',
      countryCode: 'US',
    },
    {
      name: 'Washington',
      isoCode: 'WA',
      countryCode: 'US',
    },
    {
      name: 'West Virginia',
      isoCode: 'WV',
      countryCode: 'US',
    },
    {
      name: 'Wisconsin',
      isoCode: 'WI',
      countryCode: 'US',
    },
    {
      name: 'Wyoming',
      isoCode: 'WY',
      countryCode: 'US',
    },
  ],
}

const getCountryByCode = (countryIsoCode: string) =>
  countries.find((country) => country.isoCode === countryIsoCode)

export default {
  countries,
  states,
  getCountryByCode,
}
