import {
  RequestScheduledMessagesDocument,
  useScheduleMessageMutation,
  useUpdateScheduledMessageMutation,
} from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import DateTimeSelector from '@/gf/components/next/DateTimeSelector'
import Field from '@/gf/components/next/forms/Field'
import TextArea from '@/gf/components/next/forms/TextArea'
import { DateTime } from 'luxon'
import { FormEvent, useMemo, useState } from 'react'

const defaultMessage = 'Hey, did you get a chance to review the quote I sent over yet?'

type FormValues = {
  message: string
  scheduledFor: DateTime
}

const getDefaultSchedule = () => {
  let scheduledFor = DateTime.now().plus({ hours: 4 }).startOf('hour')

  if (scheduledFor.hour > 17) {
    // next day 8am
    scheduledFor = DateTime.now().plus({ day: 1 }).startOf('day').plus({ hours: 8 })
  }

  if (scheduledFor.weekday === 6 || scheduledFor.weekday === 7) {
    // monday 8am
    scheduledFor = DateTime.now().plus({ days: 3 }).startOf('day').plus({ hours: 8 })
  }

  return scheduledFor
}

const ScheduleMessageForm = ({
  rfqId,
  scheduledMessageId,
  initialValues,
  onSuccess,
  onError,
  onCancel,
}: {
  rfqId?: string
  scheduledMessageId?: string
  initialValues?: { scheduledFor: DateTime; message: string }
  onSuccess: (scheduledFor: DateTime) => void
  onError: (err) => void
  onCancel: () => void
}) => {
  const [form, setForm] = useState<FormValues>(
    () =>
      initialValues ?? {
        message: defaultMessage,
        scheduledFor: getDefaultSchedule(),
      }
  )

  const [scheduleMessage, { loading: creating }] = useScheduleMessageMutation({
    refetchQueries: [RequestScheduledMessagesDocument],
  })

  const [updateScheduledMessage, { loading: updating }] = useUpdateScheduledMessageMutation({
    refetchQueries: [RequestScheduledMessagesDocument],
  })

  const updateValues = (changes: Partial<FormValues>) =>
    setForm((prev) => ({ ...prev, ...changes }))

  const validSchedule = useMemo(
    () => form.scheduledFor?.diffNow('minute').as('minutes') > 0,
    [form.scheduledFor]
  )

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (!validSchedule) {
      return
    }

    try {
      if (!rfqId && !scheduledMessageId) {
        throw new Error('Either rfqId or scheduledMessageId are required')
      }

      if (scheduledMessageId) {
        await updateScheduledMessage({
          variables: {
            scheduledMessageId,
            scheduledFor: form.scheduledFor.toUTC(),
            text: form.message,
          },
        })
      } else {
        await scheduleMessage({
          variables: {
            requestForQuoteId: rfqId as string,
            scheduledFor: form.scheduledFor.toUTC(),
            text: form.message,
          },
        })
      }

      onSuccess(form.scheduledFor)
    } catch (err) {
      console.error(err)
      onError(err)
    }
  }
  return (
    <form onSubmit={onSubmit} className="p-6 space-y-6 text-gray-900">
      <hgroup>
        <h4 className="text-xl font-medium">Schedule a follow up message</h4>
        <p className="mt-1 text-base text-gray-700">
          The message will only be sent if the customer has no activity on this quote.
        </p>
        <p className="mt-0.5 text-base text-gray-700">
          You can cancel the message at any time in the automations tab.
        </p>
      </hgroup>

      <div className="space-y-4">
        <TextArea
          value={form.message}
          onChange={(e) => updateValues({ message: e.target.value })}
          required
        />

        <Field
          error={
            !validSchedule
              ? `Select a date and time after ${DateTime.now().toLocaleString(DateTime.DATETIME_MED)}`
              : null
          }
        >
          <div className="inline-flex items-center gap-x-2">
            <DateTimeSelector
              value={form.scheduledFor}
              onChange={(scheduledFor) =>
                updateValues({
                  scheduledFor: scheduledFor ?? undefined,
                })
              }
              required
            />
            {form.scheduledFor && (
              <span className="ml-2 text-sm text-gray-500">({form.scheduledFor.toRelative()})</span>
            )}
          </div>
        </Field>
      </div>

      <div className="pt-2 flex gap-x-4 justify-between">
        <Action.S onClick={onCancel} className="flex-1">
          Cancel
        </Action.S>
        <Action.P
          type="submit"
          performing={creating || updating}
          disabled={!form.scheduledFor || !validSchedule || !form.message}
          className="flex-1"
        >
          Schedule Message
        </Action.P>
      </div>
    </form>
  )
}

export default ScheduleMessageForm
