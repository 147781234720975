import { CustomerAccountActivityQuery } from '@/dealers/_gen/gql'
import RfqStepBadgeNext from '@/dealers/components/RfqStepBadgeNext'
import Urls from '@/dealers/modules/Urls'
import Ghost from '@/gf/components/Ghost'
import Link from '@/gf/components/Link'
import Excavator from '@/gf/components/svgs/Excavator'
import Tooltip from '@/gf/components/Tooltip'
import Id from '@/gf/modules/Id'
import Time from '@/gf/modules/Time'
import { ExclamationIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import pluralize from 'pluralize'

const Loading = () => (
  <div className="mt-6 border rounded-lg overflow-hidden">
    <div className="flex justify-between items-center border-b p-2">
      <Ghost className="block h-8 rounded-xl w-52" />
      <Ghost className="block h-6 rounded-xl w-32" />
    </div>
    <div className="p-2">
      <Ghost className="block h-4 rounded-xl w-full mb-1" />
      <Ghost className="block h-4 rounded-xl w-full mb-1" />
      <Ghost className="block h-4 rounded-xl w-1/3 mb-1" />
    </div>

    <div className="bg-slate-50 p-2 flex justify-between">
      <Ghost className="h-4 w-33" />
      <Ghost className="h-4 w-48" />
    </div>
  </div>
)

const RequestCard = ({
  request,
  loading,
  className,
}: {
  request: CustomerAccountActivityQuery['paginatedRequestForQuotes']['entries'][number] | null
  loading: boolean
  className?: string
}) => {
  if (loading) {
    return <Loading />
  }

  if (!request) return null

  const machine = request.machines[0] ?? null

  return (
    <section className={classNames('border rounded-lg', className)}>
      <header className="flex justify-between items-center px-3 border-b">
        <div className="pb-2 pt-2">
          <h3 className="text-xl text-slate-600 leading-5">Latest Request</h3>
          <p className="text-gray-500 italic text-xs mt-1">{Time.toString(request.insertedAt)}</p>
        </div>
        <div>
          <RfqStepBadgeNext step={request.step} />
        </div>
      </header>
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="text-slate-600 flex-grow">
          <h4 className="text-xs text-zinc-400 font-bold tracking-wide uppercase pt-2 pb-1 pl-3">
            Request
          </h4>
          <ul className="px-3 -mt-1">
            {request.partRequests.map((pr) => (
              <li
                key={pr.id}
                className={classNames('w-full py-1.5 border-slate-100 border-t first:border-0')}
              >
                <div className="flex gap-x-4">
                  <span className="font-medium text-zinc-500 text-sm">
                    Part Number{' '}
                    <span className="text-slate-800 font-semibold">{pr.mpn || 'N/A'}</span>
                  </span>
                  <span className="font-semibold text-slate-800 tracking-wide text-sm">
                    {pr.quantity} {pluralize('item', pr.quantity)}
                  </span>
                </div>
                <p className="text-zinc-600 text-sm">{pr.description}</p>
              </li>
            ))}
          </ul>

          {request.partsRequest && (
            <>
              <h4 className="text-xs text-zinc-400 font-bold tracking-wide uppercase pt-2 pb-1 pl-3">
                Additional Details
              </h4>
              <p className="px-3 pb-2 leading-4 prose text-sm text-zinc-600">
                {request.partsRequest}
              </p>
            </>
          )}
        </div>

        {machine && (
          <div className="flex-shrink-0 sm:border-l px-3 w-full sm:w-52 py-2">
            <h4 className="text-xs text-zinc-400 font-bold tracking-wide uppercase pb-2">
              Machine
            </h4>

            <div className="text-medium text-slate-700 text-sm flex items-center gap-x-1">
              <Excavator className="text-gearflow" /> {machine.details.machine.make}{' '}
              {machine.details.machine.model} {machine.details.machine.year}
              {machine.machineDown && (
                <Tooltip text="Machine Down">
                  <ExclamationIcon className="inline-block text-amber-500 h-6 w-6" />
                </Tooltip>
              )}
            </div>
          </div>
        )}
      </div>
      <footer className="border-t rounded-b-lg bg-slate-50 flex justify-between items-center py-2 px-4">
        <Link.T
          to={Urls.requestForQuote(request.id)}
          title={`Request ID ${Id.shorten(request.id)}`}
        >
          View Request
        </Link.T>
      </footer>
    </section>
  )
}

export default RequestCard
