import Money from '@/gf/modules/Money'
import { CheckedOutQuery } from '@/buyers/_gen/gql'

type Order = Exclude<CheckedOutQuery['order'], null>

interface Item {
  item_id: string
  item_name: string
  quantity: number
  price: number
}

declare const window: Window & {
  dataLayer: {
    push: (data: Record<string, unknown>) => void
  }
}

const setTrackingUser = (id: string) => {
  window.dataLayer.push({ user_id: id })
}

const trackPurchase = (order: Order) => {
  window.dataLayer.push({ ecommerce: null })

  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: order.id,
      currency: 'USD',
      value: Money.toDecimal(order.total),
      items: order.storeOrders.reduce<Item[]>(
        (acc, so) => [
          ...acc,
          ...so.lineItems.map((lineItem) => ({
            item_id: lineItem.id,
            item_name: lineItem.product.name || '(unknown)',
            quantity: lineItem.quantity,
            price: Money.toDecimal(lineItem.discountedUnitPrice),
          })),
        ],
        []
      ),
    },
  })

  window.dataLayer.push({
    event: 'checked-out',
    transactionId: order.id,
    transactionTotal: Money.toDecimal(order.total),
  })
}

const trackSearch = (
  searchTerm: string,
  searchType:
    | 'global'
    | 'requests_page'
    | 'orders_page'
    | 'products_page'
    | 'customers_page'
    | 'customer_contacts_page'
    | 'inbox_page',
  meta?: { resultsFound?: number; responseTimeMs?: number }
) => {
  window.dataLayer.push({
    event: 'search',
    search_term: searchTerm,
    search_type: searchType,
    results_found: meta?.resultsFound ?? null,
    response_time_ms: meta?.responseTimeMs ?? null,
  })
}

// const trackQuoteSubmitted = () => {
//   window.dataLayer.push({
//     event: 'quote_submitted',
//   })
// }

const trackUploadQuote = (fileUrls: string[], results: number) => {
  window.dataLayer.push({
    event: 'upload_quote',
    file_urls: fileUrls,
    results,
  })
}

const trackCopyLink = (domain: 'buyers' | 'suppliers' | 'admin') => {
  window.dataLayer.push({
    event: 'copy_link',
    domain,
  })
}

const updatePartsHubSession = (
  organizationId: string | undefined,
  companyName: string | undefined,
  userId: string,
  userName: string | undefined,
  permissionGroup: string | undefined,
  xray: boolean
) => {
  window.dataLayer.push({
    event: 'session_updated',
    ...(organizationId ? { organization_id: organizationId } : {}),
    ...(companyName ? { company_name: companyName } : {}),
    user_id: userId,
    ...(userName ? { user_name: userName } : {}),
    ...(permissionGroup ? { permission_group: permissionGroup } : {}),
    xray,
  })
}

const updateSalesHubSession = (
  storeId: string | undefined,
  storeName: string | undefined,
  userId: string,
  userName: string | undefined,
  xray: boolean
) => {
  window.dataLayer.push({
    event: 'sh_session_updated',
    ...(storeId ? { store_id: storeId } : {}),
    ...(storeName ? { store_name: storeName } : {}),
    user_id: userId,
    ...(userName ? { user_name: userName } : {}),
    xray,
  })
}

export {
  setTrackingUser,
  trackPurchase,
  trackSearch,
  // trackQuoteSubmitted,
  trackUploadQuote,
  trackCopyLink,
  updatePartsHubSession,
  updateSalesHubSession,
}
