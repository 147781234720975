import { useEffect, useState } from 'react'
import { US_CENTER } from '../modules/Map'

export default function useCurrentPosition(): {
  loading: boolean
  coords: GeolocationCoordinates | null
} {
  const [coords, setCoords] = useState<GeolocationCoordinates | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!window.navigator) {
      return
    }

    navigator.geolocation.getCurrentPosition(
      (currentPosition) => {
        setCoords(currentPosition.coords)
        setLoading(false)
      },
      (err) => {
        console.error(err)
        // permission denied error on local environment returns US center
        if (err.code === 1 && window.location.hostname.indexOf('.local.gearflow')) {
          setCoords({
            accuracy: 0,
            latitude: US_CENTER.lat,
            longitude: US_CENTER.lng,
            altitude: null,
            altitudeAccuracy: null,
            heading: null,
            speed: null,
          } as GeolocationCoordinates)
        }
        setLoading(false)
      },
      {
        timeout: 1000,
      }
    )
  }, [])

  return { loading, coords }
}
