const SelectedEmptyChartDot = ({
  size,
  borderColor,
  borderWidth,
  color,
  opacity,
}: {
  size: number
  borderColor: string
  borderWidth: number
  color: string
  opacity: number
}) => (
  <g>
    {/* Chart Dot */}
    <circle
      r={size * 0.65}
      cx={0}
      cy={0}
      fill="#ffffff"
      strokeWidth={borderWidth}
      stroke={color}
      opacity={opacity}
    />
    {/* Empty Outline */}
    <circle
      r={size}
      cx={0}
      cy={0}
      fill="#ffffff00"
      strokeWidth={borderWidth}
      stroke={borderColor}
      opacity={opacity}
    />
    {/* Selected Outline */}
    <circle
      r={size * 1.4}
      cx={0}
      cy={0}
      fill="#ffffff00"
      strokeWidth={borderWidth}
      stroke="#2563eb"
      opacity={opacity}
    />
  </g>
)

export default SelectedEmptyChartDot
