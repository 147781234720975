import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMachinesSelectQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSetStoreOrderAccountMachines from '@/buyers/hooks/useSetStoreOrderAccountMachines'
import useMsgs from '@/gf/hooks/useMsgs'
import { useOrderContext } from '../context'

import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import BigSelect from '@/gf/components/BigSelect'
import Form from '@/gf/components/Form'
import Modal from '@/gf/components/ModalNext'

const EditMachine = () => {
  const { orgId } = useSession()
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const [_, msgr] = useMsgs()
  const navigate = useNavigate()
  const [machineId, setMachineId] = useState(storeOrder.orgMachines.at(0)?.id || null)
  const [performing, setPerforming] = useState(false)
  const [setStoreOrderAccountMachines] = useSetStoreOrderAccountMachines()

  const { data, refetch, loading } = useMachinesSelectQuery({
    variables: { orgId, value: null, id: machineId },
    client: useGqlClient(),
    notifyOnNetworkStatusChange: true,
  })

  const close = () => navigate(`/orders/${storeOrder.id}`)

  const options = data?.machinesSearch.map((m) => ({
    value: m.id,
    label: `${m.name}, ${m.machine.make}, ${m.machine.model}, ${m.serialNumber}`,
  }))

  const onSubmit = () => {
    setPerforming(true)

    const accountMachineIds = machineId ? [machineId] : []

    setStoreOrderAccountMachines({
      variables: { storeOrderId: storeOrder.id, accountMachineIds },
    })
      .then(() => {
        close()
        msgr.add('Machine saved.', 'positive')
        refetchStoreOrder()
      })
      .catch(() => msgr.addUnknownError())
      .finally(() => setPerforming(false))
  }

  return (
    <Modal onClose={close} overflowHidden={false}>
      <Form onSubmit={onSubmit} className="p-6 space-y-4">
        <div className="text-xl">Edit Machine</div>

        <div>
          <BigSelect
            options={options}
            filter={(term) => refetch({ value: term, id: null })}
            loading={loading}
            selectedValue={machineId}
            setSelectedValue={setMachineId}
          />
        </div>

        <div className="flex gap-2 flex-row-reverse">
          <Action.P type="submit" performing={performing}>
            Save
          </Action.P>

          <Action.S onClick={close}>Cancel</Action.S>
        </div>
      </Form>
    </Modal>
  )
}

export default EditMachine
