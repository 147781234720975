import { Switch as ReactSwitch } from '@headlessui/react'
import classNames from 'classnames'

const Switch = ({
  checked,
  onChange,
  checkedClassName,
}: {
  checked: boolean
  onChange: (newChecked: boolean) => void
  checkedClassName?: string
}) => (
  <ReactSwitch
    checked={checked}
    onChange={onChange}
    className={classNames(
      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
      checked ? (checkedClassName ?? 'bg-blue-600') : 'bg-gray-200'
    )}
  >
    <span className="sr-only">Use setting</span>
    <span
      aria-hidden="true"
      className={`pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ${
        checked ? 'translate-x-5' : 'translate-x-0'
      }`}
    />
  </ReactSwitch>
)

export default Switch
