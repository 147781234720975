import { StripeAccountCapabilityStatus } from '@/dealers/_gen/gql'
import CategoryList from '@/dealers/components/CategoryList'
import useCreateStripeAccount from '@/dealers/hooks/useCreateStripeAccount'
import useSession from '@/dealers/hooks/useSession'
import useStripeOnboarding from '@/dealers/hooks/useStripeOnboarding'
import Action from '@/gf/components/Action'
import BrandList from '@/gf/components/BrandList'
import Field from '@/gf/components/Field'
import StateList from '@/gf/components/StateList'
import { StateCode } from '@/types'
import { useEffect, useState } from 'react'
import { H2, Section } from '../WorkspaceSettings'
import { useWorkspaceSettingsContext } from './context'

const Network = () => {
  const { featureFlags } = useSession()

  const { store, storeId, brands, categories, refetch, updateWorkspace } =
    useWorkspaceSettingsContext()

  const { start, starting } = useStripeOnboarding({ storeId, onFinished: refetch })
  const { create, creating } = useCreateStripeAccount({ storeId, startOnboarding: start })

  const [fields, setFields] = useState<{
    rfqStates: StateCode[]
    brandIds: string[]
    categoryIds: string[]
  }>({
    rfqStates: [],
    brandIds: [],
    categoryIds: [],
  })

  useEffect(() => {
    if (store)
      setFields({
        rfqStates: [...(store.rfqStates as StateCode[])].sort(),
        brandIds: store.brands.map((b) => b.id),
        categoryIds: store.categories.map((c) => c.id),
      })
  }, [!store])

  const updateFields = (updates: Partial<typeof fields>) => {
    setFields({ ...fields, ...updates })
    updateWorkspace(updates)
  }

  if (!store) return null

  return (
    <Section>
      <H2>Supplier Network Settings</H2>

      {featureFlags.shStripeAccountSettings &&
        (store.stripeAccount ? (
          store.stripeAccount.capabilities.transfers !== StripeAccountCapabilityStatus.Active && (
            <Action.T onClick={start} className="flex gap-1 items-center">
              <span>Continue Stripe Account Setup</span>

              {starting && (
                <div className="animate-spin-slow border-t-blue-500 rounded-full border-2 border-gray-200 h-4 w-4 shrink-0" />
              )}
            </Action.T>
          )
        ) : (
          <div className="space-y-2">
            <p className="text-sm">Gearflow partners with Stripe for secure payments.</p>

            <Action.P onClick={() => create().then(refetch)} performing={creating}>
              Setup Stripe Account
            </Action.P>
          </div>
        ))}

      <div className="max-w-sm space-y-6">
        <Field label="Restricted States">
          <StateList
            stateCodes={fields.rfqStates}
            setStateCodes={(rfqStates) => updateFields({ rfqStates })}
          />
        </Field>

        <Field label="Brands">
          <BrandList
            brands={brands}
            brandIds={fields.brandIds}
            setBrandIds={(brandIds) => updateFields({ brandIds })}
          />
        </Field>

        <Field label="Categories">
          <CategoryList
            categories={categories}
            categoryIds={fields.categoryIds}
            setCategoryIds={(categoryIds) => updateFields({ categoryIds })}
          />
        </Field>
      </div>
    </Section>
  )
}

export default Network
