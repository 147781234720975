import MoneyM from '@/gf/modules/Money'
import { Maybe, Money } from '@/types'
import { useMemo } from 'react'

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

const useQuoteTotals = ({
  items,
  taxAmount,
  shippingAmount,
  customerDiscount,
  customerFeeRate,
}: {
  items: { unitPrice: Maybe<number>; quantity: Maybe<number> }[]
  taxAmount: Maybe<Money>
  shippingAmount: Maybe<Money>
  customerDiscount: Maybe<number>
  customerFeeRate: number | null
}) => {
  const calculatedItems = useMemo(
    () =>
      items.map((item) => {
        const { unitPrice, quantity } = item
        if (!quantity || !unitPrice) return null
        const discountedUnitPrice = unitPrice * (1 - (customerDiscount || 0) / 100)

        return {
          unitPrice,
          discountedUnitPrice,
          extendedPrice: discountedUnitPrice * quantity,
          price: unitPrice * quantity,
        }
      }),
    [items, customerDiscount]
  )

  const totals = useMemo(() => {
    const subtotal = MoneyM.fromDecimal(
      calculatedItems.filter((item) => !!item).reduce((sum, item) => sum + (item?.price ?? 0), 0),
      'USD'
    )

    const discount = MoneyM.fromDecimal(
      calculatedItems
        .filter((item) => !!item)
        .reduce(
          (sum, item) =>
            sum + (item?.price && item?.extendedPrice ? item.price - item.extendedPrice : 0),
          0
        ),
      'USD'
    )

    const totalBeforeCustomerFee = [
      MoneyM.sub(subtotal, discount),
      shippingAmount || zeroPrice,
      taxAmount || zeroPrice,
    ].reduce(MoneyM.add)

    const customerFee =
      customerFeeRate !== null ? MoneyM.mult(totalBeforeCustomerFee, customerFeeRate) : null

    const total = customerFee
      ? MoneyM.add(totalBeforeCustomerFee, customerFee)
      : totalBeforeCustomerFee

    return { total, subtotal, discount, customerFee }
  }, [calculatedItems, taxAmount, shippingAmount])

  return { totals }
}

export default useQuoteTotals
