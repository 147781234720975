import Field from '@/gf/components/Field'
import TextArea from '@/gf/components/next/forms/TextArea'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useForm from './CompanyDetails/useForm'
import useUpdate from './CompanyDetails/useUpdate'
import Frame from './Frame'
import { useSetupContext } from './context'

const CompanyDetails = () => {
  const { loading } = useSetupContext()
  const navigate = useNavigate()
  const { fields, updateFields } = useForm()
  const { update, updating } = useUpdate(fields)
  const [savingAndExiting, setSavingAndExiting] = useState(false)

  const saveAndExit = async () => {
    setSavingAndExiting(true)
    await update()
    navigate('/dashboard')
    setSavingAndExiting(false)
  }

  const back = () => navigate('/setup/business-details')

  const next = async () => {
    await update()
    navigate('/setup/gearflow-supplier-network')
  }

  return (
    <Frame
      className="space-y-6"
      progress={{ name: <>Company Details</>, mins: 6, className: 'w-1/5' }}
      savingAndExiting={savingAndExiting}
      nexting={updating}
      saveAndExit={saveAndExit}
      back={back}
      next={next}
    >
      <div className="space-y-4">
        <h2 className="text-2xl font-medium">Tell us about your company</h2>

        <p className="prose">
          Add details about your company and policies.
          <br />
          This information will be visible to customers.
        </p>
      </div>

      <Field label="About Us">
        <TextArea
          value={fields.description}
          setValue={(description) => updateFields({ description })}
          placeholder={!loading && !fields.description ? 'An overview of your company.' : undefined}
          className="h-32"
        />
      </Field>

      <Field label="Return &amp; Warranty Policy">
        <TextArea
          value={fields.returnPolicy}
          setValue={(returnPolicy) => updateFields({ returnPolicy })}
          placeholder={
            !loading && !fields.returnPolicy
              ? 'Your return and warranty policy for customers to review when making a purchase.'
              : undefined
          }
          className="h-32"
        />
      </Field>

      <Field label="Shipping Policy">
        <TextArea
          value={fields.shippingInfo}
          setValue={(shippingInfo) => updateFields({ shippingInfo })}
          placeholder={
            !loading && !fields.shippingInfo
              ? 'Your shipping policy for customers to review when making a purchase.'
              : undefined
          }
          className="h-32"
        />
      </Field>
    </Frame>
  )
}

export default CompanyDetails
