import Uppy from '@uppy/core'
import classNames from 'classnames'
import { InputHTMLAttributes, useRef } from 'react'

import useToggle from '../hooks/useToggle'

const UppyFileInput = ({
  uppy,
  onFileInputError,
  allowedFileTypes,
  className,
  ...inputProps
}: {
  uppy: Uppy
  onFileInputError?: (error: string) => void
  allowedFileTypes?: string[]
} & Omit<
  React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'ref' | 'type' | 'accept' | 'onChange' | 'onDragLeave' | 'onDragOver' | 'onDragEnter' | 'onDrop'
>) => {
  const [fileDropFocused, fileDropFocusedToggler] = useToggle()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onFilesChange = (files: (File | null)[]) => {
    files.forEach((file) => {
      if (file) {
        try {
          // Clear the uploaded file, because we only want one
          uppy.cancelAll()
          uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
          })
          if (fileInputRef.current) {
            const dataTransfer = new DataTransfer()
            dataTransfer.items.add(file)
            fileInputRef.current.files = dataTransfer.files
            fileInputRef.current.files = dataTransfer.files
          }
        } catch (error) {
          const err = error as { isRestriction: boolean }
          if (onFileInputError)
            onFileInputError(err.isRestriction ? `${err}` : 'Error attaching file')
        }
      }
    })
  }

  return (
    <input
      {...inputProps}
      ref={fileInputRef}
      className={classNames(
        'p-2 text-sm rounded-md border-gray-300 border shadow-sm focus:border-indigo-500 focus:ring-indigo-500',
        {
          'ring-1 ring-orange-500 border-orange-500': fileDropFocused,
        },
        className
      )}
      type="file"
      accept={allowedFileTypes?.join(',')}
      onChange={(event) => onFilesChange(Array.from(event.target.files || []))}
      onDragLeave={(e) => {
        e.preventDefault()
        fileDropFocusedToggler.off()
      }}
      onDragOver={(e) => {
        e.preventDefault()
        fileDropFocusedToggler.on()
      }}
      onDragEnter={(e) => e.preventDefault()}
      onDrop={(event) => {
        event.preventDefault()
        fileDropFocusedToggler.off()
        const files = event.dataTransfer.items
          ? Array.from(event.dataTransfer.items)
              .filter((item) => item.kind === 'file')
              .map((item) => item.getAsFile())
          : Array.from(event.dataTransfer.files)
        onFilesChange(files)
      }}
    />
  )
}

export default UppyFileInput
