/**
 * SortByHeaderButton
 *
 * Button for the table headers to toggle sorting order by a column.
 */
import { SortByInput, SortOrder } from '@/dealers/_gen/gql'
import { ChevronDownIcon, SwitchVerticalIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { ReactNode } from 'react'

const inverseOrder = (order: SortOrder): SortOrder =>
  order === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc

const SortByHeaderButton = ({
  field,
  display,
  sortBy,
  setSortBy,
}: {
  field: string
  display: ReactNode
  sortBy: SortByInput
  setSortBy: (sortBy: SortByInput) => void
}) => (
  <button
    className="inline-flex flex-row items-center text-xs font-bold text-gray-500 uppercase tracking-wider whitespace-nowrap"
    type="button"
    onClick={() =>
      setSortBy({
        field,
        order: sortBy.field !== field ? SortOrder.Desc : inverseOrder(sortBy.order),
      })
    }
  >
    {display}

    {sortBy.field === field ? (
      <ChevronDownIcon
        className={classNames('w-5 min-w-5 h-5 ml-0.5 text-gray-500', {
          'rotate-180': sortBy.order !== SortOrder.Desc,
        })}
      />
    ) : (
      <SwitchVerticalIcon className="w-5 h-5 ml-1 text-gray-300" />
    )}
  </button>
)

export default SortByHeaderButton
