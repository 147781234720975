import {
  JsonParam,
  QueryParamConfigMap,
  StringParam,
  withDefault,
  useQueryParams,
} from 'use-query-params'
import { Maybe, OrderBy } from '@/types'
import usePage from '@/gf/hooks/usePage'
import useSession from '@/buyers/hooks/useSession'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import { useInvoicesAndCreditMemosListQuery, useInvoicesQuery } from '@/buyers/_gen/gql'
import Layout from '@/gf/components/Layout'
import Page from '@/gf/components/Page'
import InvoicesTable from './Invoices/InvoicesTable'
import Frame from '@/buyers/components/Frame'

const emptyFilters = {
  invoiceStatus: undefined,
  creditMemoStatus: undefined,
  invoiceBilledStatus: undefined,
}

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [{ name: 'Invoices', href: '/invoices' }],
}

export const queryParamConfigMap: QueryParamConfigMap = {
  invoiceStatus: withDefault(StringParam, null),
  invoiceBilledStatus: withDefault(StringParam, null),
  creditMemoStatus: withDefault(StringParam, null),
  orderBy: withDefault<OrderBy, OrderBy>(JsonParam, { id: 'due_date', order: 'asc' }),
  search: withDefault(StringParam, ''),
}

const Invoices = () => {
  const client = useGqlClient()
  const {
    user: { id: userId },
    organization,
  } = useSession()
  const [page, setPage] = usePage()
  const [{ orderBy, search, invoiceStatus, creditMemoStatus, invoiceBilledStatus }, updateQuery] =
    useQueryParams(queryParamConfigMap)

  const user = useInvoicesQuery({ variables: { userId }, client }).data?.user

  const { loading, error, data } = useInvoicesAndCreditMemosListQuery({
    client,
    variables: {
      orgId: organization.id,
      page,
      orderBy,
      search,
      invoiceStatus: invoiceStatus || null,
      creditMemoStatus: creditMemoStatus || null,
      invoiceBilledStatus: invoiceBilledStatus || null,
    },
  })

  const setSearch = (value: string) => {
    setPage(1)
    updateQuery({ ...emptyFilters, search: value })
  }

  const setOrderBy = (newOrderBy: OrderBy) => {
    setPage(1)
    updateQuery({ orderBy: newOrderBy })
  }

  const setInvoiceStatus = (newStatus: Maybe<string>) => {
    setPage(1)
    updateQuery({ ...emptyFilters, invoiceStatus: newStatus })
  }

  const setCreditMemoStatus = (newStatus: Maybe<string>) => {
    setPage(1)
    updateQuery({ ...emptyFilters, creditMemoStatus: newStatus })
  }

  const setinvoiceBilledStatus = (newStatus: Maybe<string>) => {
    setPage(1)
    updateQuery({ ...emptyFilters, invoiceBilledStatus: newStatus })
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page
        title="Invoices"
        subtitle={
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              These are all the invoices across your Account &apos;{organization.name}&apos;
            </p>
          </div>
        }
      >
        <Layout>
          {error ? (
            <Layout.Section type="full">
              <p className="text-sm">Failed to fetch invoices. Please contact support.</p>
            </Layout.Section>
          ) : (
            <>
              {user && (
                <InvoicesTable
                  entries={data?.invoicesAndCreditMemos?.entries ?? []}
                  loading={loading}
                  search={search}
                  page={page}
                  updateSearch={setSearch}
                  updatePage={setPage}
                  pagination={data?.invoicesAndCreditMemos?.pagination}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  canPay={user.can.payInvoices}
                  invoiceStatus={invoiceStatus}
                  updateInvoiceStatus={setInvoiceStatus}
                  creditMemoStatus={creditMemoStatus}
                  updateCreditMemoStatus={setCreditMemoStatus}
                  invoiceBilledStatus={invoiceBilledStatus}
                  updateInvoiceBilledStatus={setinvoiceBilledStatus}
                />
              )}
            </>
          )}
        </Layout>
      </Page>
    </Frame>
  )
}

export default Invoices
