import Page from '@/gf/components/Page'
import Tabs from '@/gf/components/Tabs'
import { Navigate, Route, Routes, matchPath, useLocation, useNavigate } from 'react-router-dom'
import Frame from '../components/Frame'
import useQuoteContext from '../hooks/useQuoteContext'
import useSession from '../hooks/useSession'
import Notifications from './Settings/Notifications'
import Organization from './Settings/Organization'
import Profile from './Settings/Profile'
import Users from './Settings/Users'
import WorkspaceSettings from './Settings/WorkspaceSettings'

const tabs = [
  { name: 'Profile', path: '/settings/profile' },
  { name: 'Notifications', path: '/settings/notifications' },
  { name: 'Organization', path: '/settings/organization' },
  { name: 'Users', path: '/settings/users' },
  { name: 'Workspace Settings', path: '/settings/workspace' },
]

const Settings = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { fullExperience } = useSession()
  const quoteContext = useQuoteContext()
  const currentTab = tabs.find((t) => !!matchPath({ path: t.path }, location.pathname)) || tabs[0]

  const onTabSelect = (selectedTab: string) => {
    const path = tabs.find((t) => t.name === selectedTab)?.path as string
    navigate(path)
  }

  return (
    <Frame>
      <Page
        title="Settings"
        previousPage={!fullExperience && quoteContext.path ? quoteContext.path : undefined}
      >
        <div className="mt-4">
          <Tabs tabs={tabs} currentTab={currentTab} onTabSelect={onTabSelect} />
        </div>

        <div className="mt-8 pb-10">
          <Routes>
            <Route path="" element={<Navigate to="profile" replace />} />
            <Route path="profile" element={<Profile />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="organization" element={<Organization />} />
            <Route path="users/*" element={<Users />} />
            <Route path="workspace/*" element={<WorkspaceSettings />} />
          </Routes>
        </div>
      </Page>
    </Frame>
  )
}

export default Settings
