import AddressInput from '@/buyers/pages/Setup/AddressInput'
import LogoInput from '@/buyers/pages/Setup/Business/LogoInput'
import Field from '@/gf/components/Field'
import TextInput from '@/gf/components/inputs/Text'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useForm from './BusinessDetails/useForm'
import useUpdate from './BusinessDetails/useUpdate'
import Frame from './Frame'

const BusinessDetails = () => {
  const navigate = useNavigate()
  const { fields, updateFields, updateLogoUrl } = useForm()
  const { update, updating, errors } = useUpdate(fields)
  const [savingAndExiting, setSavingAndExiting] = useState(false)

  const saveAndExit = async () => {
    setSavingAndExiting(true)
    await update()
    navigate('/dashboard')
    setSavingAndExiting(false)
  }

  const next = async () => {
    if (await update()) navigate('/setup/company-details')
  }

  return (
    <Frame
      className="space-y-6"
      progress={{ name: <>Business Details</>, mins: 7, className: 'w-0' }}
      savingAndExiting={savingAndExiting}
      nexting={updating}
      saveAndExit={saveAndExit}
      next={next}
    >
      <div className="space-y-4">
        <h2 className="text-2xl font-medium">Tell us about your business</h2>
        <p className="prose">Begin by adding your business name, logo, and location.</p>
      </div>

      <Field label="Business Name" errors={errors?.name}>
        <TextInput value={fields.name} setValue={(name) => updateFields({ name })} />
      </Field>

      <Field label="Business Logo (optional)">
        <span className="py-2 text-sm text-gray-500">
          Adding your logo helps your customers quickly identify you.
        </span>
        <LogoInput url={fields.logoUrl} setUrl={updateLogoUrl} />
      </Field>

      <Field label="Business Address" errors={errors?.address}>
        <AddressInput value={fields.address} onChange={(address) => updateFields({ address })} />
      </Field>
    </Frame>
  )
}

export default BusinessDetails
