import { DeliveryMethod } from '@/buyers/_gen/gql'
import { Priority } from '@/buyers/pages/CreateRequest/types'
import { Maybe } from '@/types'
import qs from 'query-string'
import { matchPath } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { staticConfig } from '../providers/ConfigProvider'

const PLATFORM = 'web'

export enum UserFlow {
  CreateRequest,
  AccountSetup,
  CreateQuote,
  EditQuote,
  CreateQuoteFromScratch,
}

type GrammarObject = {
  type: string
  id: string
}

type Event = {
  subject: GrammarObject
  verb: string
  directObject: GrammarObject
  indirectObject?: GrammarObject
  prepositionalObject?: GrammarObject
  sentence: string
  context?: Record<string, string | boolean | number | null | EventSource>
}

let userId: Maybe<string> = null
let app: Maybe<string> = null

export function init(initUserId: string, initApp: 'parts-hub' | 'sales-hub') {
  if (userId === null) userId = initUserId
  if (app === null) app = initApp
}

export function register(event: Event) {
  const body = {
    subject_id: event.subject.id,
    subject_type: event.subject.type,
    verb: event.verb,
    direct_object_type: event.directObject.type,
    direct_object_id: event.directObject.id,
    indirect_object_type: event.indirectObject?.type,
    indirect_object_id: event.indirectObject?.id,
    prepositional_object_type: event.prepositionalObject?.type,
    prepositional_object_id: event.prepositionalObject?.id,
    sentence: event.sentence,
    context: event.context,
  }

  return fetch(`${staticConfig.gfBaseUrl}/events`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

export function initiatesRequest() {
  const flowId = createFlowId(UserFlow.CreateRequest)

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'initiates',
    directObject: { type: 'flow', id: 'create_request' },
    sentence: '[S] initiates [DO]',
    context: {
      flow_id: flowId,

      ...commonContext(),
    },
  })
}

export function selectsOrgMachine(orgMachineId) {
  const flowId = getFlowId(UserFlow.CreateRequest)

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'selects',
    directObject: { type: 'org_machine', id: orgMachineId },
    prepositionalObject: { type: 'flow', id: 'create_request' },
    sentence: '[S] selects [DO] for [PO]',
    context: {
      flow_id: flowId as string,

      ...commonContext(),
    },
  })
}

export function clicksButtonOnFlow(buttonId: string, flow: UserFlow) {
  const flowId = getFlowId(flow)

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'clicks',
    directObject: { type: 'button', id: buttonId },
    indirectObject: { type: 'flow', id: 'create_request' },
    sentence: '[S] clicks [DO] on [IO]',
    context: {
      flow_id: flowId as string,

      ...commonContext(),
    },
  })
}

export function clicksButton(buttonId: string) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'clicks',
    directObject: { type: 'button', id: buttonId },
    sentence: '[S] clicks [DO]',
    context: commonContext(),
  })
}

export function selectsPriority(priority: Priority) {
  const flowId = getFlowId(UserFlow.CreateRequest)

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'selects',
    directObject: { type: 'parts_urgency', id: priority.toString() },
    indirectObject: { type: 'flow', id: 'create_request' },
    sentence: '[S] selects [DO] on [IO]',
    context: {
      flow_id: flowId as string,

      ...commonContext(),
    },
  })
}

export function selectsDealerLocation(
  dealerLocationId: string,
  listIndex: number,
  filters,
  isNew: boolean
) {
  const flowId = getFlowId(UserFlow.CreateRequest)

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'selects',
    directObject: { type: 'dealer_location', id: dealerLocationId },
    indirectObject: { type: 'flow', id: 'create_request' },
    sentence: '[S] selects [DO] on [IO]',
    context: {
      flow_id: flowId as string,
      is_new: isNew,
      list_index: listIndex,
      filters,
      ...commonContext(),
    },
  })
}

export function removesDealerLocation(dealerLocationId: string) {
  const flowId = getFlowId(UserFlow.CreateRequest)

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'removes',
    directObject: { type: 'dealer_location', id: dealerLocationId },
    indirectObject: { type: 'flow', id: 'create_request' },
    sentence: '[S] removes [DO] on [IO]',
    context: {
      flow_id: flowId as string,
      ...commonContext(),
    },
  })
}

export function viewsPage() {
  const common = commonContext()

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'views',
    directObject: { type: 'page', id: common.path + common.query },
    sentence: '[S] views [DO]',
    context: common,
  })
}

export function createsVendor(newVendorId: string) {
  const flowId = getFlowId(UserFlow.CreateRequest)

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'creates',
    directObject: { type: 'vendor', id: newVendorId },
    indirectObject: { type: 'flow', id: 'create_request' },
    sentence: '[S] creates [DO] on [IO]',
    context: {
      flow_id: flowId as string,
      ...commonContext(),
    },
  })
}

export function completesVendor(completedVendorId: string) {
  const flowId = getFlowId(UserFlow.CreateRequest)

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'completes',
    directObject: { type: 'vendor', id: completedVendorId },
    indirectObject: { type: 'flow', id: 'create_request' },
    sentence: '[S] creates [DO] on [IO]',
    context: {
      flow_id: flowId as string,
      ...commonContext(),
    },
  })
}

export function selectsVendorContact(contactId: string) {
  const flowId = getFlowId(UserFlow.CreateRequest)

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'selects',
    directObject: { type: 'vendor_contact', id: contactId },
    indirectObject: { type: 'flow', id: 'create_request' },
    sentence: '[S] selects [DO] on [IO]',
    context: {
      flow_id: flowId as string,
      ...commonContext(),
    },
  })
}

export function selectsDeliveryMethod(deliveryMethod: DeliveryMethod) {
  const flowId = getFlowId(UserFlow.CreateRequest)

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'selects',
    directObject: { type: 'delivery_method', id: deliveryMethod },
    indirectObject: { type: 'flow', id: 'create_request' },
    sentence: '[S] selects [DO] on [IO]',
    context: {
      flow_id: flowId as string,
      ...commonContext(),
    },
  })
}

export function createsRequest(rfqId: string) {
  const flowId = getFlowId(UserFlow.CreateRequest)

  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'creates',
    directObject: { type: 'request_for_quote', id: rfqId },
    indirectObject: { type: 'flow', id: 'create_request' },
    sentence: '[S] creates [DO] on [IO]',
    context: {
      flow_id: flowId as string,
      ...commonContext(),
    },
  })
}

export function createsCreditMemo(storeOrderId: string, creditMemoId: string) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'creates',
    directObject: { type: 'credit_memo', id: creditMemoId },
    prepositionalObject: { type: 'store_order', id: storeOrderId },
    sentence: '[S] creates [DO] for [PO]',
    context: commonContext(),
  })
}

export function receivesCreditMemo(creditMemoId: string) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'receives',
    directObject: { type: 'credit_memo', id: creditMemoId },
    sentence: '[S] receives [DO]',
    context: commonContext(),
  })
}

export function selectsBrandOnAccountSetup(
  brandId: string,
  listIndex: number,
  searchTerm: Maybe<string>
) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'selects',
    directObject: { type: 'brand', id: brandId },
    indirectObject: { type: 'flow', id: 'account_setup' },
    sentence: '[S] selects [DO] on [IO]',
    context: {
      list_index: listIndex,
      search_term: searchTerm || null,
      ...commonContext(),
    },
  })
}

export function deselectsBrandOnAccountSetup(
  brandId: string,
  listIndex: number,
  searchTerm: Maybe<string>
) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'deselects',
    directObject: { type: 'brand', id: brandId },
    indirectObject: { type: 'flow', id: 'account_setup' },
    sentence: '[S] deselects [DO] on [IO]',
    context: {
      list_index: listIndex,
      search_term: searchTerm || null,
      ...commonContext(),
    },
  })
}

export function addsBrandToOrg(brandId: string, orgId: string) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'adds',
    directObject: { type: 'brand', id: brandId },
    indirectObject: { type: 'organization', id: orgId },
    sentence: '[S] adds [DO] to [IO]',
    context: commonContext(),
  })
}

export function selectsDealerLocationOnAccountSetup(dealerLocationId: string, listIndex: number) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'selects',
    directObject: { type: 'dealer_location', id: dealerLocationId },
    indirectObject: { type: 'flow', id: 'account_setup' },
    sentence: '[S] selects [DO] on [IO]',
    context: {
      list_index: listIndex,
      ...commonContext(),
    },
  })
}

export function deselectsDealerLocationOnAccountSetup(dealerLocationId: string, listIndex: number) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'deselects',
    directObject: { type: 'dealer_location', id: dealerLocationId },
    indirectObject: { type: 'flow', id: 'account_setup' },
    sentence: '[S] deselects [DO] on [IO]',
    context: {
      list_index: listIndex,
      ...commonContext(),
    },
  })
}

export function manuallyAddsVendorOnAccountSetup(vendorId: string) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'selects',
    directObject: { type: 'vendor', id: vendorId },
    indirectObject: { type: 'flow', id: 'account_setup' },
    sentence: '[S] manually creates [DO] on [IO]',
    context: {
      ...commonContext(),
    },
  })
}

export function clicksButtonOnAccountSetup(buttonId: string) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'clicks',
    directObject: { type: 'button', id: buttonId },
    indirectObject: { type: 'flow', id: 'account_setup' },
    sentence: '[S] clicks [DO] on [IO]',
    context: commonContext(),
  })
}

export function draftsVendor(vendorId: string, dealerLocationId: string) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'drafts',
    directObject: { type: 'vendor', id: vendorId },
    prepositionalObject: { type: 'dealer_location', id: dealerLocationId },
    sentence: '[S] drafts [DO] from [PO]',
    context: commonContext(),
  })
}

export function viewsAccountSetupStep(step: string) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'views',
    directObject: { type: 'step', id: step },
    indirectObject: { type: 'flow', id: 'account_setup' },
    sentence: '[S] views [DO] on [IO]',
    context: commonContext(),
  })
}

export function completesAccountSetup() {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'completes',
    directObject: { type: 'flow', id: 'account_setup' },
    sentence: '[S] completes [DO]',
    context: commonContext(),
  })
}

export const initiatesCreateQuote = ({ rfqId }: { rfqId: string }) => {
  if (userId) {
    const flowId = getFlowId(UserFlow.CreateQuote) || createFlowId(UserFlow.CreateQuote)

    register({
      subject: { type: 'user', id: userId },
      verb: 'initiates',
      directObject: { type: 'flow', id: 'create_quote' },
      sentence: '[S] initiates [DO]',
      context: { flow_id: flowId, rfq_id: rfqId, ...commonContext() },
    })
  } else warnInit()
}

export const completesCreateQuote = ({
  rfqId,
  storeOrderId,
}: {
  rfqId: string
  storeOrderId: string
}) => {
  if (userId) {
    const flowId = getFlowId(UserFlow.CreateQuote)

    if (flowId)
      register({
        subject: { type: 'user', id: userId },
        verb: 'completes',
        directObject: { type: 'flow', id: 'create_quote' },
        sentence: '[S] completes [DO]',
        context: {
          flow_id: flowId,
          rfq_id: rfqId,
          store_order_id: storeOrderId,
          ...commonContext(),
        },
      })
  } else warnInit()
}

export const initiatesCreateQuoteFromScratch = () => {
  if (userId) {
    const flowId =
      getFlowId(UserFlow.CreateQuoteFromScratch) || createFlowId(UserFlow.CreateQuoteFromScratch)

    register({
      subject: { type: 'user', id: userId },
      verb: 'initiates',
      directObject: { type: 'flow', id: 'create_quote_from_scratch' },
      sentence: '[S] initiates [DO]',
      context: { flow_id: flowId, ...commonContext() },
    })
  } else warnInit()
}

export const completesCreateQuoteFromScratch = ({ storeOrderId }: { storeOrderId: string }) => {
  if (userId) {
    const flowId = getFlowId(UserFlow.CreateQuoteFromScratch)

    if (flowId)
      register({
        subject: { type: 'user', id: userId },
        verb: 'completes',
        directObject: { type: 'flow', id: 'create_quote_from_scratch' },
        sentence: '[S] completes [DO]',
        context: { flow_id: flowId, store_order_id: storeOrderId, ...commonContext() },
      })
  } else warnInit()
}

export const initiatesEditQuote = ({
  rfqId,
  storeOrderId,
}: {
  rfqId: string
  storeOrderId: string | undefined
}) => {
  if (userId) {
    const flowId = getFlowId(UserFlow.EditQuote) || createFlowId(UserFlow.EditQuote)

    register({
      subject: { type: 'user', id: userId },
      verb: 'initiates',
      directObject: { type: 'flow', id: 'edit_quote' },
      sentence: '[S] initiates [DO]',
      context: {
        flow_id: flowId,
        rfq_id: rfqId,
        ...(storeOrderId ? { store_order_id: storeOrderId } : {}),
        ...commonContext(),
      },
    })
  } else warnInit()
}

export const completesEditQuote = ({
  rfqId,
  storeOrderId,
}: {
  rfqId: string
  storeOrderId: string
}) => {
  if (userId) {
    const flowId = getFlowId(UserFlow.EditQuote)

    if (flowId)
      register({
        subject: { type: 'user', id: userId },
        verb: 'completes',
        directObject: { type: 'flow', id: 'edit_quote' },
        sentence: '[S] completes [DO]',
        context: {
          flow_id: flowId,
          rfq_id: rfqId,
          store_order_id: storeOrderId,
          ...commonContext(),
        },
      })
  } else warnInit()
}

export function selectsProduct(productId, input: string, search: string) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'selects',
    directObject: { type: 'product', id: productId },
    sentence: '[S] selects [DO]',
    context: {
      input,
      search,
      ...commonContext(),
    },
  })
}

export function searchesCollection(
  collectionName: string,
  search: string,
  duration: number,
  totalResults: Maybe<number>
) {
  if (!userId) {
    warnInit()
    return Promise.resolve(undefined)
  }

  return register({
    subject: { type: 'user', id: userId },
    verb: 'searches',
    directObject: { type: 'collection', id: collectionName },
    sentence: '[S] searches [DO]',
    context: {
      search,
      duration,
      total_results: totalResults,
      ...commonContext(),
    },
  })
}

export function whenPathMatches(path: string, callback: () => void) {
  if (matchPath({ path }, window.location.pathname)) {
    callback()
  }
}

/**
 * Calls the callback if current path matches /rfqs/create/*
 * This function can be handy when the same component is used in multiple pages
 * and you want to filter the event for a given URL
 * @param callback function to be called
 */
export function whenCreateRequestPath(callback: () => void) {
  whenPathMatches('/rfqs/create/*', callback)
}

export function getFlowId(flow: UserFlow) {
  return sessionStorage.getItem(flowIdKey(flow))
}

export function createFlowId(flow: UserFlow) {
  const id = uuid()
  sessionStorage.setItem(flowIdKey(flow), id)
  return id
}

export function clearFlowId(flow: UserFlow) {
  sessionStorage.removeItem(flowIdKey(flow))
}

function flowIdKey(flow: UserFlow) {
  switch (flow) {
    case UserFlow.CreateRequest:
      return 'create_request_user_flow_id'
    case UserFlow.AccountSetup:
      return 'account_setup_setup'
    case UserFlow.CreateQuote:
      return 'create_quote_flow_id'
    case UserFlow.CreateQuoteFromScratch:
      return 'create_quote_from_scratch_flow_id'
    case UserFlow.EditQuote:
      return 'edit_quote_flow_id'
    default:
      throw new Error('Cannot generate storage key for an unknown flow')
  }
}

export function commonContext() {
  const { pathname, search } = window.location

  const utmTags = search.indexOf('utm_') > -1 ? parseUtmTags(search) : {}
  const appSource =
    search.indexOf('source.path') > -1 || search.indexOf('source.button')
      ? parseAppSource(search)
      : {}

  return { path: pathname, query: search, platform: PLATFORM, app, ...utmTags, ...appSource }
}

function parseUtmTags(queryString) {
  const params = qs.parse(queryString)

  let tags: Record<string, string> = params.utm_medium
    ? { utm_medium: params.utm_medium as string }
    : {}
  tags = params.utm_campaign ? { ...tags, utm_campaign: params.utm_campaign as string } : {}
  tags = params.hubspot
    ? {
        ...tags,
        utm_source: 'hubspot',
      }
    : tags
  tags = params.utm_source
    ? {
        ...tags,
        utm_source: params.utm_source as string,
      }
    : tags
  return tags
}

function parseAppSource(queryString) {
  const params = qs.parse(queryString)

  const tags: Record<string, string> = params['source.button']
    ? { source_button: params['source.button'] as string }
    : {}

  return params['source.path'] ? { ...tags, source_path: params['source.path'] as string } : {}
}

function warnInit() {
  console.warn('GrammarEvents not initialized, call init() before dispatching events')
}
