import Box from '@/gf/components/Box'
import ImageGallery from '@/gf/components/ImageGallery'
import MachineInformation from '../MachineInformation'
import { useRfqContext } from '../context'
import SidebarLayout from '../SidebarLayout'

const RequestCancelled = () => {
  const { rfq } = useRfqContext()

  return (
    <SidebarLayout className="flex flex-col sm:flex-row gap-x-6 flex-grow">
      <Box className="shadow-base flex-grow py-8 px-6 flex flex-col gap-y-4">
        <div className="space-y-2">
          <h4 className="text-lg font-medium">
            {rfq.nonparticipation ? <>Can&apos;t Participate</> : 'Request Canceled'}
          </h4>

          {rfq.nonparticipation && (
            <p>
              Reason:{' '}
              {rfq.nonparticipation.reason === 'Other'
                ? rfq.nonparticipation.details
                : rfq.nonparticipation.reason}
            </p>
          )}
        </div>

        <MachineInformation reqMachine={rfq.machines[0] ?? null} />

        {rfq.partsRequest && (
          <p className="leading-5 text-sm prose whitespace-pre-wrap">
            <span className="text-gray-900 font-medium">Comments from the customer:</span>{' '}
            {rfq.partsRequest}
          </p>
        )}

        {rfq.images.length > 0 && <ImageGallery images={rfq.images.map((i) => i.url)} />}
      </Box>
    </SidebarLayout>
  )
}

export default RequestCancelled
