import { useContext } from 'react'

import MsgsContext from '@/gf/contexts/MsgsContext'

const useMsgs = () => {
  const result = useContext(MsgsContext)
  if (result === undefined) throw new Error('must be used within provider')
  return result
}

export default useMsgs
