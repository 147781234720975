import { useEffect, useState } from 'react'

import { useUpdateSpecialOrderNumberMutation } from '@/dealers/_gen/gql'
import useMsgs from '@/gf/hooks/useMsgs'
import { ModalSize } from '@/types'

import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import TextArea from '@/gf/components/TextArea'

const UpdateSpecialOrderNumberModal = ({
  open,
  onClose,
  storeOrderId,
  specialOrderNumber,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  storeOrderId: string
  specialOrderNumber: string | null
  onSuccess: () => void
}) => {
  const [_, msgs] = useMsgs()
  const [specialOrderInput, setSpecialOrderNumberInput] = useState('')
  const [specialOrderEta, setSpecialOrderNumberEta] = useState('')
  const [performing, setPerforming] = useState(false)
  const [updateSpecialOrderNumber] = useUpdateSpecialOrderNumberMutation()

  useEffect(() => {
    setSpecialOrderNumberInput(specialOrderNumber ?? '')
  }, [specialOrderNumber])

  return (
    <>
      <Modal open={open} onClose={onClose} size={ModalSize.SM}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setPerforming(true)
            updateSpecialOrderNumber({
              variables: { storeOrderId, specialOrderNumber: specialOrderInput, specialOrderEta },
            })
              .then(onSuccess)
              .catch(() => {
                msgs.add('Error, please contact support.', 'negative')
              })
              .finally(() => setPerforming(false))
          }}
          className="relative p-8 flex flex-col gap-y-6"
        >
          <CloseModalButton onClick={onClose} className="absolute top-2 right-2" />
          <h3 className="text-2xl font-medium pt-4">Special Order Number</h3>
          <Field label="Special Order Number">
            <TextInput
              value={specialOrderInput}
              onChange={(e) => setSpecialOrderNumberInput(e.target.value)}
            />
          </Field>
          <TextArea
            className="h-[6.5rem] min-h-[6.5rem]"
            placeholder="(optional) ETA"
            value={specialOrderEta ?? ''}
            onChange={(e) => setSpecialOrderNumberEta && setSpecialOrderNumberEta(e.target.value)}
          />
          <div className="flex gap-x-3">
            <Action.S onClick={onClose} className="w-1/2 font-medium">
              Cancel
            </Action.S>
            <Action.P type="submit" performing={performing} className="w-1/2 font-medium">
              Save
            </Action.P>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default UpdateSpecialOrderNumberModal
