import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { useAddRfqViewedReceiptMutation } from '../_gen/gql'

const useRfqViewedReceipt = () => {
  const [source, _] = useQueryParam('source', StringParam)
  const params = useParams() as { rfqId?: string }
  const [viewed] = useAddRfqViewedReceiptMutation()

  useEffect(() => {
    if (params.rfqId) {
      viewed({ variables: { rfqId: params.rfqId, source: source ?? null } })
    }
  }, [params.rfqId, source])
}

export default useRfqViewedReceipt
