import { SortOrder } from '@/dealers/_gen/gql'
import Link from '@/gf/components/LinkOld'
import { getDurationDatesString, getDurationDisplay } from '@/gf/components/Reports/DurationInput'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import Tag from '@/gf/components/Reports/Tag'
import Money from '@/gf/modules/Money'
import { ReportDuration, toParam } from '@/gf/modules/ReportDuration'
import orderBy from 'lodash/orderBy'
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { StoreOrder, filterStoreOrdersReceivedBetween } from '../PartsSales'
import { useContext } from './Context'

const HistoryTable = () => {
  const {
    filteredStoreOrders,
    orgById,
    fromDate,
    toDate,
    date,
    mpn,
    orgId,
    duration,
    ordersSort,
    chartTab,
    ranges,
    update,
  } = useContext()

  const sortField = ordersSort ? ordersSort[0] : 'created'
  const sortOrder = ordersSort ? (ordersSort[1] === 'desc' ? 'desc' : 'asc') : 'desc'
  const range = date && ranges.filter(([start, _]) => start.toISODate() === date.toISODate())[0]

  const unsortedRows = filteredStoreOrders
    ? range
      ? filterStoreOrdersReceivedBetween(filteredStoreOrders, range)
      : filteredStoreOrders
    : undefined

  const orderByField = (() => {
    if (sortField === 'total') return 'totals.total.amount'
    if (sortField === 'customer') return 'customer.organization.name'
    if (sortField === 'created') return 'originatedAt'
    return sortField
  })()

  const rows =
    unsortedRows && orderBy(unsortedRows, orderByField, sortOrder === 'desc' ? 'desc' : 'asc')

  const getOrderValue = useCallback(
    ({ request }: StoreOrder) =>
      request ? <Link to={`/rfqs/${request.id}`}>Request {request.shortId}</Link> : null,
    []
  )

  return (
    <div className="pt-6 flex flex-col gap-y-3 bg-white border border-gray-300 rounded-lg shadow-sm">
      <div className="flex gap-2 items-center px-4 text-lg text-gray-900 font-medium">
        <span>Orders</span>
        <span>&mdash;</span>

        {date ? (
          <Tag onRemove={() => update({ date: null })}>
            {chartTab === 'trend' && <>Week of</>} {date.toLocaleString(DateTime.DATE_MED)}
          </Tag>
        ) : duration !== ReportDuration.Custom ? (
          getDurationDisplay(toParam(duration))
        ) : (
          getDurationDatesString({ durationStart: fromDate, durationEnd: toDate })
        )}

        {orgId && (
          <Tag onRemove={() => update({ orgId: null })}>{orgById && orgById[orgId].name}</Tag>
        )}

        {mpn && <Tag onRemove={() => update({ mpn: null })}>MPN: {mpn}</Tag>}
      </div>

      <div className="px-4 pb-3 max-h-96 overflow-x-auto overflow-y-auto">
        <ReportingTable
          data={rows}
          getRowKey={({ id }) => id}
          sortBy={{
            sortBy: {
              field: sortField,
              order: sortOrder === 'desc' ? SortOrder.Desc : SortOrder.Asc,
            },
            setSortBy: (sortBy) => update({ ordersSort: sortBy }),
          }}
          columns={[
            { header: 'Order', getValue: getOrderValue },
            {
              header: 'Created',
              getValue: ({ originatedAt }) => originatedAt.toLocaleString(DateTime.DATETIME_MED),
              sortByField: 'created',
            },
            {
              header: 'Total',
              getValue: ({ totals }) => Money.format(totals.total, { maximumFractionDigits: 0 }),
              sortByField: 'total',
            },
            {
              header: 'Customer',
              getValue: ({ customer }) => customer.organization.name,
              sortByField: 'customer',
            },
          ]}
        />
      </div>
    </div>
  )
}

export default HistoryTable
