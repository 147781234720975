// bg-gray-50 (https://tailwindcss.com/docs/customizing-colors)
const BACKGROUND_COLOR = '#f9fafb'
const SECONDARY_LINE_COLOR = '#0ea5e970'

const DATA_COLORS = ['#60a5fa', '#2563eb', '#1e40af', '#172554']
const getDataColor = (index: number) => DATA_COLORS[index % DATA_COLORS.length]

export default {
  BACKGROUND_COLOR,
  SECONDARY_LINE_COLOR,
  DATA_COLORS,
  getDataColor,
}
