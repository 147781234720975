import { Link } from 'react-router-dom'
import type { Breadcrumbs } from '@/types'
import { ArrowNarrowLeftIcon, HomeIcon } from '@heroicons/react/outline'

const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: Breadcrumbs }) => (
  <div className="w-full px-4 sm:px-8">
    <div className="py-3">
      <nav className="flex" aria-label="Breadcrumb">
        <div className="flex sm:hidden">
          <a
            href="/"
            className="group inline-flex space-x-3 text-sm text-gray-500 hover:text-gray-700"
          >
            <ArrowNarrowLeftIcon
              className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-600"
              aria-hidden="true"
            />
            <span>{breadcrumbs.copy}</span>
          </a>
        </div>
        <div className="hidden sm:block">
          <ol className="flex items-center space-x-1">
            <li>
              <div>
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {breadcrumbs.crumbs.map((item: { href: string; name: string }) => (
              <li key={item.name}>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <Link
                    to={item.href}
                    key={item.name}
                    className="ml-1 text-sm text-gray-500 hover:text-gray-700"
                  >
                    {item.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </div>
      </nav>
    </div>
  </div>
)

export default Breadcrumbs
