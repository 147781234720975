import { ModalSize } from '@/types'

import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import TrialExpiredSplashContent from './TrialExpiredSplashContent'

const TrialExpiredModal = ({ open, onClose }: { open?: boolean; onClose: () => void }) => (
  <Modal open={open} onClose={onClose} size={ModalSize.LG}>
    <div className="p-12 relative">
      <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />
      <TrialExpiredSplashContent onClose={onClose} />
    </div>
  </Modal>
)

export default TrialExpiredModal
