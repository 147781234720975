import { useEffect, useState } from 'react'

import { useUpdateQuoteNumberMutation } from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import useMsgs from '@/gf/hooks/useMsgs'
import { ModalSize } from '@/types'

import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'

const UpdateQuoteNumberModal = ({
  open,
  onClose,
  storeOrderId,
  quoteNumber,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  storeOrderId: string
  quoteNumber: string | null
  onSuccess: () => void
}) => {
  const { store } = useSession()
  const [_, msgs] = useMsgs()
  const [quoteNumberInput, setQuoteNumberInput] = useState('')
  const [performing, setPerforming] = useState(false)
  const [updateQuoteNumber] = useUpdateQuoteNumberMutation()

  useEffect(() => {
    setQuoteNumberInput(quoteNumber ?? '')
  }, [quoteNumber])

  return (
    <>
      <Modal open={open} onClose={onClose} size={ModalSize.SM}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setPerforming(true)
            updateQuoteNumber({
              variables: { storeId: store.id, storeOrderId, quoteNumber: quoteNumberInput },
            })
              .then(onSuccess)
              .catch(() => {
                msgs.add('Error, please contact support.', 'negative')
              })
              .finally(() => setPerforming(false))
          }}
          className="relative p-8 flex flex-col gap-y-6"
        >
          <CloseModalButton onClick={onClose} className="absolute top-2 right-2" />
          <h3 className="text-2xl font-medium pt-4">Edit Quote Number</h3>
          <Field label="Quote Number">
            <TextInput
              value={quoteNumberInput}
              onChange={(e) => setQuoteNumberInput(e.target.value)}
            />
          </Field>
          <div className="flex gap-x-3">
            <Action.S onClick={onClose} className="w-1/2 font-medium">
              Cancel
            </Action.S>
            <Action.P type="submit" performing={performing} className="w-1/2 font-medium">
              Save
            </Action.P>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default UpdateQuoteNumberModal
