import Select from '@/gf/components/Select'
import { ReportDuration, fromParam, toDates, toDisplay, toParam } from '@/gf/modules/ReportDuration'
import TimeM from '@/gf/modules/Time'
import { DateTime } from 'luxon'

export const DATE_FORMAT = 'yyyy-MM-dd'
const durationOptions = [
  ReportDuration.ThisWeek,
  ReportDuration.LastWeek,
  ReportDuration.Last30Days,
  ReportDuration.Last90Days,
  ReportDuration.ThisMonth,
  ReportDuration.LastMonth,
  ReportDuration.Custom,
].map((d) => ({ id: toParam(d), display: toDisplay(d) }))

export const getDurationDisplay = (durationId: string) =>
  durationOptions.find(({ id }) => id === durationId)?.display

export const getDurationDatesString = ({
  durationStart,
  durationEnd,
}: {
  durationStart: DateTime
  durationEnd: DateTime
}) =>
  `${
    durationStart.year === durationEnd.year
      ? TimeM.formatDateNoYear(durationStart)
      : durationStart.toLocaleString(DateTime.DATE_MED)
  } - ${durationEnd.toLocaleString(DateTime.DATE_MED)}`

const getDurationDates = (id: string): { start: DateTime; end: DateTime } => {
  const [start, end] = toDates(fromParam(id), () => {
    const now = DateTime.now()
    return [now.minus({ days: 30 }), now]
  })

  return { start, end }
}

export const defaultDurationId = 'last-30-days'
export const defaultDurationDates = getDurationDates(defaultDurationId)

// TODO: debounce onChange for Date pickers
const DurationInput = ({
  start,
  end,
  durationId,
  onChange,
  showDates = false,
}: {
  start: DateTime
  end: DateTime
  durationId: string
  onChange: (durationDates: { durationId: string; start: DateTime; end: DateTime }) => void
  showDates?: boolean
}) => (
  <div className="flex flex-row gap-x-4 items-center">
    <div className="flex flex-row gap-x-2 items-center">
      <Select
        className="w-auto focus:ring-blue-600 !text-base !font-medium !text-gray-900"
        currentId={durationId}
        placeholder="(Select)"
        options={durationOptions}
        onChange={(id) => {
          if (id === 'custom') {
            onChange({ start, end, durationId: id })
          } else {
            const durationDates = getDurationDates(id)
            onChange({ ...durationDates, durationId: id })
          }
        }}
      />

      {durationId === 'custom' && (
        <div className="flex flex-row items-center gap-x-2 text-base text-gray-900">
          <input
            className="rounded-md border-gray-300 shadow-sm focus:ring-blue-600 text-base text-gray-900"
            type="date"
            value={start.toFormat(DATE_FORMAT)}
            onChange={(e) =>
              onChange({ start: DateTime.fromFormat(e.target.value, DATE_FORMAT), end, durationId })
            }
            max={end.toFormat(DATE_FORMAT)}
          />

          <span>to</span>

          <input
            className="rounded-md border-gray-300 shadow-sm focus:ring-blue-600 text-base text-gray-900"
            type="date"
            value={end.toFormat(DATE_FORMAT)}
            onChange={(e) =>
              onChange({ start, end: DateTime.fromFormat(e.target.value, DATE_FORMAT), durationId })
            }
            min={start.toFormat(DATE_FORMAT)}
          />
        </div>
      )}
    </div>
    {showDates && durationId !== 'custom' && (
      <span className="text-base text-gray-900 font-medium">
        {getDurationDatesString({ durationStart: start, durationEnd: end })}
      </span>
    )}
  </div>
)

export default DurationInput
