import useFrame from '@/buyers/hooks/useFrame'
import { todaysEventsfilters } from '@/buyers/providers/FrameProvider'
import EventIcon from '@/gf/components/EventIcon'
import Ghost from '@/gf/components/Ghost'
import Link from '@/gf/components/Link'
import SlideOver from '@/gf/components/SlideOver'
import useAllEvents from '@/gf/hooks/useAllEvents'
import useConfig from '@/gf/hooks/useConfig'
import RfqEvent from '@/gf/modules/RfqEvent'
import StoreOrderEvent from '@/gf/modules/StoreOrderEvent'
import Time from '@/gf/modules/Time'
import { LightningBoltIcon } from '@heroicons/react/outline'

const Loading = () => (
  <div className="flex flex-col">
    {[1, 2, 3].map((i) => (
      <div className="flex flex-col gap-y-1 border-b p-3" key={i}>
        <Ghost className="block h-6 w-full" />
        <Ghost className="block h-4 w-3/4" />
      </div>
    ))}
  </div>
)

const ActivitiesList = () => {
  const { data, loading } = useAllEvents({ page: 1, filters: todaysEventsfilters })
  const config = useConfig()

  const events = data?.events ?? []

  const getStoreOrderEventDisplayData = (storeOrderEvent) =>
    StoreOrderEvent.getBuyerEventDisplayData(storeOrderEvent)

  const getRequestForQuoteEventDisplayData = (requestForQuoteEvent) =>
    RfqEvent.getEventDisplayData(true, config)(requestForQuoteEvent)

  if (loading) {
    return <Loading />
  }

  if (events.length === 0) {
    return (
      <p className="w-full px-3 py-6 leading-6">
        There are no activities to show for today, so far. To see everything,{' '}
        <Link.T to="/notifications" className="font-medium">
          Show activity history
        </Link.T>
        .
      </p>
    )
  }

  return (
    <div className="flex-grow">
      <div className="h-full flex flex-col justify-between relative">
        <ul className="flex-grow overflow-y-auto">
          {events.map((event) => {
            const eventDisplayData = event.storeOrderEvent
              ? getStoreOrderEventDisplayData(event.storeOrderEvent)
              : getRequestForQuoteEventDisplayData(event.requestForQuoteEvent)
            return (
              <li
                key={(event.storeOrderEvent ?? event.requestForQuoteEvent).id}
                className="border-b last:border-0 p-3 "
              >
                <div className="flex gap-x-3">
                  <div className="flex-shrink-0">
                    <EventIcon eventDisplayDataIcon={eventDisplayData} />
                  </div>

                  <div className="flex-grow">
                    <div className="flex gap-x-3">
                      <p className="text-gray-500 leading-6 flex-grow">
                        {eventDisplayData.content}
                      </p>
                      <time
                        dateTime={Time.formatSimpleDateTime(eventDisplayData.datetime)}
                        className="text-xs flex-shrink-0 text-gray-500"
                      >
                        {eventDisplayData.datetime.toFormat('t')}
                      </time>
                    </div>

                    {eventDisplayData.subContent && (
                      <p className="text-sm text-gray-500">{eventDisplayData.subContent}</p>
                    )}

                    <div className="text-sm font-medium">
                      {event.storeOrderEvent ? (
                        <>
                          <Link.T to={`/orders/${event.storeOrderEvent.storeOrderId}`}>
                            Order {event.storeOrderEvent.storeOrderShortId}
                          </Link.T>
                        </>
                      ) : (
                        <Link.T to={`/rfqs/${event.requestForQuoteEvent.requestForQuoteId}`}>
                          RFQ {event.requestForQuoteEvent.requestForQuoteShortId}
                        </Link.T>
                      )}
                    </div>

                    {eventDisplayData.details && (
                      <div className="text-sm text-gray-500 mt-1">{eventDisplayData.details}</div>
                    )}

                    {eventDisplayData.hiddenDetails && (
                      <div className="text-sm text-gray-500 mt-1">
                        {eventDisplayData.hiddenDetails}
                      </div>
                    )}
                  </div>
                </div>
              </li>
            )
          })}
        </ul>

        <p className="w-full px-3 py-4 leading-6 sticky bottom-0 bg-white border-t">
          Showing your team today’s activities. To see everything,{' '}
          <Link.T to="/notifications" className="font-medium">
            Show activity history
          </Link.T>
          .
        </p>
      </div>
    </div>
  )
}

const ActivitiesPanel = ({ open, onClose }) => {
  const todayActivitiesCount = useFrame()?.todayActivitiesCount

  return (
    <SlideOver
      open={open}
      onClose={onClose}
      title={`Team Activity ${
        todayActivitiesCount && todayActivitiesCount > 0 ? `(${todayActivitiesCount})` : ''
      }`}
      titleIcon={<LightningBoltIcon className="h-6 w-6" />}
      size="sm"
      theme="white"
    >
      {open && <ActivitiesList />}
    </SlideOver>
  )
}

export default ActivitiesPanel
