import { gql, useMutation } from '@apollo/client'

const useSetStoreOrderAccountMachines = () =>
  useMutation<
    { setStoreOrderAccountMachines: string[] },
    { storeOrderId: string; accountMachineIds: string[] }
  >(gql`
    mutation SetStoreOrderAccountMachines($storeOrderId: String, $accountMachineIds: [String]) {
      setStoreOrderAccountMachines(
        storeOrderId: $storeOrderId
        accountMachineIds: $accountMachineIds
      )
    }
  `)

export default useSetStoreOrderAccountMachines
