// From floating-ui https://floating-ui.com/docs/tooltip
// Usage:
// <Tooltip>
//   <TooltipTrigger>My trigger</TooltipTrigger>
//   <TooltipContent>My tooltip</TooltipContent>
// </Tooltip>
import { cn } from '@/gf/modules/utils'
import type { Placement } from '@floating-ui/react'
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useDelayGroup,
  useDelayGroupContext,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import classNames from 'classnames'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

interface TooltipOptions {
  initialOpen?: boolean
  placement?: Placement
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

function useTooltip({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const { delay } = useDelayGroupContext()

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip(), shift()],
  })

  const { context } = data

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
    delay,
  })
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const interactions = useInteractions([hover, focus, dismiss, role])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  )
}

type ContextType = ReturnType<typeof useTooltip> | null

const TooltipContext = React.createContext<ContextType>(null)

const useTooltipState = () => {
  const context = React.useContext(TooltipContext)

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}

export function Tooltip({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options)
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}

export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const state = useTooltipState()

  const childrenRef = (children as any).ref
  const ref = useMergeRefs([state.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      state.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': state.open ? 'open' : 'closed',
      })
    )
  }

  return (
    <button
      ref={ref}
      type="button"
      className="cursor-default"
      // The user can style the trigger based on the state
      data-state={state.open ? 'open' : 'closed'}
      {...state.getReferenceProps(props)}
    >
      {children}
    </button>
  )
})

export const TooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  function TooltipContent(props, propRef) {
    const state = useTooltipState()
    const { isInstantPhase, currentId } = useDelayGroupContext()
    const ref = useMergeRefs([state.refs.setFloating, propRef])

    useDelayGroup(state.context, { id: state.context.floatingId })

    const instantDuration = 0
    const duration = 250

    const { isMounted, styles } = useTransitionStyles(state.context, {
      duration: isInstantPhase
        ? {
            open: instantDuration,
            // `id` is this component's `id`
            // `currentId` is the current group's `id`
            close: currentId === state.context.floatingId ? duration : instantDuration,
          }
        : duration,
      initial: {
        opacity: 0,
      },
    })

    if (!isMounted) return null

    const className = cn('z-50', props.className)

    return (
      <FloatingPortal>
        <div
          ref={ref}
          style={{
            ...state.floatingStyles,
            ...props.style,
            ...styles,
          }}
          {...state.getFloatingProps({ ...props, className })}
        />
      </FloatingPortal>
    )
  }
)

export const SimpleTooltip = ({
  children,
  text,
  className,
  placement,
  theme = 'dark',
}: {
  children: React.ReactNode
  text: string
  className?: string
  placement?: Placement
  theme?: 'light' | 'dark'
}) => (
  <Tooltip placement={placement}>
    <TooltipTrigger className="text-left cursor-default">{children}</TooltipTrigger>
    <TooltipContent>
      <p
        className={classNames(
          theme === 'light'
            ? 'bg-gray-50 border-gray-300 text-gray-900'
            : 'bg-gray-700 text-gray-50 border-gray-900',
          ' border px-3 py-2 text-sm rounded-md',
          className
        )}
      >
        {text}
      </p>
    </TooltipContent>
  </Tooltip>
)

export const SimpleContentTooltip = ({
  children,
  content,
  className,
  placement,
}: {
  children: React.ReactNode
  content: React.ReactNode
  className?: string
  placement?: Placement
}) => (
  <Tooltip placement={placement}>
    <TooltipTrigger className="text-left">{children}</TooltipTrigger>
    <TooltipContent>
      <div className={twMerge('bg-white rounded-md border shadow-md overflow-hidden', className)}>
        {content}
      </div>
    </TooltipContent>
  </Tooltip>
)
