import React from 'react'

import { Option } from '../../types'

type Props = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  setValue?: (value: string) => void
}

const classes = {
  default:
    'focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
}

export const BaseSelect = React.forwardRef(
  ({ setValue, className = '', ...props }: Props, ref: React.ForwardedRef<HTMLSelectElement>) => {
    const onChange = setValue
      ? (event: React.ChangeEvent<HTMLSelectElement>) => setValue(event.target.value)
      : props.onChange

    return (
      <select
        className={`${classes.default} ${className}`}
        ref={ref}
        onChange={onChange}
        {...props}
      />
    )
  }
)

const Select = ({
  options,
  currentId,
  placeholder,
  onChange,
  required,
  className,
}: {
  options: Option[]
  currentId: string | undefined
  placeholder?: string
  onChange: (selectedId: string) => void
  required?: boolean
  className?: string
}) => (
  <BaseSelect
    required={required}
    value={currentId}
    onChange={(e) => onChange(e.target.value)}
    className={`focus:ring-indigo-500 focus:border-indigo-500 block text-sm border-gray-300 rounded-md ${className}`}
  >
    {placeholder && (
      <option value="" disabled>
        {placeholder}
      </option>
    )}

    {options.map((option) => (
      <option key={option.id} value={option.id}>
        {option.display}
      </option>
    ))}
  </BaseSelect>
)

export default Select
