import { SortOrder } from '@/dealers/_gen/gql'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import Money from '@/gf/modules/Money'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { sumStoreOrdersTotal } from '../../PartsSales'
import { useContext } from '../Context'

const Customer = () => {
  const context = useContext()
  const { storeOrders, orgById, breakdownCustomerSort, update } = context
  const sortField = breakdownCustomerSort ? breakdownCustomerSort[0] : 'total'

  const sortOrder = breakdownCustomerSort
    ? breakdownCustomerSort[1] === 'desc'
      ? 'desc'
      : 'asc'
    : 'desc'

  const storeOrdersByOrgId =
    storeOrders && groupBy(storeOrders, (so) => so.customer.organization.id)

  const unsortedRows =
    storeOrdersByOrgId &&
    map(storeOrdersByOrgId, (subStoreOrders, orgId) => ({
      orgId,
      storeOrderCount: subStoreOrders.length,
      orgName: orgById && orgById[orgId].name,
      total: sumStoreOrdersTotal(subStoreOrders),
    }))

  const orderByField = (() => {
    if (sortField === 'customer') return 'orgName'
    if (sortField === 'orders') return 'storeOrderCount'
    if (sortField === 'total') return 'total.amount'
    return sortField
  })()

  const rows = unsortedRows && orderBy(unsortedRows, orderByField, sortOrder)

  return (
    <ReportingTable
      data={rows}
      getRowKey={({ orgId }) => orgId}
      checkbox={{
        getChecked: ({ orgId }) => orgId === context.orgId,
        onToggleRow: ({ orgId }) => update({ orgId: orgId !== context.orgId ? orgId : null }),
        onClear: () => update({ orgId: null }),
      }}
      sortBy={{
        sortBy: {
          field: sortField,
          order: sortOrder === 'desc' ? SortOrder.Desc : SortOrder.Asc,
        },
        setSortBy: (sortBy) => update({ breakdownCustomerSort: sortBy }),
      }}
      columns={[
        { header: 'Customer', getValue: (row) => row.orgName, sortByField: 'customer' },
        {
          header: 'Orders',
          getValue: (row) => row.storeOrderCount.toLocaleString(),
          sortByField: 'orders',
        },
        {
          header: 'Total',
          getValue: (row) => Money.format(row.total, { maximumFractionDigits: 0 }),
          sortByField: 'total',
        },
      ]}
    />
  )
}

export default Customer
