import { ReactNode } from 'react'

import type { MultiButtonAction } from '../MultiButton'

const ActionWrapper = ({
  action,
  setMultiOpen,
  children,
}: {
  action: MultiButtonAction
  setMultiOpen: (multiOpen: boolean) => void
  children: ReactNode
}) => (
  <button
    className="w-full"
    onClick={() => {
      setMultiOpen(false)
      action.onClick()
    }}
    type="button"
  >
    {children}
  </button>
)

export default ActionWrapper
