import Link from '@/gf/components/Link'
import { DateTime } from 'luxon'
import { Route, Routes } from 'react-router-dom'
import { StripeAccountCapabilityStatus, useDashboardQuery } from '../_gen/gql'
import Frame from '../components/Frame'
import useSession from '../hooks/useSession'
import Urls from '../modules/Urls'
import OnboardingTasks from './Dashboard/OnboardingTasks'
import SetupComplete from './Dashboard/SetupComplete'
import ValuePropBanner from './Dashboard/ValuePropBanner'

const Dashboard = () => {
  const {
    storeId,
    store: { subscription, onboarding },
    user,
  } = useSession()

  const { store } = useDashboardQuery({ variables: { storeId } }).data || {}
  const name = user.name ? user.name.split(' ')[0] : user.displayName

  const stripeSetupComplete =
    store?.stripeAccount?.capabilities.transfers === StripeAccountCapabilityStatus.Active

  return (
    <>
      <Routes>
        <Route path="setup-complete" element={<SetupComplete />} />
      </Routes>

      <Frame>
        <div className="p-4 space-y-4 max-w-[73.875rem] flex flex-col grow lg:p-8 lg:space-y-6 xl:p-12 xl:space-y-8">
          {subscription.trialExpired && (
            <p className="border border-orange-200 bg-orange-50 text-orange-700 p-3 px-4 text-base rounded-lg flex justify-between flex-wrap">
              Your trial period expired on{' '}
              {subscription.trialExpiresAt?.toLocaleString(DateTime.DATE_FULL)}. Upgrade now to keep
              access to Sales Hub Pro&apos;s exclusive features
              <Link.T to={Urls.upgradeToPro()} className="font-medium">
                Upgrade to Pro
              </Link.T>
            </p>
          )}

          <h2 className="text-2xl font-medium">
            Hi {name}!{' '}
            {store &&
              (!store.setupCompletedAt ? (
                <>Let&rsquo;s finish your initial setup.</>
              ) : !stripeSetupComplete ? (
                <>Let&rsquo;s finish your Gearflow Supplier Network setup.</>
              ) : onboarding.onboardingCompletedAt ? (
                <>Here&rsquo;s what is going on at {store.name}.</>
              ) : (
                <>Let&rsquo;s finish setting up your workspace.</>
              ))}
          </h2>

          {store &&
            (!store.setupCompletedAt ? (
              <div className="space-y-4 text-base bg-white p-6 border-1 rounded-md max-w-sm">
                <p className="prose">
                  You&rsquo;ll add your company branding and store information. It&rsquo;s quick to
                  get started.
                </p>

                <Link.P to="/setup/welcome">Continue Initial Setup</Link.P>
              </div>
            ) : (
              !stripeSetupComplete && (
                <div className="space-y-4 text-base bg-white p-6 border-1 rounded-md max-w-sm">
                  <p className="prose">
                    You&rsquo;ll tell us what parts requests to send you, and connect your bank
                    account to receive funds. It&rsquo;s quick to get started.
                  </p>

                  <Link.P to="/setup/gearflow-supplier-network" color="blue">
                    Continue Setup
                  </Link.P>
                </div>
              )
            ))}

          {onboarding.onboardingTasksVisible && <OnboardingTasks />}
          {!subscription.isPro && <ValuePropBanner />}
        </div>
      </Frame>
    </>
  )
}

export default Dashboard
