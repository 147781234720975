import { SortByInput, SortOrder } from '@/buyers/_gen/gql'
import Action from '@/gf/components/Action'
import { cn } from '@/gf/modules/utils'
import { ArrowSmDownIcon, SwitchVerticalIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import pluralize from 'pluralize'
import { ReactNode, TdHTMLAttributes } from 'react'
import { Td as BaseTd, Th } from '../Table'

export const getBreakdownSelectMessage = (length: number | undefined, name: string) => (
  <>
    {typeof length !== 'undefined'
      ? `${length} ${pluralize(name, length)} ${pluralize(
          'has',
          length
        )} been included in part requests in this time period. `
      : ''}
    Select one for more detail.
  </>
)

const Table = ({ children, className }: { children: ReactNode; className?: string }) => (
  <table className={classNames('bg-white min-w-full', className)}>{children}</table>
)

const Thead = ({ children, className }: { children: ReactNode; className?: string }) => (
  <thead className={classNames(className)}>{children}</thead>
)

const Tbody = ({ children, className }: { children: ReactNode; className?: string }) => (
  <tbody className={classNames('bg-white min-w-full', className)}>{children}</tbody>
)

const Tr = ({
  children,
  className,
  onClick,
}: {
  children: ReactNode
  className?: string
  onClick?: () => void
}) => (
  <tr className={classNames(className)} onClick={onClick}>
    {children}
  </tr>
)

const Td = ({ className, ...props }: TdHTMLAttributes<HTMLTableCellElement>) => (
  <BaseTd
    {...props}
    className={cn(
      'px-3 first:pl-2 last:pr-2 py-3 text-gray-900 overflow-hidden text-ellipsis max-w-36',
      className
    )}
  />
)

const inverseOrder = (order: SortOrder): SortOrder =>
  order === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc

const SortByHeaderButton = ({
  field,
  display,
  sortBy,
  setSortBy,
}: {
  field: string
  display: ReactNode
  sortBy: SortByInput
  setSortBy: (sortBy: SortByInput) => void
}) => (
  <button
    className="inline-flex flex-row items-center gap-x-1 text-xs font-medium text-gray-900 uppercase overflow-hidden text-ellipsis whitespace-nowrap"
    type="button"
    onClick={() =>
      setSortBy({
        field,
        order: sortBy.field !== field ? SortOrder.Desc : inverseOrder(sortBy.order),
      })
    }
  >
    {display}
    {sortBy.field === field ? (
      <ArrowSmDownIcon
        className={classNames('-m-0.5 w-5 h-5 flex shrink-0 text-gray-800', {
          'rotate-180': sortBy.order !== SortOrder.Desc,
        })}
      />
    ) : (
      <SwitchVerticalIcon className="-m-0.5 w-5 h-5 flex shrink-0 text-gray-400" />
    )}
  </button>
)

export const NoResults = ({
  children = 'No Results',
  onClearFilters,
}: {
  children?: ReactNode
  onClearFilters?: () => void
}) => (
  <div className="flex gap-x-2 text-gray-500 italic">
    {children}
    {onClearFilters && <Action.T onClick={onClearFilters}>Clear Filters</Action.T>}
  </div>
)

const Loading = () => (
  <Tr>
    <Td>Loading...</Td>
  </Tr>
)

export type Column<T> = {
  header: string | { key: string; children: ReactNode }
  getValue: (row: T) => ReactNode
  getComparison?: (row: T) => ReactNode
  sortByField?: string
}

type TableCheckbox<T> = {
  getChecked: (row: T) => boolean
  onToggleRow: (row: T) => void
  onClear: () => void
}

const ReportingTable = <T,>({
  data,
  columns,
  getRowKey,
  sortBy,
  checkbox,
  noResults = <NoResults />,
  loading = <Loading />,
  aggregateRow,
}: {
  data: T[] | undefined
  columns: Column<T>[]
  getRowKey: (row: T) => string
  sortBy?: {
    sortBy: SortByInput
    setSortBy: (sortBy: SortByInput) => void
  }
  checkbox?: TableCheckbox<T>
  noResults?: ReactNode
  loading?: ReactNode
  aggregateRow?: { label?: string; columns: ReactNode[] }
}) => (
  <Table>
    <Thead>
      <Tr className="relative">
        {columns.map((column, colIndex) => (
          <Th
            className="sticky z-50 top-0 bg-white text-ellipsis p-0"
            key={typeof column.header === 'string' ? column.header : column.header.key}
          >
            <div className="px-3 first:pl-2 last:pr-2 py-3 border-b border-gray-300">
              {colIndex === 0 && <div className="absolute w-2 -left-2 inset-y-0 bg-white z-40" />}

              {colIndex === columns.length - 1 && (
                <div className="absolute w-2 -right-2 inset-y-0 bg-white z-40" />
              )}

              {sortBy && column.sortByField ? (
                <SortByHeaderButton
                  display={
                    typeof column.header === 'string' ? column.header : column.header.children
                  }
                  field={column.sortByField}
                  sortBy={sortBy.sortBy}
                  setSortBy={sortBy.setSortBy}
                />
              ) : typeof column.header === 'string' ? (
                column.header
              ) : (
                column.header.children
              )}
            </div>
          </Th>
        ))}
      </Tr>
    </Thead>
    <Tbody>
      {!data ? (
        // TODO: improve this with Ghosts and giving the number of columns as a parameter
        loading
      ) : data.length === 0 ? (
        <Tr>
          <Td>{noResults}</Td>
        </Tr>
      ) : (
        <>
          {/* Need a small empty gap at the top to show if the first item is selected */}
          {checkbox && (
            <Tr>
              <td className="h-px"> </td>
            </Tr>
          )}

          {data.map((row, rowIndex) => (
            <Tr
              key={getRowKey(row)}
              className={classNames(
                'relative group',
                checkbox && 'cursor-pointer',
                checkbox &&
                  (checkbox.getChecked(row) ? 'bg-blue-50 hover:bg-blue-100' : 'hover:bg-blue-50')
              )}
            >
              {columns.map((column, colIndex) => {
                const value = column.getValue(row)
                const comparison = column.getComparison ? column.getComparison(row) : undefined

                return (
                  <Td
                    onClick={checkbox ? () => checkbox.onToggleRow(row) : undefined}
                    key={typeof column.header === 'string' ? column.header : column.header.key}
                    title={typeof value === 'string' ? value : undefined}
                  >
                    {colIndex === 0 && (
                      <>
                        {rowIndex !== data.length - 1 && (
                          <div className="absolute inset-x-px bottom-0 border-b border-gray-300" />
                        )}

                        {checkbox &&
                          (checkbox.getChecked(row) ? (
                            <div
                              className={classNames(
                                'absolute -top-0.5 -bottom-px -inset-x-0.5 z-30 rounded border-2 border-blue-600'
                              )}
                            />
                          ) : (
                            <>
                              <div className="px-px py-0.5 hidden group-hover:flex justify-between absolute -top-px bottom-0 -inset-x-0.5 z-10">
                                <div className="w-px h-full flex bg-blue-50" />
                                <div className="w-px h-full flex bg-blue-50" />
                              </div>
                              <div className="hidden group-hover:flex justify-between absolute -top-px bottom-0 -inset-x-0.5 z-20 rounded border border-gray-300 shadow-sm" />
                            </>
                          ))}
                      </>
                    )}

                    {value}
                    {comparison}
                  </Td>
                )
              })}
            </Tr>
          ))}

          {/* Aggregate Row */}
          {aggregateRow && (
            <Tr className="border-t border-gray-300 text-xs font-semibold text-gray-900">
              <Td title={aggregateRow.label}>{aggregateRow.label}</Td>
              {aggregateRow.columns.map((column, colIndex) => {
                const colHeader = columns[colIndex].header

                return (
                  <Td
                    key={
                      typeof column === 'string'
                        ? column
                        : typeof colHeader === 'string'
                          ? colHeader
                          : colHeader.key
                    }
                    title={typeof column === 'string' ? column : undefined}
                  >
                    {column}
                  </Td>
                )
              })}
            </Tr>
          )}
        </>
      )}
    </Tbody>
  </Table>
)

export default ReportingTable
