import useGqlClient from './useGqlClient'
import { useAdditionalChargeBalanceJsonQuery } from '../_gen/gql'

const useAdditionalChargeBalanceJson = (additionalChargeId: string) => {
  const { loading, data, error } = useAdditionalChargeBalanceJsonQuery({
    variables: { additionalChargeId },
    fetchPolicy: 'no-cache',
    client: useGqlClient(),
  })

  if (loading || !data) return { balanceJson: undefined, error }

  return { balanceJson: data.additionalChargeBalanceJson, error }
}

export type BalanceJson = NonNullable<
  ReturnType<typeof useAdditionalChargeBalanceJson>['balanceJson']
>
export default useAdditionalChargeBalanceJson
