import { RequestForQuoteQuery } from '@/dealers/_gen/gql'
import { Maybe } from '@/types'
import { ExclamationIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

const Info = ({ label, value }) => (
  <div className="flex items-center gap-x-4">
    <div className="leading-6 flex-grow max-w-32">{label}</div>
    <div className="leading-6">{value}</div>
  </div>
)

const MachineInformation = ({
  reqMachine,
  className,
  textSize = 'sm',
}: {
  reqMachine: Maybe<NonNullable<RequestForQuoteQuery['requestForQuote']>['machines'][number]>
  className?: string
  textSize?: 'sm' | 'base'
}) => (
  <div className={classNames('flex flex-col gap-y-2', className)}>
    <h4 className="text-lg font-medium">Machine</h4>
    {reqMachine ? (
      <div
        className={classNames(textSize === 'sm' && 'text-sm', textSize === 'base' && 'text-base')}
      >
        <Info label="Unit number:" value={reqMachine.details.name} />
        <Info label="Serial number:" value={reqMachine.details.serialNumber} />
        <Info label="Make:" value={reqMachine.details.machine.make} />
        <Info label="Model:" value={reqMachine.details.machine.model} />
        <Info label="Year:" value={reqMachine.details.machine.year} />
        {reqMachine.machineDown && (
          <Info
            label="Status:"
            value={
              <span className="text-red-700 inline-flex items-center font-medium gap-x-2">
                <ExclamationIcon className="h-5 w-5 inline-block" />
                Machine down
              </span>
            }
          />
        )}
      </div>
    ) : (
      <p>N/A (stock order request)</p>
    )}
  </div>
)

export default MachineInformation
