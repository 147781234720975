import classNames from 'classnames'

type LayoutSectionC = React.FC<{
  type?: 'ipad' | 'full' | 'primary' | 'secondary' | 'half' | 'table'
  className?: string
  children?: React.ReactNode
}>

type FullPageLayoutC = React.FC<{ className?: string; children?: React.ReactNode }>

interface LayoutSubComponents {
  Section: LayoutSectionC
  FullPageLayout: FullPageLayoutC
}

type LayoutC = React.FC<{ marginTop?: boolean | null; children?: React.ReactNode }> &
  LayoutSubComponents

const Layout: LayoutC = ({ marginTop = true, children }) => (
  <div className={classNames('flex flex-col grid grid-cols-6 gap-4', marginTop && 'mt-4')}>
    {children}
  </div>
)

const FullPageLayout: FullPageLayoutC = ({ className, children }) => (
  <div className={classNames('flex flex-col grow relative', className)}>
    <div className="absolute inset-0 gap-4">{children}</div>
  </div>
)

const classesByType = {
  full: 'md:col-span-6',
  primary: 'md:col-span-4',
  secondary: 'md:col-span-2',
  half: 'md:col-span-3',
  table: 'md:col-span-6 rounded border border-gray-300 space-y-0',
}

const Section: LayoutSectionC = ({ type = 'full', className, children }) => (
  <div className={`self-start col-span-6 space-y-6 ${classesByType[type]} ${className || ''}`}>
    {children}
  </div>
)

Layout.Section = Section
Layout.FullPageLayout = FullPageLayout
export default Layout
