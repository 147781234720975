import { useState } from 'react'

import ModalForm from '@/gf/components/ModalForm'
import Spinner from '@/gf/components/Spinner'
import TextField from '@/gf/components/TextField'

const PurchaseOrderModal = ({
  open,
  onClose,
  onSubmit,
  spinnerLive,
  purchaseOrder,
}: {
  open: boolean
  onClose: () => void
  onSubmit: (purchaseOrder: string) => void
  spinnerLive: boolean
  purchaseOrder: string | null
}) => {
  const [purchaseOrderValue, setPurchaseOrderValue] = useState(purchaseOrder || '')

  return (
    <ModalForm
      title="Edit Purchase Order"
      open={open}
      onClose={onClose}
      onSubmit={(e) => {
        e.preventDefault()

        onSubmit(purchaseOrderValue)
      }}
      submitButtonText="Save"
      submitButtonDisabled={spinnerLive}
    >
      {spinnerLive ? (
        <Spinner />
      ) : (
        <div className="mt-2 space-y-2">
          <TextField
            label="Purchase Order"
            value={purchaseOrderValue}
            onChange={(e) => setPurchaseOrderValue(e.target.value)}
          />
        </div>
      )}
    </ModalForm>
  )
}

export default PurchaseOrderModal
