import { useNextPurchaseOrderNumberQuery } from '@/dealers/_gen/gql'
import Ghost from '@/gf/components/Ghost'

const NextPurchaseOrderNumber = ({ storeOrderId }: { storeOrderId: string }) => {
  const { data, loading } = useNextPurchaseOrderNumberQuery({
    variables: { storeOrderId },
  })

  if (loading || !data) {
    return <Ghost className="inline-block h-4 rounded-lg w-3/4" />
  }

  return <>{data.nextPurchaseOrderNumber}</>
}

export default NextPurchaseOrderNumber
