// Returns the filters array with the given operator joining all of the given filters
const addOperatorToFilters = (operator: string, filters: string[][]) =>
  filters.length === 0 ? [] : filters.length === 1 ? filters : [[operator], ...filters]

// Return the filters array with the AND operator joining all of the given filters
const andFilters = (filters: string[][]) => addOperatorToFilters('and', filters)

// Return the filters array with the OR operator joining all of the given filters
const orFilters = (filters: string[][]) => addOperatorToFilters('or', filters)

export default { andFilters, orFilters }
