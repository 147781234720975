import { PaymentMethod } from '@/buyers/_gen/gql'
import Box from '@/buyers/components/Box'
import A from '@/gf/components/A'
import useConfig from '@/gf/hooks/useConfig'
import { DownloadIcon } from '@heroicons/react/solid'
import pluralize from 'pluralize'
import { useOrderContext } from '../context'

const Invoices = () => {
  const { buyersUrl } = useConfig()
  const { storeOrder } = useOrderContext()
  const invoiceLinks = storeOrder.invoices.map((i) => i.link).filter((l): l is string => l !== null)

  const count = (
    storeOrder.paymentMethod === PaymentMethod.Direct ? invoiceLinks : storeOrder.invoices
  ).length

  if (count === 0) return null

  return (
    <Box className="text-center space-y-4 flex flex-col items-center" dim>
      <div className="text-lg font-medium">Your {pluralize('Invoice', count)}</div>

      {storeOrder.paymentMethod === PaymentMethod.Direct ? (
        <>
          <div className="text-base">
            You received {invoiceLinks.length === 1 ? 'an invoice' : 'invoices'} from{' '}
            {storeOrder.store.name}. Download the{' '}
            {invoiceLinks.length === 1 ? 'invoice' : 'invoices'} and pay them directly.
          </div>
          <div className="space-y-2">
            {invoiceLinks.map((link, index) => (
              <A.S href={link} target="_blank" className="flex gap-2 items-center" key={link}>
                <DownloadIcon className="h-5 w-5" />
                <span>Download Invoice{invoiceLinks.length > 1 && <> {index + 1}</>}</span>
              </A.S>
            ))}
          </div>
        </>
      ) : (
        <div className="space-y-2">
          {storeOrder.invoices.map((invoice, index) => (
            <A.S
              href={`${buyersUrl}/pdf/download/invoice/${invoice.id}`}
              target="_blank"
              className="flex gap-2 items-center"
              key={invoice.id}
            >
              <DownloadIcon className="h-5 w-5" />
              <span>Download Invoice{invoiceLinks.length > 1 && <> {index + 1}</>}</span>
            </A.S>
          ))}
        </div>
      )}
    </Box>
  )
}

export default Invoices
