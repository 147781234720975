import classNames from 'classnames'

interface Props {
  sidebar: React.ReactNode
  children: React.ReactNode
}

const DetailsPageWithSidebar = ({ sidebar, children }: Props) => (
  <div className="flex flex-col md:flex-row md:items-stretch pt-6 gap-x-4">
    <div
      className={classNames(
        'rounded-lg bg-white border border-slate-200 flex-shrink-0 md:min-h-[35rem] overflow-x-hidden'
      )}
    >
      {sidebar}
    </div>
    {children}
  </div>
)

export default DetailsPageWithSidebar
