import React from 'react'
import { ApolloError } from '@apollo/client'

import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'

import ModalForm from '@/gf/components/ModalForm'
import Spinner from '@/gf/components/Spinner'
import TextArea from '@/gf/components/TextArea'

const DenyModal = ({
  open,
  onClose,
  denyPlaceholder,
  onSubmit,
}: {
  open: boolean
  onClose: (args?: { successful: boolean }) => void
  denyPlaceholder: string
  onSubmit: (commentText: string) => Promise<unknown>
}) => {
  const [_msgs, msgsMgr] = useMsgs()
  const [commentText, setCommentText] = React.useState('')
  const [spinnerLive, spinner] = useToggle()

  const deny = (evt) => {
    evt.preventDefault()

    spinner.on()

    return onSubmit(commentText)
      .then(() => {
        onClose({ successful: true })
      })
      .catch((e: ApolloError) => {
        if (e.message) {
          msgsMgr.add(e.message, 'negative')
        } else {
          msgsMgr.add('Error denying', 'negative')
        }

        onClose({ successful: false })
      })
      .finally(spinner.off)
  }

  return (
    <ModalForm
      open={open}
      onClose={onClose}
      title="Deny"
      onSubmit={deny}
      submitButtonDisabled={spinnerLive}
      submitButtonShowSpinner={spinnerLive}
    >
      {spinnerLive ? (
        <Spinner />
      ) : (
        <p className="pt-4 pb-3">
          <TextArea
            required
            rows={4}
            placeholder={denyPlaceholder}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
        </p>
      )}
    </ModalForm>
  )
}

export default DenyModal
