import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSetPurchaseOrderStoreOrderMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import { useOrderContext } from './context'

import PurchaseOrderModal from '@/buyers/pages/StoreOrder/Order/PurchaseOrderModal'

const EditPurchaseOrder = () => {
  const [_, msgr] = useMsgs()
  const [performing, setPerforming] = useState(false)
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const navigate = useNavigate()
  const [setPurchaseOrder] = useSetPurchaseOrderStoreOrderMutation({ client: useGqlClient() })
  const close = () => navigate(`/orders/${storeOrder.id}`, { replace: true })

  const onSubmit = (purchaseOrder: string) => {
    setPerforming(true)

    setPurchaseOrder({ variables: { storeOrderId: storeOrder.id, purchaseOrder } })
      .then(() => {
        refetchStoreOrder()
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => {
        close()
        setPerforming(true)
      })
  }

  return (
    <PurchaseOrderModal
      open
      onClose={close}
      onSubmit={onSubmit}
      spinnerLive={performing}
      purchaseOrder={storeOrder.purchaseOrder}
    />
  )
}

export default EditPurchaseOrder
