const ChartDot = ({
  size,
  borderColor,
  borderWidth,
  color,
  opacity,
}: {
  size: number
  borderColor: string
  borderWidth: number
  color: string
  opacity: number
}) => (
  <circle
    r={size}
    cx={0}
    cy={0}
    fill={color}
    strokeWidth={borderWidth}
    stroke={borderColor}
    opacity={opacity}
  />
)

export default ChartDot
