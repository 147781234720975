import { useState } from 'react'

import { useCloseRfqMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'

import Action from '@/gf/components/Action'
import Modal from '@/gf/components/ModalNext'

const CloseRequestModal = ({
  rfqId,
  close,
  onOk,
}: {
  rfqId: string
  close: () => void
  onOk: () => void
}) => {
  const [performing, setPerforming] = useState(false)
  const [_, msgr] = useMsgs()
  const [closeRfq] = useCloseRfqMutation({ client: useGqlClient() })

  const closeRequest = () => {
    setPerforming(true)

    closeRfq({ variables: { requestForQuoteId: rfqId } })
      .then(() => {
        msgr.add('Request closed.', 'positive')
        close()
        onOk()
      })
      .catch(() => msgr.addUnknownError())
  }

  return (
    <Modal onClose={close}>
      <div className="p-6 space-y-4">
        <div className="text-xl">Mark Request as Closed?</div>

        <p className="text-base">
          Only leave this request open if you would like to receive additional quotes for the items
          in this request.
        </p>

        <div className="flex gap-2 flex-row-reverse">
          <Action.P onClick={closeRequest} performing={performing}>
            Close Request
          </Action.P>

          <Action.S onClick={close}>Leave Open</Action.S>
        </div>
      </div>
    </Modal>
  )
}

export default CloseRequestModal
