/**
 * Select Field Component
 * -----------------
 * Select input field for use in forms
 *
 * props
 * -----------------
 * label - label over the select dropdown
 * desc - description of the select dropdown
 * options - options in the select dropdown
 * currentId - id of the currently selected option
 * onChange - function called when the selection changes with the new selection id as a parameter
 * required - whether the select dropdown is required in the form or not
 */

import Field from './Field'
import { BaseSelect } from './Select'

import { Option } from '../../types'

const SelectField = ({
  label,
  required,
  placeholder,
  onChange,
  options,
  currentId,
  errorText,
}: {
  label: string
  options: Option[]
  currentId: string
  placeholder?: string
  onChange: (selectedId: string) => void
  required?: boolean
  errorText?: string | null
}) => (
  <Field label={label} errors={errorText}>
    <BaseSelect required={required} value={currentId} onChange={(e) => onChange(e.target.value)}>
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}

      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.display}
        </option>
      ))}
    </BaseSelect>
  </Field>
)

export default SelectField
