import { SearchIcon, XIcon } from '@heroicons/react/outline'
import useSearch from './useSearch'
import { FloatingPortal, useDismiss, useFloating, useInteractions } from '@floating-ui/react'
import classNames from 'classnames'
import { useMemo, ReactNode } from 'react'

export type SearchResultsRenderFn = (props: {
  searchTerm: string
  onClose: () => void
}) => ReactNode

const Search = (
  props: ReturnType<typeof useSearch> & {
    fullWidth?: boolean
    renderSearchResults: SearchResultsRenderFn
  }
) => {
  const { open, onClose, value, onChange, fullWidth } = props
  const { refs, context } = useFloating({
    open,
    onOpenChange: onClose,
    placement: 'bottom',
  })

  const dismiss = useDismiss(context)

  const floatingPosition = useMemo(() => {
    if (!context.elements.reference) {
      return null
    }

    const boundingRect = context.elements.reference.getBoundingClientRect()

    if (fullWidth) {
      return {
        top: boundingRect.top + boundingRect.height + 10,
        width: window.innerWidth - 20,
        left: 10,
      }
    }

    return {
      left: boundingRect.x,
      top: boundingRect.top + boundingRect.height + 5,
      width: boundingRect.width,
    }
  }, [fullWidth, context.elements.reference])

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  return (
    <div className="flex-grow">
      <div
        className={classNames('relative flex items-center transition-all')}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <SearchIcon className="h-5 w-5 text-gray-500 absolute left-3 pointer-events-none" />
        <input
          type="text"
          className={classNames(
            'border-none placeholder:text-transparent placeholder:lg:text-gray-400 leading-6 lg:w-full lg:pl-10 rounded-lg font-normal transition-all duration-200 ease-out focus:ring-transparent',
            props.active ? 'w-full pl-10' : 'w-10'
          )}
          placeholder="Search for requests, orders, machines, and vendors"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={props.onFocus}
          onBlur={props.onFocusLost}
        />
        {value && (
          <button type="button" onClick={() => onChange('')} className="absolute right-3">
            <XIcon className="h-5 w-5" />
          </button>
        )}
      </div>
      {open && (
        <FloatingPortal>
          <div
            className={classNames(
              'overflow-y-auto bg-white px-1 rounded-lg shadow-md border border-gray-200 w-full max-h-[80vh] fixed z-20'
            )}
            style={floatingPosition ?? undefined}
            ref={refs.setFloating}
            {...getFloatingProps()}
          >
            {props.renderSearchResults({ searchTerm: props.value, onClose })}
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}
export default Search
