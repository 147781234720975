import { useCallback, useMemo, useState } from 'react'

const useSearch = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [open, setOpen] = useState(false)
  const [focus, setFocus] = useState(false)

  const onChange = useCallback((value: string) => {
    setSearchTerm(value)
    setOpen(!!value)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
    setSearchTerm('')
  }, [])

  const onFocus = useCallback(() => setFocus(true), [])
  const onFocusLost = useCallback(() => setFocus(false), [])

  const active = useMemo(() => !!(focus || searchTerm), [focus, searchTerm])

  return {
    value: searchTerm,
    onChange,
    open,
    onClose,
    active,
    onFocus,
    onFocusLost,
  }
}

export default useSearch
