/**
 * Address Modal Form Component
 * -----------------
 * Modal form for entering and updating addresses
 *
 * props
 * -----------------
 * initialAddress - the initial address in the modal form
 * open - boolean for whether or not the modal should be open
 * onClose - function that runs when modal is closed
 * onSubmit - function that is called with the new address as a parameter when the form is submitted
 * title - the title of the modal
 * submitButtonDisabled - boolean value if submit button is disabled
 * submitButtonShowSpinner - boolean value if submit button is shows the spinner
 */

import { ReactNode, useState } from 'react'
import AddressInput from './AddressInput'

import ModalForm from './ModalForm'

import { Address } from '../../types'

interface AddressModalFormProps {
  initialAddress?: Address | null
  open: boolean
  onClose: () => void
  onSubmit: (newAddress: Address) => void
  title?: string
  submitButtonShowSpinner?: boolean
  submitButtonDisabled?: boolean
  hideFirstLastName?: boolean
  hideCompany?: boolean
  details?: ReactNode
}

const AddressModalForm = ({
  initialAddress,
  open,
  onClose,
  onSubmit,
  title = 'Edit address',
  submitButtonDisabled = false,
  submitButtonShowSpinner = false,
  hideFirstLastName,
  hideCompany,
  details,
}: AddressModalFormProps) => {
  const [address, setNewAddress] = useState<Address | undefined>(initialAddress || undefined)
  return (
    <ModalForm
      open={open}
      onClose={onClose}
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (address) onSubmit(address)
      }}
      submitButtonText="Save"
      title={title}
      submitButtonShowSpinner={submitButtonShowSpinner}
      submitButtonDisabled={submitButtonDisabled}
    >
      <div className="flex flex-col gap-4 mt-4">
        {details}
        <AddressInput
          address={address}
          onChange={(newAddress) => setNewAddress(newAddress)}
          hideFirstLastName={hideFirstLastName}
          hideCompany={hideCompany}
        />
      </div>
    </ModalForm>
  )
}

export default AddressModalForm
