import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import { Td as BaseTd, Th } from '@/gf/components/Table'
import { cn } from '@/gf/modules/utils'
import { TdHTMLAttributes } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { useRfqContext } from '../context'

const Td = ({ className, ...props }: TdHTMLAttributes<HTMLTableCellElement>) => (
  <BaseTd className={cn('align-top whitespace-normal', className)} {...props} />
)

const Shipment = () => {
  const shipmentId = useMatch('/rfqs/:rfqId/shipments/:shipmentId')?.params.shipmentId
  const { rfq } = useRfqContext()
  const navigate = useNavigate()
  const close = () => navigate(`/rfqs/${rfq.id}`, { replace: true })
  const shipment = rfq.quote?.shipments.find((s) => s.id === shipmentId)

  if (!shipment) return null

  return (
    <Modal open onClose={close}>
      <div className="p-8 pt-10">
        <CloseModalButton onClick={close} className="absolute top-2 right-2" />

        <div className="rounded-lg border overflow-auto">
          <table className="divide-y-1 divide-gray-200 w-full">
            <thead className="bg-gray-50">
              <tr>
                <Th>Part number</Th>
                <Th>Description</Th>
                <Th className="text-right">Shipped</Th>
              </tr>
            </thead>

            <tbody className="divide-y-1 divide-gray-200">
              {shipment.items.map((item) => (
                <tr key={item.id}>
                  <Td>{item.lineItem.productMpn}</Td>
                  <Td>{item.lineItem.productName}</Td>
                  <Td className="text-right">{item.quantity}</Td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}

export default Shipment
