import useLocalStorage from '@/buyers/pages/CreateRequest/useLocalStorage'
import { Maybe } from '@/types'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import Urls from '../modules/Urls'

type QuoteContext = {
  rfqId: Maybe<string>
  storeOrderId: Maybe<string>
}

const useQuoteContext = () => {
  const params = useParams() as { rfqId?: string; storeOrderId?: string }
  const state = useLocalStorage<QuoteContext>('dealers-limited-context', {
    rfqId: null,
    storeOrderId: null,
  })

  useEffect(() => {
    if (params.storeOrderId || params.rfqId) {
      state.setValue({ storeOrderId: params.storeOrderId ?? null, rfqId: params.rfqId ?? null })
    }
  }, [params])

  const path = useMemo(() => {
    if (state.value.rfqId) {
      return Urls.requestForQuote(state.value.rfqId)
    }

    if (state.value.storeOrderId) return `/orders/${state.value.storeOrderId}`

    return null
  }, [state.value])

  return { value: state.value, path }
}

export default useQuoteContext
