import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import PriceV2 from '@/gf/components/inputs/PriceV2'
import Switch from '@/gf/components/Switch'
import TextField from '@/gf/components/TextField'
import Money from '@/gf/modules/Money'
import { Maybe } from '@/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm, useWatch } from 'react-hook-form'
import ReactSelect from 'react-select'
import * as Yup from 'yup'
import { ProductAvailability } from '../_gen/gql'
import useSession from '../hooks/useSession'
import { AVAILABILITY_OPTIONS } from '../modules/Product'

export type ProductFormValues = {
  name: string
  mpn: string
  altMpn: Maybe<string>
  shortDescription: string
  salePrice: Maybe<number>
  enabled: boolean
  autoQuoteEnabled: boolean
  availability: ProductAvailability
  leadTime: Maybe<string>
}

const selectedAvailabilityOption = (v: ProductAvailability) =>
  AVAILABILITY_OPTIONS.find((o) => o.value === v) as unknown as {
    label: string
    value: ProductAvailability
  }

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .max(70, 'Should have at most 70 characters')
    .min(3, 'Should be at least 3 characters long'),
  mpn: Yup.string().required('Mpn is required'),
  altMpn: Yup.string().nullable(),
  shortDescription: Yup.string(),
  salePrice: Yup.number()
    .nullable()
    .required('Sale price is required')
    .min(0.1, 'Should be greater than 0.00'),
  enabled: Yup.boolean().required(),
  autoQuoteEnabled: Yup.boolean().required(),
  availability: Yup.string().required('Availability status is required'),
  leadTime: Yup.number()
    .integer('Lead time must be a whole number')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Lead time must be a number')
    .min(1)
    .when('availability', (availability: ProductAvailability, schema: Yup.NumberSchema) =>
      availability === ProductAvailability.BackOrdered
        ? schema.required('Lead time is required')
        : schema.nullable()
    ),
})

const ProductForm = ({
  onSubmit,
  saveInProgress,
  defaultValues,
}: {
  onSubmit: (values: ProductFormValues) => void
  saveInProgress: boolean
  defaultValues: ProductFormValues
}) => {
  const { store } = useSession()

  const form = useForm<ProductFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const selectedAvailability = useWatch({ control: form.control, name: 'availability' })

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="flex flex-col md:flex-row  gap-x-6 gap-y-4">
        <div className="w-full md:w-1/2 space-y-4">
          <TextField
            label="Name"
            {...form.register('name')}
            errors={form.formState.errors.name?.message}
          />

          <TextField
            label="Short Description"
            {...form.register('shortDescription')}
            errors={form.formState.errors.shortDescription?.message}
          />

          <TextField
            label="Mpn"
            {...form.register('mpn')}
            errors={form.formState.errors.mpn?.message}
          />

          <TextField
            label="Alt Mpn"
            {...form.register('altMpn')}
            errors={form.formState.errors.altMpn?.message}
          />

          <Controller
            name="salePrice"
            control={form.control}
            render={({ field, fieldState }) => (
              <Field label="Sale Price" errors={fieldState.error?.message}>
                <PriceV2
                  price={field.value ? Money.fromDecimal(field.value, 'USD') : undefined}
                  onChange={(value) => field.onChange(value ? Money.toDecimal(value) : null)}
                />
              </Field>
            )}
          />
        </div>

        <div className="w-full md:w-1/2 space-y-4">
          <Controller
            name="availability"
            control={form.control}
            render={({ field, fieldState }) => (
              <Field label="Availability" errors={fieldState.error?.message}>
                <ReactSelect
                  options={AVAILABILITY_OPTIONS}
                  value={selectedAvailabilityOption(field.value)}
                  onChange={(o) => o?.value && field.onChange(o.value)}
                  isSearchable={false}
                />
              </Field>
            )}
          />

          {selectedAvailability === ProductAvailability.BackOrdered && (
            <>
              <TextField
                label="Lead Time (days)"
                {...form.register('leadTime')}
                errors={form.formState.errors.leadTime?.message}
              />
            </>
          )}

          {store?.autoQuote && (
            <Controller
              name="autoQuoteEnabled"
              control={form.control}
              render={({ field, fieldState }) => (
                <Field label="Auto-Quote" errors={fieldState.error?.message}>
                  <div className="flex items-center gap-x-2">
                    <Switch
                      checked={field.value}
                      onChange={field.onChange}
                      checkedClassName="bg-green-500"
                    />{' '}
                    <span className="text-sm text-slate-500">
                      Allows this product to be included in auto-quoted requests.
                    </span>
                  </div>
                </Field>
              )}
            />
          )}
        </div>
      </div>

      <div className="mt-8 flex justify-end">
        <Action.P type="submit" disabled={saveInProgress} performing={saveInProgress} size="lg">
          Submit
        </Action.P>
      </div>
    </form>
  )
}

export default ProductForm
