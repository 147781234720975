import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ApolloError } from '@apollo/client'
import ReactSelect from 'react-select'

import { UploadedImage } from '@/types'

import useUppy from '@/gf/hooks/useUppy'
import useMsgs from '@/gf/hooks/useMsgs'

import useToggle from '@/gf/hooks/useToggle'
import { AVAILABILITY_OPTIONS } from '../modules/Product'

import ModalForm from '@/gf/components/ModalForm'
import UppyFileInput from '@/gf/components/UppyFileInput'
import { ProductAvailability, useUploadProductsMutation } from '@/dealers/_gen/gql'
import InputLabel from '@/gf/components/InputLabel'
import TextInput from '@/gf/components/inputs/Text'

interface File {
  id: string
  name: string
  extension: string
  type?: string
  data: string
}

const allowedFileTypes = ['.csv', 'text/csv']

const UploadProducts = ({ show, close }: { show: boolean; close: () => void }) => {
  const [_, msgr] = useMsgs()
  const [spinnerLive, spinnerToggle] = useToggle()
  const [_uploadFile, setUploadFile] = React.useState<File>()
  const [update] = useUploadProductsMutation()
  const [emptyProductAvailability, setEmptyProductAvailability] = useState<ProductAvailability>(
    ProductAvailability.OutOfStock
  )
  // Default to something reasonable like 7 days
  const [leadTime, setLeadTime] = useState('7')

  const onUppyComplete = useCallback(
    ([file]: UploadedImage[]) => {
      update({
        variables: {
          url: file.url,
          leadTime: emptyProductAvailability === ProductAvailability.BackOrdered ? leadTime : null,
        },
      })
        .then(() => {
          msgr.add(
            'Your file is being processed. This may take a few minutes depending on the number of parts uploaded.',
            'positive'
          )
        })
        .catch((err: ApolloError) => {
          const message = err.graphQLErrors.reduce((acc, error) => `${acc}${error.message}`, '')
          msgr.add(message, 'negative')
        })
        .finally(() => {
          spinnerToggle.off()
          close()
        })
    },
    [emptyProductAvailability, leadTime]
  )
  const onUppyCompleteRef = useRef(onUppyComplete)

  useEffect(() => {
    onUppyCompleteRef.current = onUppyComplete
  }, [onUppyComplete])

  const uppy = useUppy({
    autoProceed: false,
    onFilesAdded: ([file]) => {
      const reader = new FileReader()
      reader.onloadend = (e) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          const fileWithData: File = {
            id: file.id,
            name: file.name,
            extension: file.extension,
            type: file.type,
            data: e.target.result,
          }
          setUploadFile(fileWithData)
        }
      }
      reader.readAsDataURL(file.data)
    },
    allowedFileTypes,
    // Use the ref, because useUppy doesn't update these callback functions
    onComplete: (files) => onUppyCompleteRef.current(files),
    maxNumberOfFiles: 1,
  })

  return (
    <>
      <ModalForm
        title="Upload Catalog"
        open={show}
        onClose={close}
        onSubmit={(e) => {
          e.preventDefault()
          spinnerToggle.on()
          uppy.upload()
        }}
        submitButtonShowSpinner={spinnerLive}
      >
        <div className="mt-6 flex flex-col space-y-8">
          <div className="flex flex-col gap-y-0.5">
            <p className="text-sm font-medium text-gray-700">
              Example catalog CSV file: <span className="font-mono text-gray-800">catalog.csv</span>
            </p>
            <blockquote className="px-2 py-1 text-sm text-gray-800 font-mono bg-gray-100 rounded-sm">
              <span className="block">mpn,name,quantity,price</span>
              <span className="block">&quot;ABC123&quot;,&quot;Seal&quot;,12,&quot;2.49&quot;</span>
              <span className="block">
                &quot;0R0781&quot;,&quot;Fuel Injector&quot;,5,&quot;99.00&quot;
              </span>
            </blockquote>
          </div>
          <InputLabel label="Catalog file">
            <UppyFileInput
              required
              uppy={uppy}
              onFileInputError={(error) => msgr.add(error, 'negative')}
              allowedFileTypes={allowedFileTypes}
            />
          </InputLabel>
          <div className="flex flex-col gap-y-0.5 max-w-xs">
            <p className="text-sm font-medium text-gray-700">
              Select the availability of products with quantity 0
            </p>
            <div className="flex flex-col gap-y-2">
              <ReactSelect
                options={AVAILABILITY_OPTIONS.filter((o) =>
                  [ProductAvailability.OutOfStock, ProductAvailability.BackOrdered].includes(
                    o.value
                  )
                ).reverse()}
                value={AVAILABILITY_OPTIONS.find((o) => o.value === emptyProductAvailability)}
                onChange={(o) => o?.value && setEmptyProductAvailability(o.value)}
                isSearchable={false}
              />
              {emptyProductAvailability === ProductAvailability.BackOrdered && (
                <div className="flex flex-row items-center gap-x-2">
                  <span className="text-sm font-medium text-gray-700 shrink-0">Lead time:</span>
                  <TextInput
                    type="number"
                    step={1}
                    min={1}
                    value={leadTime}
                    setValue={setLeadTime}
                  />
                  <span className="text-sm text-gray-700">days</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalForm>
    </>
  )
}

export default UploadProducts
