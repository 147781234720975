import { useNavigate } from 'react-router-dom'
import clamp from 'lodash/clamp'
import useSession from '@/dealers/hooks/useSession'
import TaskCard from '@/gf/components/Onboarding/TaskCard'
import {
  OnboardingTasksDocument,
  UpdateOnboardingMutationVariables,
  useOnboardingTasksQuery,
  useUpdateOnboardingMutation,
} from '@/dealers/_gen/gql'
import { DateTime } from 'luxon'
import Urls from '@/dealers/modules/Urls'

const calcProgress = (addedCount: number, taskCompleteCount: number) =>
  clamp((addedCount * 100) / taskCompleteCount, 0, 100)

const OnboardingTasks = () => {
  const { store } = useSession()
  const navigate = useNavigate()
  const { data } = useOnboardingTasksQuery()
  const [updateOnboarding] = useUpdateOnboardingMutation()

  const onLearnMore = () => navigate(Urls.planAndBilling())

  // const isInitialSetupComplete = false
  // const initialSetupProgress = 10
  // const timeRemaining = 12
  // const onboardingProgress = 80

  const customersAdded = data?.customers.pagination.totalResults ?? 0
  const usersAdded = data?.session.store?.users.length ?? 0

  const usersCompletedAt = data?.session.store?.onboarding.usersCompletedAt ?? null
  const customersCompletedAt = data?.session.store?.onboarding.customersCompletedAt ?? null

  const teamSetupCompleted = !!usersCompletedAt
  const customersSetupCompleted = !!customersCompletedAt
  const teamSetupProgress = teamSetupCompleted ? 100 : calcProgress(usersAdded, 3)
  const customersSetupProgress = customersSetupCompleted ? 100 : calcProgress(customersAdded, 5)

  const onChange = (changes: Partial<UpdateOnboardingMutationVariables>) =>
    updateOnboarding({
      variables: { customersCompletedAt, usersCompletedAt, ...changes },
      refetchQueries: [OnboardingTasksDocument],
    })

  return (
    <section>
      {/* <div className="mt-4 space-y-2">
        {timeRemaining > 0 && <p className="text-sm"> About {timeRemaining} min remaining</p>}

        <ProgressBar progressPercent={onboardingProgress} />
      </div> */}

      <div className="flex gap-x-4 mt-8">
        {/* <TaskCard
          title="Initial Setup"
          text="Capturing information about your business and team to setup your workspace. You will need to complete initial setup before other steps."
          index={0}
          completed={isInitialSetupComplete}
          progress={initialSetupProgress}
          onBegin={() => navigate('/setup')}
          onContinue={() => navigate('/setup')}
          onLearnMore={onLearnMore}
        /> */}

        <TaskCard
          title="Invite Your Team"
          text="Gearflow works best when your whole team is onboard. Be able to delegate work and monitor how your team is contributing to every order."
          index={1}
          // disabled={!isInitialSetupComplete}
          completed={teamSetupCompleted}
          progress={teamSetupProgress}
          onBegin={() => navigate('/settings/users')}
          onContinue={() => navigate('/settings/users')}
          onComplete={() => onChange({ usersCompletedAt: DateTime.now() })}
          onRestart={() => onChange({ usersCompletedAt: null })}
          onLearnMore={onLearnMore}
        />

        <TaskCard
          title="Add Your Customers"
          text="Quote the customers you already have relationships with by adding them to Gearflow."
          index={2}
          // disabled={store.proSubscription}
          locked={!store.subscription.isPro}
          completed={customersSetupCompleted}
          progress={customersSetupProgress}
          onBegin={() => navigate('/accounts')}
          onContinue={() => navigate('/accounts')}
          onComplete={() => onChange({ customersCompletedAt: DateTime.now() })}
          onRestart={() => onChange({ customersCompletedAt: null })}
          onLearnMore={onLearnMore}
        />
      </div>
    </section>
  )
}

export default OnboardingTasks
