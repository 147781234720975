/**
 * Slim Badge Component
 * -----------------
 * Badge for visually highlighting certain things
 *
 * props
 * -----------------
 * title - the title of the button
 */

import classNames from 'classnames'
import React from 'react'

type SlimBadgeSize = 'sm' | 'md' | 'lg'

interface BadgeProps {
  title: string | React.ReactNode
  color?: string
  textColor?: string
  className?: string
  size?: SlimBadgeSize
}
const SlimBadge = ({
  title,
  color = 'bg-yellow-500',
  textColor = 'text-gray-900',
  className,
  size = 'md',
}: BadgeProps) => (
  <span
    className={classNames(
      'inline-flex justify-center items-center group-hover:shadow transform duration-200 group-hover:scale-110 inline-block whitespace-nowrap',
      size === 'sm' && 'text-xs py-0.5 px-2 rounded-sm',
      size === 'md' && 'text-sm py-0.5 px-3 rounded-sm',
      size === 'lg' && 'py-1 px-3 rounded-md',
      textColor,
      color,
      className
    )}
  >
    {title}
  </span>
)

export default SlimBadge
