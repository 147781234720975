import Uppy from '@uppy/core'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useRef } from 'react'

import useToggle from '../../hooks/useToggle'

import TimeM from '../../modules/Time'

import InputLabel from '../InputLabel'
import DateInput from './Date'

const allowedFileTypes = ['.jpg', '.jpeg', '.png', '.pdf']

const InvoiceInput = ({
  uppy,
  dueDate,
  onDueDateChange,
  onFileInputError,
}: {
  uppy: Uppy
  dueDate: DateTime | undefined
  onDueDateChange: (newDueDate: DateTime | undefined) => void
  onFileInputError: (error: string) => void
}) => {
  const [invoiceFileDropFocused, invoiceFileDropFocusedToggler] = useToggle()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onFilesChange = (files: (File | null)[]) => {
    files.forEach((file) => {
      if (file) {
        try {
          // Clear the uploaded file, because we only want one
          uppy.cancelAll()
          uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
          })
          if (fileInputRef.current) {
            const dataTransfer = new DataTransfer()
            dataTransfer.items.add(file)
            fileInputRef.current.files = dataTransfer.files
            fileInputRef.current.files = dataTransfer.files
          }
        } catch (error) {
          const err = error as { isRestriction: boolean }
          onFileInputError(err.isRestriction ? `${err}` : 'Error attaching file')
        }
      }
    })
  }

  return (
    <>
      <InputLabel label="Invoice File" htmlFor="invoice-upload">
        <input
          ref={fileInputRef}
          required
          id="invoice-upload"
          className={classNames(
            'p-2 text-sm rounded-md border-gray-300 border shadow-sm focus:border-indigo-500 focus:ring-indigo-500',
            {
              'ring-1 ring-orange-500 border-orange-500': invoiceFileDropFocused,
            }
          )}
          type="file"
          accept={allowedFileTypes.join(',')}
          onChange={(event) => onFilesChange(Array.from(event.target.files || []))}
          onDragLeave={(e) => {
            e.preventDefault()
            invoiceFileDropFocusedToggler.off()
          }}
          onDragOver={(e) => {
            e.preventDefault()
            invoiceFileDropFocusedToggler.on()
          }}
          onDragEnter={(e) => e.preventDefault()}
          onDrop={(event) => {
            event.preventDefault()
            invoiceFileDropFocusedToggler.off()
            const files = event.dataTransfer.items
              ? Array.from(event.dataTransfer.items)
                  .filter((item) => item.kind === 'file')
                  .map((item) => item.getAsFile())
              : Array.from(event.dataTransfer.files)
            onFilesChange(files)
          }}
        />
      </InputLabel>
      <InputLabel label="Invoice Due Date" htmlFor="invoice-due-date">
        <DateInput
          id="invoice-due-date"
          required
          value={dueDate}
          onChange={onDueDateChange}
          min={TimeM.toDateInputFormat(DateTime.now())}
        />
      </InputLabel>
    </>
  )
}

export default InvoiceInput
