import { UNKNOWN_ERROR } from '@/gf/providers/MsgsProvider'
import { ApolloError } from '@apollo/client'

export type OnErrorCallback = (message: string, needsReload: boolean) => void

const handleError = (err: unknown, callback: OnErrorCallback) => {
  const apolloErr = err as ApolloError
  if (!apolloErr.graphQLErrors) {
    callback(UNKNOWN_ERROR, false)
    return
  }

  switch (apolloErr.graphQLErrors[0]?.message) {
    case 'request_already_quoted_by_store':
      callback('This request has already been quoted by someone on your team.', true)
      break
    default:
      callback(UNKNOWN_ERROR, false)
  }
}

export { handleError }
