import { Address } from '@/types'
import { useEffect, useState } from 'react'
import { useSetupContext } from '../context'

export type Fields = {
  name: string
  address: Address | null
  logoUrl: string | null
}

const useForm = () => {
  const { loading, store } = useSetupContext()
  const [fields, setFields] = useState<Omit<Fields, 'logoUrl'>>({ name: '', address: null })
  const [logoUrl, updateLogoUrl] = useState<Fields['logoUrl']>(null)
  const updateFields = (updates: Partial<typeof fields>) => setFields({ ...fields, ...updates })

  useEffect(() => {
    if (!loading) {
      updateFields({
        name: store.name,
        address: store.address && {
          firstName: null,
          lastName: null,
          companyName: null,
          ...store.address,
        },
      })

      updateLogoUrl(store.logo?.url || null)
    }
  }, [loading])

  return { fields: { ...fields, logoUrl } satisfies Fields, updateFields, updateLogoUrl }
}

export default useForm
