import { Table, Thead, Tr, Th, Tbody } from '@/gf/components/Table'
import Subscription from './Subscription'

export type Notification = { id: string; scopes: string[] }

export type NotificationSubscription = {
  notificationId: string
  channel: string
  scope: string
}

const Subscriptions = ({
  notifications,
  notificationSubscriptions,
  update,
}: {
  notifications?: Notification[]
  notificationSubscriptions: NotificationSubscription[]
  update: (notificationId: string, channel: string, scope: string) => void
}) => (
  <Table>
    <Thead>
      <Tr>
        <Th>Notification</Th>
        <Th>Text Message</Th>
      </Tr>
    </Thead>
    <Tbody>
      {notifications?.map((notif) => (
        <Subscription
          notification={notif}
          notificationSubscriptions={notificationSubscriptions.filter(
            (ns) => ns.notificationId === notif.id
          )}
          update={(channel, scope) => update(notif.id, channel, scope)}
          key={notif.id}
        />
      ))}
    </Tbody>
  </Table>
)

export default Subscriptions
