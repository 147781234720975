import NextPurchaseOrderNumber from '@/buyers/components/NextPurchaseOrderNumber'
import Link from '@/gf/components/Link'

const GeneratedPONumber = ({ storeOrderId }: { storeOrderId: string }) => (
  <div className="text-sm block">
    <div className="flex gap-x-3">
      <span className="truncate flex-grow">
        <NextPurchaseOrderNumber storeOrderId={storeOrderId} />
      </span>
      <Link.T to="/settings/organization#approval-settings">Edit</Link.T>
    </div>

    <p className="italic text-gray-500">(Auto-Generated)</p>
  </div>
)

export default GeneratedPONumber
