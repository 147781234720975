import UserMenu from './UserMenu'
import { ReactNode } from 'react'
import { ChatAltIcon, ClipboardListIcon, LightningBoltIcon } from '@heroicons/react/outline'
import type { HeaderActions } from './types'

const ActionButton = ({
  children,
  onClick,
  notificationCount = 0,
}: {
  children: ReactNode
  onClick: () => void
  notificationCount?: number
}) => (
  <button
    type="button"
    onClick={onClick}
    className="inline-flex w-8 h-8 items-center justify-center text-gray-500 hover:text-blue-600 hover:bg-gray-100 rounded transition-colors duration-300 relative"
  >
    {notificationCount > 0 && (
      <span className="flex absolute bg-gradient-to-tr from-blue-400 to-blue-200 text-blue-900 font-medium border-2 border-white rounded-full h-5 w-5 -top-[0.7rem] -right-2 text-xs items-center justify-center">
        {notificationCount}
      </span>
    )}
    {children}
  </button>
)

const HeaderActions = ({ userName, actions }: { userName: string; actions: HeaderActions }) => (
  <>
    <div className="flex gap-x-2 md:gap-x-6 items-center">
      {actions.map((action) => (
        <ActionButton key={action.label} onClick={action.onClick} notificationCount={action.count}>
          {action.icon}
        </ActionButton>
      ))}
    </div>

    <UserMenu name={userName} />
  </>
)

export const TasksIcon = () => <ClipboardListIcon className="inline-block h-5 w-5" />
export const ActivitiesIcon = () => <LightningBoltIcon className="inline-block h-5 w-5" />
export const MessagesIcon = () => <ChatAltIcon className="inline-block h-5 w-5" />

export default HeaderActions
