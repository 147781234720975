import { SortOrder } from '@/dealers/_gen/gql'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { formatShortTimeToQuoteMins, rfqsToMedianTimeToQuoteMins } from '../../Service'
import { useContext } from '../context'

const Customer = () => {
  const context = useContext()
  const { rfqs, orgById, breakdownCustomerSort: sort, update } = context
  const sortField = sort ? sort[0] : 'total'
  const sortOrder = sort ? (sort[1] === 'desc' ? 'desc' : 'asc') : 'desc'
  const rfqsByOrgId = rfqs && groupBy(rfqs, (rfq) => rfq.quote.customer.organization.id)

  const unsortedRows =
    rfqsByOrgId &&
    map(rfqsByOrgId, (subRfqs, orgId) => ({
      orgId,
      orgName: orgById && orgById[orgId].name,
      quoteCount: subRfqs.length,
      medianTimeToQuoteMins: rfqsToMedianTimeToQuoteMins(subRfqs),
    }))

  const orderByField = (() => {
    if (sortField === 'send-quote') return 'medianTimeToQuoteMins'
    if (sortField === 'customer') return 'orgName'
    if (sortField === 'quotes') return 'quoteCount'
    return sortField
  })()

  const rows = unsortedRows && orderBy(unsortedRows, orderByField, sortOrder)

  return (
    <ReportingTable
      data={rows}
      getRowKey={(row) => row.orgId}
      checkbox={{
        getChecked: ({ orgId }) => orgId === context.orgId,
        onToggleRow: ({ orgId }) => update({ orgId: orgId !== context.orgId ? orgId : null }),
        onClear: () => update({ orgId: null }),
      }}
      sortBy={{
        sortBy: {
          field: sortField,
          order: sortOrder === 'desc' ? SortOrder.Desc : SortOrder.Asc,
        },
        setSortBy: (sortBy) => update({ breakdownCustomerSort: sortBy }),
      }}
      columns={[
        { header: 'Customer', getValue: ({ orgName }) => orgName, sortByField: 'customer' },
        { header: 'Quotes', getValue: ({ quoteCount }) => quoteCount, sortByField: 'quotes' },
        {
          header: 'Send Quote',
          getValue: ({ medianTimeToQuoteMins }) =>
            medianTimeToQuoteMins !== null ? formatShortTimeToQuoteMins(medianTimeToQuoteMins) : '',
          sortByField: 'send-quote',
        },
      ]}
    />
  )
}

export default Customer
