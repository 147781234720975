import type { StoreOrder } from '../../LimitedOrder'

type PickupAddress = Exclude<StoreOrder['pickupAddress'], null>
type ShippingAddress = Exclude<StoreOrder['shippingAddress'], null>

const Address = ({ address }: { address: PickupAddress | ShippingAddress }) => (
  <div className="text-base">
    {address.lineOne && <div>{address.lineOne}</div>}
    {address.lineTwo && <div>{address.lineTwo}</div>}

    <div>
      {address.city}, {address.state} {address.postalCode}
    </div>
  </div>
)

export default Address
