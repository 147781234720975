import { useContext } from 'react'
import SessionContext from '../contexts/SessionContext'

const useSession = () => {
  const session = useContext(SessionContext)

  if (session === undefined) throw new Error('must be used within provider')

  return session
}

export default useSession
