import { useNavigate } from 'react-router-dom'

import { SelectedPaymentMethod } from '@/buyers/_gen/gql'

import useSession from '@/buyers/hooks/useSession'
import { useOrderContext } from '../context'

// Returns a function to be called after quote approval that will redirect to the close-request modal if the request
// is not already closed.
const useOnApproved = () => {
  const {
    storeOrder: {
      order: { requestForQuote },
    },
    refetchStoreOrder,
  } = useOrderContext()
  const { user } = useSession()

  const navigate = useNavigate()

  // A function to be called after quote approval that will redirect to the close-request modal if the request
  // is not already closed.
  const onApproved = (paymentMethod: SelectedPaymentMethod) => {
    // The Stripe checkout modal redirects to close-request on its own
    if (paymentMethod !== SelectedPaymentMethod.Stripe) {
      if (requestForQuote && !requestForQuote.closedAt) {
        refetchStoreOrder()
        // Request is automatically closed for unclaimed users
        if (user.claimed) navigate('close-request')
      }
    }
  }

  return onApproved
}

export default useOnApproved
