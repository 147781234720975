import Page from '@/gf/components/Page'
import Frame from '../components/Frame'
import DetailsPageWithSidebar from '@/gf/components/Layout/DetailsPageWithSidebar'
import { useCustomerAccountDetailsQuery } from '../_gen/gql'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import Sidebar from './CustomerAccount/Sidebar'
import RouterTabs from '@/gf/components/Layout/RouterTabs'
import Urls from '../modules/Urls'
import { RssIcon, UserGroupIcon } from '@heroicons/react/outline'
import Activity from './CustomerAccount/Activity'
import Contacts from './CustomerAccount/Contacts'
import Id from '@/gf/modules/Id'

const CustomerAccount = () => {
  const { customerAccountId } = useParams<{ customerAccountId: string }>() as {
    customerAccountId: string
  }
  const { data, refetch } = useCustomerAccountDetailsQuery({ variables: { id: customerAccountId } })

  if (!data?.customer) {
    return null
  }

  const baseUrl = Urls.customerAccount(customerAccountId)

  return (
    <Frame
      breadcrumbs={{
        copy: 'Back to Dashboard',
        crumbs: [
          { name: 'Customers', href: Urls.customerAccounts() },
          {
            name: `Customer ${Id.shorten(customerAccountId)}`,
            href: Urls.customerAccount(customerAccountId),
          },
        ],
      }}
    >
      <Page>
        <DetailsPageWithSidebar
          sidebar={<Sidebar customerAccount={data.customer} onDataChanged={refetch} />}
        >
          <RouterTabs
            tabs={[
              { label: 'Activity', path: `${baseUrl}/activity`, icon: RssIcon },
              { label: 'Contacts', path: `${baseUrl}/contacts`, icon: UserGroupIcon },
            ]}
          >
            <div className="p-4">
              <Routes>
                <Route path="" element={<Navigate to="activity" replace />} />
                <Route
                  path="activity"
                  element={
                    <Activity
                      customerAccountId={data.customer.id}
                      address={data.customer.address}
                    />
                  }
                />
                <Route
                  path="contacts"
                  element={<Contacts customerAccountId={customerAccountId} />}
                />
              </Routes>
            </div>
          </RouterTabs>
        </DetailsPageWithSidebar>
      </Page>
    </Frame>
  )
}

export default CustomerAccount
