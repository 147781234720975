import { Transition } from '@headlessui/react'
import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'
import { MessageC } from '../../types'
import Action from './Action'

const Message: MessageC = ({ msg, removeMsg }) => {
  const parseTitleFromTone = () => {
    switch (msg.tone) {
      case 'positive':
        return 'Success!'

      case 'negative':
        return 'Error'

      case 'neutral':
        return 'Info'

      default:
        return 'Info'
    }
  }

  const parseIconFromTone = () => {
    switch (msg.tone) {
      case 'positive':
        return <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />

      case 'negative':
        return <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />

      case 'neutral':
        return <InformationCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />

      default:
        return <InformationCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />
    }
  }

  return (
    <div className="z-20 w-full flex flex-col items-center space-y-4 sm:items-end">
      {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
      <Transition
        appear
        show
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="max-w-sm w-full bg-white shadow-md rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">{parseIconFromTone()}</div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm text-gray-900">{parseTitleFromTone()}</p>
                <p className="mt-1 text-sm text-gray-500">{msg.text}</p>
                {msg.action && (
                  <Action.T
                    onClick={() => {
                      msg.action?.callback()

                      removeMsg(msg)
                    }}
                    className="mt-4 mb-2"
                  >
                    {msg.action.text}
                  </Action.T>
                )}
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  type="button"
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    removeMsg(msg)
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default Message
