import { useEffect, useRef, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import pick from 'lodash/pick'
import nth from 'lodash/nth'
import CountryStates from '@/suppliers/modules/CountryStates'
import Smarty from '@/suppliers/modules/Smarty'
import AddressM from '../../../modules/Address'
import {
  AddressSuggestion,
  AutocompleteSuggestion,
  InternationalAddressSuggestion,
  Maybe,
} from '../../../../types'
import useOnClickOutside from '../../../hooks/useClickOutside'
import Field from './Field'
import TextInput from './TextInput'
import Select from '../Select'

const emptyAddress = AddressM.init()

type Value = {
  firstName: string | null
  lastName: string | null
  companyName: string | null
  lineOne: string
  lineTwo: string | null
  city: string
  state: string
  postalCode: string | null
  country: string
}

interface AddressInputProps {
  address: Maybe<Value>
  onChange: (newAddress: Value) => void
  requireFirstLastName?: boolean
  hideFirstLastName?: boolean
  hideCompany?: boolean
}

const AddressInput = ({
  address,
  onChange,
  requireFirstLastName,
  hideFirstLastName = false,
  hideCompany = false,
}: AddressInputProps) => {
  const addressQueryField = useRef<HTMLInputElement>(null)
  const [showAutocompleteSuggestions, setShowAutocompleteSuggestions] = useState(false)
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<AutocompleteSuggestion[]>(
    []
  )

  const newAddress: Value = address
    ? {
        companyName: address.companyName || '',
        firstName: address.firstName || '',
        lastName: address.lastName || '',
        lineOne: address.lineOne || '',
        lineTwo: address.lineTwo || '',
        postalCode: address.postalCode || '',
        city: address.city || '',
        state: address.state || '',
        country: address.country || emptyAddress.country,
      }
    : emptyAddress
  newAddress.country = newAddress.country ?? 'United States'
  const international = newAddress.country !== 'United States'
  const addressQueryWrapper = useRef(null)
  useOnClickOutside(addressQueryWrapper, () => setShowAutocompleteSuggestions(false))

  const selectedCountryCode =
    CountryStates.countries.find((country) => country.name === newAddress.country)?.isoCode ?? 'US'
  const states = CountryStates.states[selectedCountryCode]
  const stateOptions = states.map((state) => ({
    value: state.isoCode,
    label: state.name,
  }))

  const debouncedAutocompleteSearch = useRef(
    Smarty.debouncedAutocompleteSearch((suggestions) => setAutocompleteSuggestions(suggestions))
  ).current

  useEffect(
    () =>
      // Cleanup any in-progress search requests when component is unmounted
      () => {
        debouncedAutocompleteSearch.cancel()
      },
    [debouncedAutocompleteSearch]
  )

  const selectAddress = (suggestion: AddressSuggestion | InternationalAddressSuggestion) => {
    // Only International Addresses have an addressId
    if ('addressId' in suggestion) {
      Smarty.autocompleteSearch(suggestion.addressId, international, '', suggestion.addressId).then(
        (suggestions) => {
          const internationalAddress = nth(suggestions, 0)?.suggestion
          if (
            suggestions.length === 1 &&
            internationalAddress &&
            'lineOne' in internationalAddress
          ) {
            onChange({
              ...newAddress,
              ...pick(internationalAddress, ['lineOne', 'lineTwo', 'city', 'state', 'postalCode']),
            })
            setShowAutocompleteSuggestions(false)
          } else {
            setAutocompleteSuggestions(suggestions)
            onChange({
              ...newAddress,
              lineOne: suggestion.addressText,
            })
            addressQueryField.current?.focus()
          }
        }
      )
    } else if (suggestion.entries > 1) {
      // Narrow down the selection
      const selected = `${suggestion.lineOne} (${suggestion.entries}) ${suggestion.city} ${suggestion.state} ${suggestion.postalCode}`
      Smarty.autocompleteSearch(suggestion.lineOne, international, selected).then((suggestions) =>
        setAutocompleteSuggestions(suggestions)
      )
      onChange({
        ...newAddress,
        lineOne: suggestion.lineOne,
      })
      addressQueryField.current?.focus()
    } else {
      // Select the address
      onChange({
        ...newAddress,
        ...pick(suggestion, ['lineOne', 'lineTwo', 'city', 'state', 'postalCode']),
      })
      setShowAutocompleteSuggestions(false)
    }
  }

  return (
    <>
      {!hideFirstLastName && (
        <div className="grid grid-cols-2 gap-2">
          <Field label="First name">
            <TextInput
              required={requireFirstLastName}
              value={newAddress.firstName ?? ''}
              onChange={(e) => onChange({ ...newAddress, firstName: e.target.value })}
            />
          </Field>

          <Field label="Last name">
            <TextInput
              required={requireFirstLastName}
              value={newAddress.lastName ?? ''}
              onChange={(e) => onChange({ ...newAddress, lastName: e.target.value })}
            />
          </Field>
        </div>
      )}
      {!hideCompany && (
        <Field label="Company">
          <TextInput
            value={newAddress.companyName ?? ''}
            onChange={(e) =>
              onChange({
                ...newAddress,
                companyName: e.target.value,
              })
            }
          />
        </Field>
      )}

      <div className="relative" ref={addressQueryWrapper}>
        <Field label="Address">
          <div className="relative">
            <select
              value={selectedCountryCode}
              onChange={(e) => {
                onChange({
                  ...newAddress,
                  country: CountryStates.getCountryByCode(e.target.value)?.name ?? '',
                  // Clear the address for the new country
                  lineOne: emptyAddress.lineOne,
                  lineTwo: emptyAddress.lineTwo,
                  city: emptyAddress.city,
                  state: emptyAddress.state,
                  postalCode: emptyAddress.postalCode,
                })
                // Clear autocomplete suggestions
                setAutocompleteSuggestions([])
              }}
              className="top-px left-px bottom-px rounded-l-lg outline-none absolute border-0 border-r border-slate-300"
            >
              {CountryStates.countries.map((c) => (
                <option key={c.isoCode} value={c.isoCode}>
                  {c.flag}
                </option>
              ))}
            </select>
            <TextInput
              ref={addressQueryField}
              required
              value={newAddress.lineOne}
              onChange={(e) => {
                onChange({
                  ...newAddress,
                  lineOne: e.target.value,
                })
                setShowAutocompleteSuggestions(true)
                debouncedAutocompleteSearch(e.target.value, international)
              }}
              onClick={() => setShowAutocompleteSuggestions(true)}
              className="pl-20"
            />
          </div>
        </Field>

        {showAutocompleteSuggestions && (
          <div className="absolute rounded-md shadow-md bg-white w-full max-h-60 overflow-y-scroll z-10">
            {autocompleteSuggestions.map((autocompleteSuggestion) => (
              <button
                key={autocompleteSuggestion.display}
                className="text-left text-sm text-gray-700 px-4 py-2 hover:bg-gray-100 cursor-pointer flex flex-row justify-between items-center w-full"
                type="button"
                onClick={() => selectAddress(autocompleteSuggestion.suggestion)}
              >
                <span>{autocompleteSuggestion.display}</span>
                {autocompleteSuggestion.suggestion.entries > 1 && (
                  <ChevronRightIcon className="h-4 w-4 flex text-gray-500" />
                )}
              </button>
            ))}
          </div>
        )}
      </div>

      <Field label="Address Line 2">
        <TextInput
          value={newAddress.lineTwo ?? ''}
          onChange={(e) =>
            onChange({
              ...newAddress,
              lineTwo: e.target.value,
            })
          }
        />
      </Field>

      <div className="grid grid-cols-3 gap-2">
        <Field label="City">
          <TextInput
            required
            value={newAddress.city}
            onChange={(e) =>
              onChange({
                ...newAddress,
                city: e.target.value,
              })
            }
          />
        </Field>

        <Field label={selectedCountryCode === 'CA' ? 'Province' : 'State'}>
          <Select
            required
            options={stateOptions}
            value={stateOptions.find((o) => o.value === newAddress.state)}
            onChange={(option) =>
              option &&
              onChange({
                ...newAddress,
                // We currently store the state value as the state code, not the state name
                state: option.value,
              })
            }
            placeholder=""
            className="text-sm"
          />
        </Field>

        <Field label="Postal code">
          <TextInput
            required
            value={newAddress.postalCode ?? ''}
            onChange={(e) =>
              onChange({
                ...newAddress,
                postalCode: e.target.value,
              })
            }
          />
        </Field>
      </div>
    </>
  )
}

export default AddressInput
