import { v4 as uuid } from 'uuid'

import { BlankFilter } from '../../../types'

const build = ({ id, fieldId }: { id?: string; fieldId: string }): BlankFilter => ({
  id: id || uuid(),
  typeId: 'blank',
  fieldId,
})

const toApiFilter = (filter: BlankFilter) => ['blank', filter.fieldId]

export default { build, toApiFilter }
