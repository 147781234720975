import ReactPagination from 'react-paginate'

import Text from './Text'

import type { Pagination } from '../../types'

import useWindowWidth from '../hooks/useWindowWidth'
import Ghost from './Ghost'

export const PaginationGhost = () => (
  <div className="flex sm:flex-row flex-col justify-between items-start sm:space-y-0 space-y-2">
    <Ghost className="inline-block w-14 h-5 bg-gray-300" />
    <Ghost className="inline-block w-56 h-9 bg-gray-300" />
  </div>
)

const Pagination = ({
  pagination,
  page,
  updatePage,
}: {
  pagination?: Pagination
  page: number
  updatePage: (newPage: number) => void
}) => {
  const windowWidth = useWindowWidth()
  const xsm = windowWidth < 410
  const sm = windowWidth < 640
  const md = windowWidth < 768

  const onPageChange = ({ selected }: { selected: number }) => {
    updatePage(selected + 1)
  }

  if (!pagination || pagination.totalResults === 0) return null

  return (
    <div className="flex sm:flex-row flex-col justify-between items-start sm:space-y-0 space-y-2">
      <div className="text-sm">
        <Text>{pagination.totalResults.toLocaleString()} results</Text>
      </div>

      {pagination.totalPages > 1 && (
        <ReactPagination
          forcePage={page - 1}
          pageCount={pagination.totalPages}
          pageRangeDisplayed={sm ? 0 : md ? 3 : 5}
          marginPagesDisplayed={xsm ? 0 : 1}
          onPageChange={onPageChange}
          containerClassName="flex"
          pageLinkClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm"
          breakLinkClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm"
          previousLinkClassName="rounded-l-md bg-white border-gray-300 text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm"
          nextLinkClassName="rounded-r-md bg-white border-gray-300 text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm"
          activeLinkClassName="z-10 bg-blue-100 border-blue-500 text-blue-500 hover:bg-blue-100"
        />
      )}
    </div>
  )
}

export default Pagination
