import { useMemo } from 'react'
import classNames from 'classnames'

import { HeroIcon } from '@/types'

interface FilterProgressStep {
  name: string
  icon?: HeroIcon
  iconClassName?: string
  count?: number
}

const SelectedIslandNavIcon = ({
  step,
  textColorClassName,
  className,
}: {
  step: FilterProgressStep
  textColorClassName?: string
  className?: string
}) => (
  <div
    className={classNames(
      'h-10 px-3 xl:px-4 flex w-full items-center text-sm font-medium',
      textColorClassName,
      className
    )}
    aria-current="step"
  >
    {step.name}
  </div>
)

const NotSelectedIslandNavIcon = ({
  step,
  onClick,
  className,
}: {
  step: FilterProgressStep
  onClick: () => void
  className?: string
}) => (
  <button
    className={classNames(
      'h-10 px-3 xl:px-4 flex w-full items-center text-sm font-medium text-gray-500',
      className
    )}
    type="button"
    onClick={onClick}
  >
    {step.name}
  </button>
)

const SelectedNavIcon = ({
  step,
  className,
  textColorClassName,
  bgColorClassName,
}: {
  step: FilterProgressStep
  className?: string
  textColorClassName?: string
  bgColorClassName?: string
}) => {
  const IconComponent = useMemo(() => step.icon, [step.icon]) as React.ElementType
  return (
    <div
      className={classNames(
        'flex w-full justify-between items-center px-0 lg:px-1 pr-1 py-0 text-sm font-medium',
        className
      )}
      aria-current="step"
    >
      <div className="flex items-center">
        {step.icon && (
          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center">
            <IconComponent className={classNames('h-6', step.iconClassName, textColorClassName)} />
          </span>
        )}
        <span
          className={classNames(
            'ml-0 xl:ml-2 text-sm text-center font-medium leading-none',
            textColorClassName
          )}
        >
          {step.name}
        </span>
      </div>
      <div className="flex items-center">
        {typeof step.count === 'number' && (
          <div
            className={classNames(
              'ml-4 px-2 inline-flex justify-center items-center rounded-full',
              bgColorClassName
            )}
          >
            <span className="text-sm font-medium text-white">{step.count}</span>
          </div>
        )}
        <span className="h-10 w-6">&nbsp;</span>
      </div>
    </div>
  )
}

const NotSelectedNavIcon = ({
  step,
  onClick,
  className,
}: {
  step: FilterProgressStep
  onClick: () => void
  className?: string
}) => {
  const IconComponent = useMemo(() => step.icon, [step.icon]) as React.ElementType
  return (
    <button
      className={classNames('group flex w-full items-center', className)}
      type="button"
      onClick={onClick}
    >
      <div className="w-full flex justify-between items-center px-0 lg:px-1 pr-1 py-0 text-sm font-medium">
        <div className="flex items-center">
          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center">
            <IconComponent className={classNames('h-6 text-slate-300', step.iconClassName)} />
          </span>
          <span className="ml-0 xl:ml-2 text-sm text-center font-medium text-gray-500 leading-none">
            {step.name}
          </span>
        </div>
        <div className="flex items-center">
          {typeof step.count === 'number' && (
            <div className="ml-4 px-2 inline-flex justify-center items-center bg-gray-100 rounded-full">
              <span className="text-sm font-medium text-gray-500">{step.count}</span>
            </div>
          )}
          <span className="h-10 w-6">&nbsp;</span>
        </div>
      </div>
    </button>
  )
}

const FilterProgressNav = ({
  steps,
  selectedIndex,
  setSelectedIndex,
  isFirstStepAnIsland = false,
  className,
  textColorClassName = 'text-gearflow',
  bgColorClassName = 'text-gearflow',
}: {
  steps: FilterProgressStep[]
  selectedIndex: number
  setSelectedIndex: (selectedIndex: number) => void
  isFirstStepAnIsland?: boolean
  className?: string
  textColorClassName?: string
  bgColorClassName?: string
}) => (
  <nav className={className} aria-label="Filters">
    <ol className="flex flex-col gap-y-1 xl:flex-row xl:gap-x-1.5">
      {isFirstStepAnIsland && (
        <div className="rounded-md border bg-white shadow-sm xl:flex">
          <li className="capitalize relative xl:flex xl:flex-1">
            {selectedIndex === 0 ? (
              <SelectedIslandNavIcon step={steps[0]} textColorClassName={textColorClassName} />
            ) : (
              <NotSelectedIslandNavIcon step={steps[0]} onClick={() => setSelectedIndex(0)} />
            )}
          </li>
        </div>
      )}
      <div className="flex-grow divide-y divide-gray-300 rounded-md border bg-white shadow-sm xl:flex xl:divide-y-0">
        {steps.map((step, stepIndex) =>
          isFirstStepAnIsland && stepIndex === 0 ? null : (
            <li key={step.name} className="capitalize relative xl:flex xl:flex-1">
              {stepIndex === selectedIndex ? (
                <SelectedNavIcon
                  step={step}
                  textColorClassName={textColorClassName}
                  bgColorClassName={bgColorClassName}
                />
              ) : (
                <NotSelectedNavIcon step={step} onClick={() => setSelectedIndex(stepIndex)} />
              )}
              {stepIndex !== steps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="absolute top-0 right-0 hidden h-full w-5 xl:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          )
        )}
      </div>
    </ol>
  </nav>
)

export default FilterProgressNav
