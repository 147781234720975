/**
 * Text Area Component
 * -----------------
 * Text area input for use in forms
 *
 * props
 * -----------------
 * label - label over the input
 * desc - description of the input
 * errors - text displayed for an error with this field
 * className - styles for this TextAreaField component
 * ref - input component ref
 */

import React from 'react'
import { TextAreaProps } from '../../types'
import Field from './Field'
import TextArea from './TextArea'

type Props = TextAreaProps & {
  label: string
  errors?: string | null
}

const TextAreaField = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ label, errors, ...props }, ref) => (
    <Field label={label} errors={errors}>
      <TextArea id={label.toLowerCase()} {...props} ref={ref} rows={4} />
    </Field>
  )
)

export default TextAreaField
