import Action from '@/gf/components/Action'
import Modal from '@/gf/components/ModalNext'
import useMsgs from '@/gf/hooks/useMsgs'
import useUppy from '@/gf/hooks/useUppy'
import { ChangeEvent, useRef, useState } from 'react'

const UploadPod = ({
  add,
  onComplete,
  close,
}: {
  add: (urls: string[]) => Promise<unknown>
  onComplete: () => void
  close: () => void
}) => {
  const [_, msgr] = useMsgs()
  const [uploading, setUploading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const allowedFileTypes = ['.jpg', '.jpeg', '.png', '.pdf']

  const uppy = useUppy({
    allowedFileTypes,
    autoProceed: true,
    onComplete: async (files) => {
      await add(files.map((f) => f.url))
      close()
      setUploading(false)
      msgr.add('Proof of deliveries uploaded.', 'positive')
      onComplete()
    },
  })

  const addFiles = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setUploading(true)
    const files = target.files ? [...target.files] : []

    files.forEach((file) =>
      uppy.addFile({ source: 'file input', name: file.name, type: file.type, data: file })
    )
  }

  return (
    <Modal open onClose={close}>
      <div className="p-6 space-y-6">
        <div className="text-xl">Upload Proof of Delivery</div>

        <p className="text-base prose">
          Proof of delivery will help protect you from delivery disputes.
        </p>

        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          accept={allowedFileTypes.join(',')}
          multiple
          onChange={addFiles}
        />

        <div className="flex gap-2 flex-row-reverse">
          <Action.P onClick={() => fileInputRef.current?.click()} performing={uploading}>
            Select files&hellip;
          </Action.P>

          <Action.S onClick={close}>Cancel</Action.S>
        </div>
      </div>
    </Modal>
  )
}

export default UploadPod
