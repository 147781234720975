import { XIcon } from '@heroicons/react/solid'
import { twMerge } from 'tailwind-merge'

import Box from '@/gf/components/Box'
import Link from '@/gf/components/Link'
import QuoteUploadButton, { QuoteUploadProps } from './QuoteUploadButton'

const QuoteUploadBar = ({
  onError,
  onUploadComplete,
  onUploadStarted,
  rfqId,
  cantParticipateLinkTo,
}: QuoteUploadProps & {
  cantParticipateLinkTo?: string
}) => (
  <Box className="flex grow flex-col lg:flex-row items-center">
    <div
      className={twMerge(
        'px-3 xl:px-6 py-3 lg:py-4 flex grow flex-col xl:flex-row gap-x-6 gap-y-2 items-center lg:items-start xl:items-center',
        cantParticipateLinkTo && 'border-0 lg:border-r'
      )}
    >
      <div className="flex grow flex-col gap-y-2 text-gray-900">
        <p className="font-medium text-base">Upload Quote</p>
        <p className="text-sm">
          Already created this quote in another application? Upload the file to add the line items
          here.
        </p>
      </div>
      <div className="flex shrink-0">
        <QuoteUploadButton
          onError={onError}
          onUploadComplete={onUploadComplete}
          onUploadStarted={onUploadStarted}
          rfqId={rfqId}
        />
      </div>
    </div>
    {cantParticipateLinkTo && (
      <div className="w-full px-3 xl:px-6 py-3 lg:py-4 flex flex-1 items-center justify-center border-t lg:border-0">
        <Link.S
          to="cant-participate"
          className="flex gap-x-1.5 items-center shrink-0 whitespace-nowrap"
        >
          <XIcon className="-mx-0.5 h-4 w-4" /> Can&apos;t Participate
        </Link.S>
      </div>
    )}
  </Box>
)

export default QuoteUploadBar
