import { FloatingPortal, flip, useFloating, useHover, useInteractions } from '@floating-ui/react'
import { InformationCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { ReactNode, useState } from 'react'

export const TooltipUnderlineText = ({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) => (
  <span className={classNames('underline decoration-dashed underline-offset-2', className)}>
    {children}
  </span>
)

type Props = {
  className?: string
  text: string | string[]
  element?: JSX.Element
  size?: 'small' | 'medium'
  left?: boolean
  autoPosition?: boolean
  useFlip?: boolean
  textClassName?: string
  children?: React.ReactNode
}

// TODO: on mobile change this to a modal?
const Tooltip: React.FC<Props> = ({
  className,
  text,
  element,
  size,
  left,
  autoPosition,
  useFlip,
  textClassName,
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const { x, y, strategy, refs, context } = useFloating({
    placement: autoPosition ? undefined : left ? 'bottom-end' : 'bottom-start',
    strategy: 'fixed',
    open: showTooltip,
    middleware: useFlip ? [flip()] : undefined,
    onOpenChange: setShowTooltip,
  })

  const hover = useHover(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([hover])

  const textArray = Array.isArray(text) ? text : [text]

  return (
    <div className={classNames(className)}>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children || element || <InformationCircleIcon className="h-5 w-5 text-gray-500" />}
      </div>

      {showTooltip && (
        <FloatingPortal>
          <div
            className={classNames(
              'bg-gray-700 text-gray-50 border border-gray-900 text-sm font-normal px-3 py-2 rounded-md shadow-sm text-center',
              size === 'small' ? 'w-64' : size === 'medium' ? 'w-80' : ''
            )}
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            {textArray.map((textLine) => (
              <p className={classNames('whitespace-normal', textClassName)} key={textLine}>
                {textLine}
              </p>
            ))}
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}

export default Tooltip
