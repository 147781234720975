const BoxIcon = ({ className = '' }) => (
  <svg
    className={className}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 7L12.5 3L4.5 7M20.5 7L12.5 11M20.5 7V17L12.5 21M4.5 7L12.5 11M4.5 7V17L12.5 21M12.5 11V21"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default BoxIcon
