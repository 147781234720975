import React, { InputHTMLAttributes, useEffect } from 'react'

import TextInput from './TextInput'
import Phone from '@/gf/modules/Phone'

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  value: string
  onChange: (newValue: string) => void
}

const PhoneInput = (props: Props) => {
  const { value, onChange } = props

  useEffect(() => {
    onChange(Phone.format(value))
  }, [])

  const onChangeFormat: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(Phone.format(e.target.value))
  }
  return <TextInput {...props} type="tel" value={value} onChange={onChangeFormat} />
}

export default PhoneInput
