import { SessionDocument, useStartProTrialMutation } from '@/dealers/_gen/gql'
import useReloadSession from '@/dealers/hooks/useReloadSession'
import Action from '@/gf/components/Action'
import Modal from '@/gf/components/ModalNext'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { ModalSize } from '@/types'
import { useNavigate } from 'react-router-dom'

const StartTrialModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const navigate = useNavigate()
  const [_, msgr] = useMsgs()
  const [processing, processingToggle] = useToggle()
  const reloadSession = useReloadSession()
  const [startTrialMutation] = useStartProTrialMutation({
    refetchQueries: [SessionDocument],
  })

  const startTrial = async () => {
    try {
      processingToggle.on()

      await startTrialMutation()

      msgr.add(
        "Nice! Enjoy your 30 day trial period and don't hesitate to contact us if you have any questions.",
        'positive'
      )

      await reloadSession()

      onClose()

      navigate('/dashboard')
    } catch (err) {
      console.error(err)
    } finally {
      processingToggle.off()
    }
  }

  return (
    <Modal size={ModalSize.MD} open={open} onClose={onClose}>
      <div className="p-6 space-y-6">
        <hgroup className="space-y-2 text-center">
          <h3 className="text-2xl font-medium">Try Parts Hub Pro with a 30 day free trial!</h3>
          <p className="text-lg">
            Manage all of your quotes and parts orders in one place across new and existing
            customers.
          </p>

          <p className="text-lg">Click to start your free trial now!</p>
        </hgroup>

        <div className="flex gap-x-4 justify-center">
          <Action.S onClick={onClose} className="w-40 font-medium text-base">
            Maybe later
          </Action.S>
          <Action.P
            onClick={() => startTrial()}
            performing={processing}
            disabled={processing}
            className="w-40 font-medium text-base"
          >
            Start free trial
          </Action.P>
        </div>

        <hr />

        <p className="text-gray-600 text-base text-center">
          This will start your trial automatically. After 30 days, you&apos;ll go back to your
          previous plan. No need to cancel or worry about extra charges.
        </p>
      </div>
    </Modal>
  )
}

export default StartTrialModal
