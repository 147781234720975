import { useUpdateAccountNumbersMutation } from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import TextInput from '@/gf/components/next/forms/TextInput'
import useMsgs from '@/gf/hooks/useMsgs'
import { useState } from 'react'

const UpdateAccountNumbersForm = ({
  open,
  onClose,
  customerAccountId,
  initialValue,
  onAfterSave,
}: {
  open: boolean
  onClose: () => void
  customerAccountId: string
  initialValue: string[]
  onAfterSave: () => void
}) => {
  const [updateAccountNumbers, { loading: saveInProgress }] = useUpdateAccountNumbersMutation()
  const [value, setValue] = useState(initialValue)
  const [_, msgs] = useMsgs()

  const updateValue = (updateIndex: number, newValue: string) =>
    setValue((prev) => prev.map((currentValue, i) => (i === updateIndex ? newValue : currentValue)))

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      await updateAccountNumbers({ variables: { customerAccountId, accountNumbers: value } })

      msgs.add('Account Number updated!', 'positive')

      onAfterSave()
      onClose()
    } catch (err) {
      console.error(err)

      msgs.add('Something went wrong', 'negative')
    }
  }

  const addAccountNumber = () => {
    setValue((prev) => [...prev, ''])
  }

  const removeAccountNumber = (index: number) =>
    setValue((prev) => prev.filter((_v, i) => i !== index))

  return (
    <Modal open={open} onClose={onClose}>
      <form className="relative p-8 flex flex-col gap-y-6" onSubmit={onSubmit}>
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />

        <h2 className="font-medium text-2xl">Update Account Numbers</h2>

        <div>
          <div className="space-y-4">
            {value.map((accountNumber, i) => (
              <div key={`${i * 50000}`} className="space-y-3">
                <div className="flex items-center justify-between leading-6">
                  Account Number {i + 1}
                  {value.length > 1 && (
                    <Action.T onClick={() => removeAccountNumber(i)} className="flex-shrink-0">
                      Remove
                    </Action.T>
                  )}
                </div>

                <TextInput
                  value={accountNumber}
                  onChange={(e) => updateValue(i, e.target.value)}
                  className="w-full"
                  required
                />
              </div>
            ))}
          </div>

          <Action.T onClick={addAccountNumber} className="mt-4">
            Add account number
          </Action.T>
        </div>

        <div className="flex gap-x-4 pt-2">
          <Action.S onClick={onClose} className="w-1/2">
            Cancel
          </Action.S>
          <Action.P type="submit" performing={saveInProgress} className="w-1/2">
            Save
          </Action.P>
        </div>
      </form>
    </Modal>
  )
}

export default UpdateAccountNumbersForm
