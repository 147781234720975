import { Fragment } from 'react'
import { Dialog, Portal, Transition } from '@headlessui/react'
import { ModalSize } from '@/types'
import classNames from 'classnames'

// This is the lowest Modal abstraction. To add functionality, do not modify this, but create another component that
// uses this component internally.
const Modal = ({
  open = true,
  onClose,
  children,
  size = ModalSize.SM,
  overflowHidden = true,
}: {
  open?: boolean
  onClose: () => void
  children: React.ReactNode
  size?: ModalSize
  overflowHidden?: boolean
}) => (
  <Portal>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'relative transform rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full',
                  {
                    'sm:max-w-lg': size === ModalSize.SM,
                    'sm:max-w-2xl': size === ModalSize.MD,
                    'sm:max-w-3xl': size === ModalSize.LG,
                    'sm:max-w-5xl': size === ModalSize.XL,
                  },
                  overflowHidden && 'overflow-hidden'
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  </Portal>
)

export default Modal
