import { useState, useEffect, useCallback } from 'react'
import debounce from 'lodash/debounce'
import keyBy from 'lodash/keyBy'

import Search from './BigSelect/Search'
import Options from './BigSelect/Options'

import { Option } from './BigSelect/types'

const listClassName =
  'bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md max-h-60 py-1 overflow-auto absolute w-full z-50'

const BigSelect = ({
  filter,
  setSelectedValue,
  selectedValue,
  loading = false,
  ...props
}: {
  filter: (term: string) => void
  setSelectedValue: (string: string | null) => void
  options: Option[] | undefined
  selectedValue: string | null
  loading?: boolean
}) => {
  const [term, setTerm] = useState<string | null>(null)

  const debouncedFetchOptions = useCallback(
    debounce((searchTerm: string) => {
      filter(searchTerm)
    }, 300),
    []
  )

  useEffect(() => () => debouncedFetchOptions.cancel(), [])

  const options = loading ? undefined : props.options
  const optionsByValue = keyBy(options, 'value')
  const selected = selectedValue && options ? optionsByValue[selectedValue] : null
  const clearTerm = () => setTerm(null)

  const search = (searchTerm: string) => {
    setTerm(searchTerm)
    if (term && searchTerm !== term) debouncedFetchOptions(searchTerm)
  }

  const selectOption = (option: Option) => {
    clearTerm()
    setSelectedValue(option.value)
  }

  const clearSelected = () => setSelectedValue(null)

  return (
    <div onBlur={clearTerm} className="space-y-2 relative">
      <Search search={search} term={term} selected={selected} clearSelected={clearSelected} />

      {term !== null && (
        <ul role="listbox" id="search-results" className={listClassName}>
          <Options options={options} selectOption={selectOption} selected={selected} />
        </ul>
      )}
    </div>
  )
}

export default BigSelect
