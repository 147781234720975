import { FormEvent, useState } from 'react'
import { ModalFormProps } from '../../types'
import Id from '../modules/Id'

import A from './A'
import ModalForm from './ModalForm'
import Checkbox from './next/forms/Checkbox'

interface Props extends Omit<ModalFormProps, 'title' | 'onSubmit'> {
  storeOrderId: string
  dashboardUrl: string
  title?: string
  onSubmit: (billed: boolean) => void
  showCustomerBilledCheckbox?: boolean
}
const InvoiceMarkAsPaidModal = ({
  storeOrderId,
  dashboardUrl,
  title = 'Mark as Paid',
  showCustomerBilledCheckbox,
  onSubmit,
  ...props
}: Props) => {
  const [customerBilled, setCustomerBilled] = useState(showCustomerBilledCheckbox === true)
  const _onSubmit = (e: FormEvent) => {
    e.preventDefault()

    onSubmit(customerBilled)
  }

  return (
    <ModalForm title={title} {...props} onSubmit={_onSubmit}>
      {storeOrderId && (
        <>
          <span>This will mark the Invoice for </span>
          <A.T href={`${dashboardUrl}/orders/${storeOrderId}`} target="_blank">
            Order {Id.shorten(storeOrderId)}
          </A.T>
          <span> as paid.</span>
        </>
      )}
      {showCustomerBilledCheckbox && (
        <label className="flex items-center gap-x-2 mt-4">
          <Checkbox
            checked={customerBilled}
            onChange={(e) => setCustomerBilled(e.target.checked)}
          />{' '}
          Billed the Customer
        </label>
      )}
    </ModalForm>
  )
}

export default InvoiceMarkAsPaidModal
