import { useState } from 'react'
import {
  StoreOrderState,
  useCreateShipmentMutation,
  useShipmentOrderItemsQuery,
  useMarkVendorDeliveryMutation,
} from '../../../_gen/gql'
import { CubeIcon } from '@heroicons/react/outline'

import Modal from '@/gf/components/ModalNext'
import CloseModalButton from '@/gf/components/CloseModalButton'
import CreateShipmentForm from './CreateShipmentForm'

const CreateShipmentModal = ({
  open,
  onClose,
  storeOrderId,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  storeOrderId: string
}) => {
  const [error, setError] = useState<string | null>(null)
  const [createShipment, { loading: performing }] = useCreateShipmentMutation()
  const [markVendorDeliveryMutation, { loading: vendorDeliveryPerforming }] =
    useMarkVendorDeliveryMutation()
  const { data } = useShipmentOrderItemsQuery({
    variables: { storeOrderId },
    fetchPolicy: 'network-only',
  })

  if (!data?.storeOrder) return null

  const cantShip = ![
    StoreOrderState.Received,
    StoreOrderState.Processing,
    StoreOrderState.PartiallyShipped,
  ].includes(data.storeOrder.state)

  if (cantShip) return null

  return (
    <Modal open={open} onClose={onClose}>
      <CloseModalButton onClick={onClose} className="absolute top-2 right-2" />

      <h2 className="font-medium text-xl flex items-center gap-x-4 border-b border-slate-2 00 px-4 py-3">
        <CubeIcon className="h-8 inline-block" /> Shipping Requested
      </h2>

      <div className="my-4">
        <CreateShipmentForm
          storeOrder={data.storeOrder}
          performing={performing || vendorDeliveryPerforming}
          error={error}
          onClose={onClose}
          onSubmit={(form) => {
            setError(null)
            createShipment({
              variables: {
                storeOrderId,
                trackingNumber: form.trackingNumber,
                shippingCarrier: form.shippingCarrier,
                trackingLink: null,
                shipmentItems: form.shipmentItems,
                notifyCustomer: true,
                notes: form.notes,
              },
            })
              .then(() => {
                onClose()
                onSuccess()
              })
              .catch(() => {
                setError('Error creating shipment. Please contact support.')
              })
          }}
          onSubmitVendorDelivery={(form) => {
            setError(null)
            markVendorDeliveryMutation({
              variables: {
                ...form,
                storeOrderId,
              },
            })
              .then(() => {
                onSuccess()
                onClose()
              })
              .catch(() => {
                setError('Error marking Order out for delivery. Please contact support.')
              })
          }}
        />
      </div>
    </Modal>
  )
}

export default CreateShipmentModal
