import { CheckCircleIcon } from '@heroicons/react/solid'
import Modal from '@/gf/components/ModalNext'
import { ModalSize } from '@/types'
import CloseModalButton from '@/gf/components/CloseModalButton'
import useMsgs from '@/gf/hooks/useMsgs'
import ScheduleMessageForm from '@/dealers/components/ScheduleMessageForm'
import { DateTime } from 'luxon'

const QuoteSubmittedReminderModal = ({
  open,
  onClose,
  rfqId,
}: {
  open: boolean
  onClose: () => void
  rfqId: string
}) => {
  const [_, msgs] = useMsgs()

  const onSuccess = (scheduledFor: DateTime) => {
    msgs.add(`We'll send a reminder to the customer on ${scheduledFor.toFormat('ff')}`, 'positive')

    onClose()
  }

  const onError = () => msgs.addUnknownError()

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.SM}>
      <header className="bg-green-100 flex justify-center items-center relative h-40">
        <div className="flex flex-col items-center gap-y-2 text-green-700">
          <h3 className="text-3xl font-medium flex gap-x-3">
            <CheckCircleIcon className="inline-block h-8 w-8 mt-0.5" /> Quote Submitted!
          </h3>
        </div>
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />
      </header>

      <ScheduleMessageForm
        rfqId={rfqId}
        onSuccess={onSuccess}
        onError={onError}
        onCancel={onClose}
      />
    </Modal>
  )
}

export default QuoteSubmittedReminderModal
