import classNames from 'classnames'

type SidebarLayoutProps = Omit<JSX.IntrinsicElements['div'], 'children'> & {
  children: [React.ReactNode, React.ReactNode]
  primary?: JSX.IntrinsicElements['div']
  sidebar?: JSX.IntrinsicElements['div']
}

const SidebarLayout = ({
  className,
  children,
  primary = {},
  sidebar,
  ...props
}: SidebarLayoutProps) => {
  const { className: primaryClassName, ...otherPrimaryProps } = primary

  return (
    <div className={classNames('grid grid-cols-1 md:grid-cols-3 gap-6', className)} {...props}>
      <div className={classNames('md:col-span-2', primaryClassName)} {...otherPrimaryProps}>
        {children[0]}
      </div>

      <div {...sidebar}>{children[1]}</div>
    </div>
  )
}

export default SidebarLayout
