import { twMerge } from 'tailwind-merge'

const Dot = ({ size = 'sm', className }: { size?: 'sm' | 'md'; className?: string }) => (
  <div
    className={twMerge(
      'inline-flex shrink-0 rounded-full bg-gray-500',
      size === 'sm' ? 'w-0.5 h-0.5 min-w-0.5' : 'w-1 h-1 min-w-1',
      className
    )}
  />
)

export default Dot
