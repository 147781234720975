import BaseTitleMetric from '@/gf/components/next/TitleMetric'
import { formatMoneyInt, total } from '../OpenQuotes'
import { useContext } from './Context'

const TitleMetric = () => {
  const { storeOrders } = useContext()

  return (
    <BaseTitleMetric
      title="Open Quotes"
      value={storeOrders && total(storeOrders).amount}
      valueToDisplay={formatMoneyInt}
      displayComparison={false}
    />
  )
}

export default TitleMetric
