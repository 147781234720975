import classNames from 'classnames'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import { useMemo } from 'react'
import { Point } from '../../types'
import { ORANGE_PIN, US_CENTER } from '../modules/Map'
import useGoogleMapsScriptLoader from '../hooks/useGoogleMapsScriptLoader'

interface Props {
  lat: number | null
  lng: number | null
  rounded?: boolean
  selectable?: boolean
  onChange?: (point: Point) => void
  zoom?: number
}

const SingleMarkerMap: React.FC<Props> = ({
  lat,
  lng,
  rounded = true,
  selectable = false,
  onChange,
  zoom = 10,
}) => {
  const { isLoaded } = useGoogleMapsScriptLoader()

  const location = useMemo(() => (lat && lng ? { lat, lng } : null), [lat, lng])

  if (!isLoaded) {
    return null
  }

  return (
    <GoogleMap
      mapContainerClassName={classNames('w-full h-80', rounded && 'rounded')}
      center={location || US_CENTER}
      zoom={zoom}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        draggableCursor: selectable ? 'crosshair' : undefined,
      }}
      onClick={(e) =>
        e.latLng && selectable && onChange && onChange({ lat: e.latLng.lat(), lng: e.latLng.lng() })
      }
    >
      {location && (
        <MarkerF
          animation={google.maps.Animation.DROP}
          position={location}
          icon={ORANGE_PIN}
          draggable={selectable}
          onDragEnd={(e) =>
            e.latLng && onChange && onChange({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          }
        />
      )}
    </GoogleMap>
  )
}

export default SingleMarkerMap
