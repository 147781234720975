import { useState } from 'react'

import Combobox from './Combobox'

const options = [
  { value: 'UPS', display: 'UPS', search: 'UPS' },
  { value: 'FedEx', display: 'FedEx', search: 'FedEx' },
  {
    value: 'USPS',
    display: 'USPS (U.S. Postal Service)',
    search: 'USPS,U.S. Postal Service',
  },
  {
    value: 'DHL Express',
    display: 'DHL Express',
    search: 'DHL Express',
  },
  {
    value: 'XPO',
    display: 'XPO',
    search: 'XPO,Logistics',
  },
  {
    value: 'R+L Carriers',
    display: 'R+L Carriers',
    search: 'R&L,R+L,Carriers',
  },
  {
    value: 'Central Transport',
    display: 'Central Transport',
    search: 'Central Transport',
  },
  {
    value: 'Averitt',
    display: 'Averitt',
    search: 'Averitt',
  },
  {
    value: 'Saia',
    display: 'Saia',
    search: 'Saia',
  },
  {
    value: 'Southeastern Freight Lines',
    display: 'Southeastern Freight Lines',
    search: 'Southeastern Freight Lines',
  },
  {
    value: 'AAA Cooper Transportation',
    display: 'AAA Cooper Transportation',
    search: 'AAA Cooper Transportation',
  },
  {
    value: 'Estes',
    display: 'Estes',
    search: 'Estes',
  },
  {
    value: 'Ward',
    display: 'Ward',
    search: 'Ward Trasportation & Logistics',
  },
  {
    value: 'Old Dominion',
    display: 'Old Dominion',
    search: 'Old Dominion',
  },
  {
    value: 'Diligent Delivery Systems',
    display: 'Diligent Delivery Systems',
    search: 'Diligent Delivery Systems',
  },
  {
    value: 'TForce',
    display: 'TForce',
    search: 'TForce,T-Force',
  },
].sort((a, b) => a.display.localeCompare(b.display))

const ShippingCarrierInput = ({
  value,
  onChange,
}: {
  value: string | null
  onChange: (carrier: string | null) => unknown
}) => {
  const [shippingCarrierQuery, setShippingCarrierQuery] = useState(value ?? '')
  const freeformOption = {
    value: shippingCarrierQuery,
    display: shippingCarrierQuery,
    search: shippingCarrierQuery,
    isFreeformOption: true,
  }
  return (
    <Combobox
      value={options.find((option) => option.value === value) ?? (value ? freeformOption : null)}
      options={options}
      onChange={(option) => onChange(option?.value ?? null)}
      onQueryChange={setShippingCarrierQuery}
      getOptionDisplay={({ display }) => display}
      getOptionSearch={({ search }) => search}
      placeholder="i.e. UPS, FedEx"
      freeformOption={freeformOption}
    />
  )
}

export default ShippingCarrierInput
