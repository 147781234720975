import findIndex from 'lodash/findIndex'

import FilterM from './Filter'

import { Filter, FilterTypeId, FilterField } from '../../types'

const remove = (filters: Filter[], id: string) => filters.filter((filter) => filter.id !== id)

const updateField = (filters: Filter[], id: string, field: FilterField): Filter[] => {
  const index = findIndex(filters, (filter) => filter.id === id)
  const filter = filters[index]

  const updatedFilter = FilterM.build({
    id: filter.id,
    fieldId: field.id,
    typeId: field.filterTypeIds[0],
    options: field.options,
  })

  return Object.assign([], filters, { [index]: updatedFilter })
}

const updateTypeId = (filters: Filter[], id: string, typeId: FilterTypeId): Filter[] => {
  const index = findIndex(filters, (filter) => filter.id === id)
  const filter = filters[index]

  if (filter.typeId === typeId) return filters

  const updatedFilter = FilterM.build({
    ...filter,
    typeId,
  })

  return Object.assign([], filters, { [index]: updatedFilter })
}

const updateText = (filters: Filter[], id: string, text: string): Filter[] => {
  const index = findIndex(filters, (filter) => filter.id === id)
  const filter = filters[index]

  const updatedFilter = FilterM.build({
    ...filter,
    text,
  })

  return Object.assign([], filters, { [index]: updatedFilter })
}

const updateFrom = (filters: Filter[], id: string, from: string | null): Filter[] => {
  const index = findIndex(filters, (filter) => filter.id === id)
  const filter = filters[index]

  if (filter.typeId !== 'date_time_in_range') throw new Error('unexpected filter type')

  const updatedFilter = FilterM.build({
    ...filter,
    from,
  })

  return Object.assign([], filters, { [index]: updatedFilter })
}

const updateTo = (filters: Filter[], id: string, to: string | null): Filter[] => {
  const index = findIndex(filters, (filter) => filter.id === id)
  const filter = filters[index]

  if (filter.typeId !== 'date_time_in_range') throw new Error('unexpected filter type')

  const updatedFilter = FilterM.build({
    ...filter,
    to,
  })

  return Object.assign([], filters, { [index]: updatedFilter })
}

const updateOption = (filters: Filter[], id: string, optionId: string): Filter[] => {
  const index = findIndex(filters, (filter) => filter.id === id)
  const filter = filters[index]

  if (filter.typeId !== 'is' && filter.typeId !== 'is_not')
    throw new Error('unexpected filter type')

  const updatedFilter = FilterM.build({
    ...filter,
    selectedOption: optionId,
  })

  return Object.assign([], filters, { [index]: updatedFilter })
}

const clean = (filters: Filter[]) =>
  filters
    .filter((f) => (f.typeId === 'equals' && f.fieldId === 'source_id' ? f.text !== '' : true))
    .filter((f) => (f.typeId === 'equals' && f.fieldId === 'store_id' ? f.text !== '' : true))

const toApiFilters = (filters: Filter[]) => clean(filters).map((f) => FilterM.toApiFilter(f))

const toApiFilter = (filters: Filter[]) => {
  const apiFilters = clean(filters).map((f) => FilterM.toApiFilter(f))

  if (apiFilters.length > 1) return ['and', ...apiFilters]
  if (apiFilters.length === 1) return apiFilters[0]
  return []
}

export default {
  remove,
  updateField,
  updateTypeId,
  updateText,
  updateFrom,
  updateTo,
  updateOption,
  clean,
  toApiFilters,
  toApiFilter,
}
