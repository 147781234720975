import pluralize from 'pluralize'
import useConfig from '../hooks/useConfig'
import ProductContext from '../modules/Product'
import A from './A'
import Dropdown from './Dropdown'
import Link from './LinkOld'

const TableLineItemsDropdown = ({
  lineItems,
  linkToMarketplace = false,
}: {
  lineItems: {
    product: { id: string; name: string; enabled: boolean; offline: boolean; path: string }
  }[]
  linkToMarketplace?: boolean
}) => {
  const { gfBaseUrl } = useConfig()
  return (
    <Dropdown
      title={`${lineItems.length} ${pluralize('item', lineItems.length)}`}
      header="Items in Order"
      stopEventPropagation
    >
      {lineItems.map((item) => (
        <div key={item.product.id} className="text-sm">
          <button
            type="button"
            onClick={(e) => {
              // This stops the row in the table from being clicked
              e.stopPropagation()
            }}
          >
            {linkToMarketplace ? (
              ProductContext.marketplaceUrl(item.product, gfBaseUrl) ? (
                <A.T href={ProductContext.marketplaceUrl(item.product, gfBaseUrl)} target="_blank">
                  {item.product.name}
                </A.T>
              ) : (
                <span>{item.product.name}</span>
              )
            ) : (
              <Link to={`/products/${item.product.id}`}>{item.product.name}</Link>
            )}
          </button>
        </div>
      ))}
    </Dropdown>
  )
}

export default TableLineItemsDropdown
