import React, { useEffect } from 'react'
import Phone from '../modules/Phone'
import TextField from './TextField'

const PhoneInput = ({
  value,
  onChange,
  label = 'Phone number',
  required,
  placeholder,
  errors,
}: {
  value: string
  onChange: (newValue: string) => void
  label?: string
  required?: boolean
  placeholder?: string
  errors?: string
}) => {
  useEffect(() => {
    onChange(Phone.format(value))
  }, [])

  const onChangeFormat: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(Phone.format(e.target.value))
  }
  return (
    <TextField
      label={label}
      value={value}
      errors={errors}
      noErrorText={!errors}
      onChange={onChangeFormat}
      type="tel"
      required={required}
      placeholder={placeholder}
    />
  )
}

export default PhoneInput
