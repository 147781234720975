import { Maybe, ModalSize } from '@/types'
import Modal from './ModalNext'
import MachineInformation from '@/buyers/pages/Order/YourOrder/MachineInformation'
import CloseModalButton from './CloseModalButton'
import { Table, Tbody, Th, Td, Thead, Tr } from './next/Table'

const ViewRequestModal = ({
  open,
  onClose,
  request,
  title = 'Your Request',
  disableLinks = false,
}: {
  open: boolean
  onClose: () => void
  title?: string
  request: {
    parts: {
      id: string
      mpn: Maybe<string>
      description: Maybe<string>
      quantity: number
    }[]
    images: { url: string }[]
    orgMachines: MachineInformation[]
    partsRequest: Maybe<string>
  }
  disableLinks?: boolean
}) => (
  <Modal open={open} onClose={onClose} size={ModalSize.LG}>
    <div className="p-8 relative flex flex-col gap-y-6">
      <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />

      <h4 className="text-2xl font-medium pt-4">{title}</h4>

      <Table className="w-full">
        <Thead>
          <Tr>
            <Th>Part Number</Th>
            <Th>Description</Th>
            <Th>Quantity</Th>
          </Tr>
        </Thead>

        <Tbody>
          {request.parts.map((part) => (
            <Tr key={part.id}>
              <Td>{part.mpn}</Td>
              <Td>{part.description}</Td>
              <Td>{part.quantity}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {request.images.length > 0 && (
        <div className="space-y-2">
          <div className="font-medium text-lg text-gray-900">Pictures</div>
          <div className="flex gap-2 flex-wrap">
            {request.images.map((img) => (
              <a key={img.url} href={img.url} target="_blank" rel="noreferrer" className="block">
                <img
                  src={img.url}
                  alt=""
                  className="w-20 h-20 rounded-lg block object-cover border border-gray-100"
                />
              </a>
            ))}
          </div>
        </div>
      )}

      <MachineInformation machines={request.orgMachines} disableLinks={disableLinks} />

      {request.partsRequest && (
        <div className="space-y-2">
          <div className="font-medium text-lg text-gray-900">Comments</div>
          <p className="leading-6 prose whitespace-pre-wrap">{request.partsRequest}</p>
        </div>
      )}
    </div>
  </Modal>
)

export default ViewRequestModal
