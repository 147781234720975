import useConfig from '@/gf/hooks/useConfig'
import useLocalStorage from '@/gf/hooks/useLocalStorage'
import { updateSalesHubSession } from '@/gf/modules/Analytics'
import User from '@/gf/modules/User'
import { useRollbarPerson } from '@rollbar/react'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SessionQuery, useSessionQuery, useTrackUserIsActiveMutation } from '../_gen/gql'
import ReloadSessionContext from '../contexts/ReloadSession'
import SessionContext, { AuthorizedSession } from '../contexts/SessionContext'

type Session = SessionQuery['session']

const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const config = useConfig()
  const result = useSessionQuery()
  const newSession = result.data?.session ?? null
  const [session, setSession] = useState<Session | null>(newSession)
  const location = useLocation()
  const [trackUserIsActive] = useTrackUserIsActiveMutation()
  useRollbarPerson({ email: session?.user?.email })

  const { value: storageLastActiveAt, setValue: setStoreLastActiveAt } =
    useLocalStorage<DateTime | null>(
      'lastActiveAt',
      null,
      (rawValue) => DateTime.fromISO(rawValue),
      (value) => value?.toISO() ?? null
    )

  const reload = () =>
    result.refetch().then(({ data }) => {
      setSession(data.session)
    })

  useEffect(() => {
    if (newSession) setSession(newSession)
  }, [!newSession])

  useEffect(() => {
    // Setup GA session
    if (session?.user) {
      updateSalesHubSession(
        session.store?.id,
        session.store?.name,
        session.user.id,
        session.user.displayName,
        !!session.xrayUserId
      )
    }
  }, [session])

  // Update when the user was last active at
  useEffect(() => {
    // Refresh every hour
    if (
      session?.user &&
      (!storageLastActiveAt || storageLastActiveAt.diffNow('hours').hours < -1)
    ) {
      trackUserIsActive().then(() => {
        setStoreLastActiveAt(DateTime.now())
      })
    }
  }, [location, session, storageLastActiveAt])

  if (!session) return null

  const regex = /rfqs\/[0-9a-fA-F-]+\/quote\/[0-9a-fA-F-]+/

  // public url
  if (window.location.pathname.match(regex)) {
    const unauthenticatedSession = {
      ...session,
      pendingInvites: [],
    }

    return (
      <ReloadSessionContext.Provider value={reload}>
        <SessionContext.Provider value={unauthenticatedSession as AuthorizedSession}>
          {children}
        </SessionContext.Provider>
      </ReloadSessionContext.Provider>
    )
  }

  // unauthenticated
  if (!session.user) {
    window.location.href = `${config.gfBaseUrl}/login?return_path=${encodeURIComponent(
      window.location.href
    )}`

    return null
  }

  // unauthorized
  if (!session.store && !User.isAdmin(session.user)) {
    window.location.href = config.buyersUrl
    return null
  }

  return (
    <ReloadSessionContext.Provider value={reload}>
      <SessionContext.Provider value={session as AuthorizedSession}>
        {children}
      </SessionContext.Provider>
    </ReloadSessionContext.Provider>
  )
}

export default SessionProvider
