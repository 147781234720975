import { PaymentMethod, StoreOrderStep } from '@/buyers/_gen/gql'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import Money from '@/gf/modules/Money'
import StoreOrderM from '@/gf/modules/StoreOrder'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { List, Row } from '../List'
import { useOrderContext } from '../context'

const zeroPrice = Money.fromDecimal(0, 'USD')

// TODO: consider delivery prices

const Prices = ({ selectedDirect }: { selectedDirect: boolean }) => {
  const { storeOrder } = useOrderContext()
  const subtotal = StoreOrderM.subtotal(storeOrder)

  const totalBeforeServiceFee = [subtotal, storeOrder.shippingAmount, storeOrder.salesTax].reduce(
    Money.add
  )

  // TODO: consider denied quotes
  const applyServiceFee =
    storeOrder.step === StoreOrderStep.Quoted
      ? !selectedDirect
      : storeOrder.paymentMethod !== PaymentMethod.Direct

  const serviceFee = applyServiceFee
    ? Money.mult(totalBeforeServiceFee, storeOrder.convenienceFeeRate)
    : null

  const addlChrgs = storeOrder.additionalCharges.map((ac) => ac.price)

  const total = serviceFee
    ? [totalBeforeServiceFee, serviceFee, ...addlChrgs].reduce(Money.add)
    : [totalBeforeServiceFee, ...addlChrgs].reduce(Money.add)

  const refunded = storeOrder.refunds.map((r) => r.amount).reduce(Money.add, zeroPrice)

  return (
    <List
      title={{ className: 'sm:col-span-2' }}
      desc={{ className: 'sm:text-right sm:col-span-1' }}
    >
      <Row>Subtotal {Money.format(subtotal)}</Row>
      <Row>Shipping {Money.format(storeOrder.shippingAmount)}</Row>
      <Row>Tax {Money.format(storeOrder.salesTax)}</Row>

      {storeOrder.additionalCharges.map((addlChrg) => (
        <Row key={addlChrg.id}>
          <span className="flex gap-x-4">
            <span>{addlChrg.name}</span>

            {addlChrg.state === 'pending' && (
              <span className="text-gray-500 italic">({addlChrg.state})</span>
            )}
          </span>

          {Money.format(addlChrg.price)}
        </Row>
      ))}

      {serviceFee && (
        <Row title={{ className: 'flex gap-x-2 items-center sm:col-span-2' }}>
          <>
            Service Fee
            <Tooltip>
              <TooltipTrigger className="inline-flex">
                <InformationCircleIcon className="w-5 h-5 inline-flex shrink-0 text-gray-600" />
              </TooltipTrigger>

              <TooltipContent className="max-w-prose p-3 z-50 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                This {storeOrder.convenienceFeeRate * 100}% service fee helps us operate Gearflow
              </TooltipContent>
            </Tooltip>
          </>

          {Money.format(serviceFee)}
        </Row>
      )}

      <Row>
        <span className="text-lg font-medium">Total</span>
        <span className="text-lg font-medium">{Money.format(total)}</span>
      </Row>

      {!Money.isZero(refunded) && (
        <>
          <Row>Refunded {Money.format(Money.mult(refunded, -1))}</Row>

          <Row>
            <span className="text-lg font-medium">Net Total</span>
            <span className="text-lg font-medium">{Money.format(Money.sub(total, refunded))}</span>
          </Row>
        </>
      )}
    </List>
  )
}

export default Prices
