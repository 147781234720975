import Box from '@/buyers/components/Box'
import Action from '@/gf/components/Action'
import ViewRequestModal from '@/gf/components/ViewRequestModal'
import useToggle from '@/gf/hooks/useToggle'
import { EyeIcon } from '@heroicons/react/outline'
import { useOrderContext } from '../context'

const ViewOriginalRequest = () => {
  const { storeOrder } = useOrderContext()
  const [open, modal] = useToggle()

  const request = storeOrder.order.requestForQuote

  if (!request) {
    return null
  }

  return (
    <>
      <Box className="text-center space-y-4 flex flex-col items-center" dim>
        <h4 className="text-lg font-medium">View Your Request</h4>
        <p className="mt-2 text-center leading-6">
          The details of your quote may be different than your original request. To see your
          request, select View Original Request
        </p>
        <div className="mt-4">
          <Action.S onClick={modal.on} className="flex items-center">
            <EyeIcon className="w-5 h-5 inline-block mr-1.5" />
            View Original Request
          </Action.S>
        </div>
      </Box>
      <ViewRequestModal open={open} onClose={modal.off} request={request} />
    </>
  )
}

export default ViewOriginalRequest
