import React from 'react'
import classNames from 'classnames'

type Props = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type'
>

const RadioInput = React.forwardRef<HTMLInputElement, Props>(({ className, ...props }, ref) => (
  <input
    {...props}
    type="radio"
    className={classNames('h-4 w-4 border-gray-300 text-gearflow focus:ring-orange-500', className)}
    ref={ref}
  />
))

export default RadioInput
