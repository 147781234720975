import { LineItem, LineItemPayload } from '../../types'

import Money from './Money'
import ShipmentItem from './ShipmentItem'
import Time from './Time'

const fromPayload = (payload: LineItemPayload): LineItem => ({
  ...payload,
  unitPrice: Money.fromPayload(payload.unitPrice),
  discountedUnitPrice: Money.fromPayload(payload.discountedUnitPrice),
  extendedPrice: Money.fromPayload(payload.extendedPrice),
  shipmentItems:
    payload.shipmentItems &&
    payload.shipmentItems.map((shipmentItem) => ShipmentItem.fromPayload(shipmentItem)),
  availableAt: payload.availableAt !== null ? Time.fromPayload(payload.availableAt) : null,
})

export default { fromPayload }
