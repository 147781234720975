import { Brand } from '@/dealers/_gen/gql'
import { XIcon } from '@heroicons/react/solid'
import keyBy from 'lodash/keyBy'

const BrandList = ({
  brands,
  brandIds,
  setBrandIds,
}: {
  brands: Pick<Brand, 'id' | 'name'>[] | undefined
  brandIds: string[]
  setBrandIds: (newBrandId: string[]) => void
}) => {
  const brandById = keyBy(brands, 'id')
  const unselectedBrands = brands?.filter((b) => !brandIds.includes(b.id))

  const add = (brandId: string) => {
    setBrandIds([...new Set([...brandIds, brandId])].sort())
  }

  const remove = (brandId: string) => {
    setBrandIds(brandIds.filter((bid) => bid !== brandId))
  }

  return (
    <div className="border-1 border-gray-300 rounded-md">
      <div className="py-3 px-4 border-b-1 border-gray-300">
        {brandIds.length > 0 ? (
          <div className="space-y-1">
            {brandIds.map((id) => (
              <div className="flex gap-4 items-center justify-between text-sm" key={id}>
                <span>{brandById[id] && brandById[id].name}</span>

                <button onClick={() => remove(id)} type="button">
                  <XIcon className="h-5 w-5 text-gray-300 hover:text-red-500" />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-sm text-gray-400 italic">None added.</div>
        )}
      </div>

      <div className="p-0.5">
        <select
          value=""
          onChange={({ target }) => add(target.value)}
          className="block w-full rounded-md border-transparent text-sm"
        >
          <option value="">Select&hellip;</option>

          {unselectedBrands?.map((brand) => (
            <option key={brand.id} value={brand.id}>
              {brand.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default BrandList
