import AwsS3 from '@uppy/aws-s3'
import Compressor from '@uppy/compressor'
import Uppy, { UppyFile, UppyOptions } from '@uppy/core'
import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import AttachmentM from '../modules/Attachment'
import useCreatePresignedS3URL from './useCreatePresignedS3URL'

const defaultUploadKey = (filename: string) => `uploads/${uuid()}/${filename}`

type UploadedFile = {
  id: string
  url: string
  name: string
  size: number
  type?: string
}

const useUppy = ({
  onComplete,
  onFilesAdded,
  getKey = defaultUploadKey,
  adminOnly = false,
  allowedFileTypes = AttachmentM.defaultAllowedFileTypes,
  autoProceed = true,
  maxNumberOfFiles = 10,
  restrictions,
}: {
  onComplete?: (files: UploadedFile[]) => void
  onFilesAdded?: (files: UppyFile[]) => void
  getKey?: (filename: string) => string
  adminOnly?: boolean
  allowedFileTypes?: string[]
  autoProceed?: boolean
  maxNumberOfFiles?: number
  restrictions?: UppyOptions['restrictions']
}) => {
  const createPresignedS3URL = useCreatePresignedS3URL(adminOnly)

  const fetchPresignedUrl = (fileName: string) =>
    createPresignedS3URL({ key: getKey(fileName) }).then(
      ({ data }) =>
        (adminOnly ? data?.createPresignedS3AdminURL : data?.createPresignedS3URL) as string
    )

  const uppy = useMemo(() => {
    const newUppy = new Uppy({
      restrictions: {
        maxNumberOfFiles,
        allowedFileTypes,
        ...restrictions,
      },
      autoProceed,
    })

    newUppy.on('complete', (result) => {
      if (onComplete) {
        const files = result.successful.map((item) => ({
          id: item.id,
          name: item.name,
          type: item.type,
          size: item.size,
          url: item.uploadURL,
        }))

        onComplete(files)
      }

      newUppy.cancelAll()
    })

    newUppy.on('files-added', (files) => {
      if (onFilesAdded) onFilesAdded(files)
    })

    newUppy.use(Compressor, { quality: 0.5 }).use(AwsS3, {
      limit: 10,
      getUploadParameters: (file) => {
        try {
          return fetchPresignedUrl(file.name).then((url) => ({ method: 'PUT', url }))
        } catch {
          return { url: '' }
        }
      },
    })

    return newUppy
  }, [])

  return uppy
}

export default useUppy
