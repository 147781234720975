import { Maybe } from '@/types'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export enum SidebarPanelTypes {
  TASKS = 'TASKS',
  MESSAGES = 'MESSAGES',
  ACTIVITIES = 'ACTIVITIES',
}

const useSidePanel = () => {
  const [params, setSearchParams] = useSearchParams({ sidePanel: '' })
  const sidePanel = useMemo<Maybe<SidebarPanelTypes>>(() => {
    const paramValue = params.get('sidePanel')
    return paramValue ? SidebarPanelTypes[paramValue] : null
  }, [params])

  const close = () => {
    params.delete('sidePanel')

    setSearchParams(params)
  }

  const open = (panel: SidebarPanelTypes) => {
    params.set('sidePanel', panel)
    setSearchParams(params)
  }

  return { current: sidePanel, close, open }
}

export default useSidePanel
