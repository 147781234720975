import { InputHTMLAttributes, ChangeEvent, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  setValue?: (newValue: string) => void
}

const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ value, setValue, type = 'text', ...props }, ref) => {
    const className = twMerge(
      'border border-gray-300 placeholder:text-gray-400 text-sm text-gray-900 focus:border-blue-600 focus:ring-blue-600 px-3 py-2 rounded-md w-full',
      props.disabled && 'bg-gray-50',
      props.className
    )

    const onChange =
      props.onChange || setValue
        ? (event: ChangeEvent<HTMLInputElement>) => {
            if (props.onChange) props.onChange(event)
            if (setValue) setValue(event.target.value)
          }
        : undefined

    return (
      <input
        {...props}
        value={value}
        onChange={onChange}
        className={className}
        type={type}
        ref={ref}
      />
    )
  }
)

export default TextInput
