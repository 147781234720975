import { useUpdateCompanyDetailsMutation } from '@/dealers/_gen/gql'
import { useState } from 'react'
import { useSetupContext } from '../context'
import { Fields } from './useForm'

const useUpdate = (fields: Fields) => {
  const { storeId, refetch } = useSetupContext()
  const [updating, setUpdating] = useState(false)
  const [mutate] = useUpdateCompanyDetailsMutation()

  const update = async () => {
    setUpdating(true)

    await mutate({
      variables: {
        storeId,
        description: fields.description,
        returnPolicy: fields.returnPolicy,
        shippingInfo: fields.shippingInfo,
      },
    })

    refetch()
    setUpdating(false)
  }

  return { update, updating }
}

export default useUpdate
