import AdminGqlClientProvider from '@/admin/providers/GqlClient'
import useSession from '@/dealers/hooks/useSession'
import Dashboard from '@/dealers/pages/Dashboard'
import NotFound from '@/dealers/pages/NotFound'
import Order from '@/dealers/pages/Order'
import Request from '@/dealers/pages/Request'
import ErrorBoundary from '@/gf/components/ErrorBoundary'
import HashLinks from '@/gf/components/HashLinks'
import useLocationChange from '@/gf/hooks/useLocationChange'
import * as GE from '@/gf/modules/GrammarEvents'
import ConfigProvider from '@/gf/providers/ConfigProvider'
import ErrorTrackerProvider from '@/gf/providers/ErrorTracker'
import MessageProvider from '@/gf/providers/MsgsProvider'
import StableNavigateProvider from '@/gf/providers/StableNavigateProvider'
import qs from 'query-string'
import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import GAUserTracking from './components/GAUserTracking'
import CreateProduct from './pages/CreateProduct'
import CreateQuote from './pages/CreateQuote'
import CustomerAccount from './pages/CustomerAccount'
import CustomerAccounts from './pages/CustomerAccounts'
import CustomerContacts from './pages/CustomerContacts'
import Inbox from './pages/Inbox'
import PlanAndBilling from './pages/PlanAndBilling'
import PrintRequest from './pages/PrintRequest'
import Product from './pages/Product'
import Products from './pages/Products'
import Reports from './pages/Reports'
import Requests from './pages/Requests'
import Settings from './pages/Settings'
import Setup from './pages/Setup'
import Wallet from './pages/Wallet'
import ApolloWsClientProvider from './providers/ApolloWsClient'
import GqlClientProvider from './providers/GqlClient'
import SessionProvider from './providers/SessionProvider'
import TokenSignInProvider from './providers/TokenSignInProvider'

const DashboardRedirect = () => {
  const { store } = useSession()

  if (store.setupStartedAt === null) {
    return <Navigate to="/setup/welcome" replace />
  }

  return <Navigate to="/rfqs" replace />
}

const Router = () => {
  const { user } = useSession()

  useEffect(() => {
    GE.init(user.id, 'sales-hub')
  }, [user.id])

  useLocationChange(() => GE.viewsPage())

  return (
    <Routes>
      <Route path="/" element={<DashboardRedirect />} />
      <Route path="setup/*" element={<Setup />} />

      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/rfqs" element={<Requests />} />
      <Route path="/rfqs/:rfqId/print" element={<PrintRequest />} />
      <Route path="/rfqs/:rfqId/*" element={<Request />} />

      <Route path="/orders" element={<Navigate to="/rfqs" replace />} />
      <Route path="/orders/new" element={<CreateQuote />} />
      <Route path="/orders/:storeOrderId/*" element={<Order />} />

      <Route path="/products/create" element={<CreateProduct />} />
      <Route path="/products/:productId" element={<Product />} />
      <Route path="/products" element={<Products />} />

      <Route path="/settings/*" element={<Settings />} />
      <Route path="/plan/*" element={<PlanAndBilling />} />

      <Route path="/inbox" element={<Inbox />} />
      <Route path="/inbox/messages/:conversationId" element={<Inbox />} />

      {/* TODO: CREATE MORE ACCURATE ROUTES AND USE THESE TO REDIRECT FROM SUPPLIERS DASH TO DEALER HUB */}
      <Route path="/accounts/new" element={<CustomerAccounts addCustomerOpen />} />
      <Route path="/accounts" element={<CustomerAccounts />} />
      <Route path="/accounts/:customerAccountId/*" element={<CustomerAccount />} />

      <Route path="/customers" element={<CustomerContacts />} />
      <Route path="reports/*" element={<Reports />} />
      <Route path="/metrics" element={<Navigate to="/reports" replace />} />
      <Route path="/wallet/*" element={<Wallet />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

const App = () => (
  <ConfigProvider app="dealers">
    <ErrorTrackerProvider>
      <ErrorBoundary>
        <GqlClientProvider>
          <AdminGqlClientProvider>
            <TokenSignInProvider>
              <SessionProvider>
                <ApolloWsClientProvider>
                  <MessageProvider>
                    <StableNavigateProvider>
                      <QueryParamProvider
                        adapter={ReactRouter6Adapter}
                        options={{
                          searchStringToObject: qs.parse,
                          objectToSearchString: qs.stringify,
                        }}
                      >
                        <HashLinks />
                        <GAUserTracking />
                        <Router />
                      </QueryParamProvider>
                    </StableNavigateProvider>
                  </MessageProvider>
                </ApolloWsClientProvider>
              </SessionProvider>
            </TokenSignInProvider>
          </AdminGqlClientProvider>
        </GqlClientProvider>
      </ErrorBoundary>
    </ErrorTrackerProvider>
  </ConfigProvider>
)

export default App
