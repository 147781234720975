import Action from '@/gf/components/Action'
import UploadButton from '@/gf/components/UploadButton'

const LogoInput = ({
  url,
  setUrl,
}: {
  url: string | null
  setUrl: (newUrl: string | null) => void
}) => (
  <div className="space-y-2">
    {url ? (
      <>
        <div className="w-24 h-24 rounded-full overflow-hidden">
          <img src={url} alt="Business Logo" className="h-full w-full object-contain" />
        </div>

        <div>
          <Action.T onClick={() => setUrl(null)} className="text-sm">
            Remove
          </Action.T>
        </div>
      </>
    ) : (
      <UploadButton
        allowedFileTypes={['.jpg', '.jpeg', '.png']}
        onUploadComplete={(files) => {
          setUrl(files[0].url)
          return Promise.resolve()
        }}
        onError={() => null}
      >
        <Action.S>+ Add file</Action.S>
      </UploadButton>
    )}
  </div>
)

export default LogoInput
