import { InputHTMLAttributes, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

const RadioInput = forwardRef<
  HTMLInputElement,
  Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>
>(({ className, ...props }, ref) => (
  <input
    {...props}
    type="radio"
    className={twMerge(
      'h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500',
      props.disabled && 'bg-gray-50',
      className
    )}
    ref={ref}
  />
))

export default RadioInput
