import { UserIcon } from '@heroicons/react/outline'
import SingleSelectDropdownList from '@/gf/components/SingleSelectDropdownList'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Maybe } from '@/types'
import Phone from '@/gf/modules/Phone'
import classNames from 'classnames'

interface Contact {
  id: string
  name: string
  phoneNumber: Maybe<string>
}

interface ContactSelectorProps {
  selectedContactId: string
  contacts: Contact[]
  onChange: (contactId: string) => void
}

const SelectedContact = ({
  contact,
  showDropdownIndicator,
}: {
  contact: Contact
  showDropdownIndicator: boolean
}) => (
  <span
    className={classNames(
      'text-sm p-1 inline-flex  gap-x-1 rounded-lg -m-2',
      showDropdownIndicator && 'cursor-pointer hover:bg-slate-50'
    )}
  >
    <UserIcon className="h-5 inline-block text-gearflow" />
    <span>
      <span className="block flex-grow leading-4">{contact.name}</span>
      {contact.phoneNumber && (
        <span className="text-xs italic text-slate-500 leading-3">
          {Phone.format(contact.phoneNumber)}
        </span>
      )}
    </span>

    {showDropdownIndicator && (
      <ChevronDownIcon className="h-6 inline-block text-slate-400 flex-shrink-0 self-center -mt-2" />
    )}
  </span>
)

const ContactSelector = ({ selectedContactId, contacts, onChange }: ContactSelectorProps) => (
  <SingleSelectDropdownList
    options={contacts.map((c) => ({ data: c, value: c.id }))}
    value={selectedContactId}
    onChange={(value) => value.length > 0 && onChange(value)}
    renderOption={(c) => (
      <div className="cursor-pointer flex items-center gap-x-2 hover:bg-slate-50 p-2">{c.name}</div>
    )}
    renderValue={(selectedContact) => (
      <SelectedContact contact={selectedContact} showDropdownIndicator={contacts.length > 1} />
    )}
  />
)

export default ContactSelector
