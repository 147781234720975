import React from 'react'

import useMsgs from '../hooks/useMsgs'
import useUpdateCategory from '../hooks/useUpdateCategory'

import Action from './Action'
import Form from './Form'
import TextInput from './TextInput'

import { Category, UpdateCategoryForm } from '../../types'

type GqlErrors = { message: 'name_exists' }[]

const UpdateCategoryError = (() => {
  const errorByKey = {
    name_required: 'Name is required.',
    name_exists: 'Already exists.',
  }

  const fromGqlErrors = (gqlErrors: GqlErrors) => {
    const errors = gqlErrors.map((ge) => {
      const error = errorByKey[ge.message]
      if (!error) throw new Error(`Unexpected message: ${ge.message}`)
      return error
    })

    return Array.from(new Set(errors)).join(' / ')
  }

  return { fromGqlErrors }
})()

const EditCategory = ({
  category,
  onUpdated,
  onCancel,
}: {
  category: Category
  onUpdated: () => void
  onCancel: () => void
}) => {
  const updateCategory = useUpdateCategory()
  const [form, setForm] = React.useState<UpdateCategoryForm>({
    id: category.id,
    name: category.name,
  })
  const [error, setError] = React.useState<string>()
  const [_, msgr] = useMsgs()

  const save = () => {
    setError(undefined)

    updateCategory(form)
      .then(() => {
        msgr.add('Category updated.', 'positive')
        onUpdated()
      })
      .catch((err: { graphQLErrors: GqlErrors }) => {
        if (err.graphQLErrors.length > 0) {
          setError(UpdateCategoryError.fromGqlErrors(err.graphQLErrors))
        } else {
          msgr.addUnknownError()
        }
      })
  }

  const cancel = () => {
    onCancel()
    setForm({ ...form, name: category.name })
    setError(undefined)
  }

  return (
    <Form onSubmit={save} className="space-y-1 flex-1">
      <div className="flex gap-2">
        <TextInput
          placeholder="Name"
          value={form.name}
          setValue={(name) => setForm({ ...form, name })}
        />

        <Action.S type="submit">Save</Action.S>
        <Action.S onClick={cancel}>Cancel</Action.S>
      </div>
      {error && <div className="text-sm text-red-500">{error}</div>}
    </Form>
  )
}

export default EditCategory
