import { DeliveryMethod } from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import MoneyM from '@/gf/modules/Money'
import { Maybe, Money } from '@/types'
import useQuoteTotals from '../../QuoteBuilder/useQuoteTotals'
import Summary from '../../Summary'

const ApprovePartialQuoteConfirmation = ({
  totals,
  taxCost,
  shippingCost,
  discount,
  deliveryMethod,
  open,
  onClose,
  onSubmit,
  submitInProgress,
}: {
  totals: ReturnType<typeof useQuoteTotals>['totals']
  taxCost: Maybe<Money>
  shippingCost: Maybe<number>
  discount: Maybe<number>
  deliveryMethod: DeliveryMethod
  open: boolean
  onClose: () => void
  onSubmit: () => void
  submitInProgress: boolean
}) => (
  <Modal open={open} onClose={onClose}>
    <div className="relative flex flex-col gap-y-6 p-6">
      <CloseModalButton className="absolute top-3 right-3" onClick={onClose} />
      <h3 className="text-2xl font-medium pt-4">Confirm Changes?</h3>

      <p className="text-base">
        Confirm that the below subtotal, shipping, and tax amounts are accurate and press submit to
        send the updated quote to the customer. If you need to update the quote, press cancel.
      </p>

      <div className="max-w-72">
        <Summary
          total={totals.total}
          subtotal={totals.subtotal}
          discount={totals.discount}
          discountPercent={discount}
          shippingCost={shippingCost ? MoneyM.fromDecimal(shippingCost, 'USD') : null}
          taxCost={taxCost}
          customerFee={totals.customerFee}
          refunded={MoneyM.fromInt(0, 'USD')}
          showShippingCost={deliveryMethod === DeliveryMethod.Shipping}
        />
      </div>

      <div className="flex gap-x-4">
        <Action.S onClick={onClose} disabled={submitInProgress} className="w-1/2">
          Cancel
        </Action.S>

        <Action.P
          onClick={onSubmit}
          disabled={submitInProgress}
          performing={submitInProgress}
          className="w-1/2"
        >
          Submit
        </Action.P>
      </div>
    </div>
  </Modal>
)

export default ApprovePartialQuoteConfirmation
