import OrderInboxDetails from '@/dealers/components/Inbox/OrderInboxDetails'
import RFQInboxDetails from '@/dealers/components/Inbox/RFQInboxDetails'
import { DetailsForConversationProps } from '@/dealers/components/Inbox/types'

const InboxDetails = (props: DetailsForConversationProps) =>
  props.conversation.source.type === 'request' ? (
    <RFQInboxDetails {...props} />
  ) : props.conversation.source.type === 'store_order' ? (
    <OrderInboxDetails {...props} />
  ) : null

export default InboxDetails
