import { ReactNode, useMemo } from 'react'
import { DateTime } from 'luxon'
import { DatumValue } from '@nivo/core'
import { PointSymbolProps } from '@nivo/line'

import SelectedChartDot from '@/buyers/pages/Reporting/SelectedChartDot'
import ChartDot from '@/buyers/pages/Reporting/ChartDot'
import EmptyChartDot from '@/buyers/pages/Reporting/EmptyChartDot'
import SelectedEmptyChartDot from '@/buyers/pages/Reporting/SelectedEmptyChartDot'

type IsEmptyCallback = (props: PointSymbolProps) => boolean

export const pointToDate = (pointX: DatumValue) =>
  pointX && typeof pointX === 'object' ? DateTime.fromJSDate(pointX) : undefined

const pointSymbolCallback =
  (selectedDate?: DateTime, isEmpty?: IsEmptyCallback) =>
  (props: PointSymbolProps): ReactNode => {
    const selected =
      !!props.datum.x && !!selectedDate && pointToDate(props.datum.x)?.equals(selectedDate)
    const empty = isEmpty ? isEmpty(props) : false

    if ('noPoint' in props.datum) return null

    if (empty) {
      if (selected) return <SelectedEmptyChartDot {...props} opacity={1} />
      return <EmptyChartDot {...props} opacity={1} />
    }
    if (selected) return <SelectedChartDot {...props} opacity={1} />
    return <ChartDot {...props} opacity={1} />
  }

const useChartPointSymbol = (selectedDate?: DateTime, isEmpty?: IsEmptyCallback) => {
  const pointSymbol = useMemo(
    () => pointSymbolCallback(selectedDate, isEmpty),
    [selectedDate, isEmpty]
  )
  return pointSymbol
}

export default useChartPointSymbol
