import { DeliveryMethod } from '@/dealers/_gen/gql'
import RadioInput from '@/gf/components/next/forms/RadioInput'
import { Maybe } from '@/types'

const options = [
  {
    label: 'Will Call',
    value: DeliveryMethod.Pickup,
  },
  {
    label: 'Delivery',
    value: DeliveryMethod.Shipping,
  },
]

const DeliveryMethodSelector = ({
  value,
  onChange,
}: {
  value: Maybe<string>
  onChange: (value: string) => void
}) => (
  <div className="text-sm space-y-2">
    {options.map((o) => (
      <label className="flex items-center gap-x-2" key={o.value}>
        <RadioInput
          name="deliveryMethod"
          checked={value === o.value}
          onChange={(e) => e.target.checked && onChange(o.value)}
        />
        {o.label}
      </label>
    ))}
  </div>
)

export default DeliveryMethodSelector
