/**
 * OrderByHeaderButton
 *
 * Button for the table headers to toggle sorting order by a column.
 */
import { ChevronDownIcon, SwitchVerticalIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

import { OrderBy, OrderByOrder } from '../../types'

const inverseOrder = (order: OrderByOrder): OrderByOrder => (order === 'desc' ? 'asc' : 'desc')

const OrderByHeaderButton = ({
  id,
  display,
  orderBy,
  setOrderBy,
}: {
  id: string
  display: string
  orderBy: OrderBy
  setOrderBy: (newOrderBy: OrderBy) => void
}) => (
  <button
    className="inline-flex flex-row items-center text-xs font-bold text-gray-500 uppercase tracking-wider whitespace-nowrap"
    type="button"
    onClick={() =>
      setOrderBy({ id, order: orderBy.id !== id ? 'desc' : inverseOrder(orderBy.order) })
    }
  >
    {display}
    {orderBy.id === id ? (
      <ChevronDownIcon
        className={classNames('w-5 min-w-5 h-5 min-w-5 ml-0.5 text-gray-500', {
          'rotate-180': orderBy.order !== 'desc',
        })}
      />
    ) : (
      <SwitchVerticalIcon className="w-5 h-5 ml-1 text-gray-300" />
    )}
  </button>
)

export default OrderByHeaderButton
