import {
  useSetupCompletedMutation,
  useSetupPartsQuery,
  useUpdateWorkspaceMutation,
} from '@/dealers/_gen/gql'
import CategoryList from '@/dealers/components/CategoryList'
import BrandList from '@/gf/components/BrandList'
import Field from '@/gf/components/Field'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Frame from './Frame'
import { useSetupContext } from './context'

const Parts = () => {
  const navigate = useNavigate()
  const { loading, storeId, store, refetch } = useSetupContext()
  const { brands, categories } = useSetupPartsQuery().data || {}
  const [updateWorkspaceMutation] = useUpdateWorkspaceMutation()
  const [setupCompleted] = useSetupCompletedMutation()
  const [savingAndExiting, setSavingAndExiting] = useState(false)
  const [updating, setUpdating] = useState(false)

  const [fields, setFields] = useState<{ brandIds: string[]; categoryIds: string[] }>({
    brandIds: [],
    categoryIds: [],
  })

  useEffect(() => {
    if (!loading)
      setFields({
        brandIds: store.brands.map((b) => b.id),
        categoryIds: store.categories.map((b) => b.id),
      })
  }, [loading])

  const update = async () => {
    if (!store) return

    setUpdating(true)

    await updateWorkspaceMutation({
      variables: {
        storeId,
        rfqStates: store.rfqStates,
        brandIds: fields.brandIds,
        categoryIds: fields.categoryIds,
      },
    })

    await refetch()
  }

  const saveAndExit = async () => {
    setSavingAndExiting(true)
    await update()
    await setupCompleted({ variables: { storeId } })
    navigate('/dashboard')
    setSavingAndExiting(false)
  }

  const back = () => navigate('/setup/territory-restrictions')

  const next = async () => {
    await update()
    navigate('/setup/stripe')
  }

  return (
    <Frame
      className="space-y-6"
      progress={{ name: <>Sales Info</>, mins: 3, className: 'w-4/5' }}
      savingAndExiting={savingAndExiting}
      nexting={updating}
      saveAndExit={saveAndExit}
      back={back}
      next={next}
    >
      <div className="space-y-4 max-w-md">
        <h2 className="text-2xl font-medium">What parts do you sell?</h2>

        <p className="prose">
          Add the brands and categories you represent.
          <br />
          This will help us determine what part requests to send to you.
        </p>
      </div>

      <Field label="Your Primary Brands">
        {brands && (
          <BrandList
            brands={brands}
            brandIds={fields.brandIds}
            setBrandIds={(brandIds) => setFields({ ...fields, brandIds })}
          />
        )}
      </Field>

      <Field label="Your Part Categories">
        {categories && (
          <CategoryList
            categories={categories}
            categoryIds={fields.categoryIds}
            setCategoryIds={(categoryIds) => setFields({ ...fields, categoryIds })}
          />
        )}
      </Field>
    </Frame>
  )
}

export default Parts
