import Action from '@/gf/components/Action'
import AddressInput from '@/gf/components/AddressInput'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'

import { Address, ModalSize } from '@/types'
import { FormEvent, useEffect, useState } from 'react'

const AddressModal = ({
  title,
  subtitle,
  open,
  onClose,
  address,
  onConfirm,
  submitInProgress,
}: {
  title: string
  subtitle?: string
  open: boolean
  onClose: () => void
  address: Address
  onConfirm: (address: Address) => void
  submitInProgress: boolean
}) => {
  const [newAddress, setNewAddress] = useState<Address>(address)

  useEffect(() => {
    setNewAddress(address)
  }, [address])

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()

    onConfirm(newAddress)
  }

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.SM}>
      <div className="p-8 relative">
        <CloseModalButton onClick={onClose} className="absolute right-4 top-4" />

        <form onSubmit={onSubmit} className="space-y-6">
          <hgroup className="pt-2 space-y-2">
            <h3 className="font-medium text-2xl">{title}</h3>
            {subtitle && <p className="text-lg">{subtitle}</p>}
          </hgroup>

          <div className="space-y-2">
            <AddressInput
              address={newAddress}
              onChange={setNewAddress}
              hideCompany
              hideFirstLastName
            />
          </div>

          <Action.P type="submit" className="w-full font-medium" performing={submitInProgress}>
            Confirm
          </Action.P>
        </form>
      </div>
    </Modal>
  )
}

export default AddressModal
