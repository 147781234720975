import { SortOrder } from '@/dealers/_gen/gql'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import {
  formatShortTimeToQuoteMins,
  rfqsToMedianTimeToQuoteMins,
  urgencyByPriority,
} from '../../Service'
import { useContext } from '../context'

const Urgency = () => {
  const context = useContext()
  const { rfqs, breakdownUrgencySort, update } = context
  const sortField = breakdownUrgencySort ? breakdownUrgencySort[0] : 'send-quote'

  const sortOrder = breakdownUrgencySort
    ? breakdownUrgencySort[1] === 'desc'
      ? 'desc'
      : 'asc'
    : 'desc'

  const rfqsByPriority = rfqs && groupBy(rfqs, (rfq) => rfq.priority)

  const unsortedRows =
    rfqsByPriority &&
    map(rfqsByPriority, (subRfqs, priority) => ({
      priority,
      quoteCount: subRfqs.length,
      medianTimeToQuoteMins: rfqsToMedianTimeToQuoteMins(subRfqs),
    }))

  const orderByField = (() => {
    if (sortField === 'urgency') return 'priority'
    if (sortField === 'quotes') return 'quoteCount'
    if (sortField === 'send-quote') return 'medianTimeToQuoteMins'
    return sortField
  })()

  const rows = unsortedRows && orderBy(unsortedRows, orderByField, sortOrder)

  return (
    <ReportingTable
      data={rows}
      getRowKey={({ priority }) => priority}
      checkbox={{
        getChecked: ({ priority }) => priority === context.priority,
        onToggleRow: ({ priority }) =>
          update({ priority: priority !== context.priority ? priority : null }),
        onClear: () => update({ priority: null }),
      }}
      sortBy={{
        sortBy: {
          field: sortField,
          order: sortOrder === 'desc' ? SortOrder.Desc : SortOrder.Asc,
        },
        setSortBy: (sortBy) => update({ breakdownUrgencySort: sortBy }),
      }}
      columns={[
        {
          header: 'Urgency',
          getValue: ({ priority }) => urgencyByPriority[priority],
          sortByField: 'urgency',
        },
        { header: 'Quotes', getValue: ({ quoteCount }) => quoteCount, sortByField: 'quotes' },
        {
          header: 'Send Quote',
          getValue: ({ medianTimeToQuoteMins }) =>
            medianTimeToQuoteMins !== null ? formatShortTimeToQuoteMins(medianTimeToQuoteMins) : '',
          sortByField: 'send-quote',
        },
      ]}
    />
  )
}

export default Urgency
