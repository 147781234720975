import { DateTime } from 'luxon'

import { getDurationDatesString, getDurationDisplay } from '@/gf/components/Reports/DurationInput'
import classNames from 'classnames'
import { ReactNode } from 'react'
import Ghost from '../Ghost'
import Comparison from './Comparison'

type Duration = { durationId: string; durationStart: DateTime; durationEnd: DateTime }

const Duration = ({ duration }: { duration: Duration }) => (
  <span className="text-base text-gray-900 font-medium">
    {duration.durationId !== 'custom' ? `${getDurationDisplay(duration.durationId)} - ` : ''}
    {getDurationDatesString(duration)}
  </span>
)

const TitleMetric = ({
  title,
  value,
  comparisonValue,
  duration,
  valueToDisplay,
  diffDisplay,
  onClick,
  downIsGood,
  displayComparison = true,
  ...props
}: {
  title?: ReactNode
  value?: number | null
  comparisonValue?: number | null
  duration?: Duration
  valueToDisplay?: (value: number) => string
  valueDisplay?: ReactNode
  diffDisplay?: ReactNode
  onClick?: () => void
  downIsGood?: boolean
  displayComparison?: boolean
}) => {
  const valueDisplay = (() => {
    if (value === undefined) return undefined
    if (props.valueDisplay !== undefined) return props.valueDisplay
    if (value === null) return valueToDisplay ? valueToDisplay(0) : 0
    return valueToDisplay ? valueToDisplay(value) : value
  })()

  return (
    <div
      className={classNames('flex flex-col gap-y-3', onClick && 'cursor-pointer')}
      onClick={onClick}
    >
      {title && <span className="text-2xl text-gray-900 font-medium">{title}</span>}

      <span className="text-5xl text-gray-900 font-medium">
        {valueDisplay === undefined ? (
          <Ghost className="h-12 w-52 flex bg-gray-300" />
        ) : (
          valueDisplay
        )}
      </span>

      <div className="flex flex-row gap-x-2 items-center">
        {displayComparison && (
          <>
            {value === undefined || comparisonValue === undefined ? (
              <Ghost className="h-8 w-16 flex bg-gray-300" />
            ) : (
              value !== null &&
              comparisonValue !== null && (
                <Comparison
                  value={value}
                  comparisonValue={comparisonValue}
                  downIsGood={downIsGood}
                  diffDisplay={diffDisplay}
                  formatValue={valueToDisplay}
                />
              )
            )}
          </>
        )}

        {duration && <Duration duration={duration} />}
      </div>
    </div>
  )
}

TitleMetric.Duration = Duration

export default TitleMetric
