import React from 'react'

import TextInput from '../TextInput'

type Props = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'value' | 'onChange'
> & {
  value: number | null
  setValue: (value: number | null) => void
}

const parsePercent = (value: string) => {
  if (value === '') return null
  const number = Number(value)
  if (number < 0) return 0
  return Math.round(number * 100) / 100
}

const Percent = React.forwardRef<HTMLInputElement, Props>(({ value, setValue, ...props }, ref) => {
  const inputValue = value === null ? '' : value.toString()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parsePercent(event.target.value))
  }

  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-500 text-sm font-bold">
        %
      </div>
      <TextInput
        value={inputValue}
        onChange={onChange}
        {...props}
        type="number"
        min="0"
        step="0.01"
        ref={ref}
        leftIconPad
      />
    </div>
  )
})

export default Percent
