import { DeliveryMethod } from '@/buyers/_gen/gql'
import { createContext, useContext } from 'react'
import { Org, StoreOrder, User } from '../LimitedOrder'

export const willSomeNotShip = (storeOrder: StoreOrder) =>
  storeOrder.deliveryMethod === DeliveryMethod.Shipping &&
  storeOrder.completedAt &&
  storeOrder.refunds.length > 0

export const OrderContext = createContext<
  | {
      storeOrderId: string
      storeOrder: StoreOrder
      org: Org
      user: User
      refetchStoreOrder: () => Promise<unknown>
      rejectedLineItemIds: string[]
      toggleRejectedLineItemId: (lineItemId: string) => void
    }
  | undefined
>(undefined)

export const useOrderContext = () => {
  const context = useContext(OrderContext)
  if (context === undefined) throw new Error('must be used within provider')
  return context
}
