import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDenyQuoteMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import { useOrderContext } from '../context'

import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Form from '@/gf/components/Form'
import Modal from '@/gf/components/ModalNext'
import Field from '@/gf/components/next/forms/Field'
import TextArea from '@/gf/components/next/forms/TextArea'
import Select from '@/gf/components/next/Select'
import useToggle from '@/gf/hooks/useToggle'
import { CheckCircleIcon } from '@heroicons/react/solid'
import pluralize from 'pluralize'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'

const denyReasons = ['Not available in time', 'Too expensive', 'Other'].map((value) => ({
  value,
  label: value,
}))

const RejectForm = ({
  partialApproval,
  onSubmitted,
  onCancel,
}: {
  partialApproval: boolean
  onSubmitted: () => void
  onCancel: () => void
}) => {
  const { storeOrder, rejectedLineItemIds } = useOrderContext()
  const [_, msgr] = useMsgs()
  const [comments, setComments] = useState('')
  const [reason, setReason] = useState(partialApproval ? denyReasons[0].value : '')
  const [performing, setPerforming] = useState(false)
  const [rejectQuote] = useDenyQuoteMutation({ client: useGqlClient() })

  const onSubmit = () => {
    setPerforming(true)

    rejectQuote({
      variables: {
        storeOrderId: storeOrder.id,
        reason: partialApproval ? reason : comments,
        additionalComments: partialApproval ? comments : null,
        rejectedLineItemIds: partialApproval ? rejectedLineItemIds : [],
      },
    })
      .then(() => onSubmitted())
      .catch(() => msgr.addUnknownError())
      .finally(() => setPerforming(false))
  }
  return (
    <Form onSubmit={onSubmit} className="p-6 space-y-6">
      <h4 className="text-2xl font-medium mt-4">
        {partialApproval
          ? `Why you are removing the following ${pluralize('item', rejectedLineItemIds.length)}?`
          : 'Reject Quote'}
      </h4>

      {partialApproval ? (
        <ul className="space-y-2">
          {storeOrder.lineItems
            .filter((li) => rejectedLineItemIds.includes(li.id))
            .map((item) => (
              <li className="grid grid-cols-[8rem_auto] gap-x-4 leading-6">
                <span>Part number: </span>
                <span>{item.product.mpn}</span>
                <span>Description: </span>
                <span className="truncate text-ellipsis">{item.product.name}</span>
              </li>
            ))}
        </ul>
      ) : (
        <div className="space-y-2">
          <div className="text-base font-medium">
            What&apos;s the reason for rejecting this quote?
          </div>

          <div className="text-base">
            This can help {storeOrder.store.name} adjust the quote to better suit your needs.
          </div>
        </div>
      )}

      {partialApproval && (
        <Field label="Select Reason">
          <Select
            value={denyReasons.find((o) => o.value === reason)}
            options={denyReasons}
            onChange={(o) => setReason(o?.value ?? '')}
          />
        </Field>
      )}

      <Field label={partialApproval ? `Additional Comments (optional)` : 'Reason'}>
        <TextArea
          required={!partialApproval}
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </Field>

      {partialApproval && (
        <p className="leading-6">
          Once you submit, your quote will be sent back to your vendor so they can make the
          necessary changes.
        </p>
      )}

      <div className="flex gap-3 justify-center">
        <Action.S onClick={onCancel} className="w-36">
          Cancel
        </Action.S>

        <Action.P type="submit" performing={performing} className="w-36">
          Reject Quote
        </Action.P>
      </div>
    </Form>
  )
}

const Confirmation = ({
  partialApproval,
  onClose,
}: {
  partialApproval: boolean
  onClose: () => void
}) => (
  <div>
    <div className="bg-yellow-100 p-8 h-40 flex items-center justify-center">
      <h4 className="text-yellow-700 flex items-center text-center gap-x-2 text-3xl font-medium">
        <CheckCircleIcon className="w-7 h-7 inline-block" />{' '}
        {partialApproval ? 'Quote Changes Requested' : 'Quote Denied'}
      </h4>
    </div>
    <div className="px-8 pt-6 pb-8">
      <p className="text-lg">
        {partialApproval
          ? 'We’ve sent the requested changes back to your vendor with the reason why the original quote wasn’t approved. They will update and resubmit the quote for your approval.'
          : 'We’ve notified your vendor with the reason why the quote wasn’t approved.'}
      </p>

      <Action.S className="w-full mt-6" onClick={onClose}>
        Ok
      </Action.S>
    </div>
  </div>
)

const RejectModal = () => {
  const [partialApproval, _setParam] = useQueryParam('partial', withDefault(BooleanParam, false))
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const [confirmationVisible, confirmationToggle] = useToggle()
  const navigate = useNavigate()
  const close = () => navigate(`/orders/${storeOrder.id}`)

  const onFormSubmitted = () => {
    confirmationToggle.on()

    refetchStoreOrder()
  }

  return (
    <Modal onClose={close}>
      <div className="relative">
        <CloseModalButton onClick={close} className="absolute top-3 right-3" />

        {!confirmationVisible ? (
          <RejectForm
            partialApproval={partialApproval}
            onCancel={close}
            onSubmitted={onFormSubmitted}
          />
        ) : (
          <Confirmation partialApproval={partialApproval} onClose={close} />
        )}
      </div>
    </Modal>
  )
}

export default RejectModal
