import { SortOrder } from '@/dealers/_gen/gql'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { formatConversionRate, rfqsToConversionRate } from '../../Performance'
import { useContext } from '../context'

const User = () => {
  const context = useContext()
  const { rfqs, creatorById, breakdownUserSort: sort, update } = context
  const sortField = sort ? sort[0] : 'conv-rate'
  const sortOrder = sort ? (sort[1] === 'desc' ? 'desc' : 'asc') : 'desc'
  const quotedRfqs = rfqs?.flatMap((rfq) => (rfq.quote ? { ...rfq, quote: rfq.quote } : []))
  const rfqsByCreatorId = quotedRfqs && groupBy(quotedRfqs, (rfq) => rfq.quote.creation.creator.id)

  const unsortedRows =
    rfqsByCreatorId &&
    map(rfqsByCreatorId, (subRfqs, creatorId) => ({
      creatorId,
      creatorName: creatorById && creatorById[creatorId].displayName,
      rfqCount: subRfqs.length,
      conversionRate: rfqsToConversionRate(subRfqs),
    }))

  const orderByField = (() => {
    if (sortField === 'user') return 'creatorName'
    if (sortField === 'requests') return 'rfqCount'
    if (sortField === 'conv-rate') return 'conversionRate'
    return sortField
  })()

  const rows = unsortedRows && orderBy(unsortedRows, orderByField, sortOrder)

  return (
    <ReportingTable
      data={rows}
      getRowKey={({ creatorId }) => creatorId}
      checkbox={{
        getChecked: ({ creatorId }) => creatorId === context.creatorId,
        onToggleRow: ({ creatorId }) =>
          update({ creatorId: creatorId !== context.creatorId ? creatorId : null }),
        onClear: () => update({ creatorId: null }),
      }}
      sortBy={{
        sortBy: {
          field: sortField,
          order: sortOrder === 'desc' ? SortOrder.Desc : SortOrder.Asc,
        },
        setSortBy: (sortBy) => update({ breakdownUserSort: sortBy }),
      }}
      columns={[
        { header: 'User', getValue: ({ creatorName }) => creatorName, sortByField: 'user' },
        { header: 'Requests', getValue: ({ rfqCount }) => rfqCount, sortByField: 'requests' },
        {
          header: 'Conv. Rate',
          getValue: ({ conversionRate }) => formatConversionRate(conversionRate),
          sortByField: 'conv-rate',
        },
      ]}
    />
  )
}

export default User
