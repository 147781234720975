import { UpdateBusinessDetailsError, useUpdateBusinessDetailsMutation } from '@/dealers/_gen/gql'
import { useState } from 'react'
import { useSetupContext } from '../context'
import { Fields } from './useForm'

type Errors = Omit<UpdateBusinessDetailsError, 'address'> & { address: string[] | null }

const useUpdate = (fields: Fields) => {
  const { storeId, refetch } = useSetupContext()
  const [updating, setUpdating] = useState(false)
  const [errors, setErrors] = useState<Errors | null>(null)
  const [mutate] = useUpdateBusinessDetailsMutation()

  const update = async () => {
    setUpdating(true)
    setErrors(null)

    const { data } = await mutate({
      variables: {
        storeId,
        name: fields.name,
        logoUrl: fields.logoUrl,
        address: fields.address && {
          city: fields.address.city,
          state: fields.address.state,
          country: fields.address.country,
          lineOne: fields.address.lineOne,
          lineTwo: fields.address.lineTwo,
          postalCode: fields.address.postalCode,
        },
      },
    })

    if (data?.updateBusinessDetails)
      setErrors({
        ...data.updateBusinessDetails,
        address: data.updateBusinessDetails.address && ['is required'],
      })
    else refetch()

    setUpdating(false)

    return !data?.updateBusinessDetails
  }

  return { update, updating, errors }
}

export default useUpdate
