/**
 * Use the Tabs component if you want the Tabs to automatically control what content is displayed.
 */
import classNames from 'classnames'

import { Tab } from '@/types'

import NotificationDot from './NotificationDot'

const defaultRenderNotification = () => <NotificationDot className="absolute -mt-1" />

const Tabs = ({
  tabs,
  currentTab,
  currentTabName,
  onTabSelect,
  className,
  renderNotification = defaultRenderNotification,
  subTabs = false,
}: {
  tabs: Tab[] | undefined
  currentTab?: Tab | undefined
  currentTabName?: string | undefined
  onTabSelect: (selectedTabName: string) => void
  className?: string
  renderNotification?: (tab: Tab) => React.ReactNode
  subTabs?: boolean
}) => (
  <div className={classNames('border-b border-gray-300 overflow-visible', className)}>
    <div className="-mb-px flex gap-8 overflow-x-auto">
      {tabs?.map((tab) => (
        <button
          id={tab.name}
          key={tab.name}
          className={classNames(
            tab.name === currentTab?.name || tab.name === currentTabName
              ? 'border-gearflow text-gray-900'
              : 'border-transparent text-gray-600 hover:text-gray-700 hover:border-gray-400',
            subTabs ? 'text-sm' : 'text-base',
            'relative whitespace-nowrap py-3 px-1 border-b-2 font-medium focus:outline-none'
          )}
          type="button"
          onClick={() => onTabSelect(tab.name)}
        >
          {tab.name}
          {tab.showNotification && renderNotification(tab)}
        </button>
      ))}
    </div>
  </div>
)

export default Tabs
