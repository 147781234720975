import { useOrderDetailsQuery } from '@/dealers/_gen/gql'
import { DetailsForConversationProps } from '@/dealers/components/Inbox/types'
import useSession from '@/dealers/hooks/useSession'
import Urls from '@/dealers/modules/Urls'
import AddressC from '@/gf/components/Address'
import Link from '@/gf/components/Link'
import Spinner from '@/gf/components/Spinner'
import ConversationM from '@/gf/modules/Conversation'
import Id from '@/gf/modules/Id'
import Money from '@/gf/modules/Money'
import Phone from '@/gf/modules/Phone'
import StoreOrderM from '@/gf/modules/StoreOrder'
import AddressMetadata from '@/suppliers/components/AddressMetadata'
import { Address } from '@/types'

const AdminSection = () => (
  <>
    <div>
      <p className="mb-2 text-base font-medium text-gray-900">
        {ConversationM.ADMIN_CONVERSATION_NAME}
      </p>
      <p className="text-sm text-gray-700">
        This is a private conversation between your store and Gearflow.
      </p>
    </div>
    <div className="border-b border-gray-300" />
  </>
)

const OrganizationSection = ({ orgName }: { orgName: string }) => (
  <div>
    <p className="mb-2 text-base font-medium text-gray-900">Customer Account</p>
    <p className="text-sm text-gray-700">{orgName}</p>
  </div>
)

const CustomerSection = ({
  customer,
  displayCustomerContactInfo,
}: {
  customer: { id: string; name: string; phoneNumber: string | null }
  displayCustomerContactInfo: boolean
}) => {
  const customerName = displayCustomerContactInfo
    ? customer.name
    : ConversationM.getHiddenCustomerName(customer.name)
  return (
    <div>
      <p className="mb-2 text-base font-medium text-gray-900">Customer</p>
      <div className="flex flex-col gap-y-1 text-sm">
        {customer.id ? (
          <Link.T to={Urls.customerAccount(customer.id)}>{customerName}</Link.T>
        ) : (
          <p>{customerName}</p>
        )}
        {displayCustomerContactInfo && customer.phoneNumber && (
          <p>{Phone.format(customer.phoneNumber)}</p>
        )}
      </div>
    </div>
  )
}

const ShippingAddressSection = ({
  pickup,
  pickupAddress,
  shippingAddress,
  displayCustomerContactInfo,
}: {
  pickup: boolean
  pickupAddress: Address | null
  shippingAddress: Address
  displayCustomerContactInfo: boolean
}) => (
  <>
    <div>
      <p className="mb-2 text-base font-medium text-gray-900">
        {pickup ? 'Customer Location' : 'Shipping Address'}
      </p>
      <AddressC
        className="text-sm text-gray-700"
        address={shippingAddress}
        hideName={!displayCustomerContactInfo}
      />
      {!pickup && typeof shippingAddress.deliverable === 'boolean' && (
        <AddressMetadata className="mt-2" address={shippingAddress} />
      )}
    </div>
    {pickup && (
      <div>
        <p className="text-base font-medium text-gray-900">In Store Pickup</p>
        {pickupAddress && (
          <AddressC
            className="mt-2 text-sm text-gray-700"
            address={pickupAddress}
            hideName
            hideCompany
          />
        )}
      </div>
    )}
  </>
)

const RequestForQuoteSection = ({ requestForQuoteId }: { requestForQuoteId: string }) => (
  <div>
    <p className="mb-2 text-base font-medium text-gray-900">RFQ</p>
    <div className="flex items-center gap-x-3 gap-y-2 flex-wrap text-sm whitespace-nowrap">
      <Link.T to={Urls.requestForQuote(requestForQuoteId)}>
        RFQ {Id.shorten(requestForQuoteId)}
      </Link.T>
    </div>
  </div>
)

const OrderInboxDetails = ({ conversation }: DetailsForConversationProps) => {
  const { store } = useSession()
  const { data } = useOrderDetailsQuery({
    variables: { id: conversation.source.id, storeId: store.id },
    fetchPolicy: 'network-only',
  })
  const storeOrder = data?.storeOrder

  if (!storeOrder) {
    return <Spinner />
  }

  const { organization } = storeOrder.customer

  const displayCustomerContactInfo = StoreOrderM.isApprovedBySupplier(storeOrder)

  return (
    <>
      {conversation.admin && <AdminSection />}
      {displayCustomerContactInfo && organization && (
        <OrganizationSection orgName={organization.name} />
      )}
      <CustomerSection
        customer={storeOrder.customer}
        displayCustomerContactInfo={displayCustomerContactInfo}
      />
      {storeOrder.shippingAddress && (
        <ShippingAddressSection
          pickup={storeOrder.pickup}
          pickupAddress={storeOrder.pickupAddress}
          shippingAddress={storeOrder.shippingAddress}
          displayCustomerContactInfo={displayCustomerContactInfo}
        />
      )}
      <div>
        <p className="mb-2 text-base font-medium text-gray-900">Order Total</p>
        <div className="text-sm text-gray-700">
          {storeOrder.totals.netTotal && Money.format(storeOrder.totals.netTotal)}
        </div>
      </div>
      {storeOrder.request && <RequestForQuoteSection requestForQuoteId={storeOrder.request.id} />}
    </>
  )
}

export default OrderInboxDetails
