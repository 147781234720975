import classNames from 'classnames'
import { useMemo } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { HeroIcon } from '../../../types'

interface Tab {
  label: string
  path: string
  icon: HeroIcon
}

interface Props {
  tabs: Tab[]
  children: React.ReactNode
}

const RouterTabs = ({ tabs, children }: Props) => {
  const { pathname } = useLocation()
  const selectedTab = useMemo(() => {
    const currentPath = tabs.find((t) => !!matchPath({ path: t.path }, pathname))
    return currentPath?.label
  }, [pathname])

  return (
    <div className="flex flex-col flex-grow bg-white border border-slate-200 rounded-lg mt-4 md:mt-0">
      <nav className="flex bg-slate-50 rounded-t-lg flex-shrink-0 overflow-hidden">
        {tabs.map((tab) => (
          <Link
            key={tab.label}
            to={tab.path}
            className={classNames(
              'whitespace-nowrap font-medium block w-1/3 md:w-auto text-center text-sm',
              {
                'border-slate-200 text-gearflow shadow-md': tab.label === selectedTab,
                'text-slate-500 hover:text-slate-700 hover:border-slate-300 hover:bg-slate-100':
                  tab.label !== selectedTab,
              }
            )}
            aria-current={tab.label === selectedTab ? 'page' : undefined}
          >
            <span
              className={classNames(
                'h-full w-full border-t-4 px-3 md:px-8 py-4 md:py-5 flex items-center justify-center transition-colors duration-100',
                tab.label === selectedTab ? 'bg-white border-gearflow' : 'border-transparent'
              )}
            >
              {
                <tab.icon
                  className={classNames(
                    'h-5 mr-1 hidden md:block',
                    tab.label === selectedTab ? 'text-gearflow' : 'text-slate-400'
                  )}
                />
              }{' '}
              {tab.label}
            </span>
          </Link>
        ))}
      </nav>

      <div className=" bg-white h-full rounded-b-lg pb-2">{children}</div>
    </div>
  )
}

export default RouterTabs
