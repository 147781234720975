import debounce from 'debounce-promise'
import AsyncSelect from 'react-select/async'
import { ApolloClient, DocumentNode, OperationVariables } from '@apollo/client'
import { AutocompleteOption } from '../../types'

// Query data transformation: QueryDataT -> DatumT[] -> AutocompleteOption[]
const AutocompleteSingleSelectInputNext = <
  QueryDataT,
  QueryVarsT extends OperationVariables,
  DatumT,
>({
  name,
  label,
  query,
  queryVars,
  onChange,
  transformQueryToData,
  transformDatumToOption,
  defaultValue,
  selectedValue,
  filterOption,
  reactSelectOptions = {},
  gqlClient,
  id,
}: {
  id?: string
  name?: string
  label?: string
  query: DocumentNode
  queryVars: (search: string) => QueryVarsT
  onChange: (datum: DatumT) => void
  transformQueryToData: (response: QueryDataT) => DatumT[]
  transformDatumToOption: (data: DatumT) => AutocompleteOption
  defaultValue?: DatumT | undefined
  selectedValue?: DatumT | undefined | null
  filterOption?: (option: AutocompleteOption) => boolean
  reactSelectOptions?: any
  gqlClient: ApolloClient<object>
}) => {
  const runQuery = async (search: string) => {
    const { loading, data } = await gqlClient.query<QueryDataT, QueryVarsT>({
      query,
      variables: queryVars(search),
    })
    return loading || !data
      ? []
      : transformQueryToData(data).map((datum) => ({
          ...transformDatumToOption(datum),
          datum,
        }))
  }

  const debouncedLoadOptions = debounce(runQuery, 300, { leading: true })

  return (
    <div className="w-full text-sm space-y-1">
      {label && (
        <label className="block text-sm text-gray-700" htmlFor={name}>
          {label}
        </label>
      )}
      <AsyncSelect
        id={id}
        className="shadow-sm"
        onChange={(option: AutocompleteOption & { datum: DatumT }) => {
          // convert option to datum
          onChange(option.datum)
        }}
        defaultOptions
        loadOptions={debouncedLoadOptions}
        isMulti={false}
        defaultValue={
          defaultValue
            ? { ...transformDatumToOption(defaultValue), datum: defaultValue }
            : undefined
        }
        value={
          selectedValue
            ? { ...transformDatumToOption(selectedValue), datum: selectedValue }
            : selectedValue === null
              ? null
              : undefined
        }
        filterOption={filterOption}
        {...reactSelectOptions}
      />
    </div>
  )
}

export default AutocompleteSingleSelectInputNext
