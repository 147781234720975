import { gql, useQuery } from '@apollo/client'
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query'

import { Event, EventPayload, Pagination, SearchArgs } from '../../types'

import { pagination, requestForQuoteEvent, storeOrderEvent } from '../queries/commonGqlTypes'
import EventContext from '../modules/Event'

const doc = gql(
  jsonToGraphQLQuery(
    {
      query: {
        __variables: {
          page: 'Int',
          filters: '[[String]]',
        },
        allEventsSearch: {
          __args: { page: new VariableType('page'), filters: new VariableType('filters') },
          pagination,
          events: {
            storeOrderEvent,
            requestForQuoteEvent,
          },
        },
      },
    },
    { pretty: true }
  )
)

interface EventsSearchResultPayload {
  pagination: Pagination
  events: EventPayload[]
}
interface EventsSearchResult extends Omit<EventsSearchResultPayload, 'events'> {
  events: Event[]
}
interface SearchEventsData {
  allEventsSearch: EventsSearchResultPayload
}
type SearchEventsVars = SearchArgs

const useAllEvents = ({ page = 1, filters }: SearchArgs) => {
  const { loading, error, data } = useQuery<SearchEventsData, SearchEventsVars>(doc, {
    variables: {
      page,
      filters,
    },
    fetchPolicy: 'no-cache',
  })

  let searchResult: EventsSearchResult | undefined
  if (data) {
    searchResult = {
      ...data.allEventsSearch,
      events: data.allEventsSearch.events.map(EventContext.fromPayload),
    }
  }
  return { loading, error, data: searchResult }
}

useAllEvents.Query = doc
export default useAllEvents
