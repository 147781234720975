import classNames from 'classnames'
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

const Table = forwardRef<HTMLTableElement, JSX.IntrinsicElements['table']>(
  ({ children, className, ...props }, ref) => (
    <table {...props} className={classNames('w-full', className)} ref={ref}>
      {children}
    </table>
  )
)

const Thead = ({ className, ...props }: JSX.IntrinsicElements['thead']) => (
  <thead {...props} className={className} />
)

const Tr = ({ className, ...props }: JSX.IntrinsicElements['tr']) => (
  <tr {...props} className={twMerge('border-gray-200 border-b divide-x-1 text-left ', className)} />
)

const Th = ({
  className,
  ...props
}: JSX.IntrinsicElements['th'] & { wrap?: boolean; pad?: boolean }) => (
  <th
    {...props}
    className={twMerge(
      'align-middle px-3 py-2 first:pl-0 last:pr-0 h-[3.75rem] text-gray-500 font-medium',
      className
    )}
  />
)

const Tbody = ({ className, ...props }: JSX.IntrinsicElements['tbody']) => (
  <tbody {...props} className={className} />
)

const Td = ({ className, ...props }: JSX.IntrinsicElements['td']) => (
  <td
    {...props}
    className={twMerge('align-middle px-3 py-2 first:pl-0 last:pr-0 h-[3.75rem]', className)}
  />
)

export { Table, Tbody, Td, Th, Thead, Tr }
