import { useRemoveProofOfDeliveriesMutation } from '@/dealers/_gen/gql'
import GfProofOfDeliveries from '@/gf/components/ProofOfDeliveries'
import { useRfqContext } from './context'

const ProofOfDeliveries = () => {
  const { rfq, refetch } = useRfqContext()
  const [removePods] = useRemoveProofOfDeliveriesMutation()
  const { quote } = rfq

  if (!quote || quote.proofOfDeliveries.length === 0) return null

  const remove = async (pod: string) => {
    await removePods({ variables: { storeOrderId: quote.id, urls: [pod] } })
    refetch()
  }

  return (
    <GfProofOfDeliveries
      uploadPath={`/rfqs/${rfq.id}/upload-proof-of-delivery`}
      proofOfDeliveries={quote.proofOfDeliveries}
      remove={remove}
    />
  )
}

export default ProofOfDeliveries
