import { useAcceptQuoteMutation, useAcceptQuoteOrderQuery } from '@/dealers/_gen/gql'
import NextPurchaseOrderNumber from '@/dealers/components/NextPurchaseOrderNumber'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import Spinner from '@/gf/components/Spinner'
import useMsgs from '@/gf/hooks/useMsgs'
import { ModalSize } from '@/types'
import { FormEvent, useState } from 'react'

const AcceptQuoteModal = ({
  open,
  onClose,
  storeOrderId,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  storeOrderId: string
  onSuccess: () => void
}) => {
  const [_, msgs] = useMsgs()
  const { data } = useAcceptQuoteOrderQuery({ variables: { storeOrderId } })
  const [purchaseOrder, setPurchaseOrder] = useState('')
  const [acceptQuoteMutation, { loading: approving }] = useAcceptQuoteMutation()

  const storeOrder = data?.storeOrder ?? null
  const purchaseOrderRequired = storeOrder?.customer.organization.showPurchaseOrder ?? false
  const generatePurchaseOrderNumber =
    storeOrder?.customer.organization.generatePurchaseOrderNumber ?? false

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      await acceptQuoteMutation({
        variables: { storeOrderId, purchaseOrder: purchaseOrder || null },
      })

      msgs.add('Quote accepted!', 'positive')

      onSuccess()
    } catch (err) {
      console.error(err)

      msgs.addUnknownError()
    }
  }

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.SM}>
      <div className="p-6 relative">
        <CloseModalButton onClick={onClose} className="absolute right-3 top-3" />

        {!storeOrder ? (
          <Spinner />
        ) : (
          <form onSubmit={onSubmit} className="space-y-6">
            <hgroup className="space-y-2">
              <h2 className="text-2xl font-medium">Accept Quote</h2>
              <div className="space-y-2 text-base">
                <p>
                  Are you sure you would like to accept the quote on the customer&apos;s behalf?
                </p>
                <p>This will create the customer&apos;s purchase order in Gearflow.</p>
              </div>
            </hgroup>

            {!storeOrder.purchaseOrder && (
              <Field label={`Purchase Order${!purchaseOrderRequired ? ' (optional)' : ''}`}>
                {generatePurchaseOrderNumber ? (
                  <>
                    <p className="text-sm flex items-center gap-x-2">
                      <NextPurchaseOrderNumber storeOrderId={storeOrder.id} />
                      <span className="italic text-gray-500 text-xs">(Auto-Generated)</span>
                    </p>
                  </>
                ) : (
                  <TextInput
                    value={purchaseOrder}
                    onChange={(e) => setPurchaseOrder(e.target.value)}
                    placeholder="Purchase order number"
                    required={purchaseOrderRequired}
                  />
                )}
              </Field>
            )}

            <div className="flex gap-x-4">
              <Action.S onClick={onClose} className="flex-1">
                Cancel
              </Action.S>
              <Action.P type="submit" performing={approving} className="flex-1">
                Accept Quote
              </Action.P>
            </div>
          </form>
        )}
      </div>
    </Modal>
  )
}

export default AcceptQuoteModal
