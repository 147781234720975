import { v4 as uuid } from 'uuid'

import { InFilter } from '../../../types'

const build = ({
  id,
  fieldId,
  list = [],
}: {
  id?: string
  fieldId: string
  list?: string[]
}): InFilter => ({
  id: id || uuid(),
  typeId: 'in',
  fieldId,
  list,
})

const toApiFilter = (filter: InFilter) => ['in', filter.fieldId, filter.list.join(',')]

export default { build, toApiFilter }
